import { FC } from "react";
import "./_AppUsersNumber.scss";

interface AppUsersNumberProps {}

const AppUsersNumber: FC<AppUsersNumberProps> = () => {
  return (
    <div className="appssersnumberprops">
      <div className="appssersnumberprops-content container">
        <div className="appssersnumberprops-content-item">
          <h3>-</h3>
          <p>Utilisateurs</p>
        </div>
        <div className="appssersnumberprops-content-item">
          <h3>-</h3>
          <p>Clubs</p>
        </div>
        <div className="appssersnumberprops-content-item">
          <h3>-</h3>
          <p>Évènements</p>
        </div>
        <div className="appssersnumberprops-content-item">
          <h3>-</h3>
          <p>Réservations</p>
        </div>
      </div>
    </div>
  );
};

export default AppUsersNumber;

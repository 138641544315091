import { FC } from "react";
import "../_CardsSharedStyle.scss";
import "./_NightclubEventCard.scss";
import calendar from "../../../../../assets/icons/calendar.svg";
import loactionIcon from "../../../../../assets/icons/location.svg";
import { Link } from "react-router-dom";

interface NightclubEventCardProps {
  id: number;
  date: string;
  address: string;
  distance: string;
  banner: string;
  title: string;
}

const NightclubEventCard: FC<NightclubEventCardProps> = ({
  id,
  date,
  address,
  distance,
  banner,
  title,
}) => {
  const localDate = new Date(date);
  const localDateString = localDate.toLocaleDateString(undefined, {
    day: "numeric",
    month: "short",
    year: "numeric",
  });
  const localTimeString = localDate.toLocaleTimeString(undefined, {
    hour: "2-digit",
    minute: "2-digit",
  });

  return (
    <section className="nightclubeventcard cardssharedstyle">
      <Link to={`/evenings/${id}`}>
        <div className="nightclubeventcard-img cardssharedstyle-img">
          <img src={banner} alt="" />
        </div>
        <div className="nightclubeventcard-content cardssharedstyle-content">
          <div>
            <h2>{title}</h2>
            <div className="info-item">
              <img src={calendar} alt="" />
              <span className="address">
                {localDateString}, {localTimeString}
              </span>
            </div>
            <div className="info-item">
              <img className="info-item-location" src={loactionIcon} alt="" />
              {distance === (undefined || null) ? (
                <span className="address">{address}</span>
              ) : (
                <span className="address">{`${address} (${Number(
                  distance
                )?.toFixed(1)} km)`}</span>
              )}
            </div>
          </div>
        </div>
      </Link>
    </section>
  );
};

export default NightclubEventCard;

import { Link, NavLink, useNavigate } from "react-router-dom";
import logo7night from "../../../../assets/icons/DashboardMainLogo.png";
import defaultProfile from "../../../../assets/photos/defaultProfile.png";
import AttendeesAdminIcon from "../../../../assets/icons/AttendeesAdminIcon.png";
import profiledisconnect from "../../../../assets/icons/profiledisconnect.svg";
import go from "../../../../assets/icons/go.svg";
import "./_ManagerNavbar.scss";
import { useAuthContext } from "../../../../context/AuthContext/AuthContext";
import { getFullName } from "../../../../utils/getFullName";
import { useLoginRegisterResetPasswordContext } from "../../../../context/LoginRegisterResetPasswordContext/LoginRegisterResetPasswordContext";
import DashboadAdminIcon from "../../../../assets/icons/DashboadAdminIcon.webp";
import SubscriptionAdminIcon from "../../../../assets/icons/SubscriptionAdminIcon.png";
import ClubsAdminIcon from "../../../../assets/icons/ClubsAdminIcon.png";
import EventsAdminIcon from "../../../../assets/icons/EventsAdminIcon.png";
const ManagerNavbar = () => {
  const navigate = useNavigate();
  const authCtx = useAuthContext();
  const LRRPCtx = useLoginRegisterResetPasswordContext();

  return (
    <div className="managernavbar">
      <div className="managernavbar-logo">
        <img src={logo7night} alt="" />
      </div>
      <div className="managernavbar-navbar">
        <div className="managernavbar-navbar-part1">
          <NavLink to="" className="managernavbar-navbar-item" end>
            <div className="firstpart">
              <div className="firstpart-icon">
                <img className="info-icon" src={DashboadAdminIcon} alt="" />
              </div>
              <h3>Tableau de bord</h3>
            </div>
            <img className="go-icon" src={go} alt="" />
          </NavLink>
          <NavLink to="clubs" className="managernavbar-navbar-item">
            <div className="firstpart">
              <div className="firstpart-icon">
                <img className="info-icon" src={ClubsAdminIcon} alt="" />
              </div>
              <h3>Mes Clubs</h3>
            </div>
            <img className="go-icon" src={go} alt="" />
          </NavLink>
          <NavLink to="events" className="managernavbar-navbar-item">
            <div className="firstpart">
              <div className="firstpart-icon">
                <img className="info-icon" src={EventsAdminIcon} alt="" />
              </div>
              <h3>Mes évènements</h3>
            </div>
            <img className="go-icon" src={go} alt="" />
          </NavLink>
          <NavLink to="clients" className="managernavbar-navbar-item">
            <div className="firstpart">
              <div className="firstpart-icon">
                <img className="info-icon" src={AttendeesAdminIcon} alt="" />
              </div>
              <h3>Mes clients</h3>
            </div>
            <img className="go-icon" src={go} alt="" />
          </NavLink>
          <NavLink to="subscription" className="managernavbar-navbar-item">
            <div className="firstpart">
              <div className="firstpart-icon">
                <img className="info-icon" src={SubscriptionAdminIcon} alt="" />
              </div>
              <h3>Abonnements</h3>
            </div>
            <img className="go-icon" src={go} alt="" />
          </NavLink>
        </div>
        <div className="managernavbar-navbar-part2">
          <Link to="/profile">
            <div className="avatar">
              <img
                src={authCtx.user?.profilePicture || defaultProfile}
                alt="user profile"
              />
            </div>
          </Link>
          <p>{getFullName(authCtx.user?.firstName, authCtx.user?.lastName)}</p>
          <button
            className="item"
            onClick={() => {
              authCtx.logout();
              navigate("/");
              LRRPCtx.setModalshow(false);
            }}
          >
            <img className="icon" src={profiledisconnect} alt="" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ManagerNavbar;

import { ReactElement, useState } from "react";
import { Modal } from "react-bootstrap";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { useQueryClient } from "@tanstack/react-query";

const PaymentCards = (props: any): ReactElement => {
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<any>("");
  const stripe = useStripe();
  const elements = useElements();
  const queryClient = useQueryClient();
  const handleSubmit = async (event: any) => {
    setLoading(true);
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const { error } = await stripe.confirmSetup({
      elements,
      redirect: "if_required",
    });

    if (error) {
      setLoading(false);
      setError(error.message);
    } else {
      setLoading(false);
      setSuccess(true);
      props.refetch !== null && props.refetch();
      setTimeout(() => {
        queryClient.fetchQuery(["paymentCards"]);
        setSuccess(false);
        props.refetch !== null && props.onHide();
        props.onSuccessCardAdded();
        props.setPaymentLoading(true);
        props.onHide();
        setError("");
      }, 3000);
    }
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => {
        props.onHide();
        setError("");
      }}
    >
      <Modal.Body className="stripeClientPayment">
        <div className="stripeClientPayment-body">
          <form onSubmit={handleSubmit}>
            <PaymentElement />
            <button type="submit" disabled={loading}>
              Ajouter
            </button>
          </form>
          {loading && <p className="les">Envoyer...</p>}
          <p className="les">{error}</p>
          {success && (
            <p className="les">Votre carte a été ajoutée avec succès!</p>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default PaymentCards;

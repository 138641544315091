import { FC } from "react";
import locationIcon from "../../../../assets/icons/location.svg";
import music from "../../../../assets/icons/music.svg";
import ageParticipant from "../../../../assets/icons/ageParticipant.svg";
import palma from "../../../../assets/icons/palma.svg";
import restaurantIcon from "../../../../assets/icons/restaurant.svg";
import "./_NightclubBasicinfo.scss";
import OverlayTrigger from "react-bootstrap/esm/OverlayTrigger";
import { Popover } from "react-bootstrap";

interface NightclubBasicinfoProps {
  location?: string;
  minAge?: number;
  maxAge?: number;
  patio?: boolean;
  musicTypes?: {
    id: number;
    title: string;
    insertedDate: string;
  }[];
  restaurant?: boolean;
}

const NightclubBasicinfo: FC<NightclubBasicinfoProps> = ({
  location,
  minAge,
  maxAge,
  patio,
  musicTypes,
  restaurant,
}) => {
  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        {musicTypes?.slice(2)?.map((type, index) => {
          return <p>{`${type.title}`}</p>;
        })}
      </Popover.Body>
    </Popover>
  );
  return (
    <div className="nightclubasicsinfo">
      <div className="item">
        <img src={locationIcon} alt="" />
        <p>{location}</p>
      </div>
      {musicTypes !== undefined && musicTypes.length !== 0 ? (
        <div className="item">
          <img src={music} alt="" />
          <p>
            <span>{musicTypes[0]?.title}</span>
            {musicTypes?.length > 1 && <span>, </span>}
            <span>{musicTypes[1]?.title} </span>
            {musicTypes?.length > 2 && <span>...</span>}
          </p>
          {musicTypes?.length! > 2 ? (
            <p>
              <OverlayTrigger
                trigger="click"
                placement="bottom"
                overlay={popover}
              >
                <button className="popover-btn">{`+${
                  musicTypes?.length! - 2
                } autes`}</button>
              </OverlayTrigger>
            </p>
          ) : null}
        </div>
      ) : null}
      {minAge !== null && maxAge !== null && (
        <div className="item">
          <img src={ageParticipant} alt="" />
          <p>{`Âge moyen: ${minAge}-${maxAge} ans`}</p>
        </div>
      )}
      <div className="item">
        <img src={palma} alt="" />
        {patio ? <p>Avec patio</p> : <p>Sans patio</p>}
      </div>
      <div className="item">
        <img src={restaurantIcon} alt="" />
        {restaurant ? <p>A un restaurant</p> : <p>Sans restaurant</p>}
      </div>
    </div>
  );
};

export default NightclubBasicinfo;

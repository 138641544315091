import {FC} from 'react';
import reservationImage from '../../../../assets/photos/reservationImage.png';
import './_Reservation.scss';
import '../_ClubSpaceWhy7NightSharedStyle.scss';

interface ReservationProps {
}

 const Reservation: FC<ReservationProps>=()=> {
  return (
    <div className='reservation container'>
      <div className='reservation-section1'>
       <img src={reservationImage} alt="" />
      </div>
      <div className='reservation-section2  clubspacewhy7nightsharedstyle'>
        <h3>Avec la réservation 24h/24 et 7j/7, aucune réservation ne vous échappera</h3>
        <p>Certains clients veulent surement réserver une table dans votre club lorsque vous n’avez pas le temps de répondre au téléphone ou lorsque que votre établissement est fermé. En utilisant notre système de réservation fonctionnant 24h/24 et 7j/7, vous vous adaptez aux besoins de vos clients et obtenez ainsi davantage de réservations.
        </p>
        <ul>
          <li>Vos clients peuvent réserver <span> 24 heures sur 24 et 7 jours sur 7 </span>, à l’aide de leur appareil préféré <span>(téléphone, ordinateur, tablette).</span></li>
          <li>Le moteur de réservation vérifie <span>automatiquement votre disponibilité </span>et confirme la réservation aux clients.</li>
          <li>Vous <span>gagnez du temps</span> dans le traitement des appels reçus pendant vos horaires d’ouverture.</li>
        </ul>
      </div>
    </div>
  );
}

export default Reservation;
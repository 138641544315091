import { ChangeEvent, ReactElement, useEffect, useState } from "react";
import searchIcon from "../../../assets/icons/searchIconOff.svg";
import queryString from "query-string";
import { EventParticipantInfo2 } from "../../components/Molecules/EventParticipantInfo2/EventParticipantInfo2";
import exportIcon from "../../../assets/icons/export.png";
import bell from "../../../assets/icons/bellIcon.svg";
import SendNotificationParticipants from "../../modals/SendNotificationParticipants";
import { useLocation, useNavigate } from "react-router-dom";
import { useDebounceValue } from "../../../hooks/useDebounceValue";
import {
  attendeStatuses,
  userGenders,
  usersAgeFrom,
} from "../../../static/user.filter";
import Select, { SingleValue } from "react-select";
import { useEventNames } from "../../../react-query/hooks/Events";
import { getFullName } from "../../../utils/getFullName";
import { CSVLink } from "react-csv";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import { EventParticipantDetailsModel } from "../../../interfaces/models/getModels/EventParticipantDetails.model";
import { useEventParticipantsQuery } from "../../../react-query/hooks/useEventParticipantDetails";
import { NoResults } from "../../components/Organisms/NoResults/NoResults";
import { useSubscriptionStatus } from "../../../react-query/hooks/Subscription";
import { useAuthContext } from "../../../context/AuthContext/AuthContext";
import MuiPagination from "../../../components/MuiPagination/MuiPagination";

const EventParticipants = (): ReactElement => {
  /**
   * local state
   */
  const [searchWord, setSearchWord] = useState<string | null>(null);
  const [eventSearchWord, setEventSearchWord] = useState<string | null>(null);
  const [selectedParticipants, setSelectedParticipants] = useState<
    {
      reservationId: number;
      isSelected: boolean;
    }[]
  >([]);
  const authCtx = useAuthContext();
  const [minAge, setMinAge] = useState<number>(16);
  const [page, setPage] = useState(1);
  const [eventParticipants, setEventParticipants] = useState<
    EventParticipantDetailsModel[] | undefined
  >(undefined);
  const [isSendNotificationModalShown, setShowSendNotificationModal] =
    useState(false);

  /**
   * hooks
   */
  const navigate = useNavigate();
  const { search } = useLocation();

  /**
   * query string
   */
  const queryParams = queryString.parse(search);
  const stringifiedQueryParams = queryString.stringify(queryParams);

  // react-query
  const {
    data: { data: eventParticipantsData, metaData } = {},
    isLoading,
    refetch,
  } = useEventParticipantsQuery(stringifiedQueryParams);
  const { data: eventNames = [], refetch: refetchEventNames } = useEventNames(
    eventSearchWord || ""
  );
  const { data: { data: SubscriptionData } = {} } = useSubscriptionStatus();
  /**
   * hooks
   */
  const debouncedValue = useDebounceValue(searchWord as string);

  /**
   * functions
   */
  const handleNavigateWithParams = (params?: Object) =>
    navigate(
      {
        search: `${queryString.stringify({
          ...queryParams,
          ...params,
        })}`,
      },
      { replace: true }
    );

  /**
   * effects
   */
  useEffect(() => {
    if (eventParticipantsData) {
      if (selectedParticipants.length === 0)
        setEventParticipants(eventParticipantsData);
      else
        setEventParticipants(
          eventParticipantsData?.map((eventParticipant) => {
            const isParticipantSelected = selectedParticipants.find(
              (participant) =>
                participant.reservationId === eventParticipant.reservationId
            );

            if (isParticipantSelected)
              return { ...eventParticipant, isSelected: true };
            return { ...eventParticipant };
          })
        );
    }
  }, [eventParticipantsData, selectedParticipants]);
  const usersAgeTo = Array.from(
    { length: 80 - minAge },
    (_, i) => i + minAge + 1
  );
  useEffect(() => {
    refetch();
  }, [stringifiedQueryParams, refetch]);

  useEffect(() => {
    if (debouncedValue === "") delete queryParams.searchWord;
    handleNavigateWithParams(debouncedValue && { searchWord: debouncedValue });
  }, [debouncedValue]);

  useEffect(() => {
    handleNavigateWithParams({
      pageNumber: page,
    });
  }, [page]);

  useEffect(() => {
    refetchEventNames();
  }, [eventSearchWord]);

  /**
   * query params
   */
  const handleChangeSearchWord = (event: ChangeEvent<HTMLInputElement>) => {
    const search = event.currentTarget.value;
    setSearchWord(search);
  };
  const handleEventChange = (
    newValue: SingleValue<{ value: number; label: string }>
  ) => {
    if (newValue?.value) handleNavigateWithParams({ eventId: newValue.value });
    else {
      delete queryParams.eventId;
      handleNavigateWithParams();
    }
  };

  const handleSelect =
    (field: string) => (event: ChangeEvent<HTMLSelectElement>) => {
      const selectedValue = event.currentTarget.value;

      // removes fields from url params if select has the default value
      if (
        selectedValue === "AgeFrom" ||
        selectedValue === "AgeTo" ||
        selectedValue === "Genre" ||
        selectedValue === "Statut"
      ) {
        delete queryParams[field];
        handleNavigateWithParams();
      } else handleNavigateWithParams({ [field]: selectedValue });
      if (field === "AgeFrom") {
        setMinAge(+selectedValue);
      }
    };

  const handleSelectEventParticipant = (id: number, selected: boolean) => {
    if (!selected)
      setSelectedParticipants((previousParticipants) => [
        ...previousParticipants.filter(
          (participant) => participant.reservationId !== id
        ),
      ]);
    else
      setSelectedParticipants((previousParticipants) => [
        ...previousParticipants,
        { reservationId: id, isSelected: selected },
      ]);
  };

  const handleToggleSendNotificationModal = (toggled: boolean) => () =>
    setShowSendNotificationModal(toggled);

  const handleSendNotification = () => setSelectedParticipants([]);

  // contants
  const csvData = [
    [
      "USER",
      "EMAIL",
      "AGE",
      "GENDER",
      "NIGHTCLUB",
      "EVENT",
      "RESTAURANT PARTICIPANTS",
      "RESTAURANT ARRIVALTIME",
      "EVENT PARTICIPANTS",
      "EVENT ARRIVALTIME",
      "EVENT TABLE",
      "STATUS",
    ],
    ...(eventParticipants?.map(
      ({
        firstName,
        lastName,
        email,
        age,
        gender,
        clubName,
        eventTitle,
        restaurantParticipantNumber,
        restaurantArrivalTime,
        eventReservationParticipants,
        eventArrivalTime,
        tableName,
        reservationStatus,
      }) => {
        return [
          `${getFullName(firstName, lastName)}`,
          email,
          age,
          gender,
          clubName,
          eventTitle,
          restaurantParticipantNumber || 0,
          restaurantArrivalTime,
          eventReservationParticipants || 0,
          eventArrivalTime,
          tableName,
          reservationStatus,
        ];
      }
    ) || []),
  ];

  const reservationIds =
    selectedParticipants.map(
      (eventParticipant) => eventParticipant.reservationId
    ) || [];

  if (isLoading) return <LoadingSpinner />;

  return (
    <div className="eventparticipants">
      <div className="eventparticipants-title dashboardTitle">
        <h3>Mes clients</h3>
        <span>{metaData?.totalItemCount || 0}</span>
      </div>
      <div className="eventparticipants-filter dashboardFilter">
        <div className="eventparticipants-filter-1 searchInput">
          <img src={searchIcon} alt="" />
          <input
            type="text"
            defaultValue={queryParams?.searchWord as string}
            placeholder="Rechercher"
            onChange={handleChangeSearchWord}
          />
        </div>
        <div className="eventparticipants-filter-2">
          <Select
            className="searchSelect"
            options={eventNames?.map(({ id, label }) => ({
              value: id,
              label,
            }))}
            onInputChange={(e) => {
              setEventSearchWord(e);
            }}
            onChange={handleEventChange}
            isClearable={true}
            placeholder="Sélectionner"
          />
        </div>
        <div className="eventparticipants-filter-3 dropdownInput">
          <select
            defaultValue={queryParams.genderId as string}
            onChange={handleSelect("genderId")}
          >
            <option className="option">Genre</option>
            {userGenders.map(({ id, title }) => (
              <option key={id} value={id}>
                {title}
              </option>
            ))}
          </select>
        </div>
        <div className="eventparticipants-filter-4 dropdownInput">
          <select
            defaultValue={queryParams.AgeFrom as string}
            onChange={handleSelect("AgeFrom")}
          >
            <option className="option" hidden selected>
              Min Âge
            </option>
            {usersAgeFrom.map((age: number) => (
              <option key={age} value={age}>
                {age}
              </option>
            ))}
          </select>
        </div>
        <div className="eventparticipants-filter-5 dropdownInput">
          <select
            disabled={!("AgeFrom" in queryParams)}
            defaultValue={queryParams.AgeTo as string}
            onChange={handleSelect("AgeTo")}
          >
            <option className="option" hidden selected>
              Max Âge
            </option>
            {usersAgeTo.map((age: number) => (
              <option key={age} value={age}>
                {age}
              </option>
            ))}
          </select>
        </div>
        <div className="eventparticipants-filter-6 dropdownInput">
          <select
            defaultValue={queryParams.status as string}
            onChange={handleSelect("status")}
          >
            <option className="option">Statut</option>
            {attendeStatuses.map(({ id, title }) => (
              <option key={id} value={id}>
                {title}
              </option>
            ))}
          </select>
        </div>

        {eventParticipants && eventParticipants.length > 0 && (
          <div className="eventparticipants-filter-7 w-100 justify-content-end my-2">
            {authCtx?.decoded?.role === "Admin" ? (
              <div className="button">
                <button
                  className="button-withIcon button1"
                  onClick={handleToggleSendNotificationModal(true)}
                >
                  <img src={bell} alt="" />
                  <p>Notifier</p>
                </button>
              </div>
            ) : (
              SubscriptionData?.name === "Pro" && (
                <div className="button">
                  <button
                    className="button-withIcon button1"
                    onClick={handleToggleSendNotificationModal(true)}
                  >
                    <img src={bell} alt="" />
                    <p>Notifier</p>
                  </button>
                </div>
              )
            )}
            <div className="button">
              <CSVLink
                data={csvData}
                className="tw-no-underline"
                filename="list-of-attendees"
              >
                <button className="button-withIcon button2">
                  <img src={exportIcon} alt="" />
                  <p>Exporter</p>
                </button>
              </CSVLink>
            </div>
          </div>
        )}
      </div>

      {eventParticipants && eventParticipants.length > 0 ? (
        <>
          <div className="dashboardResults">
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th>UTILISATEUR</th>
                  <th>ÉVÈNEMENT ET BOÎTE DE NUIT</th>
                  <th>RESTAURANT</th>
                  <th>ÉVÈNEMENT</th>
                  <th>STATUT</th>
                  <th className="lastElement actionColumn">ACTION</th>
                </tr>
              </thead>

              <tbody>
                {eventParticipants.map((eventParticipant) => (
                  <EventParticipantInfo2
                    key={eventParticipant.reservationId}
                    participant={eventParticipant}
                    onSelectParticipant={handleSelectEventParticipant}
                  />
                ))}
              </tbody>
            </table>
            <div className="dashboardPagination">
              <MuiPagination
                pagesNumber={metaData?.pageCount!}
                page={page}
                setPage={setPage}
              />
            </div>
          </div>
        </>
      ) : (
        <NoResults text="Vous retrouverez ici tous les clients qui ont réservé ou qui ont été géolocalisé à votre évènement" />
      )}

      <SendNotificationParticipants
        reservationIds={reservationIds}
        show={isSendNotificationModalShown}
        onSendNotification={handleSendNotification}
        onHide={handleToggleSendNotificationModal(false)}
      />
    </div>
  );
};

export default EventParticipants;

import './_SkeletonDjCard.scss';

const SkeletonDjCard = () => {
    return (
        <section className='skeletondjcard'>
          <div className='skeletondjcard-img'>
            <div className="img"></div>         
          </div>
          <div className='skeletondjcard-content'>
              <div className="h2"></div>
              <div className='info-item'> 
                <div className="img"></div>
                <span className='address'></span>
              </div>
          </div>
        </section>
    )
}

export default SkeletonDjCard;
import { ChangeEvent, ReactElement, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import queryString from "query-string";
import searchIcon from "../../../assets/icons/searchIconOff.svg";
import ClientInfo from "../../components/Molecules/ClientInfo/ClientInfo";
import bell from "../../../assets/icons/bellIcon.svg";
import exportIcon from "../../../assets/icons/export.png";
import SendNotificationUsers from "../../modals/SendNotificationUsers";
import { useUsersManagerQuery } from "../../../react-query/hooks/useUserManager.query";
import { UserModel } from "../../../interfaces/models/getModels/User.model";
import { getFullName } from "../../../utils/getFullName";
import { useDebounceValue } from "../../../hooks/useDebounceValue";
import {
  userGenders,
  usersAgeFrom,
  userStatuses,
} from "../../../static/user.filter";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import { NoResults } from "../../components/Organisms/NoResults/NoResults";
import { useSubscriptionStatus } from "../../../react-query/hooks/Subscription";
import { useAuthContext } from "../../../context/AuthContext/AuthContext";
import MuiPagination from "../../../components/MuiPagination/MuiPagination";

const AdminUsers = (): ReactElement => {
  /**
   * local state
   */
  const [searchParams, setSearchParams] = useState<{
    searchWord?: string;
  } | null>(null);
  const [selectedUsers, setSelectedUsers] = useState<
    {
      userId: string;
      isSelected: boolean;
    }[]
  >([]);
  const [users, setUsers] = useState<UserModel[] | undefined>(undefined);
  const [minAge, setMinAge] = useState<number>(16);
  const [isSendNotificationModalShown, setShowSendNotificationModal] =
    useState(false);
  const authCtx = useAuthContext();
  const [page, setPage] = useState(1);
  const { data: { data: SubscriptionData } = {} } = useSubscriptionStatus();
  /**
   * hooks
   */
  const navigate = useNavigate();
  const { search } = useLocation();

  /**
   * query string
   */
  const queryParams = queryString.parse(search);
  const stringifiedQueryParams = queryString.stringify(queryParams);
  /**
   * react query
   */
  const {
    data: { data: usersData, metaData } = {},
    isLoading,
    refetch,
  } = useUsersManagerQuery(stringifiedQueryParams);

  /**
   * hooks
   */
  const debouncedValue = useDebounceValue(searchParams?.searchWord as string);

  /**
   * functions
   */
  const handleNavigateWithParams = (params?: Object) =>
    navigate(
      {
        search: `${queryString.stringify({
          ...queryParams,
          ...params,
        })}`,
      },
      { replace: true }
    );

  const usersAgeTo = Array.from(
    { length: 80 - minAge },
    (_, i) => i + minAge + 1
  );
  /**
   * effects
   */
  useEffect(() => {
    if (usersData) {
      if (selectedUsers.length === 0) setUsers(usersData);
      else
        setUsers(
          usersData?.map((user) => {
            const isUserSelected = selectedUsers.find(
              (selectedUser) => selectedUser.userId === user.id
            );

            if (isUserSelected) return { ...user, isSelected: true };
            return { ...user };
          })
        );
    }
  }, [usersData, selectedUsers]);

  useEffect(() => {
    refetch();
  }, [stringifiedQueryParams, refetch]);

  useEffect(() => {
    if (debouncedValue === "") delete queryParams.searchWord;
    handleNavigateWithParams(debouncedValue && { searchWord: debouncedValue });
  }, [debouncedValue]);

  useEffect(() => {
    handleNavigateWithParams({
      pageNumber: page,
    });
  }, [page]);

  /**
   * query params
   */
  const handleChangeSearchWord = (event: ChangeEvent<HTMLInputElement>) => {
    const search = event.currentTarget.value;
    setSearchParams({ searchWord: search });
  };
  const handleSelect =
    (field: string) => (event: ChangeEvent<HTMLSelectElement>) => {
      const selectedValue = event.currentTarget.value;
      // removes fields from url params if select has the default value
      if (
        selectedValue === "AgeFrom" ||
        selectedValue === "AgeTo" ||
        selectedValue === "Gender" ||
        selectedValue === "Status"
      ) {
        delete queryParams[field];
        handleNavigateWithParams();
      } else handleNavigateWithParams({ [field]: selectedValue });
      if (field === "AgeFrom") {
        setMinAge(+selectedValue);
      }
    };

  const handleSelectUser = (id: string, selected: boolean) => {
    if (!selected)
      setSelectedUsers((previousSelectedUsers) => [
        ...previousSelectedUsers.filter(
          (selectedUser) => selectedUser.userId !== id
        ),
      ]);
    else
      setSelectedUsers((previousSelectedUsers) => [
        ...previousSelectedUsers,
        { userId: id, isSelected: selected },
      ]);
  };

  const handleToggleSendNotificationModal = (toggled: boolean) => () =>
    setShowSendNotificationModal(toggled);

  const handleSendNotification = () => setSelectedUsers([]);

  // contants
  const csvData = [
    ["USER", "EMAIL", "GENDER", "AGE", "STATUS"],
    ...(users?.map(({ firstName, lastName, email, gender, age, isBlocked }) => {
      return [
        `${getFullName(firstName, lastName)}`,
        email,
        gender,
        age,
        isBlocked ? "Blocked" : "Active",
      ];
    }) || []),
  ];

  const receivers =
    selectedUsers.map((selectedUser) => selectedUser.userId) || [];

  if (isLoading) return <LoadingSpinner />;

  return (
    <div className="adminusers">
      <div className="dashboardTitle">
        <h3>Liste des utilisateurs</h3>
      </div>
      <div className="adminusers-filter dashboardFilter">
        <div className="adminusers-filter-1 searchInput">
          <img src={searchIcon} alt="" />
          <input
            type="text"
            defaultValue={queryParams?.searchWord as string}
            placeholder="Rechercher"
            onChange={handleChangeSearchWord}
          />
        </div>
        <div className="adminusers-filter-2 dropdownInput">
          <select
            defaultValue={queryParams.genderId as string}
            onChange={handleSelect("genderId")}
          >
            <option className="option">Genre</option>
            {userGenders.map(({ id, title }) => (
              <option key={id} value={id}>
                {title}
              </option>
            ))}
          </select>
        </div>
        <div className="adminusers-filter-3 dropdownInput">
          <select
            defaultValue={queryParams.AgeFrom as string}
            onChange={handleSelect("AgeFrom")}
          >
            <option className="option" selected hidden>
              Min Âge
            </option>
            {usersAgeFrom.map((age: number) => (
              <option key={age} value={age}>
                {age}
              </option>
            ))}
          </select>
        </div>
        <div className="adminusers-filter-4 dropdownInput">
          <select
            disabled={!("AgeFrom" in queryParams)}
            defaultValue={queryParams.AgeTo as string}
            onChange={handleSelect("AgeTo")}
          >
            <option className="option" hidden selected>
              Max Âge
            </option>
            {usersAgeTo.map((age: number) => (
              <option key={age} value={age}>
                {age}
              </option>
            ))}
          </select>
        </div>
        <div className="adminusers-filter-5 dropdownInput">
          <select
            defaultValue={queryParams.status as string}
            onChange={handleSelect("status")}
          >
            <option className="option">Status</option>
            {userStatuses.map(({ id, title }) => (
              <option key={id} value={id}>
                {title}
              </option>
            ))}
          </select>
        </div>

        {users && users.length > 0 && (
          <div className="adminusers-filter-6 my-2">
            {authCtx?.decoded?.role === "Admin" ? (
              <div className="button">
                <button
                  className="button-withIcon button1"
                  onClick={handleToggleSendNotificationModal(true)}
                >
                  <img src={bell} alt="" />
                  <p>Notifier</p>
                </button>
              </div>
            ) : (
              SubscriptionData?.name === "Pro" && (
                <div className="button">
                  <button
                    className="button-withIcon button1"
                    onClick={handleToggleSendNotificationModal(true)}
                  >
                    <img src={bell} alt="" />
                    <p>Notifier</p>
                  </button>
                </div>
              )
            )}
            <div className="adminusers-filter-7">
              <CSVLink
                data={csvData}
                className="tw-no-underline button"
                filename="list-of-users"
              >
                <button className="button-withIcon button2">
                  <img src={exportIcon} alt="" />
                  <p>Exporter</p>
                </button>
              </CSVLink>
            </div>
          </div>
        )}
      </div>
      {users && users.length > 0 ? (
        <div className="dashboardResults">
          <table>
            <thead>
              <tr>
                <th></th>
                <th>UTILISATEUR</th>
                <th>GENRE</th>
                <th>ÂGE</th>
                <th>STATUT</th>
                <th className="lastElement actionColumn">ACTION</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <ClientInfo
                  key={user.id}
                  user={user}
                  onSelectUser={handleSelectUser}
                />
              ))}
            </tbody>
          </table>
          <div className="dashboardPagination">
            <MuiPagination
              pagesNumber={metaData?.pageCount!}
              page={page}
              setPage={setPage}
            />
          </div>
        </div>
      ) : (
        <NoResults />
      )}

      <SendNotificationUsers
        receivers={receivers}
        show={isSendNotificationModalShown}
        onSendNotification={handleSendNotification}
        onHide={handleToggleSendNotificationModal(false)}
      />
    </div>
  );
};
export default AdminUsers;

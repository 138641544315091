import DJsearchstar from "../../../assets/icons/DJsearchstar.png";
import DJsSection from "../../page-sections/DJs/DJsSection/DJsSection";
import AppUsersNumber from "../../page-sections/shared/AppUsersNumber/AppUsersNumber";
import DownloadApp from "../../page-sections/shared/DownloadApp/DownloadApp";
import "./_DJs.scss";
import "./_DJsSearchBar.scss";
import { useState } from "react";

const DJs = () => {
  const [inputWord, setInputWord] = useState<string>("");
  const [word, setWord] = useState<string>("");

  return (
    <div className="djs">
      <div className="djs-searchbar ">
        <div className="djsearchbar">
          <div className="djsearchbar-mainPhoto"></div>
          <h1>
            Réserve à l’évènement où se trouve tes <span>Célébrités</span> et{" "}
            <span>DJs</span> préférés !
          </h1>
          <form
            className="djsearchbar-input container-searchbar"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div className="djsearchbar-input-section1">
              <img src={DJsearchstar} alt="" />
              <div className="djsearchbar-input-section1-part2">
                <h5>Célébrité / DJ</h5>
                <input
                  type="text"
                  placeholder="Rechercher"
                  onChange={(e) => setInputWord(e.target.value)}
                  value={inputWord}
                />
              </div>
            </div>
            <button onClick={() => setWord(inputWord)}>Rechercher</button>
          </form>
        </div>
      </div>
      <div className="djs-results">
        <div className="container-dj">
          <DJsSection inputWord={word} />
        </div>
      </div>
      <div className="djs-appusernumber">
        <AppUsersNumber />
      </div>
      <div className="djs-downloadapp">
        <DownloadApp />
      </div>
    </div>
  );
};

export default DJs;

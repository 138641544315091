import { FC, useMemo } from "react";
import { GoogleMap, useJsApiLoader, MarkerF } from "@react-google-maps/api";
import "./_GoogleMapComponent.scss";
import { GOOGLE_MAPS_API_KEY } from "../../config/app";

interface GoogleMapComponentProps {
  marksObject: {
    lat: number;
    lng: number;
  }[];
}

const GoogleMapComponent: FC<GoogleMapComponentProps> = ({ marksObject }) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: `${GOOGLE_MAPS_API_KEY}`,
  });
  const center = useMemo(() => ({ lat: 47.78578, lng: 2.724488 }), []);
  return (
    <>
      {!isLoaded && <div>Loading Google Map...</div>}
      <GoogleMap zoom={6} center={center} mapContainerClassName="googlemap">
        {marksObject?.map((map, index) => {
          return <MarkerF key={index} position={map} />;
        })}
      </GoogleMap>
    </>
  );
};

export default GoogleMapComponent;

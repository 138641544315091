import axios from "axios";
import { API_URL } from "../config/app";
import { readFromStorage } from "../utils/webStorage";

const instance = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// interceptors
instance.interceptors.request.use(
  (config) => {
    const authToken = readFromStorage("Token");
    if (authToken)
      instance.defaults.headers.common["Authorization"] = `Bearer ${authToken}`;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;

import Rating from "@mui/material/Rating";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { djReview, getDjReviews } from "../../../../api/Dj";
import pen from "../../../../assets/icons/pen.svg";
import AddReview from "../../../modals/AddReview/AddReview";
import "./_Reviews.scss";

interface ReviewsProps {
  id?: number;
  rating?: number;
  numberOfRatings?: number;
  name?: string;
  djReview: boolean;
  clubReview: boolean;
}

const DjReviews: FC<ReviewsProps> = ({
  rating,
  numberOfRatings,
  id,
  name,
  djReview,
  clubReview,
}) => {
  const [modalShow, setModalShow] = useState(false);
  const [reviews, setReviews] = useState<djReview[]>([]);
  const [zeroItem, setZeroItem] = useState<number>();
  const [loading, setLoading] = useState<boolean>(true);
  const [hasMore, setHasMore] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [rereder, setReRender] = useState<boolean>(false);
  const observer = useRef<IntersectionObserver | null>(null);
  const lastItemElementRef = useCallback(
    (node: any) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const getAllDjReviews = async () => {
    try {
      const response: any = await getDjReviews({
        id: id,
        pageNumber: pageNumber,
      });
      !rereder && setReviews([...reviews, ...response?.data?.data]);
      rereder && setReviews(response?.data?.data);
      setZeroItem(response?.data?.metaData?.totalItemCount);
      setHasMore(response?.data?.metaData?.hasNextPage);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getAllDjReviews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rereder === true]);

  return (
    <div className="reviews">
      <div className="reviews-section1">
        <div className="reviews-section1-rated">
          <h4>{Number(rating?.toFixed(1))}</h4>
          <Rating
            name="read-only"
            className="mui-stars"
            value={Number(rating)}
            readOnly
            precision={0.5}
          />
          <span className="totalRating">{`${numberOfRatings} avis totaux`}</span>
        </div>
        <div className="reviews-section1-rate">
          <button onClick={() => setModalShow(true)}>
            <img src={pen} alt="" />
            <h3>Laissez un avis</h3>
          </button>
        </div>
      </div>
      <div className="reviews-section2">
        {zeroItem === 0 ? (
          <div>No Review</div>
        ) : (
          <div>
            {reviews?.map((comment) => (
              <div
                className="reviews-section2-comment"
                key={comment.djReviewId}
                ref={lastItemElementRef}
              >
                <div className="reviews-section2-comment-info">
                  <div className="photoFistLetter">
                    <p>{comment.firstName[0].toUpperCase()}</p>
                  </div>
                  <div className="info">
                    <h2>{comment.firstName}</h2>
                    <div className="info-rating">
                      <Rating
                        name="read-only"
                        className="mui-stars"
                        value={comment?.rating}
                        readOnly
                        precision={0.5}
                      />
                    </div>
                    <p className="review">{comment.comment}</p>
                    {comment.comment.length < 1 && (
                      <p className="review">Aucun commentaire</p>
                    )}
                  </div>
                </div>
              </div>
            ))}
            {hasMore && <p>Chargement...</p>}
          </div>
        )}
      </div>
      <AddReview
        show={modalShow}
        onHide={() => setModalShow(false)}
        id={id}
        name={name}
        djReview={djReview}
        clubReview={clubReview}
        setReRender={setReRender}
      />
    </div>
  );
};

export default DjReviews;

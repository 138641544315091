import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "@tanstack/react-query";
import { BasicResponse } from "../../interfaces/mainModels/BasicResponse.model";
import { PaymentMethodResponseModel } from "../../interfaces/models/getModels/PaymentMethodResponse.model";
import { EditPaymentMethodPostModel } from "../../interfaces/models/postModels/EditPaymentPost.model";
import {
  changeDefaultPaymentMethod,
  removePaymentMethod,
} from "../mutations/paymentProfile";
import { getAllPaymentMethods } from "../queries/paymentProfile";

export const useGetAllPaymentMethods = (): UseQueryResult<
  BasicResponse<PaymentMethodResponseModel[]>
> => useQuery(["getAllPaymentMethod"], () => getAllPaymentMethods());

export const useRemovePaymentMethod = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ["removePaymentMethod"],
    (data: EditPaymentMethodPostModel) => removePaymentMethod(data),
    {
      onSuccess: () => queryClient.invalidateQueries(["getAllPaymentMethod"]),
    }
  );
};

export const useChangeDefaultPaymentMethod = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ["changePaymentMethod"],
    (data: EditPaymentMethodPostModel) => changeDefaultPaymentMethod(data),
    {
      onSuccess: () => queryClient.invalidateQueries(["getAllPaymentMethod"]),
    }
  );
};

import { FC, ReactElement, useState } from "react";
import { ButtonBase } from "@mui/material";
import edit from "../../../../assets/icons/edit.png";
import EditPackage from "../../../modals/EditPackage";
import { PackConfigurationProps } from "./PackConfigurationInfo.props";
import { currencyFormat } from "../../../../utils/formaters/currency.format";

const PackConfigurationInfo: FC<PackConfigurationProps> = ({
  configPackage,
}): ReactElement => {
  // local state
  const [modalShow, setModalShow] = useState(false);

  return (
    <tr>
      <td>
        <p>{configPackage.name}</p>
      </td>
      <td>
        <p>{configPackage.likes}</p>
      </td>
      <td>
        <p>{configPackage.winks}</p>
      </td>
      <td>
        <p>{configPackage.drinks}</p>
      </td>
      <td>
        <p>{currencyFormat(configPackage.price)}</p>
      </td>
      <td className="actionColumn">
        <ButtonBase onClick={() => setModalShow(true)}>
          <img src={edit} alt="" />
        </ButtonBase>
      </td>

      <EditPackage
        configPackage={configPackage}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </tr>
  );
};
export default PackConfigurationInfo;

import { FC, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { BusinessBasicInfo, getBusiness } from "../../../../api/Api";
import { useAuthContext } from "../../../../context/AuthContext/AuthContext";
import ClubCard from "../../../components/shared/Cards/ClubCard/ClubCard";
import SkeletonClubCard from "../../../components/shared/Cards/SkeletonClubCard/SkeletonClubCard";
import NoSuggestedClubEvent from "../../shared/NoSuggestedClubEvent";
import "./_HomeClubsSuggestion.scss";

interface HomeClubsSuggestionProps {
  longitude?: number;
  latitude?: number;
}

const HomeClubsSuggestion: FC<HomeClubsSuggestionProps> = ({
  longitude,
  latitude,
}) => {
  const [clubsData, setClubsData] = useState<BusinessBasicInfo[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const authCtx = useAuthContext();
  const [location, setLocation] = useState<boolean>(false);
  const getBusinessEventData = async () => {
    try {
      const businessResponse = await getBusiness(
        {
          longitude: longitude,
          latitude: latitude,
          radius: 10,
        },
        authCtx?.location
      );
      setClubsData(businessResponse?.data?.data);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getBusinessEventData();
    setLocation(longitude !== undefined && latitude !== undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [longitude, latitude]);
  const showSkeleton = (n: number) => {
    return [...Array(n)].map((e, i) => (
      <div className="homeclubssuggestion-section2-item" key={`${2000 + i}`}>
        <SkeletonClubCard />
      </div>
    ));
  };
  const sortedClubsEvents = clubsData?.sort(function compareNumber(
    a: BusinessBasicInfo,
    b: BusinessBasicInfo
  ) {
    return b.averageRating - a.averageRating;
  });
  return (
    <div className="homeclubssuggestion">
      <div className="homeclubssuggestion-section1 container-home">
        <div className="homeclubssuggestion-section1-part1">
          <h2>Nos suggestions du moment</h2>
        </div>
        <NavLink to="/evenings">VOIR PLUS</NavLink>
      </div>
      <h3 className="container-home nameClubs">Clubs</h3>
      {location && clubsData?.length !== 0 ? (
        <div className="homeclubssuggestion-section2 container-home">
          {sortedClubsEvents?.slice(0, 3)?.map((business) => (
            <div
              className="homeclubssuggestion-section2-item"
              key={business.id}
            >
              <ClubCard
                id={business?.id}
                photo={business?.banner}
                name={business?.name}
                averageRating={business?.averageRating}
                address={business?.address}
                city={business?.city}
                distance={business?.distance}
                isOpened={business?.isOpened}
              />
            </div>
          ))}
          {loading && showSkeleton(3)}
        </div>
      ) : (
        <NoSuggestedClubEvent
          location={location}
          noItemInThatRatio={clubsData?.length === 0}
        />
      )}
    </div>
  );
};

export default HomeClubsSuggestion;

import * as Yup from "yup";
import { contact, ContactInput } from "../../../api/Api";
import ContactLogo from "../../../assets/photos/ContactLogo.jpg";
import "./_Contact.scss";
import { useState } from "react";
import successIcon from "../../../assets/icons/successIcon.png";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const schema = Yup.object().shape({
  name: Yup.string().required("Obligatoire"),
  body: Yup.string().required("Obligatoire"),
  email: Yup.string().email("Format d'email invalide").required("Obligatoire"),
});

const Contact = () => {
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<any>();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<ContactInput>({
    resolver: yupResolver(schema),
  });

  const handleContact = async (ContactInputs: ContactInput) => {
    try {
      const response: any = await contact(ContactInputs);
      setSuccess(response.isSuccess);
      delay();
    } catch (e: any) {
      setError(e.response?.data?.errors[0]?.message);
    }
  };

  const delay = () => {
    setTimeout(() => {
      setSuccess(false);
      reset();
    }, 3000);
  };
  return (
    <div className="contact">
      <div className="contact-content container-contact">
        <img src={ContactLogo} alt="" />
        <h2>Nous contacter</h2>
        <p>Vous ne trouvez pas ce que vous cherchez ? Contactez-nous !</p>
        <form onSubmit={handleSubmit(handleContact)}>
          <div className="inputs">
            <input
              type="text"
              id="name"
              placeholder="Nom"
              {...register("name")}
            />
            {errors.name && <p className="error">{errors.name.message}</p>}
          </div>
          <div className="inputs">
            <input
              type="email"
              id="email"
              placeholder="E-mail"
              {...register("email")}
            />
            {errors.email && <p className="error">{errors.email.message}</p>}
          </div>
          <div className="inputs">
            <textarea
              id="body"
              placeholder="Message"
              rows={5}
              {...register("body")}
            />
            {errors.body && <p className="error">{errors.body.message}</p>}
          </div>
          <button disabled={isSubmitting} type="submit" className="login-btn">
            {isSubmitting ? "Envoi en cours..." : "Envoyer"}
          </button>
        </form>
        {!!error && (
          <p
            style={{
              color: "red",
              fontSize: "14px",
              padding: "0",
              paddingTop: "8px",
            }}
          >
            {error}
          </p>
        )}
        {success && (
          <div className="contact-successMessage">
            <img src={successIcon} alt="" />
            <p>Votre message est envoyé!</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Contact;

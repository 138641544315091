import { useEffect, useState } from "react";
import LoadingSpinner from "../../../../components/LoadingSpinner/LoadingSpinner";
import MuiPagination from "../../../../components/MuiPagination/MuiPagination";
import { useAuthContext } from "../../../../context/AuthContext/AuthContext";
import SubscriptionInfo from "../../../../dashboard/components/Molecules/SubscriptionInfo/SubscriptionInfo";
import { NoResults } from "../../../../dashboard/components/Organisms/NoResults/NoResults";
import { useWalletHistoryQuery } from "../../../../react-query/hooks/Wallet";

const ProfileWalletHistory = () => {
  const authCtx = useAuthContext();
  const [page, setPage] = useState(1);
  const { data: { data, metaData } = {}, isLoading } = useWalletHistoryQuery(
    authCtx?.user?.id,
    page
  );
  return (
    <div style={{ marginTop: "20px" }}>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="subscription">
          {data?.length! > 0 ? (
            <div className="dashboardResults">
              <table>
                <tr>
                  <th>DATE</th>
                  <th>USER</th>
                  <th>PACK/PRODUCT</th>
                  <th>PRICE</th>
                </tr>
                {data?.map((subscription: any) => {
                  return (
                    <SubscriptionInfo
                      key={subscription.id}
                      date={subscription.purchaseDate}
                      userEmail={subscription.email}
                      subscriptionName={subscription.packageName}
                      chargedAmount={subscription.price}
                      bill={undefined}
                    />
                  );
                })}
              </table>
              <div className="dashboardPagination">
                <MuiPagination
                  pagesNumber={metaData?.pageCount!}
                  page={page}
                  setPage={setPage}
                />
              </div>
            </div>
          ) : (
            <NoResults />
          )}
        </div>
      )}
    </div>
  );
};

export default ProfileWalletHistory;

import { ReactElement, useState, FC } from "react";
import { Dropdown } from "react-bootstrap";
import ellipsis from "../../../../assets/icons/ellipsis.svg";
import trash from "../../../../assets/icons/trash.png";
import edit from "../../../../assets/icons/edit.png";
import AddNewTable from "../../../modals/AddNewClubTable";
import { useDeleteClubTableQuery } from "../../../../react-query/hooks/Clubs";

interface ClubTableInfoProps {
  tableId: number;
  name: string;
  price: number;
  maxNumber: number;
  numberOfTables: number;
  businessId: number;
  pageNumber: number;
}
const ClubTableInfo: FC<ClubTableInfoProps> = ({
  tableId,
  name,
  price,
  maxNumber,
  numberOfTables,
  businessId,
  pageNumber,
}): ReactElement => {
  const [modalShow, setModalShow] = useState(false);
  const { mutate: deleteClubTable } = useDeleteClubTableQuery(
    businessId,
    pageNumber
  );
  const handleDeleteTable = (tableId: number) => () => {
    deleteClubTable(tableId);
  };
  return (
    <tr className="tableinfo">
      <td>
        <p>{name}</p>
      </td>
      <td>
        <p>{`${price} €`}</p>
      </td>
      <td>
        <p>{maxNumber}</p>
      </td>
      <td>
        <p>{numberOfTables}</p>
      </td>
      <td className="actionColumn">
        <Dropdown
          className="d-inline actionColumn-action action lastElement"
          align={"end"}
        >
          <Dropdown.Toggle id="dropdown-autoclose-true">
            <img src={ellipsis} alt="" />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item href="#" onClick={() => setModalShow(true)}>
              <img src={edit} alt="" />
              <p>Éditer</p>
            </Dropdown.Item>
            <Dropdown.Item href="#" onClick={handleDeleteTable(tableId)}>
              <img src={trash} alt="" />
              <p>Effacer</p>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </td>
      <AddNewTable
        show={modalShow}
        onHide={() => setModalShow(false)}
        name={name}
        price={price}
        maxNumber={maxNumber}
        numberOfTables={numberOfTables}
        clubId={businessId}
        tableId={tableId}
        pageNumber={pageNumber}
        update
      />
    </tr>
  );
};
export default ClubTableInfo;

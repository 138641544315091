import { ReactElement } from "react";
import { Modal } from "react-bootstrap";
import { useUpdateSubscription } from "../../react-query/hooks/Subscription";
import "./_paymentModalsStyle.scss";
import closeButton from "../../assets/icons/closeButton.png";

const UpDownGradePayment = (props: any): ReactElement => {
  const {
    mutate: updateSubscription,
    data: { data: updateSubscriptionResponse } = {},
    isLoading: isUpdateSubscriptionLoading,
    reset,
  } = useUpdateSubscription();

  const updateSubscriptionPack = () => {
    updateSubscription(props.packedId);
    setTimeout(() => {
      reset();
      props.onHide();
    }, 3000);
  };
  const localDate = new Date(props.priceToAdd?.nextPayment);
  const nextPaymentDate = localDate.toLocaleDateString(undefined, {
    day: "numeric",
    month: "short",
    year: "numeric",
  });
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="paymentModal"
      onHide={() => {
        reset();
        props.onHide();
      }}
    >
      <Modal.Body>
        {props.priceToAdd === "downgrade" ? (
          <div className="modalBodyDowngrade">
            <h3 className="modalBodyDowngrade-title">Êtes-vous sûr?</h3>
            <div className="modalBodyDowngrade-buttons">
              <button
                onClick={() => props.onHide()}
                className="no"
                disabled={isUpdateSubscriptionLoading}
              >
                Non
              </button>
              <button
                onClick={updateSubscriptionPack}
                className="yes"
                disabled={
                  isUpdateSubscriptionLoading ||
                  updateSubscriptionResponse?.isSuccess
                }
              >
                Oui
              </button>
            </div>
            {isUpdateSubscriptionLoading && (
              <p className="les">Chargement...</p>
            )}
            {updateSubscriptionResponse?.isSuccess && (
              <p className="les">
                {updateSubscriptionResponse?.successMessage}
              </p>
            )}
          </div>
        ) : (
          <div className="modalBodyUpgrade">
            <div className="modalBodyUpgrade-title">
              <h3>Informations de paiement</h3>
              <img src={closeButton} alt="" onClick={() => props.onHide()} />
            </div>
            <p className="modalBodyUpgrade-pro">PRO Abonnement</p>
            <div className="modalBodyUpgrade-informations">
              <div className="info">
                <p>
                  Abonnement actuel: <span className="span1">BASIC</span>
                </p>
                {props?.prices[0] < props?.prices[1] ? (
                  <p>{`€${props?.prices[0]}`}</p>
                ) : null}
              </div>
              <div className="info">
                <p>
                  Abonnement mise à niveau: <span className="span2">PRO</span>
                </p>
                {props?.prices[1] > props?.prices[0] ? (
                  <p>{`€${props?.prices[1]}`}</p>
                ) : null}
              </div>
              <div className="lastInfo">
                <p>Paiement total pour la mise à niveau</p>
                <p>€{props.priceToAdd?.amountToPay}</p>
              </div>
            </div>
            <button onClick={updateSubscriptionPack}>
              <div>
                <p style={{ fontSize: "20px" }}>Confirmer</p>
                <p
                  style={{
                    color: "rgba(255, 255, 255, 0.7)",
                    fontSize: "12px",
                  }}
                >
                  Prochain paiement le{" "}
                  <span
                    style={{
                      color: "white",
                      backgroundColor: "rgba(255, 255, 255, 0.1)",
                      padding: "0 5px",
                      borderRadius: "5px",
                    }}
                  >
                    {" "}
                    {`${nextPaymentDate} €${props.priceToAdd?.amountToPay}`}
                  </span>
                </p>
              </div>
            </button>
            {isUpdateSubscriptionLoading && (
              <p className="les">Chargement...</p>
            )}
            {updateSubscriptionResponse?.isSuccess && (
              <p className="les">
                {updateSubscriptionResponse?.successMessage}
              </p>
            )}
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};
export default UpDownGradePayment;

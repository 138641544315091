import "./_NoNotification.scss";
import noNotifications from "../../../../../assets/photos/NoNotifications.png";

const NoNotification = () => {
  return (
    <div className="nonotifications">
      <div className="nonotifications-content">
        <img src={noNotifications} alt="" />
        <h3>Pas de notifications</h3>
        <p>Toute nouvelle notification apparaîtra ici</p>
      </div>
    </div>
  );
};
export default NoNotification;

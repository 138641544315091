import {FC} from 'react';
import googleIconViolet from "../../../../assets/icons/googleIconViolet.png";
import instagramIconViolet from "../../../../assets/icons/instagramIconViolet.png";
import facebookIconViolet from "../../../../assets/icons/facebookIconViolet.png";
import appleIconViolet from "../../../../assets/icons/appleIconViolet.png";
import androidIconViolet from "../../../../assets/icons/androidIconViolet.png";
import './_SocialMedia.scss';

interface SocialMediaProps {
}

 const SocialMedia: FC<SocialMediaProps>=()=> {
  return (
    <div className='socialmedia container'>
      <h3 className='socialmedia-text'>Soyez visible et offrez un service de réservation en ligne  
      sur les plates-formes les plus utilisées au monde 
      </h3>
      <div className='socialmedia-icons'>
      <img className='socialmedia-icons-instagram' src={instagramIconViolet} alt="" />
        <img className='socialmedia-icons-google' src={googleIconViolet} alt="" />
        <img className='socialmedia-icons-apple' src={appleIconViolet} alt="" />
        <img className='socialmedia-icons-facebook' src={facebookIconViolet} alt="" />
        <img className='socialmedia-icons-android' src={androidIconViolet} alt="" />
      </div>
    </div>
  );
}

export default SocialMedia;
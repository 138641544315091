import { joiResolver } from "@hookform/resolvers/joi";
import clsx from "clsx";
import queryString from "query-string";
import { ChangeEvent, FC, ReactElement, useEffect } from "react";
import { Modal, ModalProps } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { DjBasicInfo } from "../../api/Dj";
import uploadImg from "../../assets/icons/uploadImg.png";
import { UseConvertToBase64 } from "../../hooks/UseConvertToBase64";
import { DjFormPayload } from "../../interfaces/models/postModels/DjFormPayload.model";
import {
  useAddDjMutation,
  useEditDjMutation,
} from "../../react-query/hooks/useConfiguration.mutation";
import { DjFormSchema } from "../../validations/DjForm.schema";

interface DjFormProps extends ModalProps {
  dj?: DjBasicInfo;
  onHide: () => void;
}

const DjForm: FC<DjFormProps> = ({ dj, onHide, ...rest }): ReactElement => {
  /**
   * hooks
   */
  const { search } = useLocation();

  /**
   * query string
   */
  const queryParams = queryString.parse(search);

  // hook form
  const {
    reset,
    control,
    setValue,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<DjFormPayload>({
    resolver: joiResolver(DjFormSchema),
  });
  const djAvatar = useWatch({
    name: "file",
    control,
  });

  // react-query
  const { mutate: addDj, isLoading: isInsertingDj } = useAddDjMutation();
  const { mutate: editDj, isLoading: isEditingDj } = useEditDjMutation(
    dj?.id || 0,
    Number(queryParams.pageNumber)
  );

  // effects
  useEffect(() => {
    if (dj)
      reset({
        name: dj.name,
        file: {
          image: null,
          imagePath: dj.imagePath,
        },
      });
  }, [dj, reset]);

  // handlers
  const handleHideModal = () => {
    reset();
    onHide();
  };
  const handleImageChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.currentTarget.files || null;

    if (!!files) {
      const image = (await UseConvertToBase64(files[0])) as string;
      setValue("file", { image, imagePath: files[0].name });
    }
  };
  const onSubmit = ({ name, file }: DjFormPayload) => {
    if (!dj) addDj({ name, file }, { onSuccess: handleHideModal });
    else editDj({ name, file }, { onSuccess: handleHideModal });
  };

  return (
    <Modal
      {...rest}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="dashboardModal"
    >
      <Modal.Header>
        <h4>{!dj?.id ? "Ajouter un DJ" : "DJ de mise à jour"}</h4>
      </Modal.Header>
      <Modal.Body as="form" onSubmit={handleSubmit(onSubmit)}>
        <div className="modalBody">
          <div className="modalBody-Input modalItem">
            <label htmlFor="name">Nom du DJ</label>
            <input id="name" {...register("name")} placeholder="Nom" />
            {errors.name && (
              <p className="tw-text-red-600">{errors.name.message}</p>
            )}
          </div>
          <label className="imageInput modalItem">
            <input type="file" onChange={handleImageChange} />
            <div className="imageInput-style">
              <img
                src={djAvatar?.image ?? dj?.imagePath ?? uploadImg}
                alt="dj profile"
                className={clsx(
                  "dj-avatar",
                  (dj?.imagePath || djAvatar?.image) && "tw-w-20"
                )}
              />
              <h6>Déposez votre image ici, ou parcourez sur votre appareil</h6>
              <p>Format requis: JPG, PNG</p>
            </div>
            {errors.file && (
              <p className="tw-text-red-700">{errors.file.message}</p>
            )}
          </label>
          <div className="modalBody-buttons modalItem">
            <button type="button" onClick={handleHideModal}>
              Annuler
            </button>
            <button type="submit">
              {!dj?.id
                ? isInsertingDj
                  ? "Ajouter..."
                  : "Ajouter"
                : isEditingDj
                ? "Sauvegarder..."
                : "Sauvegarder"}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default DjForm;

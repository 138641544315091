import { useMutation, useQueryClient } from "@tanstack/react-query";
import { cancelReservation } from "../mutations/reservation";

export const useCancelReservationMutation = (
  reservationId: number,
  pageNumber: number
) => {
  const queryClient = useQueryClient();

  return useMutation(
    [`cancelReservation-${pageNumber}`],
    () => cancelReservation(reservationId),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([`eventParticipants-${pageNumber}`]),
    }
  );
};

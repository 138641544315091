import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import {
  getAllClubServices,
  getClubGallery,
  getClubJurisdictions,
  getClubPricing,
  getClubProfile,
  getClubReviews,
  getClubSchedules,
  getClubsName,
  getClubsTypes,
  getClubTables,
  getMyClubs,
  getServicesType,
} from "../queries/clubs";
import {
  addClub,
  addClubJurisdiction,
  addClubPhoto,
  addClubSchedule,
  addClubService,
  addClubTable,
  deleteClub,
  deleteClubJurisdiction,
  deleteClubPhoto,
  deleteClubReview,
  deleteClubSchedule,
  deleteClubService,
  deleteClubTable,
  searchClubs,
  updateClub,
  updateClubJurisdiction,
  updateClubSchedule,
  updateClubService,
  updateClubTable,
} from "../mutations/clubs";
import { ScheduleResponseModel } from "../../interfaces/models/getModels/ScheduleResponse.model";
import { JurisdictionResponseModel } from "../../interfaces/models/getModels/JurisdictionResponse.model";
import { TableResponseModel } from "../../interfaces/models/getModels/TableResponse.model";
import { ServiceTypeResponseModel } from "../../interfaces/models/getModels/ServiceTypeResponse.model";
import { ServiceResponseModel } from "../../interfaces/models/getModels/ServiceResponse";
import { ClubResponseModel } from "../../interfaces/models/getModels/ClubResponse.model";
import { BasicResponse } from "../../interfaces/mainModels/BasicResponse.model";
import { MetaDataResponse } from "../../interfaces/mainModels/MetaDataResponse.model";
import { ClubPhotoResponseModel } from "../../interfaces/models/getModels/ClubPhotoResponse.model";
import { ReviewResponseModel } from "../../interfaces/models/getModels/ReviewResponse.model";
import { ClubPhotoPostModel } from "../../interfaces/models/postModels/ClubPhotoPost.model";
import { SchedulePostModel } from "../../interfaces/models/postModels/SchedulePost.model";
import { JurisdictionPostModel } from "../../interfaces/models/postModels/JurisdictionPost.model";
import { ServicePostModel } from "../../interfaces/models/postModels/ServicePost.model";
import { ClubTablePostModel } from "../../interfaces/models/postModels/ClubTablePost.model";
import { ScheduleUpdateModel } from "../../interfaces/models/updateModels/ScheduleUpdate.model";
import { JurisdictionUpdateModel } from "../../interfaces/models/updateModels/JurisdictionUpdate.model";
import { ServiceUpdateModel } from "../../interfaces/models/updateModels/ServiceUpdate.model";
import { TableUpdateModel } from "../../interfaces/models/updateModels/TableUpdate.model";
import { ClubPostModel } from "../../interfaces/models/postModels/ClubPost.model";
import { ClubsTypesResponseModel } from "../../interfaces/models/getModels/ClubsTypesResponse.model";

interface GetClubPricingResponse {
  serviceType: ServiceTypeResponseModel;
  services: ServiceResponseModel[];
}

interface ClubUpdateModel extends ClubPostModel {
  id: number;
}
interface clubName {
  id: number;
  name: string;
}
interface searchInput {
  searchWord: string;
  pageNumber?: number | undefined;
  pageSize: number;
}

// -----GET-----
export const useMyClubsQuery = (
  pageNumber: number,
  options?: Omit<
    UseQueryOptions<BasicResponse<MetaDataResponse<ClubResponseModel[]>>>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<BasicResponse<MetaDataResponse<ClubResponseModel[]>>> =>
  useQuery(
    [`business-events-pageNumber-${pageNumber}`],
    () => getMyClubs(pageNumber),
    options
  );

export const useClubsNameQuery = (
  searchWord?: string,
  options?: Omit<
    UseQueryOptions<MetaDataResponse<clubName[]>>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<MetaDataResponse<clubName[]>> =>
  useQuery(
    [`clubsName-${searchWord}`],
    () => getClubsName(searchWord),
    options
  );

export const useClubQuery = (
  id: number,
  options?: Omit<
    UseQueryOptions<BasicResponse<ClubResponseModel>>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<BasicResponse<ClubResponseModel>> =>
  useQuery([`business-club-${id}`], () => getClubProfile(id), options);

export const useClubGalleryQuery = (
  id: number,
  pageNumber: number,
  options?: Omit<
    UseQueryOptions<MetaDataResponse<ClubPhotoResponseModel[]>>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<MetaDataResponse<ClubPhotoResponseModel[]>> =>
  useQuery(
    [`club-gallery-${id}-${pageNumber}`],
    () => getClubGallery(id, pageNumber),
    options
  );

export const useClubPricingQuery = (
  id: number,
  pageNumber: number,
  options?: Omit<
    UseQueryOptions<BasicResponse<MetaDataResponse<GetClubPricingResponse[]>>>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<BasicResponse<MetaDataResponse<GetClubPricingResponse[]>>> =>
  useQuery(
    [`club-pricing-${id}-${pageNumber}`],
    () => getClubPricing(id, pageNumber),
    options
  );

export const useClubServicesTypeQuery = (
  id: number,
  options?: Omit<
    UseQueryOptions<BasicResponse<MetaDataResponse<GetClubPricingResponse[]>>>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<BasicResponse<MetaDataResponse<GetClubPricingResponse[]>>> =>
  useQuery([`club-services-type-${id}`], () => getAllClubServices(id), options);

export const useServicesTypeQuery = (
  options?: Omit<
    UseQueryOptions<
      BasicResponse<MetaDataResponse<ServiceTypeResponseModel[]>>
    >,
    "queryKey" | "queryFn"
  >
): UseQueryResult<
  BasicResponse<MetaDataResponse<ServiceTypeResponseModel[]>>
> => useQuery([`club-services-types`], () => getServicesType(), options);

export const useClubReviewQuery = (
  id: number,
  pageNumber: number,
  options?: Omit<
    UseQueryOptions<BasicResponse<MetaDataResponse<ReviewResponseModel[]>>>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<BasicResponse<MetaDataResponse<ReviewResponseModel[]>>> =>
  useQuery(
    [`club-reviews-${id}-${pageNumber}`],
    () => getClubReviews(id, pageNumber),
    options
  );

export const useClubScheduleQuery = (
  id: number,
  options?: Omit<
    UseQueryOptions<BasicResponse<ScheduleResponseModel[]>>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<BasicResponse<ScheduleResponseModel[]>> =>
  useQuery([`club-schedules-${id}`], () => getClubSchedules(id), options);

export const useClubJurisdictionsQuery = (
  id: number,
  options?: Omit<
    UseQueryOptions<BasicResponse<JurisdictionResponseModel[]>>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<BasicResponse<JurisdictionResponseModel[]>> =>
  useQuery(
    [`club-jurisdictions-${id}`],
    () => getClubJurisdictions(id),
    options
  );

export const useClubTablesQuery = (
  id: number,
  pageNumber: number,
  options?: Omit<
    UseQueryOptions<MetaDataResponse<TableResponseModel[]>>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<MetaDataResponse<TableResponseModel[]>> =>
  useQuery(
    [`club-table-${id}-${pageNumber}`],
    () => getClubTables(id, pageNumber),
    options
  );
export const useClubTypesQuery = (): UseQueryResult<
  BasicResponse<ClubsTypesResponseModel[]>
> => useQuery(["clubs-types"], getClubsTypes);

// -----DELETE-----
export const useDeleteClubQuery = () => {
  return useMutation(["deleteClub"], (clubId: number) => deleteClub(clubId));
};

export const useDeleteClubServiceQuery = (
  clubId: number,
  pageNumber: number
) => {
  const queryClient = useQueryClient();
  return useMutation(
    [`deleteServiceClub-${clubId}`],
    (serviceId: number) => deleteClubService(serviceId),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([`club-pricing-${clubId}-${pageNumber}`]),
    }
  );
};

export const useDeleteClubTableQuery = (clubId: number, pageNumber: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    [`deleteTableClub-${clubId}`],
    (tableId: number) => deleteClubTable(tableId),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([`club-table-${clubId}-${pageNumber}`]),
    }
  );
};

export const useDeleteClubPhotoQuery = (clubId: number, pageNumber: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    [`deletePhotoClub-${clubId}`],
    (photoId: number) => deleteClubPhoto(photoId),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([`club-gallery-${clubId}-${pageNumber}`]),
    }
  );
};

export const useDeleteClubJuridictionQuery = (clubId: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    [`deleteJuridictionClub-${clubId}`],
    (juridictionId: number) => deleteClubJurisdiction(juridictionId),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([`club-jurisdictions-${clubId}`]),
    }
  );
};
export const useDeleteClubScheduleQuery = (clubId: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    [`deleteScheduleClub-${clubId}`],
    (scheduleId: number) => deleteClubSchedule(scheduleId),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([`club-schedules-${clubId}`]),
    }
  );
};
export const useDeleteClubReviewQuery = (
  clubId: number,
  pageNumber: number
) => {
  const queryClient = useQueryClient();
  return useMutation(
    [`deleteReviewClub-${clubId}`],
    (reviewId: number) => deleteClubReview(reviewId),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([`club-reviews-${clubId}-${pageNumber}`]),
    }
  );
};

//-----POST-----

export const usePostClubQuery = () => {
  return useMutation(["addClub"], (data: ClubPostModel) => addClub(data));
};

export const usePostClubsQuery = () => {
  return useMutation((data: searchInput) => searchClubs(data));
};

export const usePostClubPhotoQuery = (clubId: number, pageNumber: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    [`addPhotoClub-${clubId}`],
    (data: ClubPhotoPostModel) => addClubPhoto(data),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([`club-gallery-${clubId}-${pageNumber}`]),
    }
  );
};

export const usePostClubScheduleQuery = (clubId: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    [`addScheduleClub-${clubId}`],
    (data: SchedulePostModel) => addClubSchedule(data),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([`club-schedules-${clubId}`]),
    }
  );
};
export const usePostClubJurisdictionQuery = (clubId: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    [`addJurisdictionClub-${clubId}`],
    (data: JurisdictionPostModel) => addClubJurisdiction(data),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([`club-jurisdictions-${clubId}`]),
    }
  );
};
export const usePostClubServiceQuery = (clubId: number, pageNumber: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    [`addServiceClub-${clubId}`],
    (data: ServicePostModel) => addClubService(data),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([`club-pricing-${clubId}-${pageNumber}`]),
    }
  );
};
export const usePostClubTableQuery = (clubId: number, pageNumber: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    [`addTableClub-${clubId}`],
    (data: ClubTablePostModel) => addClubTable(data),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([`club-table-${clubId}-${pageNumber}`]),
    }
  );
};

//-----PUT-----
export const usePutClubQuery = (clubId: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    [`updateBusinessClub-${clubId}`],
    (data: ClubUpdateModel) => updateClub(data),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([`business-club-${clubId}`]),
    }
  );
};

export const usePutClubScheduleQuery = (clubId: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    [`updateScheduleClub-${clubId}`],
    (data: ScheduleUpdateModel) => updateClubSchedule(data),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([`club-schedules-${clubId}`]),
    }
  );
};
export const usePutClubJurisdictionQuery = (clubId: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    [`updateJurisdictionClub-${clubId}`],
    (data: JurisdictionUpdateModel) => updateClubJurisdiction(data),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([`club-jurisdictions-${clubId}`]),
    }
  );
};
export const usePutClubServiceQuery = (clubId: number, pageNumber: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    [`updateServiceClub-${clubId}`],
    (data: ServiceUpdateModel) => updateClubService(data),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([`club-pricing-${clubId}-${pageNumber}`]),
    }
  );
};
export const usePutClubTableQuery = (clubId: number, pageNumber: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    [`updateTableClub-${clubId}`],
    (data: TableUpdateModel) => updateClubTable(data),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([`club-table-${clubId}-${pageNumber}`]),
    }
  );
};

import { Modal } from "react-bootstrap";
import "./_Photo.scss";
import closeButton from "../../../assets/icons/closeButton.svg";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

const Photo = (props: any) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="modalOnlyPhoto">
        <div className="modalOnlyPhoto-content">
          <img
            src={props.photoSrc}
            alt=""
            className="modalOnlyPhoto-content-img"
          />
          <img
            src={closeButton}
            alt=""
            onClick={() => props.onHide()}
            className="modalOnlyPhoto-content-closeButton"
          />
          {!props.firstItem && (
            <ArrowBackIosNewIcon
              className="previcon"
              sx={{
                color: "rgba(255, 255, 255, .7)",
                fontSize: "40px",
                border: "1px solid rgba(255, 255, 255, .7)",
                borderRadius: "7px",
                padding: "5px",
                "&:hover": {
                  color: "rgba(255, 255, 255, 1)",
                  border: "1px solid rgba(255, 255, 255, 1)",
                },
              }}
              onClick={() => props.setPhotoOpened((prev: number) => prev - 1)}
            />
          )}
          {!props.lastItem && (
            <ArrowForwardIosIcon
              className="forwardicon"
              sx={{
                color: "rgba(255, 255, 255, .7)",
                fontSize: "40px",
                border: "1px solid rgba(255, 255, 255, .7)",
                borderRadius: "7px",
                padding: "5px",
                "&:hover": {
                  color: "rgba(255, 255, 255, 1)",
                  border: "1px solid rgba(255, 255, 255, 1)",
                },
              }}
              onClick={() => props.setPhotoOpened((prev: number) => prev + 1)}
            />
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Photo;

import './_SkeletonFavoriteClubCard.scss';

const SkeletonFavoriteClubCard=()=>{
    return(
        <div className='skeletonfavoriteclubcard'>
            <div className='skeletonfavoriteclubcard-photo'></div>
            <div className='skeletonfavoriteclubcard-info'>
                <div className="h3"></div>
                <div className='skeletonfavoriteclubcard-info-section2'>
                    <div className='skeletonfavoriteclubcard-info-section2-item'>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SkeletonFavoriteClubCard;
import { Link } from "react-router-dom";
import pageNotFound from "../../../assets/photos/404.png";
import "./_Page404.scss";

const Page404 = () => {
  return (
    <div className="page404">
      <div className="page404-content">
        <img src={pageNotFound} alt="" />
        <h2>Page non trouvée</h2>
        <p>Il semble que cette page n'existe pas</p>
        <button>
          <Link to="/">Aller à la accuiel</Link>
        </button>
      </div>
    </div>
  );
};

export default Page404;

import { FC } from "react";
import "../_CardsSharedStyle.scss";
import "./_DJCard.scss";
import ratingStar from "../../../../../assets/icons/star.svg";
import { DjBasicInfo } from "../../../../../api/Api";
import { Link } from "react-router-dom";

interface DJCardProps {
  DJData?: DjBasicInfo;
}

const DJCard: FC<DJCardProps> = ({ DJData }) => {
  return (
    <section className="djcard cardssharedstyle">
      <Link to={`/djs/${DJData?.id}`}>
        <div className="djcard-img cardssharedstyle-img">
          <img src={DJData?.imagePath} alt="" />
        </div>
        <div className="djcard-content cardssharedstyle-content">
          <h2>{DJData?.name}</h2>
          <div>
            <img className="ratingstar" src={ratingStar} alt="" />
            <h3>{DJData?.rating?.toFixed(1)}</h3>
          </div>
        </div>
      </Link>
    </section>
  );
};

export default DJCard;

import { FC, useState } from "react";
import { useLoginRegisterResetPasswordContext } from "../../../../context/LoginRegisterResetPasswordContext/LoginRegisterResetPasswordContext";
import LoginRegisterResetPassword from "../../../modals/LoginRegisterResetPassword/LoginRegisterResetPassword";
import "./_BoostClub.scss";

interface BoostClubProps {}

const BoostClub: FC<BoostClubProps> = () => {
  const [modalShow, setModalShow] = useState(false);
  const LRRPCtx = useLoginRegisterResetPasswordContext();
  return (
    <div className="boostclub">
      <div className="boostclub-section1">
        <h2>Boostez votre club !</h2>
        <p className="boostclub-section1-text1">
          Vous souhaitez ajouter de la valeur, rendre visible votre club et
          optimiser vos réservations?
        </p>
        <p className="boostclub-section1-text2">
          Essayez gratuitement 7Night Manager.
        </p>
        <button
          className="boostclub-section1-btn"
          onClick={() => {
            setModalShow(true);
            LRRPCtx.setDefaultKey("register");
          }}
        >
          Poursuivre
        </button>
        <p className="pEnd">essaie gratuit sans engagement</p>
      </div>
      <LoginRegisterResetPassword
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </div>
  );
};

export default BoostClub;

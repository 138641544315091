import { ReactElement, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { EventTablePostModel } from '../../interfaces/models/postModels/EventTablePost.model';
import {
  usePostEventTableQuery,
  usePutEventTableQuery,
} from '../../react-query/hooks/Events';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { TableUpdateModel } from '../../interfaces/models/updateModels/TableUpdate.model';

const schema = yup.object().shape({
  name: yup.string().required('Obligatoire'),
  price: yup
    .number()
    .positive()
    .integer()
    .moreThan(-1, 'Le prix doit être un nombre positif')
    .typeError('Obligatoire')
    .required(),
  maxNumber: yup
    .number()
    .positive()
    .integer()
    .typeError('Obligatoire')
    .required(),
  numberOfTables: yup
    .number()
    .positive()
    .integer()
    .typeError('Obligatoire')
    .required(),
});

const AddNewEventTable = (props: any): ReactElement => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = useForm<EventTablePostModel>({
    resolver: yupResolver(schema),
  });
  const {
    mutate: addEventTable,
    isLoading: isAddLoading,
    isSuccess: isAddSuccess,
  } = usePostEventTableQuery(props.eventId, props.pageNumber);
  const {
    mutate: updateEventTable,
    isLoading: isUpdateLoading,
    isSuccess: isUpdateSuccess,
  } = usePutEventTableQuery(props.eventId, props.pageNumber);

  const handleTableSubmit = (data: EventTablePostModel | TableUpdateModel) => {
    if (props.post) {
      return addEventTable({ ...data, eventId: props.eventId });
    }
    return updateEventTable({ ...data, id: props.tableId });
  };

  useEffect(() => {
    props.onHide();
    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful === true]);

  return (
    <Modal
      {...props}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      className='dashboardModal'
    >
      <Modal.Header>
        {props.post && <h4>Ajouter un nouveau tableau</h4>}
        {props.update && <h4>Tableau de mise à jour</h4>}
      </Modal.Header>
      <Modal.Body>
        <form className='modalBody' onSubmit={handleSubmit(handleTableSubmit)}>
          <div className='modalBody-Input modalItem'>
            <label htmlFor='désignation'>Désignation table</label>
            <input
              type='text'
              id='désignation'
              placeholder='Table simple, Table VIP..'
              defaultValue={props.name}
              {...register('name')}
            />
            {errors.name && (
              <p className='tw-text-red-600 mb-0'>{errors.name.message}</p>
            )}
          </div>
          <div className='modalBody-Input modalItem'>
            <label htmlFor='désignation'>
              Prix consommation min. pour une table{' '}
            </label>
            <input
              type='number'
              id='désignation'
              placeholder='€'
              defaultValue={props.price}
              {...register('price')}
            />
            {errors.price && (
              <p className='tw-text-red-600 mb-0'>{errors.price.message}</p>
            )}
          </div>
          <div className='modalBody-Input modalItem'>
            <label htmlFor='désignation'>
              Personnes max. autour d’une table (facultatif)
            </label>
            <input
              type='number'
              id='désignation'
              placeholder='Personnes max.'
              defaultValue={props.maxNumber}
              {...register('maxNumber')}
            />
            {errors.maxNumber && (
              <p className='tw-text-red-600 mb-0'>{errors.maxNumber.message}</p>
            )}
          </div>
          <div className='modalBody-Input modalItem'>
            <label htmlFor='désignation'>Nombre de tables disponibles</label>
            <input
              type='number'
              id='désignation'
              placeholder='Nombre de tables'
              defaultValue={props.numberOfTables}
              {...register('numberOfTables')}
            />
            {errors.numberOfTables && (
              <p className='tw-text-red-600 mb-0'>
                {errors.numberOfTables.message}
              </p>
            )}
          </div>
          <div className='modalBody-buttons modalItem'>
            <button
              type='button'
              onClick={() => {
                props.onHide();
              }}
            >
              Annuler
            </button>
            {props.post && (
              <button type='submit' disabled={isAddLoading}>
                Ajouter
              </button>
            )}
            {props.update && (
              <button type='submit' disabled={isUpdateLoading}>
                Sauvegarder
              </button>
            )}
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};
export default AddNewEventTable;

import { FC } from "react";
import { schedule } from "../../../../api/Business";
import "./_NightclubSchedules.scss";

interface NightclubSchedulesProps {
  schedules?: schedule[];
}
var weekday = new Array(7);
weekday[0] = "Lundi";
weekday[1] = "Mardi";
weekday[2] = "Wednesday";
weekday[3] = "Mercredi";
weekday[4] = "Vendredi";
weekday[5] = "Samedi";
weekday[6] = "Dimanche";

const NightclubSchedules: FC<NightclubSchedulesProps> = ({ schedules }) => {
  return (
    <div className="nightclubschedules">
      <h3>Horaires</h3>
      {schedules?.length === 0 ? (
        <p className="noschedules">Pas de contenu</p>
      ) : (
        <>
          {schedules?.map((schedule, index) => {
            return (
              <div className="nightclubschedules-item" key={index}>
                <div>
                  <span>{weekday[schedule?.weekDay - 1]}</span>
                </div>
                <div>
                  <span>
                    {schedule?.startTime.slice(0, 5)}
                    <span className="span">à</span>
                    {schedule?.endTime.slice(0, 5)}
                  </span>
                </div>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};

export default NightclubSchedules;

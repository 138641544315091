import { FC, ReactElement } from "react";
import { Modal, ModalProps } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { SendNotificationSchema } from "../../validations/SendNotification.schema";
import { useSendNotificationUserManagerMutation } from "../../react-query/hooks/useUserManager.mutation";

interface SendNotificationProps extends ModalProps {
  receivers: string[];
  onSendNotification: () => void;
  onHide: () => void;
}
interface SendNotificationInputs {
  title: string;
  message: string;
}

const SendNotificationUsers: FC<SendNotificationProps> = ({
  receivers,
  onHide,
  onSendNotification,
  ...rest
}): ReactElement => {
  // hooks
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    clearErrors,
  } = useForm<SendNotificationInputs>({
    resolver: joiResolver(SendNotificationSchema),
  });

  // react-query
  const { mutate: sendNotification, isLoading } =
    useSendNotificationUserManagerMutation();

  // handlers
  const handleHideModal = () => {
    reset();
    clearErrors();
    onHide();
  };
  const onSubmit = (data: SendNotificationInputs) => {
    sendNotification(
      { receivers, ...data },
      {
        onSuccess: () => {
          onSendNotification();
          handleHideModal();
        },
      }
    );
  };

  return (
    <Modal
      {...rest}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="dashboardModal"
    >
      <Modal.Header>
        <h4>Envoyer une notification</h4>
        <p>
          Vous enverrez un message sur le smartphone d'un ou plusieurs
          utilisateurs sélectionnés
        </p>
        {receivers.length === 0 && (
          <div
            className="alert alert-warning mt-3 mb-0 w-100 d-flex align-items-center"
            role="alert"
          >
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                style={{ display: "none" }}
              >
                <symbol
                  id="exclamation-triangle-fill"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                </symbol>
              </svg>
              <svg
                className="bi flex-shrink-0 me-2"
                width="24"
                height="24"
                role="img"
                aria-label="Warning:"
              >
                <use xlinkHref="#exclamation-triangle-fill" />
              </svg>
            </div>

            <div className="pt-1">
              Vous envoyez une notification à tous les utilisateurs!
            </div>
          </div>
        )}
      </Modal.Header>
      <Modal.Body as="form" onSubmit={handleSubmit(onSubmit)}>
        <div className="modalBody">
          <div className="Inputs">
            <div className="modalBody-Input modalItem">
              <label htmlFor="title">Titre des notifications</label>
              <input id="title" placeholder="Title" {...register("title")} />
              {errors.title && (
                <p className="tw-text-red-600">{errors.title.message}</p>
              )}
            </div>
          </div>
          <div className="modalBody-Input modalItem">
            <label htmlFor="message">Message</label>
            <textarea
              rows={5}
              cols={10}
              id="message"
              placeholder="Message"
              {...register("message")}
            />
            {errors.message && (
              <p className="tw-text-red-600">{errors.message.message}</p>
            )}
          </div>
          <div className="modalBody-buttons modalItem">
            <button type="button" onClick={handleHideModal}>
              Annuler
            </button>
            <button type="submit" disabled={isLoading}>
              Envoyer
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SendNotificationUsers;

import Login from "../Login/Login";
import "./_LoginRegister.scss";
import { Tabs, Tab } from "react-bootstrap";
import { useLoginRegisterResetPasswordContext } from "../../../../context/LoginRegisterResetPasswordContext/LoginRegisterResetPasswordContext";
import Register from "../Register/Register";
import RegisterManager from "../RegisterManager/RegisterManager";

const LoginRegister = () => {
  const LRRPCtx = useLoginRegisterResetPasswordContext();
  return (
    <div className="loginregister">
      <h3>Connectez-vous à votre compte</h3>
      <Tabs
        defaultActiveKey={
          LRRPCtx.defaultKey === "register" ? "register" : "login"
        }
        transition={false}
        id="noanim-tab-example"
        className="loginregister-nav"
        onSelect={(e) => {
          LRRPCtx.setPhoto(e!);
        }}
      >
        <Tab eventKey="login" title="Connexion">
          <Login />
        </Tab>
        <Tab eventKey="register" title="S’inscrire">
          {LRRPCtx.defaultKey === "register" ? (
            <RegisterManager />
          ) : (
            <Register />
          )}
        </Tab>
      </Tabs>
    </div>
  );
};

export default LoginRegister;

import "../_modalStyle.scss";
import { Modal } from "react-bootstrap";
import { useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { usePaymentContextContext } from "../../../context/PaymentContext/PaymentContext";
import { useWalletBalance } from "../../../react-query/hooks/Wallet";

const Reservation = (props: any) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const { productPrice } = usePaymentContextContext();
  const [error, setError] = useState<any>("");
  const stripe = useStripe();
  const elements = useElements();
  const { refetch } = useWalletBalance({ enabled: false });

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const { error } = await stripe.confirmPayment({
      elements,
      redirect: "if_required",
    });

    if (error) {
      setLoading(false);
      setError(error.message);
    } else {
      setLoading(false);
      setError("");
      setSuccess(true);
      setTimeout(() => {
        refetch();
        setSuccess(false);
        props.onHide();
      }, 3000);
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="stripeClientPayment">
        <div className="stripeClientPayment-body">
          <form onSubmit={handleSubmit}>
            <PaymentElement />
            <button
              type="submit"
              disabled={success}
              onClick={() => setLoading(true)}
            >
              {`Payer  ${productPrice}€`}
            </button>
          </form>
          {loading && <p className="les">Envoyer...</p>}
          {!loading && <p className="les">{error}</p>}
          {success && (
            <p className="les">Votre paiement est effectué avec succès!</p>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Reservation;

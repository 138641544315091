import { FC, ReactElement } from "react";
import { Tab, Tabs } from "react-bootstrap";
import ClubsDetails from "../../components/Organisms/Details/ClubDetails";
import ClubJuridictions from "../../components/Organisms/ClubJuridictions/ClubJuridictions";
import ClubPricing from "../../components/Organisms/ClubPricing/ClubPricing";
import ClubSchedules from "../../components/Organisms/ClubSchedules/ClubSchedules";
import ClubTables from "../../components/Organisms/Tables/ClubTables";
import "./_ClubStyle.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ClubGallery from "../../components/Organisms/Gallery/ClubGallery";
import ClubRate from "../../components/Organisms/ClubRate/ClubRate";
import queryString from "query-string";

const AdminClub = (): ReactElement => {
  let { clubId } = useParams<{ clubId?: string }>();

  const navigate = useNavigate();

  const { search } = useLocation();

  const queryParams = queryString.parse(search);

  const handleTabSelect = (value: string | null) =>
    navigate({ search: `tab=${value}` });

  const activeKey = queryParams.tab ? String(queryParams.tab) : "packs";

  return (
    <div className="adminclub">
      <div className="dashboardTitle">
        <h3>Club</h3>
      </div>
      <Tabs
        defaultActiveKey={activeKey}
        onSelect={handleTabSelect}
        transition={false}
        id="noanim-tab-example"
        className="dashboardNav"
      >
        <Tab eventKey="clubdetails" title="Détails" mountOnEnter unmountOnExit>
          <ClubsDetails id={clubId || ""} />
        </Tab>
        <Tab eventKey="clubpricing" title="Tarifs" mountOnEnter unmountOnExit>
          <ClubPricing id={clubId || ""} />
        </Tab>
        <Tab
          eventKey="clubtables"
          title="Réservations"
          mountOnEnter
          unmountOnExit
        >
          <ClubTables id={clubId || ""} />
        </Tab>
        <Tab eventKey="photos" title="Photos" mountOnEnter unmountOnExit>
          <ClubGallery id={clubId || ""} />
        </Tab>
        <Tab
          eventKey="clubjuridictions"
          title="Juridictions"
          mountOnEnter
          unmountOnExit
        >
          <ClubJuridictions id={clubId || ""} />
        </Tab>
        <Tab
          eventKey="clubschedules"
          title="Horaires d’ouverture"
          mountOnEnter
          unmountOnExit
        >
          <ClubSchedules id={clubId || ""} />
        </Tab>
        <Tab eventKey="clubrate" title="Avis">
          <ClubRate id={clubId || ""} />
        </Tab>
      </Tabs>
    </div>
  );
};
export default AdminClub;

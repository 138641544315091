import "./_NoReviews.scss";
import ReviewsEmpty from "../../../../assets/photos/ReviewsEmpty.webp";
import AddReview from "../../../modals/AddReview/AddReview";
import { FC, useState } from "react";
import { useAuthContext } from "../../../../context/AuthContext/AuthContext";

interface NoReviewProps {
  id: number;
  name: string;
  clubReview: boolean;
  djReview: boolean;
}
const NoReview: FC<NoReviewProps> = ({ id, name, clubReview, djReview }) => {
  const [modalShow, setModalShow] = useState(false);
  const authCtx = useAuthContext();
  return (
    <div className="noreviews">
      <img src={ReviewsEmpty} alt="" />
      <h3 className="noReview">
        Pas encore <span>d’avis</span>
      </h3>
      {authCtx?.isAuthenticated && (
        <button onClick={() => setModalShow(true)} className="makeReview">
          Laissez un avis
        </button>
      )}
      <AddReview
        show={modalShow}
        onHide={() => setModalShow(false)}
        id={id}
        name={name}
        clubReview={clubReview}
        djReview={djReview}
      />
    </div>
  );
};
export default NoReview;

import {FC} from 'react';
import {Outlet} from 'react-router-dom';

interface NightclubsSharedLayoutProps {
}

 const NightclubsSharedLayout: FC<NightclubsSharedLayoutProps>=()=> {
  return (
    <>
    <Outlet/>
    </>
  );
}

export default NightclubsSharedLayout;
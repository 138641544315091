import RegisterImg from "../../../assets/photos/RegisterImg.webp";
import LoginImg from "../../../assets/photos/LoginImg.webp";
import closeButton from "../../../assets/icons/closeButton.svg";
import "./_LoginRegisterResetPassword.scss";
import "../_modalStyle.scss";
import { Modal } from "react-bootstrap";
import LoginRegister from "../components/LoginRegister/LoginRegister";
import ResetPassword from "../components/ResetPassword/ResetPassword";
import EmailVerification from "../components/EmailVerification/EmailVerification";
import CreateNewPassword from "../components/CreateNewPassword/CreateNewPassword";
import { useLoginRegisterResetPasswordContext } from "../../../context/LoginRegisterResetPasswordContext/LoginRegisterResetPasswordContext";
import ConfirmAccount from "../components/ConfirmAccount/ConfirmAccount";
import { useEffect } from "react";

const LoginRegisterResetPassword = (props: any) => {
  const LRRPCtx = useLoginRegisterResetPasswordContext();
  const page = LRRPCtx.page;

  useEffect(() => {
    if (LRRPCtx.modalshow) {
      props.onHide();
      LRRPCtx.setPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [LRRPCtx.modalshow]);
  const handleOnHide = () => {
    props.onHide();
    LRRPCtx.setPage(1);
    LRRPCtx.setEmail("");
    LRRPCtx.setResetPasswordCode("");
    LRRPCtx.setDefaultKey("");
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => {
        props.onHide();
        LRRPCtx.setPage(1);
        LRRPCtx.setEmail("");
        LRRPCtx.setResetPasswordCode("");
        LRRPCtx.setDefaultKey("");
      }}
    >
      <Modal.Body>
        <div className="loginregisterresetpasswordmodal">
          <div className="loginregisterresetpasswordmodal-section1">
            {LRRPCtx.photo === "login" && <img src={LoginImg} alt="" />}
            {LRRPCtx.photo === "register" && <img src={RegisterImg} alt="" />}
          </div>
          <div className="loginregisterresetpasswordmodal-section2">
            <div className="closebutton">
              <img src={closeButton} alt="" onClick={handleOnHide} />
            </div>
            <div className="content">
              {page === 1 && <LoginRegister />}
              {page === 2 && <ResetPassword />}
              {page === 3 && <EmailVerification />}
              {page === 4 && <CreateNewPassword />}
              {page === 5 && <ConfirmAccount />}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default LoginRegisterResetPassword;

import { FC, useEffect, useState } from "react";
import ClubListIcon from "../../../../assets/icons/ClubListIcon.png";
import { NavLink } from "react-router-dom";
import ClubCard from "../../../components/shared/Cards/ClubCard/ClubCard";
import "./_HomeClubsList.scss";
import { BusinessBasicInfo, getBusiness } from "../../../../api/Api";
import SkeletonClubCard from "../../../components/shared/Cards/SkeletonClubCard/SkeletonClubCard";
import { useAuthContext } from "../../../../context/AuthContext/AuthContext";

interface HomeClubsListProps {
  input?: string;
}

const HomeClubsList: FC<HomeClubsListProps> = ({ input }) => {
  const authCtx = useAuthContext();
  const [businessData, setBusinessData] = useState<BusinessBasicInfo[]>();
  const [loading, setLoading] = useState<boolean>(true);

  const getBusinessData = async () => {
    try {
      const businessResponse = await getBusiness(
        { location: input },
        authCtx?.location
      );
      setBusinessData(businessResponse?.data?.data);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getBusinessData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input, authCtx?.location?.latitude]);

  const showSkeleton = (n: number) => {
    return [...Array(n)].map((e, i) => (
      <div className="homeclubslist-section2-item" key={`${2000 + i}`}>
        <SkeletonClubCard />
      </div>
    ));
  };

  const sortedBusiness = businessData?.sort(function compareNumber(
    a: BusinessBasicInfo,
    b: BusinessBasicInfo
  ) {
    return b.averageRating - a.averageRating;
  });

  return (
    <div className="homeclubslist">
      <div className="homeclubslist-section1">
        <div className="homeclubslist-section1-part1">
          <img src={ClubListIcon} alt="" />
          <h2>Clubs Du Moment !</h2>
        </div>
        <NavLink to="/nightclubs">VOIR PLUS</NavLink>
      </div>
      <div className="homeclubslist-section2">
        {sortedBusiness?.slice(0, 6)?.map((business) => (
          <div className="homeclubslist-section2-item" key={business.id}>
            <ClubCard
              id={business?.id}
              photo={business?.banner}
              name={business?.name}
              averageRating={business?.averageRating}
              address={business?.address}
              city={business?.city}
              distance={business?.distance}
              isOpened={business?.isOpened}
            />
          </div>
        ))}
        {loading && showSkeleton(6)}
      </div>
    </div>
  );
};

export default HomeClubsList;

import "./_ClubsEveningsNoResults.scss";
import noResults from "../../../../assets/icons/noResults.svg";

const ClubsEveningsNoResults = () => {
  return (
    <div className="clubseveningsnoresults">
      <div className="clubseveningsnoresults-content">
        <img src={noResults} alt="" />
        <h3>Aucun résultat trouvé</h3>
        <p>
          Essayez d'ajuster votre recherche ou votre filtre pour trouver ce que
          vous cherchez.
        </p>
      </div>
    </div>
  );
};
export default ClubsEveningsNoResults;

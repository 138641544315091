import location from "../../../assets/icons/location.svg";
import building from "../../../assets/icons/building.svg";
import calendar from "../../../assets/icons/calendar.svg";
import "./_Evenings.scss";
import "./_EveningsFilter.scss";
import { useEffect, useState } from "react";
import { GetBusinessEventsInput } from "../../../api/Api";
import useEveningsFilter from "../../modals/EveningsFilter/EveningsFilter";
import GoogleMapComponent from "../../../components/GoogleMapComponent/GoogleMapComponent";
import useEveningsSection from "../../page-sections/Evenings/EveningsSection/EveningsSection";
import { useGoogleAutoFill } from "../../../components/GoogleAutoFill/GoogleAutoFill";

const Evenings = () => {
  const today = new Date();
  const date = today.setDate(today.getDate());
  const defaultValue = new Date(date).toISOString().split("T")[0]; // yyyy-mm-dd
  const { address, render: googleAutoFill } = useGoogleAutoFill("Où?");
  const scrollToTop = () => {
    window.document.getElementById("results")?.scroll(0, 0);
  };
  const [searchedLocation, setSearchedLocation] = useState<
    string | undefined | null
  >("");
  const [modalShow, setModalShow] = useState(false);
  const { secondSearch, render } = useEveningsFilter({
    show: { modalShow },
    onHide: () => setModalShow(false),
  });
  const [todayOpen, setTodayOpen] = useState<boolean | null>(false);
  const [filterInputs, setFilterInputs] = useState<GetBusinessEventsInput>({
    exterior: null,
    hasShowcase: null,
    isFreeEntryForGirls: null,
  } as GetBusinessEventsInput);
  const [search, setSearch] = useState<GetBusinessEventsInput>(filterInputs);

  const { lngLatArray, renderItems, zeroItem } = useEveningsSection({
    filterSearch: search,
  });
  const [citySearch, setCitySearch] = useState<boolean>(false);
  useEffect(() => {
    setSearch(secondSearch);
  }, [secondSearch]);
  useEffect(() => {
    setFilterInputs({ ...filterInputs, location: address });
  }, [address]);
  return (
    <div className="evenings container">
      <div className="evenings-section1">
        <form
          className="eveningsfilter filter"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div className="eveningsfilter-section1">
            <div className="eveningsfilter-section1-part1">
              <div className="input">
                <img src={building} alt="" />
                <input
                  type="text"
                  value={filterInputs.searchWord || ""}
                  onChange={(e) =>
                    setFilterInputs({
                      ...filterInputs,
                      searchWord: e.target.value,
                    })
                  }
                  placeholder="Nom de soirée, thème, club..."
                />
              </div>
              <div className="input">
                <img src={location} alt="" />
                <div className="clubsGoogleautofill">{googleAutoFill}</div>
              </div>
              <div className="input">
                <img src={calendar} alt="" />
                <input
                  type="date"
                  id="date"
                  value={(todayOpen ? defaultValue : filterInputs.date) || ""}
                  onChange={(e) => {
                    setFilterInputs({ ...filterInputs, date: e.target.value });
                    setTodayOpen(false);
                  }}
                />
              </div>
            </div>
            <div className="eveningsfilter-section1-part2">
              <div className="chechbox-btn">
                <label htmlFor="isOpenToday">
                  <input
                    type="checkbox"
                    id="isOpenToday"
                    value={defaultValue}
                    checked={!!todayOpen}
                    onChange={(e) => {
                      if (e.target.checked === true) {
                        setTodayOpen(true);
                        setFilterInputs({
                          ...filterInputs,
                          date: e.target.value,
                        });
                      } else if (e.target.checked === false) {
                        setTodayOpen(null);
                        setFilterInputs({ ...filterInputs, date: undefined });
                      }
                    }}
                  />
                  <span>Ouvert aujourd’hui</span>
                </label>
              </div>

              <div className="chechbox-btn">
                <label htmlFor="hasExterior">
                  <input
                    type="checkbox"
                    id="hasExterior"
                    checked={
                      filterInputs.exterior === null
                        ? false
                        : filterInputs.exterior
                    }
                    onChange={(e) => {
                      if (e.target.checked === true) {
                        setFilterInputs({ ...filterInputs, exterior: true });
                      } else if (e.target.checked === false) {
                        setFilterInputs({ ...filterInputs, exterior: null });
                      }
                    }}
                  />
                  <span>En extérieur</span>
                </label>
              </div>

              <div className="chechbox-btn">
                <label htmlFor="hasShowcase">
                  <input
                    type="checkbox"
                    id="hasShowcase"
                    checked={
                      filterInputs.hasShowcase === null
                        ? false
                        : filterInputs.hasShowcase
                    }
                    onChange={(e) => {
                      if (e.target.checked === true) {
                        setFilterInputs({ ...filterInputs, hasShowcase: true });
                      } else if (e.target.checked === false) {
                        setFilterInputs({
                          ...filterInputs,
                          hasShowcase: null,
                        });
                      }
                    }}
                  />
                  <span>Showcase</span>
                </label>
              </div>

              <div className="chechbox-btn">
                <label htmlFor="freeForGirls">
                  <input
                    type="checkbox"
                    id="freeForGirls"
                    checked={
                      filterInputs.isFreeEntryForGirls === null
                        ? false
                        : filterInputs.isFreeEntryForGirls
                    }
                    onChange={(e) => {
                      if (e.target.checked === true) {
                        setFilterInputs({
                          ...filterInputs,
                          isFreeEntryForGirls: true,
                        });
                      } else if (e.target.checked === false) {
                        setFilterInputs({
                          ...filterInputs,
                          isFreeEntryForGirls: null,
                        });
                      }
                    }}
                  />
                  <span>Entrée gratuite filles</span>
                </label>
              </div>
            </div>
          </div>
          <div className="eveningsfilter-section2">
            <button
              type="button"
              onClick={() => {
                setModalShow(true);
                setFilterInputs({});
                setTodayOpen(false);
              }}
            >
              Plus de filtres
            </button>
            {modalShow && render}
            <button
              type="submit"
              onClick={() => {
                setSearch(filterInputs);
                scrollToTop();
                !!(
                  filterInputs?.location !== "" ||
                  filterInputs?.location !== undefined ||
                  filterInputs?.location !== null
                ) && setCitySearch(true);
                !!(
                  filterInputs?.location === "" ||
                  filterInputs?.location === undefined ||
                  filterInputs?.location === null
                ) && setCitySearch(false);
                setSearchedLocation(filterInputs?.location);
              }}
            >
              Rechercher
            </button>
          </div>
        </form>
        <p className="evenings-p">
          {zeroItem !== 0 &&
            citySearch &&
            `Les évènements des meilleurs clubs de ${searchedLocation}`}
        </p>
        <div className="results" id="results">
          {renderItems}
        </div>
      </div>
      <div className="evenings-map">
        <GoogleMapComponent marksObject={lngLatArray} />
      </div>
    </div>
  );
};

export default Evenings;

import { FC, ReactElement, useEffect, useState } from "react";
import LoadingSpinner from "../../../../components/LoadingSpinner/LoadingSpinner";
import MuiPagination from "../../../../components/MuiPagination/MuiPagination";
import { useClubTablesQuery } from "../../../../react-query/hooks/Clubs";
import AddNewTable from "../../../modals/AddNewClubTable";
import ClubTableInfo from "../../Molecules/TableInfo/ClubTableInfo";
import { NoResults } from "../NoResults/NoResults";

interface eventId {
  id: string;
}
const ClubTables: FC<eventId> = ({ id }): ReactElement => {
  const [page, setPage] = useState<number>(1);
  const [modalShow, setModalShow] = useState(false);
  const { data: { data, metaData } = {}, isLoading } = useClubTablesQuery(
    +id,
    page
  );
  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div>
          <div className="configurationButton">
            <button onClick={() => setModalShow(true)}>+ Ajouter</button>
          </div>
          {data?.length! > 0 ? (
            <div className="dashboardResults">
              <table>
                <tr>
                  <th>NOM</th>
                  <th>PRIX MINIMUM</th>
                  <th>PERSONNE MAXIMUM</th>
                  <th>TABLES DISPONIBLES</th>
                  <th className="lastElement actionColumn">ACTIONS</th>
                </tr>
                {data?.map((table) => {
                  return (
                    <ClubTableInfo
                      key={table.id}
                      tableId={table.id}
                      businessId={table.businessId}
                      name={table.name}
                      price={table?.price}
                      maxNumber={table.maxNumber}
                      numberOfTables={table.numberOfTables}
                      pageNumber={page}
                    />
                  );
                })}
              </table>
              <div className="dashboardPagination">
                <MuiPagination
                  pagesNumber={metaData?.pageCount!}
                  page={page}
                  setPage={setPage}
                />
              </div>
            </div>
          ) : (
            <NoResults text="Ajoutez ici les différents types de réservations que vos clients pourront sélectionner lors d’une réservation" />
          )}
          <AddNewTable
            show={modalShow}
            onHide={() => setModalShow(false)}
            clubId={+id}
            pageNumber={page}
            post
          />
        </div>
      )}
    </>
  );
};
export default ClubTables;

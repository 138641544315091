import axios from "../../initializations/axios";
import { AdminDashboardResponseModel } from "../../interfaces/models/getModels/AdminDashboardResponse.model";
import { MangerDashboardPayloadModel } from "../../interfaces/models/getModels/ManagerDashboardPayload.model";
import { ManagerDashboardResponseModel } from "../../interfaces/models/getModels/ManagerDashboardResponse.model";

// fetch admin dashboard data
export const fetchAdminDashboard =
  async (): Promise<AdminDashboardResponseModel> => {
    const { data } = await axios.get("Home/AdminDashboard");

    return data.data;
  };

// fetch manager dashboard data
export const fetchManagerDashboard = async (
  payload: MangerDashboardPayloadModel
): Promise<ManagerDashboardResponseModel> => {
  const { data } = await axios.get("Home/ManagerDashboard", {
    params: payload,
  });

  return data.data;
};

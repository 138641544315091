import { FC, ReactElement, useEffect, useState } from "react";
import LoadingSpinner from "../../../../components/LoadingSpinner/LoadingSpinner";
import MuiPagination from "../../../../components/MuiPagination/MuiPagination";
import { useClubPricingQuery } from "../../../../react-query/hooks/Clubs";
import AddNewTarif from "../../../modals/AddNewTarif";
import TarifInfo from "../../Molecules/TarifInfo/TarifInfo";
import { NoResults } from "../NoResults/NoResults";

interface ClubPricingProps {
  id: string;
}
const ClubPricing: FC<ClubPricingProps> = ({ id }): ReactElement => {
  const [modalShow, setModalShow] = useState(false);
  const [page, setPage] = useState(1);
  const { data: { data } = {}, isLoading } = useClubPricingQuery(+id, page);

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div>
          <div className="configurationButton">
            <button onClick={() => setModalShow(true)}>+ Ajouter</button>
          </div>
          {data?.data?.length! > 0 ? (
            <div className="dashboardResults">
              <table>
                <tr>
                  <th>CATÉGORIE</th>
                  <th>DÉSIGNATION</th>
                  <th>PRIX</th>
                  <th className="lastElement actionColumn">ACTIONS</th>
                </tr>
                {data?.data?.map((serviceType) => {
                  return serviceType?.services?.map((item) => {
                    return (
                      <TarifInfo
                        key={item.id}
                        serviceId={item?.id}
                        serviceTypeName={item?.serviceType?.name}
                        serviceTypeId={item?.serviceType?.id}
                        serviceName={item?.name}
                        price={item?.price}
                        clubId={+id}
                        currentPage={page}
                      />
                    );
                  });
                })}
              </table>
              <div className="dashboardPagination">
                <MuiPagination
                  pagesNumber={data?.metaData?.pageCount!}
                  page={page}
                  setPage={setPage}
                />
              </div>
            </div>
          ) : (
            <NoResults text="Aucuns tarifs ajoutés" />
          )}
          <AddNewTarif
            show={modalShow}
            onHide={() => setModalShow(false)}
            clubId={+id}
            pageNumber={page}
            post
          />
        </div>
      )}
    </>
  );
};
export default ClubPricing;

import { FC, useEffect, useMemo, useState } from "react";
import { getDjs, GetDJsResponse } from "../../../../api/Api";
import DJCard from "../../../components/shared/Cards/DJCard/DJcard";
import "./_DJsSection.scss";
import SkeletonDjCard from "../../../components/shared/Cards/SkeletonDjCard/SkeletonDjCard";
import MuiPagination from "../../../../components/MuiPagination/MuiPagination";

interface DJsSectionProps {
  inputWord?: string;
}

const DJsSection: FC<DJsSectionProps> = ({ inputWord }) => {
  const [djsData, setDjsData] = useState<GetDJsResponse>();
  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState(1);
  const pageNumber: number =
    djsData?.metaData?.pageCount !== undefined
      ? djsData?.metaData?.pageCount
      : 1;

  const params = useMemo(() => ({ pageNumber: page }), [page]);

  const getData = async () => {
    try {
      const data = await getDjs({ searchKeyWord: inputWord }, params);
      setDjsData(data as GetDJsResponse);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputWord, page]);

  const showSkeleton = (n: number) => {
    return [...Array(n)].map((e, i) => (
      <div className="item" key={`${2000 + i}`}>
        <SkeletonDjCard />
      </div>
    ));
  };

  return (
    <div className="djssection container-1">
      <div className="djssection-items ">
        {djsData?.data.map((dj) => {
          return (
            <div className="item" key={dj?.id}>
              <DJCard DJData={dj} />
            </div>
          );
        })}
        {loading && showSkeleton(6)}
      </div>
      <div className="djssection-pagination">
        {" "}
        <MuiPagination pagesNumber={pageNumber} page={page} setPage={setPage} />
      </div>
    </div>
  );
};

export default DJsSection;

import { apiRequest } from "./Api";
import { responseBody } from "./Shared";

// -----WalletPackets-----
export interface GetWalletPacketsResponse extends responseBody {
  data: {
    id: number;
    price: number;
    name: string;
    winks: number;
    likes: number;
    drinks: number;
    productId: string;
  }[];
}

export const getWalletPackets = async () =>
  apiRequest<GetWalletPacketsResponse>("get", "/Wallet/packets");

export const getClientClientId = async (id?: number) =>
  apiRequest<GetWalletPacketsResponse>("get", `/Wallet/stripeCheckout/${id}`);

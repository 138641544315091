import "../_ChangeResetVerifyStyle.scss";
import { useState } from "react";
import { checkResetPasswordCode, forgotPassword } from "../../../../api/Api";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useLoginRegisterResetPasswordContext } from "../../../../context/LoginRegisterResetPasswordContext/LoginRegisterResetPasswordContext";
import { CheckResetPasswordCodePostModel } from "../../../../interfaces/models/postModels/CheckResetPasswordCodePost.model";

const schema = Yup.object().shape({
  resetPasswordCode: Yup.string()
    .min(6, "Le code a 6 chiffres")
    .max(6, "Le code a 6 chiffres")
    .required("Obligatoire"),
});
interface getNewCodeValues {
  email: string;
  languageId: number;
}

const EmailVerification = () => {
  const LRRPCtx = useLoginRegisterResetPasswordContext();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<CheckResetPasswordCodePostModel>({
    resolver: yupResolver(schema),
  });

  const [error, setError] = useState<any>();
  const getNewCodeValues = {
    email: LRRPCtx.email,
    languageId: LRRPCtx.languageId,
  };
  const getNewCode = async (getNewCodeValues: getNewCodeValues) => {
    try {
      await forgotPassword(getNewCodeValues);
    } catch (e) {}
  };
  const handleEmailVerification = async (
    EmailVerificationInputs: CheckResetPasswordCodePostModel
  ) => {
    try {
      const response: any = await checkResetPasswordCode({
        ...EmailVerificationInputs,
        email: LRRPCtx.email,
      });
      if (response?.data === false) {
        setError(response?.errors[0]?.message);
      }
      LRRPCtx.setResetPasswordCode(EmailVerificationInputs.resetPasswordCode);
      response.isSuccess && LRRPCtx.setPage(4);
    } catch (e: any) {
      console?.log(error);
    }
  };

  return (
    <div className="changeresetverifystyle">
      <div>
        <div className="changeresetverifystyle-info">
          <h3>Vérification de l'E-mail</h3>
          <p>
            Veuillez entrer le code à 6 chiffres qui vous a été envoyé à{" "}
            <span>{LRRPCtx.email}</span>
          </p>
        </div>
        <form onSubmit={handleSubmit(handleEmailVerification)}>
          <label htmlFor="resetPasswordCode">Entrer le code</label>
          <div className="input">
            <input
              type="text"
              id="resetPasswordCode"
              placeholder="______"
              {...register("resetPasswordCode")}
            />
            {errors.resetPasswordCode && (
              <p className="error">{errors.resetPasswordCode.message}</p>
            )}
          </div>
          <button disabled={isSubmitting} type="submit" className="submit-btn">
            {isSubmitting ? "Soumettre..." : "Soumettre"}
          </button>
        </form>
        {!!error && <p className="error">{error}</p>}
      </div>
      <div className="changeresetverifystyle-bottom">
        <p>Vous n'avez pas reçu le code ?</p>
        <button onClick={() => getNewCode(getNewCodeValues)}>
          Obtenez un nouveau
        </button>
      </div>
    </div>
  );
};
export default EmailVerification;

import axios from "../../initializations/axios";

export const subscribe = async (subscriptionPackId?: number) => {
  const { data } = await axios.post(
    `Subscription/subscribe?id=${subscriptionPackId}`
  );
  return data;
};
export const cancelSubscription = async (subscriptionPackId?: number) => {
  const data = await axios.post(
    `Subscription/cancel?userSubscriptionId=${subscriptionPackId}`
  );
  return data;
};
export const updatePreviewSubscription = async (
  newSubscriptionPackId?: number
) => {
  const { data } = await axios.post(
    `Subscription/update/preview?packetId=${newSubscriptionPackId}`
  );
  return data;
};
export const updateSubscription = async (newSubscriptionPackId?: number) => {
  const data = await axios.post(
    `Subscription/update?packetId=${newSubscriptionPackId}`
  );
  return data;
};

export const reactivateSubscription = async (subscriptionPackId?: number) => {
  const data = await axios.post(
    `Subscription/reactivate?userSubscriptionId=${subscriptionPackId}`
  );
  return data;
};

import { Tabs, Tab } from "react-bootstrap";
import AllFavorites from "../../../page-sections/ProfilePages/ProfileFavorites/AllFavorites";
import DjsFavorites from "../../../page-sections/ProfilePages/ProfileFavorites/DjsFavorites";
import EveningsFavorites from "../../../page-sections/ProfilePages/ProfileFavorites/EveningsFavorites";
import NightclubsFavorites from "../../../page-sections/ProfilePages/ProfileFavorites/NightclubsFavorites";
import "./_ProfileFavorites.scss";

const ProfileFavorites = () => {
  return (
    <div className="profilefavorites">
      <h3 className="profilefavorites-title">Mes favoris</h3>
      <Tabs
        defaultActiveKey="All"
        transition={false}
        id="noanim-tab-example"
        className="profilefavorites-nav"
      >
        <Tab eventKey="All" title="All" mountOnEnter unmountOnExit>
          <AllFavorites />
        </Tab>
        <Tab
          eventKey="nightclubs"
          title="Nightclubs"
          mountOnEnter
          unmountOnExit
        >
          <NightclubsFavorites />
        </Tab>
        <Tab eventKey="evenings" title="Evenings" mountOnEnter unmountOnExit>
          <EveningsFavorites />
        </Tab>
        <Tab eventKey="djs" title="DJs" mountOnEnter unmountOnExit>
          <DjsFavorites />
        </Tab>
      </Tabs>
    </div>
  );
};

export default ProfileFavorites;

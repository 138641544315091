import Joi from "joi";

export const DjFormSchema = Joi.object({
  name: Joi.string().required().messages({
    "string.empty": "Name must not be empty",
  }),
  file: Joi.any().required().messages({
    "any.required": "Image is required",
  }),
});

import { FC } from "react";
import { Link } from "react-router-dom";
import Possibilities1 from "../../../../assets/photos/Possibilities1.webp";
import Possibilities2 from "../../../../assets/photos/Possibilities2.webp";
import Possibilities3 from "../../../../assets/photos/Possibilities3.webp";
import Possibilities4 from "../../../../assets/photos/Possibilities4.webp";
import Possibilities5 from "../../../../assets/photos/Possibilities5.webp";
import "./_Possibilities.scss";

interface PossibilitiesProps {}

const Possibilities: FC<PossibilitiesProps> = () => {
  return (
    <div className="possibilities container">
      <h3>Ce que 7Night peut vous apporter</h3>
      <div className="possibilities-items">
        <div className="possibilities-items-item">
          <img src={Possibilities1} alt="" />
          <h4>Obtenez plus de visibilité en ligne </h4>
          <p>
            Disponible dans toute la France, 7Night est la première plateforme
            de recherche et de réservation en club ! Vous pouvez bénéficier
            d’une page personnalisée et optimisée de votre club sur mobile,
            tablette et PC
          </p>
        </div>
        <div className="possibilities-items-item">
          <img src={Possibilities2} alt="" />
          <h4>Digitalisez les réservations</h4>
          <p>
            Rendez votre club réservable en ligne 24h/24, 7j/7 et centralisez
            toutes vos réservations dans une seule application de gestion
          </p>
        </div>
        <div className="possibilities-items-item">
          <img src={Possibilities3} alt="" />
          <h4>Apportez de la valeur à vos clients</h4>
          <p>
            Avec 7Night, vos clients disposent désormais d’une facilité de
            réservation en ligne ainsi qu’un système de rencontre lui permettant
            de retrouver un participant, lui offrir un verre ou bien de
            simplement matcher pour se rencontrer
          </p>
        </div>
        <div className="possibilities-items-item">
          <img src={Possibilities4} alt="" />
          <h4>Augmentez votre taux d’occupation</h4>
          <p>
            Consultez votre fichier client, triez parmi de nombreux filtres et
            envoyez-leur des notifications afin de promouvoir vos évènements
          </p>
        </div>
        <div className="possibilities-items-item">
          <img src={Possibilities5} alt="" />
          <h4>Réduisez les no-shows</h4>
          <p>
            Réduisez les no-shows à l’aide des outils de 7Night grâce aux
            notifications de rappels automatiques
          </p>
        </div>
      </div>
      <Link to="/why-7night">
        <button className="possibilities-otherBenefitsBtn">
          Voir d’autres avantages
        </button>
      </Link>
    </div>
  );
};

export default Possibilities;

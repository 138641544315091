import './_SkeletonClubCard.scss';

const SkeletonClubCard = () => {
    return (
      <section className='skeletonclubcard'>
      <div className='skeletonclubcard-img'>
        <div className="img"></div>         
      </div>
      <div className='skeletonclubcard-content'>
        <div>
          <div className='h2'></div>
          <div className='info-item'> 
            <div className="img"></div>
            <span className='address'></span>
          </div>
        </div>
        <div className='ratingstar'></div>
      </div>
  </section>
    )
}

export default SkeletonClubCard;
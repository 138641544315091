import { Link } from "react-router-dom";
import "./_TermsOfServices.scss";
import goTop from "../../../assets/icons/goTop.png";

const TermsOfServices = () => {
  return (
    <div className="termsofservices">
      <div className="termsofservices-bg"></div>
      <div className="termsofservices-content">
        <h1>TERMS OF SERVICES</h1>
        <div className="SectionsTitles">
          <p>A. Mentions Légales</p>
          <p>B. Conditions d'Utilisation</p>
          <p>C. Déclaration Relative à la Confidentialité et aux Cookies</p>
        </div>
        <div className="SectionA">
          <h2>A. MENTIONS LEGALES</h2>
          <div className="SectionA content">
            <p>
              Le site <b>https://www.7night.fr</b> et les applications mobiles
              et tablettes 7Night sont édités par:
            </p>
            <h3>7Night</h3>
            <p>Société par actions simplifiée au capital de 43 000,20 euros.</p>
            <p>
              Siège social: 45 rue frédéric joliot curie, 13013 Marseille RCS
            </p>
            <p>Marseille fN° TVA intracom : FR25900318858</p>
            <p>
              Téléphone: <a href="tel:+33 04 91 05 50 71">+33 04 91 05 50 71</a>
            </p>
            <p>
              Email: <a href="mailto:contact@7night.fr">contact@7night.fr</a>
            </p>
            <p>
              Le site <Link to="/">https://www.7night.fr</Link> est hébergé par:
            </p>
            <p>OVH</p>
            <p>2 rue Kellermann - 59100 Roubaix - France.</p>
            <p>
              <a href="http://ovh.com">http://ovh.com</a>
            </p>
          </div>
        </div>
        <div className="SectionB">
          <h2>B. CONDITIONS D'UTILISATION</h2>
          <h3>Dernière mise à jour : 01/01/2022</h3>
          <p>
            Les présentes Conditions d'Utilisation ainsi que la Déclaration
            relative à la confidentialité et aux cookies forment votre contrat
            avec 7Night (si vous êtes propriétaire d'un club acceptant les
            réservations par le biais du Site 7Night, ces dispositions
            s'appliquent également à vous en plus des conditions générales et
            particulières de vente que vous avez acceptées lors de votre
            inscription). Lisez-les attentivement ; ces dispositions vous
            engagent dès lors que vous utilisez le site 7Night. Attention: Si
            vous n'êtes pas en accord avec tout ou partie des présentes
            Conditions d'Utilisation, il vous est vivement recommandé de ne pas
            utiliser le Site 7Night.
          </p>
          <div className="SectionB content">
            <h2>TITRE 1. CONDITIONS GENERALES D'UTILISATION</h2>
            <h3>Définitions</h3>
            <p>
              <span>« 7Night »</span>: désigne la société 7Night.
            </p>
            <p>
              <span>« Site 7Night »</span>: désigne l'ensemble des sites web et
              mobile accessibles à l'adresse https://www.7night.fr et des
              applications mobiles et tablettes 7Night.
            </p>
            <p>
              <span>« Utilisateur »</span>: désigne toute personne physique âgée
              de plus de 18 ans, capable de souscrire des contrats, visitant le
              Site 7Night et/ou titulaire d'un compte sur le Site 7Night.
            </p>
            <p>
              <span>« Conditions d'Utilisation »</span>: désigne l'ensemble des
              présentes conditions générales d'utilisation et conditions
              particulières d'utilisation.
            </p>
            <p>
              <span>« Contenu club »</span>: désigne les éléments de la fiche
              club fournis à 7Night, par le clubbeur ou que le clubbeur a
              autorisé 7Night à télécharger sur le Site 7Night, notamment les
              prix des plats et des boissons, le prix moyen d'un repas, le nom
              du chef, les disponibilités du club, la carte, le calendrier, les
              thématiques du club et les photos du club.
            </p>
            <p>
              <span>« Contenu Utilisateur »</span>: désigne l'ensemble des
              éléments fournis par l'Utilisateur sur le Site 7Night, composés
              notamment de texte, document, image, photo, avis ou droit de
              réponse.
            </p>
            <h3 className="underline">
              Acceptation et modification des Conditions d'Utilisation
            </h3>
            <p>
              Un service de recherche et de réservation en ligne de table de
              club est proposé par 7Night à l'Utilisateur, sous réserve de son
              acceptation inconditionnelle des présentes Conditions
              d'Utilisation.
              <br /> L'Utilisateur déclare et reconnaît avoir lu l'intégralité
              des termes des présentes Conditions d'Utilisation. En outre, la
              connexion à l'un des quelconques services proposés sur le Site
              7Night emporte une acceptation sans réserve par l'Utilisateur des
              présentes Conditions d'Utilisation.
              <br /> 7Night se réserve la possibilité de modifier à tout moment,
              en tout ou partie, les Conditions d'Utilisation. Il appartient en
              conséquence à l'Utilisateur de consulter régulièrement la dernière
              version des Conditions d'Utilisation affichée sur le Site 7Night.
              <br />
              L'Utilisateur est réputé accepter cette dernière version à chaque
              nouvelle connexion sur le Site 7Night. En accédant au Site 7Night
              ou en l'utilisant de quelque façon que ce soit, l'Utilisateur
              accepte d'être lié par les présentes Conditions d'Utilisation.
            </p>
            <h3>1. Description du Site 7Night</h3>
            <p>
              L'utilisation du Site 7Night est soumise à des limitations de
              responsabilité de la part de 7Night.
            </p>
            <h3>1.1 Plateforme de mise en relation</h3>
            <p>
              Le Site 7Night est une plateforme permettant la mise en relation
              de l'Utilisateur avec les clubs présents sur le Site 7Night. A ce
              titre, 7Night agit exclusivement comme un intermédiaire dans le
              cadre de la réservation desdits club par l'Utilisateur.
            </p>
            <h3>
              1.2 Recherche et réservation de tables de club en temps réel
            </h3>
            <p>
              Le Site 7Night permet notamment à l'Utilisateur de rechercher et
              de réserver en temps réel une place dans un club.
            </p>
            <p>
              Le Site 7Night permet également à l'Utilisateur d'annuler sa
              réservation en cas d'imprévu.
            </p>
            <h3>
              1.3 Offres promotionnelles proposées par les clubs référencés sur
              7Night
            </h3>
            <p>
              Le Site 7Night permet à l'Utilisateur, si et seulement s'il
              réserve par le biais du Site 7Night, de bénéficier d'offres
              promotionnelles proposées par les clubs référencés sur le Site
              7Night. Tous les clubs ne proposent pas d'offres promotionnelles.
              Les conditions de validité de ces offres promotionnelles sont
              explicitées sur le Site 7Night. L'Utilisateur s'oblige à vérifier
              les conditions de validité d'une offre promotionnelle avant de
              réserver sur le Site 7Night et ne pourra en aucun cas réclamer au
              club le bénéfice d'une offre promotionnelle en dehors des
              conditions de validité telles qu'explicitées sur le Site 7Night,
              et/ou en dehors de la procédure de réservation avec offre
              promotionnelle.
            </p>
            <p>
              Parmi ces conditions de validité, l'Utilisateur et l'ensemble de
              ses convives doivent se présenter au club réservé à l'horaire de
              réservation. L'Utilisateur est informé que si lui et l'ensemble de
              ses convives ne se sont pas présentés au club dans les 15 minutes
              suivant l'horaire de réservation, le club pourra ne pas appliquer
              l'offre promotionnelle associée à la réservation à l'Utilisateur
              ainsi qu'à l'ensemble de ses convives. Par souci de clarté, cela
              signifie que si l'Utilisateur ou un des convives est en retard de
              plus de 15 minutes, à la fois l'Utilisateur et tout le groupe de
              ses convives perdront le bénéfice automatique de l'offre
              promotionnelle et que le club pourra seul décider d'appliquer ou
              non l'offre promotionnelle à tout ou partie des convives.
            </p>
            <h3>1.4 Système de rencontre</h3>
            <p>
              L'application 7Night permet aux utilisateurs de retrouver les
              profils des personnes présentes dans le même événement qu'eux. Ils
              peuvent liker des profils, créer des matchs et s'offrir des drinks
              afin de se rapprocher durant l'évènements ou après.
            </p>
            <h3>2. Gratuité du Site 7Night</h3>
            <p>
              Les services proposés sur le Site 7Night sont généralement
              gratuits.
            </p>
            <p>
              Des tarifs pourront éventuellement être applicables, notamment en
              fonction de l'évolution des services proposés, l'évolution du
              réseau, de la technique et/ou des contraintes légales.
              L'Utilisateur en sera alors dûment informé par la modification des
              présentes Conditions d'Utilisation et/ou par l'insertion dans le
              Site 7Night de conditions particulières relatives aux services
              payants.
            </p>
            <h3>2.1 Conclusion du contrat, début du contrat, prix</h3>
            <p>
              <span>2.1.1</span>Le contrat entre 7Night et l'utilisateur est
              conclu par la création d'un compte utilisateur au moyen de
              l'inscription gratuite de l'utilisateur sur le site Internet ou
              les applications mobiles respectives.
            </p>
            <p>
              <span>2.1.2</span>L'utilisateur peut s'inscrire gratuitement en
              remplissant de formulaire d'inscription. L'utilisateur peut
              s'enregistrer via des fonctions de connexion tierces (par ex.
              Facebook Connect). Certaines données sélectionnées à partir des
              profils respectifs de l'utilisateur sont transférées dans la base
              de données 7Night. En s'inscrivant via les fonctions Connect,
              l'utilisateur accepte les conditions générales respectives du
              fournisseur tiers et consent au transfert de certaines données
              dans la base de données 7Night.
            </p>
            <p>
              <span>2.1.3</span> 7Night se réserve le droit de refuser
              l’inscription d’utilisateurs pour des raisons objectives. Ces
              raisons peuvent être, par exemple : violation des conditions
              d’utilisation, signalements de la prévention de la fraude, doutes
              sur l’identité, suspicion de SPAM, doutes sur l’âge, tromperie,
              inscription dans des marchés où 7Night n’est pas disponible.
              7Night peut également subordonner l’inscription à d’autres
              mesures, y compris des mesures d’identification ou de prévention
              du SPAM.
            </p>
            <p>
              <span>2.1.4</span> 7L’utilisation du service est gratuite.
              L’utilisateur peut activer des fonctions payantes en achetant ou
              en concluant un abonnement.
            </p>
            <p>
              <span>2.1.5</span> Un seul compte utilisateur peut être créé par
              utilisateur. Les comptes utilisateurs existants doivent être
              supprimés avant qu’un nouveau compte utilisateur puisse être créé.
              L’inscription multiple et la réinscription sont interdites pour
              les utilisateurs bloqués par 7Night.
            </p>
            <p>
              <span>2.1.6</span> L’utilisation du service n’est autorisée que
              pour les personnes physiques majeures.
            </p>
            <p>
              <span>2.1.7</span> L’utilisation du service n’est pas autorisée
              pour les délinquants sexuels. Il s’agit de personnes qui sont
              enregistrées aux États-Unis comme « délinquants sexuels » ou qui
              ont été condamnées pour des délits sexuels (§ 174 à 180 ou § 182
              du Code pénal allemand (StGB) ou § 180 a, 181 a, 183 à 184g du
              StGB ou pour des délits criminels qui ont également un rapport
              particulier avec la protection des enfants et adolescents (§ 171,
              225, 232 à 233 a, 234, 235 ou 236 du StGB) ou leurs équivalents
              régionaux respectifs.
            </p>
            <p>
              <span>
                2.1.8. NOUS NE PROCÉDONS À AUCUNE VÉRIFICATION DES ANTÉCÉDENTS
                CRIMINELS
              </span>
              , mais nous évaluerons toutefois les informations accessibles au
              public en cas de suspicion justifiée pour nous assurer de
              l’absence de violation de nos conditions d’utilisation.
            </p>
            <h3>3. Fonctions payantes</h3>
            <p>
              <span>3.1</span> L’utilisateur peut acquérir différentes valeurs
              ajoutées payantes (« fonctions payantes »), par exemple des
              abonnements, des biens virtuels (par exemple des crédits valables
              dans l’application ou autres) dans les applications et sur le site
              Internet.
            </p>
            <p>
              <span>3.2</span> Les fonctions qui sont assujetties à des frais
              peuvent avoir une durée fixe.
            </p>
            <p>
              <span>3.3</span> Les fonctions payantes peuvent être liées au
              compte utilisateur.
            </p>
            <p>
              <span>3.4</span> Le prix valide de la fonction payante sera
              affiché à l’utilisateur avant l’achat final. Les conditions des
              fonctions payantes respectives s’appliquent. e. Les utilisateurs
              ayant leur résidence habituelle en dehors de l’Union européenne ne
              peuvent acheter des fonctions rémunérées que dans le pays de leur
              résidence habituelle. Les utilisateurs ayant leur résidence
              habituelle dans l’Union européenne ne peuvent acheter de fonctions
              payantes que dans l’Union européenne.
            </p>
            <h3>4. Biens virtuels</h3>
            <p>
              <span>4.1</span> Les biens virtuels sont des biens virtuels
              permanents ou consommables que l’utilisateur peut acheter via le
              site Internet ou dans les applications.
            </p>
            <p>
              <span>4.2</span> Les biens virtuels sont des biens virtuels que
              l’utilisateur peut acheter via le site Internet ou dans les
              applications. Ces biens virtuels sont liés à un compte utilisateur
              et l’utilisateur ne peut pas transférer de biens virtuels à
              d’autres comptes utilisateur à moins que les conditions de la
              fonction payante concernée ne décrivent le contraire.
            </p>
            <p>
              <span>4.3</span> Lors de l’achat de biens virtuels, l’utilisateur
              reçoit des biens virtuels liés au compte utilisateur, par exemple
              des crédits ou autres, qu’il peut utiliser dans l’application ou
              sur le site Internet pour activer des fonctions ou pour acheter
              d’autres biens virtuels, tels que des effets ou des cadeaux.
            </p>
            <p>
              <span>4.4</span> Si l’utilisateur supprime ou résilie son compte
              utilisateur, utilisateur perd irrévocablement ses biens virtuels.
            </p>
            <h3>5. Obligations de l’utilisateur</h3>
            <p>
              <span>5.1.1</span> Informations vraies et exactes, vérification
              par 7NIGHT
            </p>
            <p>
              <span>5.1.2</span> L’utilisateur peut utiliser un pseudonyme pour
              la plate-forme. Si l’utilisateur décide d’ajouter des informations
              (par exemple des détails ou des photos) à son compte utilisateur,
              ceux-ci doivent être vrais et décrire sa personnalité avec
              précision. En particulier, les informations sont inexactes ou
              fausses si les informations fournies ne permettent pas
              d’identifier l’utilisateur ou désignent une personne autre que
              l’utilisateur.
            </p>
            <p>
              <span>5.1.3</span> L’utilisateur est tenu de s’assurer qu’il est
              joignable à l’adresse e-mail qu’il a indiquée lors de son
              inscription. L’utilisateur doit tenir à jour l’adresse e-mail
              enregistrée sur 7NIGHT et traiter sa communication avec 7NIGHT via
              cette adresse. Dans ses communications, l’utilisateur est tenu
              d’utiliser l’adresse e-mail enregistrée sur 7NIGHT afin de
              permettre à 7NIGHT de relier cette adresse à un compte
              utilisateur. Si l’utilisateur communique à partir d’une autre
              adresse ou qu’il ne communique pas par e-mail, il doit prendre les
              précautions nécessaires pour pouvoir confirmer l’autorisation de
              communiquer avec 7NIGHT à partir de cette adresse. Tout retard dû
              à un manque d’information de la part de l’utilisateur relève de la
              responsabilité de l’utilisateur.
            </p>
            <p>
              <span>5.1.4</span> L’utilisateur s’engage à ne fournir que ses
              propres données (y compris son adresse e-mail) et non celles d’une
              autre personne. Il s’engage notamment à ne pas divulguer
              frauduleusement les coordonnées bancaires ou de carte de crédit de
              tiers.
            </p>
            <p>
              <span>5.1.5</span> 7NIGHT peut vérifier l’exactitude des
              informations fournies par l’utilisateur, si nécessaire, afin de
              s’assurer de l’identité des utilisateurs et du fonctionnement de
              la plate-forme. A la demande de 7NIGHT, l’utilisateur devra
              prouver ou vérifier son identité. En cas de refus ou de
              non-vérification, 7NIGHT a droit à une résiliation extraordinaire
              sans préavis.
            </p>
            <p>
              <span>
                5.2 Comportement et contenu de l’utilisateur, octroi des droits
                d’utilisation
              </span>
            </p>
            <p>
              <span>5.2.1</span> L’utilisateur est tenu de se comporter sur la
              plate-forme 7NIGHT conformément à notre politique sur le contenu
              et la conduite. Tout comportement raciste, offensant,
              discriminatoire, harcelant, diffamatoire, sexuel, pornographique,
              glorifiant la violence ou autrement illégal est interdit.
            </p>
            <p>
              <span>5.2.2</span> L’utilisateur accorde à 7NIGHT des droits
              libres, non exclusifs, transférables, concédables en sous-licence
              et illimités dans le temps, dans l’espace et dans le contenu
              (ci-après le « droit d’utilisation ») d’utiliser et d’exploiter le
              contenu utilisateur à des fins marketing pour tout contenu généré,
              transmis, enregistré ou publié sur la plate-forme 7NIGHT (par
              exemple des images, textes, commentaires) (ci-après dénommé le «
              contenu utilisateur »).
            </p>
            <p>
              <span>5.2.3</span> L’utilisateur est seul responsable de son
              contenu. L’utilisateur est tenu de s’assurer que son contenu
              utilisateur n’est pas{" "}
              <span>
                raciste, offensant, discriminatoire, harcelant, diffamatoire,
                sexuel, pornographique, glorifiant la violence ou autrement
                illégal.
              </span>{" "}
              7NIGHT a le droit, sans y être obligé, de supprimer ou de résilier
              les contenus utilisateur qui enfreignent ces règles et de bloquer
              temporairement le compte utilisateur de l’utilisateur responsable
              ou de mettre fin à la relation contractuelle.
            </p>
            <p>
              <span>5.2.4</span> Le droit d’utilisation comprend le droit
              d’utilisation commerciale et non commerciale, y compris toutes les
              formes d’utilisation actuellement inconnues mais connues à
              l’avenir. En particulier, le droit accordé comprend le droit de
              reproduction, de distribution et d’exposition, le droit de
              reproduction publique, y compris le droit de conférence,
              d’exécution et de démonstration, le droit d’accès public, le droit
              de diffusion en continu, le droit de reproduction par image ou
              support sonore ainsi que le droit d’accès public (en particulier
              via Internet) et de reproduction ainsi que tous droits du
              fabricant de base de données conformément au § 87 b de la loi sur
              le droit d’auteur (UrhG), notamment le droit à reproduction,
              distribution ou reproduction publique et en particulier le droit à
              figurer dans des bases de données. L’octroi de droits comprend
              notamment le droit illimité de rendre le contenu conçu par
              l’utilisateur, en particulier les vidéos, graphiques et textes,
              accessibles au public sur le site Internet de 7NIGHT ou dans les
              moteurs de recherche ainsi que dans les réseaux sociaux et de les
              utiliser pour des mesures publicitaires de toute nature ainsi que
              le droit de les modifier et les remodeler. Si le contenu est
              utilisé en dehors des applications de 7NIGHT ou de la plate-forme
              7NIGHT, l’utilisateur a le droit d’être nommé comme auteur sous la
              forme de son nom de profil enregistré dans son compte utilisateur
              et d’une référence à la plate-forme 7NIGHT. En cas d’utilisation
              dans les applications de 7NIGHT ou sur la plate-forme 7NIGHT, la
              désignation en tant qu’auteur se fait dans le cadre de la manière
              habituelle pour la plate-forme.
            </p>
            <p>
              <span>5.2.5</span> L’utilisateur assure et garantit qu’il est
              autorisé à accorder le droit d’utilisation dans ce formulaire à
              ses contenus utilisateur et qu’il peut en disposer librement dans
              le formulaire contractuel. Si l’utilisateur a connaissance de
              l’existence de droits de tiers, il doit en informer 7NIGHT
              immédiatement. L’utilisateur est tenu de décharger 7NIGHT de toute
              réclamation de tiers dans ce contexte et de rembourser les frais
              de défense raisonnables.
            </p>
            <h3>5.3. L’utilisation de la plateforme</h3>
            <p>
              7NIGHT n’est autorisée que pour un usage privé. En cas
              d’utilisation commerciale non autorisée, 7NIGHT peut résilier le
              compte utilisateur de manière extraordinaire et sans préavis.
            </p>
            <p>
              <span>5.3.1</span> Messages de l’utilisateur <br />
              L’utilisateur n’enverra aucun message privé qui soit{" "}
              <span>
                raciste, offensant, discriminatoire, harcelant, diffamatoire,
                sexuel, pornographique, glorifiant la violence ou autrement
                illégal.
              </span>
              7NIGHT peut vérifier les messages de l’utilisateur pour détecter
              tout soupçon de SPAM dans le filtre anti-spam utilisé par 7NIGHT
              et les utiliser pour entraîner le filtre anti-spam.
            </p>
            <h3>6. Rétractation</h3>
            <p>
              Si l’utilisateur réside dans un pays de l’Union Européenne, le
              droit de rétractation suivant pour les consommateurs s’applique à
              l’utilisation de 7NIGHT dans le cadre de la conclusion des
              abonnements payants et de l’achat de crédits:
            </p>
            <h3>6.1 Instruction de la rétractation</h3>
            <div className="paddingLeft">
              <h3>Droit de rétractation</h3>
              <p>
                Vous avez le droit de vous rétracter de ce contrat dans un délai
                de quatorze jours sans donner de raisons. Le délai de
                rétractation est de quatorze jours à compter de la date de
                conclusion du contrat. Pour exercer votre droit de rétractation,
                vous devez nous informer (7NIGHT 45 Rue frédéric joliot curie,
                13013 Marseille ; Email : support@7night.fr) de votre décision
                de vous rétracter du présent contrat par une déclaration claire
                (par exemple par lettre, fax ou e-mail envoyé par voie postale).
                Vous pouvez utiliser le modèle de formulaire de rétractation
                ci-joint, qui n’est toutefois pas obligatoire.
              </p>
              <p>
                Pour respecter le délai de rétractation, il suffit d’envoyer la
                notification de l’exercice du droit de rétractation avant
                l’expiration du délai de rétractation.
              </p>
              <h3>Conséquences de la rétractation</h3>
              <p>
                Si vous vous rétractez du présent contrat, nous vous
                rembourserons tous les paiements que nous avons reçus de votre
                part, y compris les frais de livraison (à l’exception des frais
                supplémentaires résultant de votre choix d’un mode de livraison
                autre que la livraison standard la moins chère que nous
                offrons), immédiatement et au plus tard quatorze jours après la
                date à aquelle nous recevons votre avis de rétractation du
                présent contrat. Pour ce remboursement, nous utiliserons le même
                moyen de paiement que celui que vous avez utilisé pour la
                transaction originale, sauf accord contraire exprès avec vous ;
                en aucun cas des frais ne vous seront facturés en vertu de ce
                remboursement. Si vous avez demandé que les services commencent
                pendant la période de rétractation, vous devrez nous payer un
                montant raisonnable égal à la proportion des services déjà
                fournis au moment où vous nous avisez de l’exercice de votre
                droit de rétractation en vertu du présent contrat par rapport au
                total des services fournis prévus dans le contrat.
              </p>
              <p>Exemple de formulaire de rétractation</p>
              <p>
                2. Si vous souhaitez vous rétracter de ce contrat, veuillez
                remplir ce formulaire et nous le retourner. À l’adresse suivante
                : 7NIGHT 45 Rue frédéric joliot curie, 13013 Marseille. Email :
                support@7night.fr Je me rétracte/nous nous rétractons (*) par la
                présente du contrat que j’ai/nous avons conclu (*) concernant
                l’achat des biens (*)/
              </p>
              <p>la fourniture des services (*) suivants</p>
              <p>Commandés le (*)/ reçus le (*): _______</p>
              <p>Nom du/des consommateur(s) : _______</p>
              <p>Adresse du/des consommateur(s) : _________</p>
              <p>Signature du/des consommateur(s) : Lieu, date</p>
              <p>(uniquement en cas de communication sur papier)</p>
              <p>(*) Rayer la mention inutile.</p>
            </div>
            <h3>7. Services et sites tiers</h3>
            <h3>7.1 Services offerts par des tiers</h3>
            <p>
              L'Utilisateur reconnaît que le Site 7Night renvoie à des
              prestations de service payantes. En particulier, toute réservation
              effectuée sur le Site 7Night renvoie à une prestation de club
              effectuée par un tiers clubbeur qui sera payante.
            </p>
            <p>
              L’Utilisateur est informé que tout prix ou gamme de prix
              concernant un tiers prestataire affiché sur le Site 7Night n’est
              fourni qu'à titre indicatif et par souci de commodité. En aucun
              cas 7Night ne garantit l'exactitude de telles informations.
            </p>
            <h3>7.2 Liens vers des sites tiers</h3>
            <p>
              Le Site 7Night peut contenir des liens renvoyant à des sites gérés
              par des tiers. Ces hyperliens sont uniquement proposés à titre
              indicatif. 7Night ne contrôle pas ces sites et n'est pas
              responsable de leur contenu ni du respect de la vie privée ou
              d'autres pratiques de ces sites. La présence de liens vers ces
              sites ne saurait constituer une approbation des éléments présents
              sur ces sites ni une quelconque association avec les gestionnaires
              de ces sites. Il appartient à l’Utilisateur de faire toutes
              vérifications qui lui semblent nécessaires ou opportunes avant
              d’utiliser ces sites et/ou de procéder à une quelconque
              transaction avec l'un d’eux.
            </p>
            <h3>8. Accès et Utilisation du Site 7Night</h3>
            <h3>8.1 Conditions d’éligibilité</h3>
            <p>
              L’utilisation du Site 7Night est soumise aux conditions
              d’éligibilité cumulatives suivantes : en tant qu’Utilisateur (i)
              vous avez au moins 18 ans ; (ii) vous disposez de la capacité
              juridique requise pour vous soumettre à des obligations juridiques
              contraignantes; (iii) et les réservations que vous effectuerez sur
              le Site 7Night, pour vous ou pour toute autre personne pour
              laquelle vous êtes légalement autorisé à agir, seront légitimes.
            </p>
            <h3>8.2 Accès au Site 7Night</h3>
            <p>
              7Night se réserve le droit de suspendre, supprimer ou de modifier
              tout ou partie du Site 7Night ou des services offerts sur le Site
              7Night sans préavis. 7Night se réserve de plus le droit de
              suspendre l’accès au Site 7Night, à tous les, ou à une partie des,
              Utilisateurs pour maintenance, en cas d’urgence (cyberattaque,
              etc.) ou pour toute autre raison, et ce à tout moment.
            </p>
            <h3>8.3 Conditions d’ouverture d’un compte</h3>
            <p>
              En ouvrant un compte, l'Utilisateur accepte, expressément et sans
              réserve, les termes des présentes Conditions d’Utilisation.
            </p>
            <p>
              L'Utilisateur s'engage à transmettre des renseignements exacts, à
              jour et véritables notamment sur sa civilité, son nom, son ou ses
              prénoms, son adresse email, son numéro de téléphone, nécessaires à
              sa bonne identification, en vue de l'ouverture et du maintien de
              son compte.
            </p>
            <h3>8.4 Confidentialité des identifiants du compte</h3>
            <p>
              Lors de la création de son compte, l'Utilisateur choisit un
              identifiant et un mot de passe (ci-après « Identifiants ») lui
              permettant d'accéder à son compte.
            </p>
            <p>
              Les Identifiants sont personnels et confidentiels. Ils ne peuvent
              être changés que sur demande de l'Utilisateur ou à l'initiative de
              7Night.
            </p>
            <p>
              L'Utilisateur est seul et entièrement responsable de l'utilisation
              de son compte et/ou des Identifiants le concernant et s'engage à
              mettre tout en œuvre pour conserver secret ses Identifiants et à
              ne pas les divulguer, à qui que ce soit, sous quelque forme que ce
              soit.
            </p>
            <p>
              En cas de perte ou de vol d'un des Identifiants le concernant,
              l'Utilisateur est responsable de toute conséquence dommageable de
              cette perte ou de ce vol, et doit utiliser, dans les plus brefs
              délais, la procédure lui permettant de les modifier, qui peut être
              consultée par l’Utilisateur dans la rubrique "Mes informations
              personnelles" du compte de l’Utilisateur.
            </p>
            <h3>8.5 Clôture du compte</h3>
            <p>
              L’Utilisateur a la possibilité de clôturer son compte à tout
              moment. Pour cela, il doit envoyer sa demande de clôture de compte
              par email à:
              <a href="mailto:support@7night.fr">support@7night.fr</a>
            </p>
            <p>
              7Night s’efforcera de traiter toute demande de clôture de compte
              dans un délai raisonnable.
            </p>
            <p>
              L’Utilisateur est informé qu’à compter de la clôture de son
              compte, celui-ci ne pourra plus bénéficier des services offerts
              par 7Night, ses réservations en cours seront annulées.
            </p>
            <h3>8.6 Obligations de l’Utilisateur – Actions Prohibées</h3>
            <p>
              L’Utilisateur s’engage à utiliser le Site 7Night conformément aux
              présentes Conditions d’Utilisation et au droit en vigueur.
              Notamment, l’Utilisateur s’engage à ne pas réaliser les actions
              prohibées ci-dessous:
            </p>
            <ul>
              <li>
                Le fait de ne pas se rendre au club sans avoir annulé la
                réservation au préalable (no-show) constitue une action
                prohibée.
              </li>
              <li>
                Le contenu et les données du Site 7Night (incluant, mais sans
                s'y limiter, les messages, données, informations, textes,
                musiques, sons, photos, graphiques, vidéos, cartes, icônes,
                logiciels, codes ou tout autre élément), tout comme
                l'infrastructure utilisée pour fournir ces contenus et ces
                informations appartiennent à 7Night; l’Utilisateur accepte de ne
                pas modifier, copier, distribuer, transmettre, afficher, mettre
                à disposition, reproduire, publier, octroyer une licence, créer
                des œuvres dérivées, transférer, ou vendre ou revendre toute
                information, tout logiciel, produit ou service obtenus depuis ou
                grâce au Site 7Night.
              </li>
              <li>
                Sont strictement interdits toute copie, transmission,
                reproduction, ré-édition, redistribution ou tout envoi du
                contenu du Site 7Night, en totalité ou en partie, sans
                l'autorisation préalable et écrite de 7Night. Pour obtenir cette
                autorisation, contacter 7Night à l'adresse suivante :
              </li>
            </ul>
            <p>7Night</p>
            <p>45 rue frédéric joliot curie</p>
            <p>13013 Marseille</p>
            <p>En outre, l’Utilisateur s’engage à ne pas :</p>
            <ul>
              <li>
                utiliser le Site 7Night ou ses contenus à des fins illégales,
                illégitimes ou frauduleuses ;
              </li>
              <li>
                déposer sur le Site 7Night de Contenu Utilisateur ou Contenu
                clubbeur inexact, illégal et notamment portant atteinte à la vie
                privée, injurieux, indécent, menaçant, incitant à la haine ou la
                violence, ou dont les droits de propriété intellectuelle ne lui
                appartiennent pas et sans la permission expresse du titulaire de
                droits ;
              </li>
              <li>
                utiliser, surveiller, extraire ou copier l’architecture, le
                contenu ou les données du Site 7Night ou les actions des
                Utilisateurs sur le Site 7Night à l'aide d'un robot, d'une
                araignée, d'un scraper, d’un logiciel espion, d’un enregistreur
                de frappe clavier ou de tout autre programme ou dispositif
                automatique ou procédé manuel, à quelque fin que ce soit;
              </li>
              <li>
                violer les restrictions des fichiers d’exclusion des robots sur
                le Site 7Night ou contourner les mesures visant à empêcher ou
                limiter l’accès au Site 7Night;
              </li>
              <li>
                prendre de mesure qui impose, ou pourrait imposer une charge
                déraisonnable ou excessive aux infrastructures du Site 7Night;
              </li>
              <li>
                établir de lien invisible menant au Site 7Night pour toute
                raison;
              </li>
              <li>
                « encadrer », « refléter » ou incorporer toute partie du Site
                7Night dans un autre site; et
              </li>
              <li>
                essayer de modifier, traduire, adapter, réviser, décompiler,
                désassembler, assembler à rebours tout programme logiciel
                utilisé par 7Night en lien avec le Site 7Night ou ses services.
              </li>
            </ul>
            <h3>8.7 Sanction des manquements contractuels</h3>
            <h3>
              8.7.1 Suspension ou arrêt définitif du ou des services du Site
              7Night
            </h3>
            <p>
              En cas d'inexécution ou de non-respect, total ou partiel, par
              l'Utilisateur de l'une des obligations ou stipulations prévues par
              les présentes Conditions d’Utilisation ou en cas d’exécution par
              L’Utilisateur d’une ou plusieurs actions prohibées prévue par les
              présentes Conditions d’Utilisation, ou pour tout autre motif
              raisonnable 7Night pourra modifier, suspendre, limiter ou
              supprimer l'accès à tout ou partie du service du Site 7Night, et
              notamment désactiver le compte de l’Utilisateur, sans préavis et
              sans que l’Utilisateur ne puisse réclamer aucune indemnité
              quelconque, et sans préjudice des indemnités que 7Night pourrait
              réclamer par ailleurs en justice.
            </p>
            <h3>8.7.2 Dommages-intérêts</h3>
            <p>
              Sans préjudice des sanctions opérées par 7Night en vertu de
              l’article 4.7.1, 7Night sera également en droit de réclamer des
              indemnités destinées à compenser le préjudice subi.
            </p>
            <h3>9. Avis et Contenu Utilisateur</h3>
            <p>
              L'Utilisateur doit suivre les règles suivantes pour publier tout
              Contenu Utilisateur et notamment tout avis sur le site concernant
              un club référencé sur le Site 7Night.
            </p>
            <h3>9.1 Conditions de publication des avis</h3>
            <ul>
              <li>
                Pour publier un avis, l'Utilisateur doit avoir un compte sur le
                Site 7Night qui l'identifie ou avoir été invité à déposer un
                avis par un email envoyé par 7Night et avoir réservé ou avoir
                été invité dans un club par l'intermédiaire du Site 7Night, tout
                en ayant honoré la réservation dans ce club.
              </li>
              <li>
                Afin d'éviter tout conflit d'intérêt et pour des raisons
                d'objectivité évidentes, si l'Utilisateur travaille dans le
                domaine du club, celui-ci n'est pas autorisé à publier d'avis
                sur le Site 7Night.
              </li>
              <li>
                L'avis doit contenir plus de 200 caractères et concerner
                exclusivement le club au sein duquel l'Utilisateur s'est rendu.
                Tout avis mentionnant un autre club pourra être refusé par
                7Night.
              </li>
            </ul>
            <h3>9.2 Motifs de rejet du Contenu Utilisateur</h3>
            <p>
              L’Utilisateur est informé que son Contenu Utilisateur peut être
              rejeté pour les motifs suivants :
            </p>
            <ul>
              <li>
                si les « Conditions de publication des avis » énoncées ci-dessus
                ne sont pas respectées par l’Utilisateur ;
              </li>
              <li>
                si 7Night estime que sa responsabilité civile ou pénale peut
                être engagée ;
              </li>
              <li>
                si le Contenu Utilisateur ou les éléments relatifs à l’identité
                de l’auteur comportent des injures ou grossièretés ;
              </li>
              <li>
                si le Contenu Utilisateur textuel comporte des caractères
                aléatoires ou des suites de mots sans aucune signification ;
              </li>
              <li>
                si le Contenu Utilisateur est sans rapport avec le club noté ;
              </li>
              <li>
                si le Contenu Utilisateur est ou est soupçonné d’être une
                contrefaçon ;
              </li>
              <li>
                si l’avis soulève ou si 7Night estime que l’avis comporte des
                éléments de conflits d’intérêts ou de fraude ;
              </li>
              <li>
                si le Contenu Utilisateur textuel est mal écrit au point d'en
                être inintelligible ;
              </li>
              <li>
                si un Utilisateur formule un commentaire inapproprié sur un
                autre Contenu Utilisateur ou son auteur ;
              </li>
              <li>
                si le Contenu Utilisateur comporte des informations personnelles
                ou des éléments susceptibles d'aboutir à un vol d'identité,
                telles que le nom ou prénom d'individus qui ne sont pas des
                personnes publiques, un numéro de téléphone, une adresse
                physique précise ou une adresse email
              </li>
              <li>
                si le Contenu Utilisateur mentionne des sites Internet, liens
                hypertexte, URL, adresses email ou numéros de téléphone ; o
              </li>
              <li>si le Contenu Utilisateur est clairement du spam.</li>
            </ul>
            <p>
              Pour plus de précision sur les conditions d’acceptation des
              photos, cliquez ici .
            </p>
            <p>
              En cas d’identification d’un Utilisateur ayant déposé un avis
              frauduleux ou que 7Night estime être frauduleux, et après avoir
              appliqué les procédures liées au rejet ou à la suppression d’avis,
              7Night pourra désactiver le compte de l’Utilisateur concerné (ce
              qui entraine l’annulation des réservations en cours), et
              supprimera l’ensemble des avis liés à cet Utilisateur.
            </p>
            <h3>9.3 Modération des avis</h3>
            <p>
              7Night n’a aucune obligation de modérer ou de supprimer les avis.
              7Night procède à une modération des avis, dans le cadre d’une
              obligation de moyen, dans le but de s'assurer de la conformité des
              avis aux présentes Conditions d’Utilisation en vue de publier,
              rejeter ou supprimer cet avis. Chaque avis est soumis à une
              modération a priori, par filtres automatiques et, en cas de
              signalement particulier, à une modération humaine. Le délai de
              modération est de 2 semaines au maximum. L'Utilisateur peut
              demander la modération d'un avis déjà publié à l'adresse{" "}
              <a href="mailto:support@7night.fr">support@7night.fr</a> en
              précisant les motifs de sa demande de modération.
            </p>
            <p>
              L'Utilisateur qui a publié un avis peut demander a posteriori la
              suppression de cet avis à l'adresse support@7night.fr . Si 7Night
              a connaissance d’une fermeture ou d’un changement de propriétaire
              d’un club, les avis s’y rapportant seront supprimés. L'Utilisateur
              est informé qu'il peut être contacté par 7Night à des fins de
              vérification de l'authenticité de son avis, par email et/ou par
              téléphone. L'Utilisateur est informé que son avis peut être
              transmis à des sites partenaires de 7Night et être publié sur ces
              sites partenaires. Afin de faciliter la lecture des avis par les
              Utilisateurs du Site 7Night, l’Utilisateur autorise 7Night à
              publier à côté de l’avis déposé les éléments suivants : date
              d'inscription au site, nombre d'avis déposés, prénom et 1ère
              lettre du nom, statut et date de l’évènement. Conformément à la
              loi ‘Informatique et Libertés’ du 6 janvier 1978 modifiée,
              l'Utilisateur dispose d'un droit d'accès, de modification, de
              rectification et de suppression des données le concernant, ainsi
              que d'un droit d'opposition pour motifs légitimes. L'Utilisateur
              peut exercer ces droits à l'adresse suivante{" "}
              <a href="mailto:support@7night.fr">support@7night.fr</a> en
              indiquant bien « demande de droit d’accès » dans la ligne d’objet.
            </p>
            <h3>9.4 Avis de la part de plusieurs convives</h3>
            <p>
              L'Utilisateur qui réserve un club pour plusieurs convives est
              invité à indiquer les adresses email de ses convives pour que leur
              soient transmises les informations pratiques concernant la
              réservation (adresse du club, heure de réservation...)
              L'Utilisateur reconnaît dans ce cas avoir reçu l'autorisation
              préalable de ses convives pour transmettre leurs adresses email à
              7Night. Chaque convive est ensuite invité à poster un avis sur son
              expérience dans le club réservé par l’Utilisateur.
            </p>
            <p>
              Les données personnelles des convives collectées dans ce contexte
              ne seront pas réutilisées par 7Night.
            </p>
            <h3>9.5 Droit de réponse</h3>
            <p>Chaque club dispose d'un droit de réponse afin notamment de :</p>
            <ul>
              <li>donner sa version des faits ;</li>
              <li>remercier le consommateur pour sa contribution ; et/ou</li>
              <li>
                indiquer les éventuels changements intervenus dans le club
                depuis la rédaction de l'avis.
              </li>
            </ul>
            <p>
              Le droit de réponse doit être exercé dans les 3 mois de la
              diffusion de l’avis auquel il répond écrivant à l’adresse. La d
              email <a href="mailto:support@7night.fr">support@7night.fr</a>{" "}
              emande d’exercice du droit de réponse par email doit comporter les
              éléments suivants :
            </p>
            <ul>
              <li>références de l'avis</li>
              <li>identification de son auteur ;</li>
              <li>mention des passages contestés ;</li>
              <li>
                teneur de la réponse sollicitée (la réponse ne peut être plus
                longue que l'avis de l'Utilisateur auquel elle répond).
              </li>
            </ul>
            <p>
              La réponse devra être conforme aux dispositions des présentes
              Conditions d’Utilisation et sera modérée dans les mêmes conditions
              que les avis des Utilisateurs et publiée le cas échéant à la suite
              de l’avis auquel elle répond.
            </p>
            <h3>9.6 Note des clubs</h3>
            <p>
              Chaque Utilisateur ayant réservé un club par le Site 7Night et
              honoré sa réservation dans ce club est invité à donner une note
              correspondant à son expérience de consommation.
            </p>
            <p>
              L’Utilisateur est informé que la note indiquée sur le Site 7Night
              à côté de chaque club correspond à une moyenne pondérée des notes
              données.
            </p>
            <h3>9.7 Utilisation du Contenu Utilisateur</h3>
            <p>
              L’utilisateur convient qu’en publiant le Contenu Utilisateur sur
              le Site 7Night il accorde automatiquement à 7Night, une
              autorisation irrévocable, permanente, non exclusive, sans
              contrepartie pécuniaire d’utiliser, de copier, d’afficher,
              d’adapter, de modifier, de traduire, de distribuer, de faire
              distribuer ou de promouvoir, d'incorporer à des publicités ou
              autres documents, de créer des œuvres dérivées de celui-ci, de
              mettre en valeur, de distribuer ledit Contenu Utilisateur, dans le
              monde entier, mais aussi de permettre à des tiers d’agir de même,
              et ce sur tout support en ligne ou non. En outre, en publiant un
              Contenu Utilisateur sur le Site 7Night, l’Utilisateur accorde
              automatiquement à 7Night tous les droits nécessaires pour
              interdire tout affichage, agrégation, copie ou exploitation
              ultérieure dudit Contenu Utilisateur par un tiers à quelque fin
              que ce soit. L’Utilisateur s’interdit d’exercer son droit moral
              sur le Contenu Utilisateur de manière incompatible avec les
              autorisations ci-dessus accordées. L’utilisateur déclare et
              garantit qu’il est en droit d’accorder les autorisations ci-dessus
              accordées.
            </p>
            <h3>10. Confidentialité</h3>
            <p>
              7Night a mis en place une Déclaration relative à la
              confidentialité et aux cookies afin de protéger au mieux les
              données personnelles des Utilisateurs.
            </p>
            <h3>11. Propriété intellectuelle</h3>
            <h3>
              11.1 Respect du droit national et international de la propriété
              intellectuelle
            </h3>
            <p>
              L'Utilisateur s'engage à ne pas soumettre, copier, revendre,
              rééditer, ou, en général, rendre disponible par quelque forme que
              ce soit tout contenu, donnée, information ou élément, reçu de
              7Night ou disponible sur le Site 7Night, à une autre personne
              physique ou morale, de tous pays. L'Utilisateur s'engage à
              respecter les dispositions ci-après relatives à la propriété
              intellectuelle
            </p>
            <h3>11.2 Propriété des droits</h3>
            <p>
              Tous les droits, patrimoniaux et moraux, de propriété
              intellectuelle, afférents aux contenus et aux éléments
              d'information du Site 7Night appartiennent en propre à 7Night,
              sous réserve de tout droit patrimonial pouvant appartenir à un
              tiers et pour lesquels la Société 7Night a obtenu les cessions de
              droits ou les autorisations nécessaires.
            </p>
            <p>
              Les droits conférés à l'Utilisateur en vue de l'utilisation du
              Site 7Night et des services fournis par 7Night n'emportent aucune
              cession ni aucune autorisation d'exploiter ou d'utiliser l'un
              quelconque des éléments du Site 7Night.
            </p>
            <h3>
              11.3 Protection de tous les éléments : Marques, dessins, logos,
              liens hypertextes, informations, etc.
            </h3>
            <p>
              Tous les éléments (marques, dessins, textes, liens hypertextes,
              logos, images, vidéos, éléments sonores, logiciels, mise en page,
              bases de données, codes...) contenus sur le Site 7Night et dans
              les sites associés sont protégés par le droit national et
              international de la propriété intellectuelle. Ces éléments restent
              la propriété exclusive de 7Night et/ou de ses partenaires ou
              concédants.
            </p>
            <h3>11.4 Interdiction d'utilisation sans autorisation</h3>
            <p>
              Par conséquent, sauf autorisation préalable et écrite de 7Night
              et/ou de ses partenaires, l'Utilisateur ne peut procéder à une
              quelconque reproduction, représentation, réédition,
              redistribution, adaptation, traduction et/ou transformation
              partielle ou intégrale, ou un transfert sur un autre support de
              tout élément composant ou présent sur le Site 7Night.
            </p>
            <h3>11.5 Sanctions</h3>
            <p>
              L'Utilisateur reconnaît et prend connaissance que le non-respect
              des dispositions du présent article 7 est constitutif d'un acte de
              contrefaçon répréhensible tant civilement que pénalement.
            </p>
            <h3>12. Limitations de responsabilité</h3>
            <h3>12.1 Avertissement</h3>
            <p>
              Il appartient à l'Utilisateur de faire toutes vérifications qui
              semblent nécessaires ou opportunes avant de procéder à une
              quelconque réservation dans l'un des clubs référencés sur le Site
              7Night.
            </p>
            <p>
              7Night ne garantit aucunement et de quelque façon que ce soit le
              Contenu clubbeur ou les services et/ou pratiques commerciales des
              tiers présents sur le site 7Night. En ce sens, 7Night ne garantit
              pas à l'Utilisateur qu'il soit satisfait des produits, services
              et/ou pratiques commerciales qu'il a obtenus suite à une
              réservation par le biais du Site 7Night.
            </p>
            <p>
              7Night ne garantit aucunement et de quelque façon que ce soit la
              teneur, l’objectivité ou l’exactitude du Contenu Utilisateur, et
              notamment des avis déposés sur le Site 7Night.
            </p>
            <h3>12.2 Modification du Site 7Night</h3>
            <p>
              Toutes les informations contenues sur le Site 7Night sont
              susceptibles d'être modifiées à tout moment, compte tenu de
              l'interactivité du Site 7Night, sans que cela puisse engager la
              responsabilité de 7Night.
            </p>
            <h3>12.3 Utilisation du Site 7Night</h3>
            <p>
              Compte tenu des spécificités du réseau Internet, 7Night n'offre
              aucune garantie d’accès au Site 7Night ou de continuité du
              service, n'étant tenue à cet égard que d'une obligation de moyen.
            </p>
            <p>
              7Night décline toute responsabilité en cas de de difficulté
              d’accès au Site 7Night ou pour tout dommage ou perte lié à
              l'utilisation ou l'impossibilité d'utiliser le Site 7Night ou son
              contenu, sauf exception prévue par la loi.
            </p>
            <p>
              7Night ne garantit pas que les informations présentées soient
              détaillées, complètes, vérifiées ou exactes. Le Contenu clubbeur,
              les informations, fiches descriptives, et, en général, tout
              contenu présent sur le Site 7Night sont fournis en "l'état", sans
              aucune garantie expresse ou tacite de quelque sorte que ce soit.
            </p>
            <p>
              L'Utilisateur reconnaît expressément que les photos présentes sur
              le Site 7Night ne soient pas contractuelles.
            </p>
            <p>
              De façon générale, l'Utilisateur accepte et reconnaît que la
              réservation auprès d’un club ne soit pas garantie. En ce sens,
              7Night ne garantit pas l'effectivité du service de réservation. La
              disponibilité est vérifiée en temps réel informatiquement.
              Toutefois, 7Night ne pouvant pas matériellement vérifier
              l'exactitude des renseignements collectés et/ou donnés par les
              club, l'Utilisateur accepte que la responsabilité de 7Night ne
              puisse être engagée si l'Utilisateur ne parvient pas à bénéficier
              des prestations du club. En effet, les paramétrages du logiciel de
              réservation en temps réel dépendent pour partie des informations
              fournies et/ou enregistrées par le club et peuvent ne pas
              correspondre à la réalité. Ainsi, par exemple et de façon non
              exhaustive, l'Utilisateur reconnaît et accepte que la
              responsabilité de 7Night ne soit en aucun cas recherchée en cas
              d'annulation de réservation, en cas d'établissement fermé, et ce
              pour quelque raison que ce soit, ou encore en cas de refus de
              prestation, et ce pour quelque raison que ce soit.
            </p>
            <p>
              De même, l'Utilisateur accepte que la responsabilité de 7Night ne
              puisse pas être engagée si l'Utilisateur ne parvient pas à
              bénéficier des promotions ou offres spéciales proposées par le
              club. L'Utilisateur reconnaît et accepte que la responsabilité de
              7Night ne soit en aucun cas recherchée dans le cas où le club
              n'honorerait pas une promotion ou une offre spéciale, et ce pour
              quelque raison que ce soit.
            </p>
            <h3>12.4 Garanties de l'Utilisateur</h3>
            <p>
              L'Utilisateur déclare qu'il connaît parfaitement les
              caractéristiques et les contraintes de l'Internet. Il reconnaît
              notamment qu'il est impossible de garantir que les données que
              l'Utilisateur aura transmises via Internet seront entièrement
              sécurisées. 7Night ne pourra être tenue responsable des incidents
              qui pourraient découler de cette transmission.
            </p>
            <p>
              L'Utilisateur s'engage à indemniser 7Night à hauteur des coûts que
              7Night devrait supporter à la suite de toute réclamation ou
              contestation, judiciaire ou extrajudiciaire, liées à l'utilisation
              des services de 7Night et notamment le dépôt de Contenu
              Utilisateur sur le Site 7Night par l'Utilisateur et garantit
              7Night de toute condamnation à ce titre.
            </p>
            <p>
              En tout état de cause, l'Utilisateur reconnaît expressément et
              accepte d'utiliser le Site 7Night à ses propres risques et sous sa
              responsabilité exclusive.
            </p>
            <h3>12.5 Notification et retrait de contenu illicite</h3>
            <p>
              7Night informe tout Utilisateur qu'il peut notifier une
              réclamation ou une objection quant à des éléments ou des contenus
              du Site 7Night.
            </p>
            <p>
              Si l'Utilisateur estime que des éléments ou des contenus du Site
              7Night sont illicites et/ou contrefont des droits d'auteur qu'il
              détient, l'Utilisateur doit adresser une notification à 7Night par
              Lettre Recommandée avec Accusé de Réception adressée au ‘Service
              Juridique’ ou par courriel à{" "}
              <a href="mailto:support@7night.fr">support @7night.fr</a> avec la
              mention « A l’attention du Service Juridique » dans la ligne
              d’objet et contenant tous les éléments justificatifs de titularité
              des droits le cas échéant. Une fois cette procédure suivie et
              après vérification de l'exactitude de la notification, 7Night
              s'efforcera, dans une mesure raisonnable et dans les meilleurs
              délais, de retirer le contenu illicite.
            </p>
            <p>
              7Night agit comme intermédiaire dans le cadre de la fourniture de
              ses services à l’Utilisateur. A ce titre, conformément à l’article
              6 I.3 de la loi pour la confiance dans l’économie numérique (LCEN)
              du 21 juin 2004, modifiée, 7Night ne saurait voir sa
              responsabilité pénale engagée à raison des informations stockées
              sur le Site 7Night si elle n'avait pas effectivement connaissance
              de l'activité ou de l'information illicites (notamment Contenu
              Utilisateur et Contenu clubbeur) ou si, dès le moment où elle en a
              eu connaissance, elle a agi promptement pour retirer ces
              informations ou en rendre l'accès impossible.
            </p>
            <h3>13. Dispositions diverses</h3>
            <h3>13.1 Nullité partielle - Dissociation - Titres</h3>
            <p>
              Dans l'hypothèse où une disposition des présentes conditions
              générales serait nulle, illégale, inopposable ou inapplicable
              d'une manière quelconque, la validité, la légalité, l’opposabilité
              ou l'application des autres dispositions des présentes Conditions
              d’Utilisation n'en serait aucunement affectée ou altérée, ces
              autres dispositions demeurant en vigueur et conservant leur plein
              et entier effet.
            </p>
            <p>
              7Night pourra le cas échéant procéder à la rédaction d'une
              nouvelle clause ayant pour effet de rétablir la volonté commune
              des Parties telle qu'exprimée dans la clause initiale, et ce, dans
              le respect du droit en vigueur applicable aux présentes Conditions
              d’Utilisation.
            </p>
            <p>
              Les titres des articles des présentes n'ont qu'une valeur
              indicative et ne doivent pas être considérés comme faisant partie
              intégrante des présentes Conditions d’Utilisation.
            </p>
            <h3>13.2 Absence de renonciation</h3>
            <p>
              Sauf stipulation contraire prévue éventuellement dans les
              présentes Conditions d’Utilisation, aucune tolérance, inaction,
              abstention ou omission, aucun retard de 7Night pour se prévaloir
              de l'un quelconque de ses droits conformément aux termes des
              présentes, ne portera atteinte audit droit, ni ne saurait
              impliquer une renonciation pour l'avenir à se prévaloir d'un tel
              droit. Au contraire, ledit droit demeurera pleinement en vigueur.
            </p>
            <h3>13.3 Loi applicable</h3>
            <p>
              Les présentes Conditions d’Utilisation et les relations qui se
              nouent entre 7Night et l'Utilisateur sont soumises au droit
              français. Dans la mesure permise par la loi, en cas de rédaction
              des présentes Conditions d’Utilisation en plusieurs langues ou de
              traduction, seule la version française fera foi.
            </p>
            <h3>13.4 Compétence juridictionnelle</h3>
            <p>
              Toute contestation et/ou difficulté d'interprétation ou
              d'exécution des présentes Conditions d‘Utilisation relèvera des
              tribunaux compétents de la ville de Aix-en-provence.
            </p>
            <p>
              En application de la directive européenne 2013/11/EU du 21 mai
              2013 relative au règlement extrajudiciaire des litiges de
              consommation, veuillez-trouver ci-dessous le lien vers la
              plateforme européenne de règlement en ligne des litiges de
              consommation
            </p>
          </div>
          <div className="SectionC">
            <h2>C. DÉCLARATION RELATIVE À LA CONFIDENTIALITÉ ET AUX COOKIES</h2>
            <div className="SectionC content">
              <h3>Dernière mise à jour : 3 juin 2021</h3>
              <p>
                Nous savons que le partage de vos informations personnelles avec
                nous est basé sur la confiance. Nous prenons cette question très
                au sérieux et nous nous engageons à respecter votre vie privée
                lorsque vous consultez notre site Web ou utilisez nos services.
                Veuillez examiner la Déclaration suivante avec attention pour en
                savoir plus sur nos pratiques en matière de confidentialité.
              </p>
              <p>
                Nous exploitons un service en ligne qui permet aux utilisateurs
                (« vous », « votre ») de choisir et réserver une table dans un
                club, voire, selon le lieu, de régler leur repas. Dans la
                présente Déclaration, les options susmentionnées sont désignées
                collectivement comme nos Services.
              </p>
              <p>
                Nos Services sont détenus exploités par 7Night qui agit en
                qualité de responsable du traitement des données et de
                propriétaire. En consultant nos sites Web et nos applications
                connexes, que ce soit sur un ordinateur, un téléphone, une
                tablette ou un appareil similaire (tous désignés sous le nom
                d'«Appareil »), vous acceptez les pratiques décrites ci-dessous.
              </p>
              <p>
                En tant qu'entreprise internationale, nous exerçons nos
                activités dans un certain nombre de pays et de territoires où
                les lois et les coutumes diffèrent. La présente Déclaration
                donne un aperçu général de nos pratiques en matière de
                confidentialité ;
                <br />
                vous pouvez consulter certaines informations spécifiques à votre
                pays en sélectionnant votre lieu de résidence dans les liens de
                juridiction au bas de ce document.
              </p>
              <h3>Collecte de données</h3>
              <p>
                Lorsque vous accédez ou utilisez nos Services, nous collectons
                des données vous concernant afin de vous offrir une expérience
                plus personnalisée et pertinente. Certaines données sont
                collectées automatiquement. D'autres données sont collectées de
                différentes sources, y compris des entités affiliées, des
                partenaires commerciaux et d'autres sources tierces
                indépendantes. Lorsque vous utilisez nos Services en cliquant
                sur un lien figurant sur un site tiers ou lorsque vous consultez
                des sites tiers via nos Services, ces sites tiers peuvent
                partager avec nous des données sur votre utilisation de leur
                service. Les données collectées peuvent comprendre les éléments
                suivants:
              </p>
              <ul>
                <li>
                  Coordonnées, y compris nom, numéro de téléphone et adresses
                  postale et électronique
                </li>
                <li>
                  Informations de facturation ou de paiement (telles que le
                  numéro de votre carte bancaire, le nom du titulaire de la
                  carte, la date d'expiration, le code d'authentification et
                  l'adresse de facturation), collectées par le biais de notre
                  partenariat avec un prestataire de services de paiement de
                  confiance
                </li>
                <li>Nom d'utilisateur et mot de passe</li>
                <li>Sexe Homme / femme de l’utilisateur</li>
                <li>
                  Photos, avis et messages que vous nous avez peut-être fournis
                </li>
                <li>Données relatives à la géolocalisation</li>
                <li>
                  Données relatives à l'appareil, telles que l'heure à laquelle
                  vous avez accédé à nos Services et des informations sur
                  l'Appareil utilisé (par exemple, adresse IP, logiciel ou
                  navigateur Internet utilisé, langues préférées,
                  identificateurs uniques d'appareils et identificateurs
                  publicitaires)
                </li>
                <li>
                  Activité en ligne, y compris les pages que vous avez
                  consultées, le contenu et les applications examinés
                </li>
                <li>Historique des réservations auprès de club</li>
                <li>
                  Informations sur vos réservations dans les club et vos
                  préférences
                </li>
              </ul>
              <p>
                Si vous nous les avez fournies, nous pouvons également collecter
                des informations sur d'autres clients vous ayant accompagnés, y
                compris leur adresse électronique et d'autres données fournies
                lors des réservations, notamment leur prénom et leur nom de
                famille. Si vous partagez avec nous des informations sur
                d'autres personnes, vous devez obtenir leur consentement. Vous
                devez obtenir le consentement de toute autre personne sur
                laquelle vous nous communiquez des informations.
              </p>
              <p>
                Outre les catégories susmentionnées, nous pouvons également
                collecter certaines informations concernant votre localisation
                si votre Appareil a été configuré pour envoyer ce type
                d'informations via ses paramètres de confidentialité ou, par
                exemple, si vous avez chargé des photos contenant des
                informations de géolocalisation. Nous pouvons utiliser les
                informations concernant votre localisation directement et/ou
                partager votre localisation avec des tiers. Nous recueillons et
                partageons vos informations de localisation afin de vous fournir
                un contenu pertinent, d'analyser votre utilisation de nos
                Services, de les améliorer et de fournir des recommandations.
                Nous pouvons par exemple utiliser votre localisation pour vous
                montrer des avis sur des club proches de vous.
              </p>
              <p>
                Vous pouvez modifier les paramètres de confidentialité de votre
                Appareil à tout moment et désactiver la fonctionnalité qui
                collecte et partage des informations sur votre localisation
                et/ou la fonctionnalité de géolocalisation de vos photos.
                Toutefois, la désactivation du partage de lieux peut affecter
                certaines fonctionnalités que nous proposons. Pour obtenir de
                l'aide concernant les paramètres de confidentialité de votre
                Appareil, nous vous invitons à contacter le fabricant de votre
                Appareil ou votre fournisseur de service mobile.
              </p>
              <p>
                Veuillez consulter la section sur les cookies ci-dessous pour
                plus d'informations concernant l'utilisation des cookies et des
                autres technologies qui y sont décrites, y compris concernant
                vos choix relatifs à ces technologies.
              </p>
              <h3>Utilisation des informations</h3>
              <p>
                Nous nous engageons à vous fournir un contenu pertinent sur nos
                Services et à respecter les lois relatives à la confidentialité
                des données des différentes juridictions dans lesquelles nous
                exerçons nos activités. Nous utilisons les informations vous
                concernant afin de vous aider à profiter et à utiliser nos
                Services, notamment des manières suivantes:
              </p>
              <ul>
                <li>
                  Pour votre inscription et gérer votre compte, y compris pour
                  vous permettre d'accéder à nos Services et de les utiliser.
                </li>
                <li>Pour faciliter vos réservations auprès des club</li>
                <li>
                  Pour mesurer l'intérêt pour nos Services et les améliorer
                </li>
                <li>
                  Pour vous informer sur les offres spéciales et les services
                  disponibles chez nous ou auprès de nos sociétés affiliées, nos
                  partenaires ou des club qui pourraient vous intéresser
                </li>
                <li>Pour gérer et mener à bien le programme de fidélité</li>
                <li>Pour communiquer avec vous</li>
                <li>
                  Pour nous permettre de publier vos avis, évaluations, messages
                  sur les forums, photos et autres contenus
                </li>
                <li>
                  Pour organiser des tirages au sort, des concours ou des
                  enquêtes
                </li>
                <li>
                  Pour personnaliser votre expérience, y compris en
                  personnalisant les publicités qui vous sont présentées sur nos
                  Services et sur Internet
                </li>
                <li>
                  Pour faire des suppositions sur vos intérêts ou préférences
                </li>
                <li>
                  Pour vous fournir une expérience optimisée et regrouper les
                  utilisateurs de nos Services en fonction, par exemple, de
                  l'utilisation et des données démographiques
                </li>
                <li>
                  Pour vous envoyer des invitations à participer à des sondages
                  ou des études de marché
                </li>
                <li>Pour répondre à vos questions et commentaires</li>
                <li>Pour résoudre des conflits ou problèmes</li>
                <li>
                  Pour empêcher toute activité potentiellement interdite ou
                  illicite
                </li>
                <li>
                  Pour appliquer nos conditions d'utilisation et avis ; et comme
                  décrit par ailleurs lors du recueil des informations
                </li>
              </ul>
              <p>
                Veuillez noter que certaines informations vous concernant sont
                nécessaires pour que nous puissions vous proposer des offres
                pertinentes de notre part, de la part de nos sociétés affiliées,
                de nos partenaires et de club et pour vous permettre de profiter
                d'autres avantages en tant qu'utilisateur inscrit. Veuillez
                prendre connaissance de la section « Choix des informations »
                ci-dessous en ce qui concerne vos choix relatifs à la collecte
                et à l'utilisation de vos données.
              </p>
              <h3>Partage des informations</h3>
              <p>
                Afin de fournir certains de nos Services, nous pouvons être
                amenés à partager des informations avec d'autres tiers, y
                compris notre groupe de sociétés, dans les circonstances
                suivantes :
              </p>
              <ul>
                <li>
                  Notre groupe de sociétés. Nous partageons des informations
                  avec des entreprises au sein de notre famille de sociétés afin
                  de pouvoir vous proposer des informations relatives à des
                  produits et services (en matière de club et autre)
                  susceptibles de vous intéresser. Ces sociétés et sites Web se
                  conformeront à la présente Déclaration et à toutes les lois
                  applicables régissant la transmission de communications
                  promotionnelles.
                </li>
                <li>
                  club. Nous partageons vos informations avec nos club
                  partenaires afin de leur permettre de traiter et gérer vos
                  réservations ainsi que de gérer les plaintes ou conflits les
                  concernant. Lors de vos réservations, vous aurez peut-être la
                  possibilité de choisir de recevoir des communications
                  marketing de la part des club concernés. Si vous acceptez de
                  recevoir ces communications, le club dont elles émanent est
                  responsable de l'ensemble du traitement des informations que
                  nous lui communiquons.
                </li>
                <li>
                  Fournisseurs. Nous partageons des informations avec certains
                  fournisseurs avec lesquels nous travaillons pour faciliter la
                  prestation de nos Services, y compris pour faciliter les
                  transactions, telles que les réservations que vous pourriez
                  effectuer.
                </li>
                <li>
                  Partenaires commerciaux. Nous pouvons partager des
                  informations vous concernant, concernant vos Appareils et
                  votre utilisation de nos Services avec nos partenaires
                  commerciaux de confiance. Nous pouvons par exemple être amenés
                  à partager des informations afin de vous fournir un contenu
                  pertinent ou de faciliter le paiement de vos repas. Ce partage
                  se fait généralement en vertu de contrats écrits qui
                  comprennent des obligations en matière de confidentialité, de
                  protection de la vie privée et de sécurité ; toutefois,
                  veuillez noter que nous ne contrôlons pas les pratiques en
                  matière de protection de la vie privée de ces partenaires
                  commerciaux tiers.
                </li>
                <li>
                  Sites de réseaux sociaux. Lorsque vous utilisez nos Services
                  et choisissez de partager des informations avec des sites de
                  réseaux sociaux tiers, les informations que vous partagez sont
                  régies par les politiques de confidentialité de ces sites de
                  réseaux sociaux et les paramètres de confidentialité que vous
                  avez définis avec lesdits sites.
                </li>
                <li>
                  Réseaux publicitaires. Nous recueillons et partageons parfois
                  des informations sur vos centres d'intérêt avec des réseaux
                  publicitaires. Certaines de ces sociétés sont membres de la
                  Network Advertising Initiative ou de la Digital Advertising
                  Alliance, qui vous permettent, depuis un emplacement unique,
                  de vous désinscrire des annonces ciblées des sociétés membres.
                  Pour en savoir plus, cliquez ici .
                </li>
                <li>
                  Autres tiers, tels que des sites Web de renvoi. Des tiers
                  peuvent également nous aider à recueillir des informations,
                  notamment pour permettre l'exécution des fonctionnalités de
                  notre site Web ou vous proposer des annonces en ligne ciblées
                  sur vos intérêts. Nous pouvons partager des segments
                  d'audience et d'autres informations avec des tiers qui
                  utilisent ces informations pour vous envoyer des publicités
                  ciblées.
                </li>
              </ul>
              <p>
                Ces tiers ne peuvent recueillir ou accéder aux informations que
                dans la mesure nécessaire à l'exécution des fonctionnalités
                autorisées. Certaines versions du système d'exploitation de
                l'Appareil vous permettent de refuser le partage de certains
                types d'informations, y compris vers certains réseaux
                publicitaires. Veuillez vérifier les paramètres de votre
                Appareil si vous souhaitez limiter ce type de suivi.
              </p>
              <h3>Choix des informations</h3>
              <p>
                Plusieurs options s'offrent à vous s'agissant du recueil et de
                l'utilisation de vos informations. Vous pouvez accéder à votre
                compte, le mettre à jour et même le fermer via la page « Mes
                informations personnelles » sur notre site Web Vos options :
              </p>
              <ul>
                <li>
                  Vous pouvez choisir la façon dont nous communiquerons avec
                  vous. Vous pouvez choisir de ne pas nous communiquer certaines
                  informations, bien que ces dernières puissent être nécessaires
                  pour bénéficier de certaines fonctions proposées sur les sites
                  Web.
                </li>
                <li>
                  Vous pouvez empêcher la collecte de vos informations de
                  géolocalisation, mais notez que la désactivation du partage de
                  localisation peut affecter certaines fonctionnalités de nos
                  Services.
                </li>
                <li>
                  Vous pouvez ajouter ou mettre à jour vos informations et
                  fermer votre compte. Si vous fermez votre compte, nous le
                  désactiverons et supprimerons les informations de votre profil
                  de la vue active. Nous pouvons conserver certaines
                  informations associées à votre compte (y compris les
                  réservations passées) à des fins internes, y compris les
                  sauvegardes, la prévention de la fraude, le règlement des
                  différends, les enquêtes et la conformité juridique, pendant
                  la période nécessaire pour atteindre les objectifs énoncés
                  dans la présente Déclaration.
                </li>
                <li>
                  En tant qu‘utilisateur inscrit, vous pouvez modifier vos
                  abonnements marketing, y compris vos choix d'abonnement par
                  e-mail à tout moment dans vos paramètres de compte. Vous
                  pourrez également choisir de ne plus recevoir de messages
                  promotionnels, par le biais de chaque e-mail que nous
                  envoyons.
                </li>
              </ul>
              <h3>Transferts d'informations</h3>
              <p>
                Nous sommes une entreprise mondiale et nous exerçons nos
                activités dans de nombreuses juridictions. Si nous transférons
                vos informations à d'autres pays, nous utiliserons et
                protégerons ces informations conformément aux dispositions
                décrites dans la présente Déclaration et au droit applicable.
              </p>
              <h3>Sécurité des informations</h3>
              <p>
                Nous avons mis en place des procédures de sécurité
                administrative, technique et physique appropriées pour nous
                aider à protéger vos informations.
              </p>
              <p>
                Seuls certains de nos employés sont autorisés à accéder aux
                données personnelles, et ils ne peuvent le faire qu'à des fins
                professionnelles légitimes. Nous avons recours au chiffrement
                lors de la transmission de vos données personnelles entre votre
                système et le nôtre et entre notre système et celui des parties
                avec lesquelles nous partageons des informations. Nous utilisons
                également des pare-feu et des systèmes de détection des
                intrusions afin d'empêcher toute personne non autorisée
                d'accéder à vos informations. Toutefois, nous ne pouvons
                garantir la sécurité des informations en cas d'accès ou
                d'utilisation non autorisée, de pannes matérielles ou
                logicielles, ou d'autres circonstances indépendantes de notre
                volonté.
              </p>
              <h3>Conservation des informations</h3>
              <p>
                Nous conserverons des copies de vos données personnelles tant
                que votre compte restera ouvert ou aussi longtemps que
                nécessaire dans le cadre des fins énoncées dans la présente
                Déclaration, à moins que le droit applicable n'exige une période
                de conservation plus longue. De plus, nous pouvons être amenés à
                conserver vos informations pendant toute la durée de la période
                nécessaire pour faire valoir ou exercer nos droits ou nous
                défendre contre toute action en justice.
              </p>
              <h3>Signaux d'anti-pistage</h3>
              <p>
                Sécurité des informationsNous avons mis en place des procédures
                de sécurité administrative, technique et physique appropriées
                pour nous aider à protéger vos informations.Seuls certains de
                nos employés sont autorisés à accéder aux données personnelles,
                et ils ne peuvent le faire qu'à des fins professionnelles
                légitimes. Nous avons recours au chiffrement lors de la
                transmission de vos données personnelles entre votre système et
                le nôtre et entre notre système et celui des parties avec
                lesquelles nous partageons des informations. Nous utilisons
                également des pare-feu et des systèmes de détection des
                intrusions afin d'empêcher toute personne non autorisée
                d'accéder à vos informations. Toutefois, nous ne pouvons
                garantir la sécurité des informations en cas d'accès ou
                d'utilisation non autorisée, de pannes matérielles ou
                logicielles, ou d'autres circonstances indépendantes de notre
                volonté.Conservation des informationsNous conserverons des
                copies de vos données personnelles tant que votre compte restera
                ouvert ou aussi longtemps que nécessaire dans le cadre des fins
                énoncées dans la présente Déclaration, à moins que le droit
                applicable n'exige une période de conservation plus longue. De
                plus, nous pouvons être amenés à conserver vos informations
                pendant toute la durée de la période nécessaire pour faire
                valoir ou exercer nos droits ou nous défendre contre toute
                action en justice.Signaux d'anti-pistage
              </p>
              <h3>Cookies</h3>
              <p>
                Nous voulons que votre accès à nos Services soit aussi facile,
                efficace et utile que possible. Pour ce faire, nous utilisons
                des cookies et des technologies similaires pour enrichir votre
                expérience, améliorer votre sécurité sur le site Web et vous
                présenter des publicités pertinentes.
              </p>
              <p>Les cookies, c'est quoi ?</p>
              <p>
                Les cookies sont de petits fichiers texte qui sont copiés
                automatiquement sur votre Appareil lorsque vous consultez
                n'importe quel site Web. Ils sont stockés par votre navigateur
                et contiennent des informations de base sur votre utilisation
                d'Internet. Votre navigateur renvoie ces cookies à un site Web
                chaque fois que vous le consultez, afin que votre Appareil soit
                reconnu et que votre expérience soit améliorée, entre autres, en
                vous proposant un contenu personnalisé. Nous utilisons également
                des cookies pour mémoriser vos informations de connexion, afin
                que vous n'ayez pas à les ressaisir à chaque utilisation de nos
                Services. D'autres cookies nous aident à comprendre vos
                préférences.
              </p>
              <p>
                Les cookies que nous utilisons sont répartis en trois
                catégories.
              </p>
              <p>Cookies de base :</p>
              <p>
                Ces cookies sont nécessaires pour vous permettre d'accéder au
                site Web, de le parcourir et d'utiliser l'ensemble de ses
                fonctionnalités. Sans eux, le site Web risque de ne pas
                fonctionner correctement et de vous empêcher d'utiliser
                certaines de ses fonctionnalités les plus importantes. Par
                exemple, nous utilisons un cookie qui maintient votre connexion
                tout au long de votre visite, afin de vous éviter d'avoir à
                indiquer systématiquement vos identifiants dès que vous accédez
                à une nouvelle page. Nous pouvons également utiliser des cookies
                de base à des fins de prévention et de détection des fraudes.
              </p>
              <p>
                Vous ne pouvez pas désactiver les cookies de base à l'aide de
                l'outil de gestion des cookies, dans la mesure où ils sont
                nécessaires pour que vous puissiez accéder aux fonctionnalités
                du site Web et les utiliser.
              </p>
              <p>Cookies de personnalisation et d'analyse :</p>
              <p>
                Ces cookies nous permettent de comprendre l'utilisation qui est
                faite de notre site Web et nous aident à l'améliorer. Ils
                peuvent nous fournir des informations qui nous aident à
                connaître les éléments du site qui intéressent nos visiteurs et
                à détecter toute erreur pendant la navigation. Nous les
                utilisons pour tester différentes mises en page et
                fonctionnalités, ainsi que pour savoir par quels moyens les
                visiteurs parviennent jusqu'au site Web.
              </p>
              <p>
                Nous avons également recours à des cookies pour enregistrer vos
                paramètres et préférences sur le site Web (langue favorite et
                informations précédemment saisies lors d'une recherche sur les
                options d'un club, par exemple).
              </p>
              <p>
                Cookies publicitaires : Les cookies publicitaires permettent de
                s'assurer que les publicités que vous voyez sont aussi
                pertinentes que possible pour vous. Par exemple, certains
                cookies publicitaires permettent de sélectionner des publicités
                en fonction de vos centres d'intérêt. D'autres permettent
                d'éviter que la même annonce ne réapparaisse continuellement à
                votre écran.
              </p>
              <p>
                Nous utilisons également des cookies pour faciliter le partage
                du contenu du site Web avec vos amis via vos réseaux sociaux
                préférés. Les sites de réseaux sociaux peuvent installer des
                cookies qui vous reconnaissent lorsque vous visualisez du
                contenu.
              </p>
              <p>
                Les cookies publicitaires permettent de s'assurer que les
                publicités que vous voyez sont aussi pertinentes que possible
                pour vous. Par exemple, certains cookies publicitaires
                permettent de sélectionner des publicités en fonction de vos
                centres d'intérêt. D'autres permettent d'éviter que la même
                annonce ne réapparaisse continuellement à votre écran.
              </p>
              <p>
                Nous utilisons également des cookies pour faciliter le partage
                du contenu du site Web avec vos amis via vos réseaux sociaux
                préférés. Les sites de réseaux sociaux peuvent installer des
                cookies qui vous reconnaissent lorsque vous visualisez du
                contenu sur le site Web. De plus, ils vous permettent de
                partager du contenu à la fois sur le site Web et sur le site de
                réseaux sociaux grâce à l'utilisation de paramètres de partage.
                Pour plus d'informations, veuillez consulter les conditions
                d'utilisation et la politique de confidentialité de votre site
                de réseaux sociaux.
              </p>
              <p>
                Cookies publicitaires : Nous travaillons également avec des
                annonceurs tiers pour vous donner accès à du contenu relatif à
                des restaurants susceptible de vous intéresser, et pouvons
                placer des cookies sur nos Services pour vous présenter des
                annonces publicitaires qui correspondent à vos centres
                d'intérêts et préférences. Ces « cookies tiers » collectent des
                informations sur votre comportement de navigation et vos
                interactions avec les publicités ou les Services en général. Ces
                informations nous aident également à limiter le nombre de fois
                que vous voyez les mêmes annonces et à améliorer votre
                expérience en ligne.
              </p>
              <h3>Outil de gestion des cookies</h3>
              <p>
                Pour vous donner plus de contrôle, nous vous fournissons un
                Outil de gestion des cookies qui vous permet d'examiner les
                cookies internes et de tiers placés sur notre site Web et
                d'ajuster vos paramètres afin de les accepter ou de les refuser.
              </p>
              <p>
                Vous pouvez accéder à l'Outil de gestion des cookiesen cliquant
                ici ou à tout moment via le lien « Gestion des cookies » en bas
                de chaque page ou dans notre bannière cookie. En outre, vous
                pouvez gérer les cookies via les paramètres de votre navigateur
                Internet. Vous pouvez faire en sorte que le navigateur vous
                envoie une notification lorsque vous recevez un nouveau cookie,
                supprimez certains cookies ou l'ensemble de ceux-ci.
              </p>
              <p>
                La plupart des réseaux publicitaires vous offrent la possibilité
                de refuser les cookies publicitaires. Vous trouverez des
                informations utiles sur la manière de procéder sur ces pages{" "}
                <a href="http://www.aboutads.info/choices/">
                  www.aboutads.info/choices/
                </a>{" "}
                et{" "}
                <a href="http://www.youronlinechoices.com/fr/">
                  www.youronlinechoices.com/fr/
                </a>
                .
              </p>
              <p>
                Si vous supprimez vos cookies, votre accès à certaines
                fonctionnalités et zones de nos Services pourrait être dégradé
                ou restreint.
              </p>
              <p>
                L'utilisation que nous faisons de toute information que nous
                recueillons par le biais des cookies est assujettie à la
                présente Déclaration, laquelle peut être consultée à partir de
                chaque page de notre site Web grâce à un lien.
              </p>
              <h3>Modifications de la Déclaration et notifications</h3>
              <p>
                Nous pourrons être amenés à mettre à jour la présente
                Déclaration ultérieurement. Si nous estimons que les
                modifications apportées sont importantes, nous vous en
                informerons par l'un ou plusieurs des moyens suivants : en vous
                envoyant une communication sur les modifications, en plaçant une
                notification sur le site Web et/ou en affichant une mise à jour
                de la Déclaration sur le site Web. Nous indiquerons au début de
                la présente Déclaration la date de sa dernière mise à jour. Nous
                vous encourageons à consulter et examiner périodiquement la
                version la plus récente pour obtenir les dernières informations
                disponibles sur nos pratiques en matière de confidentialité des
                données.
              </p>
              <h3>Contact</h3>
              <p>
                Pour toute demande concernant la confidentialité des données,
                par exemple pour demander la suppression ou l'accès à vos
                données, veuillez consulter notre portail dédié à la
                confidentialité ici. Pour toute demande d'ordre général
                concernant la confidentialité des données ou pour toute question
                concernant notre Déclaration relative à la confidentialité et
                aux cookies, veuillez pouvez nous envoyer un email à{" "}
                <a href="mailto:contact@7night.fr">contact@7night.fr</a>.
              </p>
              <p>Europe</p>
              <p>
                Déclaration de confidentialité au titre du Règlement général sur
                la protection des données (« Déclaration de confidentialité des
                données au titre du RGPD »)
              </p>
              <p>
                La présente Déclaration de confidentialité des données au titre
                du RGPD s'applique aux personnes se trouvant dans l'Espace
                économique européen (« EEE »), y compris celles basées au
                Royaume-Uni. La présente Déclaration de confidentialité des
                données au titre du RGPD complète notre Déclaration ; toutefois,
                lorsque celle-ci entre en conflit avec la Déclaration de
                confidentialité des données au titre du RGPD, cette dernière
                prévaudra pour les personnes se trouvant dans l'EEE.
              </p>
              <h3>Responsable du traitement des données personnelles</h3>
              <p>
                7Night est le responsable du traitement des données personnelles
                que nous recueillons. Les coordonnées de notre représentant pour
                l’Union européenne sont les suivantes :
              </p>
              <p>7Night</p>
              <p>45 rue Frédéric joliot curie</p>
              <p>13013 Marseille</p>
              <p>France</p>
              <h3>Vos droits en vertu du RGPD</h3>
              <p>
                Vous disposez de certains droits concernant vos données
                personnelles :
              </p>
              <p>
                Vos droits concernant les données personnelles sont les suivants
                :
              </p>
              <ul>
                <li>
                  Le droit de demander l'accès à vos données personnelles. Cela
                  vous permet de recevoir une copie des données personnelles que
                  nous détenons à votre sujet.
                </li>
                <li>
                  Le droit de demander la correction de vos données personnelles
                  si elles sont inexactes. Vous pouvez également compléter les
                  données personnelles incomplètes en notre possession, compte
                  tenu des fins du traitement.
                </li>
                <li>
                  Le droit de demander la suppression de vos données
                  personnelles si :
                  <ul className="nestedUl">
                    <li>
                      vos données personnelles ne sont plus nécessaires aux fins
                      pour lesquelles nous les avons collectées ou traitées ; ou
                    </li>
                    <li>
                      vous retirez votre consentement lorsque le traitement de
                      vos données personnelles est basé sur le consentement et
                      qu'il n'est pas autrement justifié juridiquement ; ou
                    </li>
                    <li>
                      vous vous opposez au traitement de vos données
                      personnelles et celui-ci n'est pas autrement justifié par
                      des motifs légitimes impérieux ; ou
                    </li>
                    <li>
                      vos données personnelles sont traitées illégalement ; ou
                    </li>
                    <li>
                      {" "}
                      l'effacement de vos données personnelles est nécessaire
                      pour remplir une obligation légale.
                    </li>
                  </ul>
                </li>
                <li>
                  Le droit de vous opposer au traitement de vos données
                  personnelles. Nous accéderons à votre demande, à moins que le
                  traitement ne soit motivé par un intérêt légitime sérieux et
                  impérieux ou que nous devions continuer à traiter vos données
                  personnelles afin d'engager, exercer ou défendre une action en
                  justice.
                </li>
                <li>
                  Le droit de demander la limitation du traitement de vos
                  données personnelles si :
                  <ul className="nestedUl">
                    <li>
                      vous contestez l'exactitude de vos données personnelles,
                      pendant la période durant laquelle nous sommes tenus de
                      vérifier l'exactitude des données personnelles ; ou
                    </li>
                    <li>
                      le traitement est illégal et vous vous opposez à la
                      suppression et demandez plutôt une limitation du
                      traitement ; ou
                    </li>
                    <li>
                      nous n'avons plus besoin de vos données personnelles aux
                      fins du traitement, mais vous en avez besoin pour des
                      actions en justice ; ou
                    </li>
                    <li>
                      vous vous opposez au traitement, pour la période durant
                      laquelle nous sommes tenus de vérifier les motifs
                      légitimes impérieux.
                    </li>
                  </ul>
                </li>
                <li>
                  Le droit à la portabilité des données. Vous pouvez également
                  nous demander d'envoyer ces données personnelles à un tiers,
                  dans la mesure du possible. Vous disposez de ce droit
                  uniquement pour les données personnelles que vous nous avez
                  fournies et pour lesquelles le traitement est basé sur le
                  consentement ou nécessaire à l'exécution d'un contrat entre
                  vous et nous et s'il est réalisé par des moyens automatisés.
                </li>
                <li>
                  Le droit de déposer une plainte auprès du responsable de la
                  protection des données de votre pays.
                </li>
              </ul>
              <p>
                Vous ne devrez pas payer de frais pour accéder à vos données
                personnelles (ou exercer l'un quelconque des autres droits
                décrits dans la présente Déclaration). Toutefois, nous pouvons
                vous facturer des frais d'un montant raisonnable si votre
                demande est manifestement injustifiée, répétée ou excessive.
              </p>
              <p>
                Nous pouvons vous demander de nous communiquer des informations
                spécifiques afin de nous aider à confirmer votre identité et
                d'assurer que vous avez effectivement le droit d'accéder à vos
                données personnelles (ou d'exercer l'un quelconque de vos autres
                droits). Il s'agit d'une mesure de sécurité visant à empêcher la
                divulgation de vos données personnelles à des personnes non
                autorisées. Dans un souci de réduction de notre délai de
                réponse, nous pouvons également vous contacter pour vous
                demander des informations complémentaires concernant votre
                demande. Vous pouvez exercer plusieurs de vos droits par
                l'intermédiaire de la section de votre compte réservée aux
                informations personnelles. Vous pouvez demander à exercer vos
                autres droits en cliquant ici .
              </p>
              <h3>Utilisation des informations</h3>
              <p>
                Nous utilisons vos données personnelles uniquement lorsque nous
                y sommes autorisés par la loi.
              </p>
              <p>
                Conformément au RGPD, nous utilisons vos données personnelles
                dans l'une ou plusieurs des circonstances suivantes :
              </p>
              <ul>
                <li>
                  Lorsque nous en avons besoin pour exécuter le contrat que nous
                  avons conclu avec vous
                </li>
                <li>
                  Lorsque cela est nécessaire pour la défense de nos intérêts
                  légitimes (ou de ceux d'un tiers) et que vos intérêts et vos
                  droits fondamentaux ne l'emportent pas sur ces intérêts
                </li>
                <li>
                  Lorsque nous devons nous conformer à une obligation légale ou
                  réglementaire
                </li>
                <li>Avec votre consentement</li>
              </ul>
              <h3>Transferts internationaux</h3>
              <p>
                Vos données personnelles peuvent être transférées vers des pays
                situés hors de l'Espace économique européen (EEE) et stockées
                dans ces pays aux fins indiquées dans la présente Déclaration.
                Lorsque nous stockons ou transférons vos données personnelles
                hors de l'EEE, nous prenons les précautions suivantes afin de
                nous assurer qu'elles sont correctement protégées.
              </p>
              <p>
                Lorsque nous stockons ou transférons vos données personnelles
                hors de l'EEE, nous le faisons conformément à la loi en vigueur
                et nous nous assurons qu'elles bénéficient d'un niveau de
                protection similaire en mettant en place des dispositifs de
                sauvegarde appropriés. Les transferts de données personnelles
                sont réalisés:
              </p>
              <ul>
                <li>
                  vers un pays reconnu par la Commission européenne comme
                  assurant un niveau de protection adéquat, ou ;
                </li>
                <li>
                  vers un pays qui n'offre pas de protection adéquate, le
                  transfert étant régi par les clauses contractuelles types de
                  la Commission européenne, ou en mettant en œuvre d'autres
                  solutions appropriées de transfert international pour assurer
                  l'adéquation de la protection.
                </li>
              </ul>
              <p>
                En utilisant nos services, vous comprenez que vos données
                personnelles peuvent être transférées vers nos installations et
                aux tiers avec lesquels nous les partageons, comme indiqué dans
                la présente Déclaration.
              </p>
              <h3>©2022 7Night. Tous droits réservés</h3>
            </div>
            <div className="go" onClick={() => window.scrollTo(0, 0)}>
              <img src={goTop} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TermsOfServices;

import { FC, ReactElement, useState } from "react";
import LoadingSpinner from "../../../../components/LoadingSpinner/LoadingSpinner";
import { useClubScheduleQuery } from "../../../../react-query/hooks/Clubs";
import AddNewSchedule from "../../../modals/AddNewSchedule";
import ScheduleInfo from "../../Molecules/ScheduleInfo/ScheduleInfo";
import { NoResults } from "../NoResults/NoResults";

interface ClubSchedulesProps {
  id: string;
}
const ClubSchedules: FC<ClubSchedulesProps> = ({ id }): ReactElement => {
  const [modalShow, setModalShow] = useState(false);
  const { data: { data } = {}, isLoading } = useClubScheduleQuery(+id);

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div>
          <div className="configurationButton">
            <button onClick={() => setModalShow(true)}>+ Add</button>
          </div>
          {data?.length! > 0 ? (
            <div className="dashboardResults">
              <table>
                <tr>
                  <th>JOUR</th>
                  <th>HEURE D'OUVERTURE</th>
                  <th>HEURE DE FERMETURE</th>
                  <th className="lastElement actionColumn">ACTIONS</th>
                </tr>
                {data?.map((schedule) => {
                  return (
                    <ScheduleInfo
                      key={schedule?.id}
                      scheduleId={schedule?.id}
                      startTime={schedule?.startTime}
                      endTime={schedule?.endTime}
                      weekday={schedule?.weekDay}
                      clubId={schedule?.businessId}
                    />
                  );
                })}
              </table>
              <div className="dashboardPagination"></div>
            </div>
          ) : (
            <NoResults text="Aucun horaire ajouté" />
          )}
          <AddNewSchedule
            show={modalShow}
            onHide={() => setModalShow(false)}
            clubId={+id}
            post
          />
        </div>
      )}
    </>
  );
};
export default ClubSchedules;

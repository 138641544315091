import { useEffect, useState } from "react";
import { favoritesBusinessArray } from "../../../../api/Business";
import { DjBasicInfo } from "../../../../api/Dj";
import { favoritesEveningArray } from "../../../../api/Events";
import { getAllFavorites } from "../../../../api/User";
import FavoriteClubCard from "../../../components/ProfilePages/ProfileFavorites/FavoriteClubCard/FavoriteClubCard";
import FavoriteDjCard from "../../../components/ProfilePages/ProfileFavorites/FavoriteDjCard/FavoriteDjCard";
import FavoriteEveningCard from "../../../components/ProfilePages/ProfileFavorites/FavoriteEveningCard/FavoriteEveningCard";
import NoFavorites from "../../../components/ProfilePages/ProfileFavorites/NoFavorites/NoFavorites";
import SkeletonFavoriteClubCard from "../../../components/ProfilePages/ProfileFavorites/SkeletonFavoriteClubCard/SkeletonFavoriteClubCard";
import SkeletonFavoriteDjCard from "../../../components/ProfilePages/ProfileFavorites/SkeletonFavoriteDjCard/SkeletonFavoriteDjCard";
import SkeletonFavoriteEventCard from "../../../components/ProfilePages/ProfileFavorites/SkeletonFavoriteEventCard/SkeletonFavoriteEventCard";
import "./_ProfileFavorites.scss";

const AllFavorites = () => {
  const [allFavoriteData, setAllFavoriteData] =
    useState<
      (favoritesBusinessArray | favoritesEveningArray | DjBasicInfo)[]
    >();
  const [loading, setLoading] = useState<boolean>(true);

  const getAllFavoritesData = async () => {
    try {
      const response: any = await getAllFavorites();
      setAllFavoriteData(response);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getAllFavoritesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function isBussiness(item: object): item is favoritesBusinessArray {
    return (item as favoritesBusinessArray).averageRating !== undefined;
  }
  function isDj(item: object): item is DjBasicInfo {
    return (item as DjBasicInfo).imagePath !== undefined;
  }
  function isEvent(item: object): item is favoritesEveningArray {
    return (item as favoritesEveningArray).clubName !== undefined;
  }

  return (
    <>
      {allFavoriteData?.length === 0 ? (
        <div className="profilefavorites-empty">
          <NoFavorites
            text={
              "Ajoutez des clubs, èvènements, djs à vos favoris pour les retrouver facilement "
            }
          />
        </div>
      ) : (
        <div className="profilefavorites-content">
          <div>
            {loading && (
              <div>
                <SkeletonFavoriteClubCard />
                <SkeletonFavoriteEventCard />
                <SkeletonFavoriteDjCard />
              </div>
            )}
            <div>
              {allFavoriteData?.map((item) => (
                <div key={item.id}>
                  {isBussiness(item) && (
                    <FavoriteClubCard
                      name={item.name}
                      address={item.address}
                      banner={item.banner}
                      id={item.id}
                    />
                  )}
                  {isEvent(item) && (
                    <FavoriteEveningCard
                      title={item.title}
                      address={item.address}
                      banner={item.banner}
                      clubName={item.clubName}
                      schedule={item.date}
                      id={item.id}
                    />
                  )}
                  {isDj(item) && (
                    <FavoriteDjCard
                      id={item.id}
                      name={item.name}
                      imagePath={item.imagePath}
                      rating={item.rating}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default AllFavorites;

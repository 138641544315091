import { ReactElement, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ServicePostModel } from "../../interfaces/models/postModels/ServicePost.model";
import {
  usePostClubServiceQuery,
  usePutClubServiceQuery,
  useServicesTypeQuery,
} from "../../react-query/hooks/Clubs";
import { ServiceUpdateModel } from "../../interfaces/models/updateModels/ServiceUpdate.model";

const schema = yup.object().shape({
  name: yup.string().typeError("Obligatoire").required("Obligatoire"),
  price: yup.number().positive().integer().typeError("Obligatoire").required(),
  serviceType: yup
    .number()
    .positive()
    .integer()
    .typeError("Obligatoire")
    .required(),
});

const AddNewTarif = (props: any): ReactElement => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitSuccessful, errors },
  } = useForm<ServicePostModel>({
    resolver: yupResolver(schema),
  });

  const {
    mutate: addClubSchedule,
    isLoading: isAddLoading,
    isSuccess: isAddSuccess,
  } = usePostClubServiceQuery(props.clubId, props.pageNumber);
  const {
    mutate: updateClubSchedule,
    isLoading: isUpdateLoading,
    isSuccess: isUpdateSuccess,
  } = usePutClubServiceQuery(props.clubId, props.pageNumber);

  const { data: { data } = {} } = useServicesTypeQuery();

  const handleScheduleSubmit = (
    data: ServicePostModel | ServiceUpdateModel
  ) => {
    if (props.post) {
      return addClubSchedule({ ...data, businessId: props.clubId });
    }
    return updateClubSchedule({ ...data, id: props.serviceId });
  };

  useEffect(() => {
    props.onHide();
    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful === true]);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="dashboardModal"
    >
      <Modal.Header>
        {props.post && <h4>Ajouter un nouveau tarif</h4>}
        {props.update && <h4>Tarif de mise à jour</h4>}
      </Modal.Header>
      <Modal.Body>
        <form
          className="modalBody"
          onSubmit={handleSubmit(handleScheduleSubmit)}
        >
          <div className="modalBody-Input modalItem">
            <label htmlFor="title">Catégorie</label>
            <select id="serviceType" {...register("serviceType")}>
              <option selected hidden disabled={props.update}>
                Select Catégorie
              </option>
              {data?.data?.map((service) => {
                return (
                  <option
                    value={service?.id}
                    selected={service?.id === props?.serviceTypeId}
                  >
                    {service?.name}
                  </option>
                );
              })}
            </select>
            {errors.serviceType && (
              <p className="tw-text-red-600 mb-0">
                {errors.serviceType.message}
              </p>
            )}
          </div>
          <div className="modalBody-Input modalItem">
            <label htmlFor="désignation">Désignation</label>
            <input
              type="text"
              id="désignation"
              placeholder="Désignation"
              {...register("name")}
              defaultValue={props.name}
            />
            {errors.name && (
              <p className="tw-text-red-600 mb-0">{errors.name.message}</p>
            )}
          </div>
          <div className="modalBody-Input modalItem">
            <label htmlFor="price">Prix €</label>
            <input
              type="text"
              id="price"
              placeholder="Prix"
              {...register("price")}
              defaultValue={props.price}
            />
            {errors.price && (
              <p className="tw-text-red-600 mb-0">{errors.price.message}</p>
            )}
          </div>
          <div className="modalBody-buttons modalItem">
            <button
              onClick={() => {
                props.onHide();
                reset();
              }}
              type="button"
            >
              Annuler
            </button>
            {props.post && (
              <button type="submit" disabled={isAddLoading}>
                Ajouter
              </button>
            )}
            {props.update && (
              <button type="submit" disabled={isUpdateLoading}>
                Sauvegarder
              </button>
            )}
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};
export default AddNewTarif;

import React, { useState, useEffect, useRef } from "react";
import Axios from "axios";
import { AuthContextType, AuthContext } from "./AuthContext";
import { getUserProfile, LoginResponse, user } from "../../api/Api";
import jwt_decode from "jwt-decode";
import { decodedJwt } from "../../api/Shared";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

interface AuthContextProviderProps {
  children: React.ReactNode | null;
}

export const AuthContextProvider = (props: AuthContextProviderProps) => {
  const queryClient = useQueryClient();
  const effectRun = useRef(false);
  const [userProfilePicture, setUserProfilePicture] = useState<
    string | undefined
  >(undefined);
  const [user, setUser] = useState<user | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const [decoded, setDecoded] = useState<decodedJwt>();
  const [location, setLocation] = useState<
    { latitude?: number; longitude?: number } | undefined
  >({});
  const navigate = useNavigate();

  useEffect(() => {
    if (effectRun.current === false) {
      checkAuthentication();
    }
    return () => {
      effectRun.current = true;
    };
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (user !== undefined) {
      const jwt = localStorage.getItem("Token");
      setDecoded(jwt_decode(jwt!));
    }
  }, [user]);

  const getUserProfileData = async () => {
    try {
      const response: any = await getUserProfile();
      setUser(response?.data);
      setUserProfilePicture(response?.userProfile?.profilePicture);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const checkAuthentication = () => {
    const token = localStorage.getItem("Token");
    if (!token) {
      setUser(undefined);
      setDecoded(undefined);
      return;
    }
    Axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    getUserProfileData();
  };

  const login = (response: LoginResponse) => {
    const user = response?.data?.userData;
    const token = response?.data?.jwt;
    localStorage.setItem("Token", token);
    Axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    const decoded: decodedJwt = jwt_decode(token);
    setDecoded(decoded);
    setUser(user);
    setUserProfilePicture(response?.data?.userData?.profilePicture);
    setLoading(false);
    switch (decoded?.role) {
      case "Admin":
        navigate("/admin");
        break;
      case "Manager":
        navigate("/manager");
        break;
    }
  };

  const logout = () => {
    localStorage.removeItem("Token");
    delete Axios.defaults.headers.common.Authorization;
    queryClient.removeQueries(["subscriptionStaus"]);
    setUser(undefined);
    setDecoded(undefined);
  };

  const context: AuthContextType = {
    isAuthenticated: user !== undefined,
    isLoading: loading,
    user: user,
    setUser: setUser,
    login: login,
    logout: logout,
    checkAuthentication,
    decoded,
    userProfilePicture,
    setUserProfilePicture,
    location,
    setLocation,
  };

  return (
    <AuthContext.Provider value={context}>
      {props.children}
    </AuthContext.Provider>
  );
};

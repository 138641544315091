import {FC} from 'react';
import obtenez from '../../../../assets/photos/obtenez.webp';
import './_ObtainConcreteCF.scss';
import '../_ClubSpaceWhy7NightSharedStyle.scss';

interface ObtainConcreteCFProps {
}

 const ObtainConcreteCF: FC<ObtainConcreteCFProps>=()=> {
  return (
    <div className='obtainconcretecf'>
      <div className='obtainconcretecf-content container'>
        <div className='obtainconcretecf-content-section1  clubspacewhy7nightsharedstyle'>
          <h3>Obtenez un fichier client béton</h3>
          <p><span>Le fichier client est indispensable pour un club.</span><br />
             Gagner du temps, de l’organisation et arrêtez d’enregistrer les numéro de téléphone, Snapchat et autre de vos clients.  Chaque utilisateur de l’application 7Night qui assiste à un de vos évènements <span>même sans réserver, vous fera obtenir ses informations</span> tels que l’adresse mail, le prénom, l’âge et le sexe seront enregistré dans votre fichier clients. 
              Vous pourrez ainsi lui <span>envoyer des notifications</span> pour promouvoir d’autres évènements.</p>
        </div>
        <div className='obtainconcretecf-content-section2'>
          <img src={obtenez} alt="" />
        </div>
      </div>
    </div>
  );
}

export default ObtainConcreteCF;
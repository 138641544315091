import objectToQueryString from "../utils/objectToQueryString";
import { apiRequest } from "./Api";
import { responseBody, metaData } from "./Shared";

//-----BusinessEvent-----
export interface BusinessEventBasicInfo {
  hasShowCase: boolean;
  isAlmostFull: boolean;
  isFreeForBoys: boolean;
  isOpened: boolean;
  isFreeForGirls: boolean;
  id: number;
  city: string;
  country: string;
  address: string;
  title: string;
  clubName: string;
  date: string;
  averageScore: number;
  banner: string;
  insertedDate: string;
  distance: number;
  longitude: number;
  latitude: number;
}

//-------BusinessEventInput--------
export interface GetBusinessEventsInput {
  searchWord?: string;
  date?: string;
  businessId?: number;
  longitude?: number;
  latitude?: number;
  radius?: number;
  businessType?: number;
  interior?: boolean;
  exterior?: boolean | null;
  hasShowcase?: boolean | null;
  pageSize?: number;
  pageNumber?: number;
  isFreeEntryForBoys?: boolean;
  isFreeEntryForGirls?: boolean | null;
  location?: string;
}
export interface GetBusinessEventsResponse {
  data: {
    data: BusinessEventBasicInfo[];
    metaData: metaData;
  };
}

// -----GetEventProfileInput-----
export interface GetEventProfileInput {
  Id?: string;
}

// -----BusinessEventFavorites----

// ---Get---
export interface GetFavoritesBusinessEventResponse extends responseBody {
  data: {
    data: favoritesEveningArray;
    metaData: metaData;
  };
}
export interface favoritesEveningArray {
  longitude: number;
  latitude: number;
  id: number;
  city: string;
  country: string;
  address: string;
  title: string;
  clubName: string;
  hasShowCase: boolean;
  date: string;
  averageScore: 4;
  banner: string;
  insertedDate: string;
  distance: number;
}

// ---Post---

export interface DeleteFavoriteEveningResponse extends responseBody {
  data: string;
}

// -------apiRequests---------
export const getBusinessEvents = async (
  data: GetBusinessEventsInput,
  location?: { latitude?: number; longitude?: number } | undefined
) =>
  apiRequest<GetBusinessEventsInput, GetBusinessEventsResponse>(
    "post",
    "/BusinessEvents/search",
    data,
    { Location: `${location?.latitude},${location?.longitude}` }
  );

export const getFavoritesBusinessEvent = async (params: any) =>
  apiRequest<GetFavoritesBusinessEventResponse>(
    "get",
    `/BusinessEvents/favorite?${objectToQueryString(params)}&pageSize=3`
  );

export const postFavoriteEvent = async (data: { id?: number }) =>
  apiRequest<{ id?: number }, responseBody>(
    "post",
    `/BusinessEvents/favorite/${data.id}`,
    data
  );

export const deleteFavoriteEvent = async (data: { id?: number }) =>
  apiRequest<{ id?: number }, DeleteFavoriteEveningResponse>(
    "delete",
    `/BusinessEvents/favorite/${data.id}`,
    data
  );

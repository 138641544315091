import { Tab, Tabs } from "react-bootstrap";
import queryString from "query-string";
import ConfigurationsDjs from "../../components/Organisms/ConfigurationsDjs/ConfigurationsDjs";
import ConfigurationsMusics from "../../components/Organisms/ConfigurationsMusics/ConfigurationsMusics";
import ConfigurationsPacks from "../../components/Organisms/ConfigurationsPacks/ConfigurationsPacks";
import "./_AdminConfiguration.scss";
import ConfigurationsConstants from "../../components/Organisms/ConfigurationsConstants/ConfigurationsConstants";
import { useLocation, useNavigate } from "react-router-dom";

const AdminConfiguration = () => {
  // hooks
  const navigate = useNavigate();
  const { search } = useLocation();

  // queryString
  const queryParams = queryString.parse(search);

  // handlers
  const handleTabSelect = (value: string | null) =>
    navigate({ search: `tab=${value}` });

  // constants
  const activeKey = queryParams.tab ? String(queryParams.tab) : "packs";

  return (
    <div className="adminconfiguration">
      <div className="dashboardTitle">
        <h3>Configurations</h3>
      </div>
      <Tabs
        id="noanim-tab-example"
        defaultActiveKey={activeKey}
        onSelect={handleTabSelect}
        transition={false}
        className="dashboardNav"
      >
        <Tab eventKey="packs" title="Packs" mountOnEnter unmountOnExit>
          <ConfigurationsPacks />
        </Tab>
        <Tab eventKey="djs" title="DJ's" mountOnEnter unmountOnExit>
          <ConfigurationsDjs />
        </Tab>
        <Tab eventKey="music" title="Musique" mountOnEnter unmountOnExit>
          <ConfigurationsMusics />
        </Tab>
        <Tab eventKey="wld" title="Winkes/Likes" mountOnEnter unmountOnExit>
          <ConfigurationsConstants />
        </Tab>
      </Tabs>
    </div>
  );
};
export default AdminConfiguration;

import { joiResolver } from "@hookform/resolvers/joi";
import { FC, ReactElement } from "react";
import { Modal, ModalProps } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { PackageResponseModel } from "../../interfaces/models/getModels/ConfigurationResponse.model";
import { PackageFormPayload } from "../../interfaces/models/postModels/PackageFormPayload.model";
import { useEditPackageMutation } from "../../react-query/hooks/useConfiguration.mutation";
import { PackageFormSchema } from "../../validations/PackageForm.schema";

interface EditPackageProps extends ModalProps {
  configPackage: PackageResponseModel;
  onHide: () => void;
}

const EditPackage: FC<EditPackageProps> = ({
  configPackage,
  onHide,
  ...rest
}): ReactElement => {
  const { id, productId, name, price, ...restInfo } = configPackage;

  // hook form
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<PackageFormPayload>({
    resolver: joiResolver(PackageFormSchema),
    defaultValues: restInfo,
  });

  // react-query
  const { mutate: editPackage } = useEditPackageMutation();

  // handlers
  const handleHideModal = () => {
    reset();
    onHide();
  };

  const onSubmit = (data: PackageFormPayload) =>
    editPackage(
      { ...data, id, productId, name, price },
      {
        onSuccess: () => {
          reset(data);
          onHide();
        },
      }
    );

  return (
    <Modal
      {...rest}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="dashboardModal"
    >
      <Modal.Header>
        <h4>Modifier le package</h4>
      </Modal.Header>
      <Modal.Body as="form" onSubmit={handleSubmit(onSubmit)}>
        <div className="modalBody">
          <div className="modalBody-Input modalItem">
            <label htmlFor="numberOfLikes">Nombre de Likes</label>
            <input {...register("likes")} id="numberOfLikes" />
            {errors.likes && (
              <p className="tw-text-red-600">{errors.likes.message}</p>
            )}
          </div>

          <div className="modalBody-Input modalItem">
            <label htmlFor="numberOfWinks">Nombre de Winkes</label>
            <input {...register("winks")} id="numberOfWinks" />
            {errors.winks && (
              <p className="tw-text-red-600">{errors.winks.message}</p>
            )}
          </div>

          <div className="modalBody-Input modalItem">
            <label htmlFor="numberOfDrinks">Nombre de Drinks</label>
            <input {...register("drinks")} id="numberOfDrinks" />
            {errors.drinks && (
              <p className="tw-text-red-600">{errors.drinks.message}</p>
            )}
          </div>
          <div className="modalBody-buttons modalItem">
            <button type="button" onClick={handleHideModal}>
              Annuler
            </button>
            <button type="submit">Sauvegarder</button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default EditPackage;

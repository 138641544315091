import PaymentCard from "../../Molecules/PaymentCard/PaymentCard";
import AddIcon from "@mui/icons-material/Add";
import { useGetAllPaymentMethods } from "../../../../react-query/hooks/paymentProfile";
import LoadingSpinner from "../../../../components/LoadingSpinner/LoadingSpinner";
import { useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { REACT_APP_STRIPE_CONFIG_CODE } from "../../../../config/app";
import { loadStripe } from "@stripe/stripe-js";
import PaymentCards from "../../../modals/PaymentCards";
import { addPaymentMethod } from "../../../../react-query/mutations/paymentProfile";

const stripePromise = loadStripe(`${REACT_APP_STRIPE_CONFIG_CODE}`);

const PaymentMethods = () => {
  const { data: { data } = {}, isLoading, refetch } = useGetAllPaymentMethods();
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [clientSecretCode, setClientSecretCode] = useState<unknown>("");
  const addNewPaymentMethod = async () => {
    try {
      const response: any = await addPaymentMethod();
      setClientSecretCode(response?.data?.data?.clientSecret);
      !!response?.data?.data?.clientSecret && setModalShow(true);
    } catch (e) {
      console.log(e);
    }
  };
  const options = {
    clientSecret: `${clientSecretCode}`,
  };
  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <Elements
          stripe={stripePromise}
          options={options}
          key={`clientSecretCode-${clientSecretCode}`}
        >
          <div className="paymentMethods">
            <div className="paymentMethods-button">
              <button type="button" onClick={() => addNewPaymentMethod()}>
                <AddIcon sx={{ fontSize: "18px" }} />{" "}
                <p>Ajouter une nouvelle carte</p>
              </button>
            </div>
            <>
              {data?.map((card) => {
                return (
                  <PaymentCard
                    key={card.paymentMethodId}
                    cardId={card.paymentMethodId}
                    cardNumber={card.cardNumber}
                    expireMonth={card.expMonth}
                    expireYear={card.expYear}
                    cardType={card.brand}
                    contry={card.country}
                    isDefaultCard={card.isDefaultPaymentMethod}
                  />
                );
              })}
            </>
          </div>
          <PaymentCards
            show={modalShow}
            onHide={() => setModalShow(false)}
            refetch={refetch}
          />
        </Elements>
      )}
    </>
  );
};
export default PaymentMethods;

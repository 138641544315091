import axios from "../../initializations/axios";
import { MetaDataResponse } from "../../interfaces/mainModels/MetaDataResponse.model";
import { UserModel } from "../../interfaces/models/getModels/User.model";

export const fetchAllUsers = async (
  params: string
): Promise<MetaDataResponse<UserModel[]>> => {
  const { data } = await axios.get(`UserManager/all${params && `?${params}`}`);

  return data.data;
};

import { Outlet, useLocation } from "react-router-dom";
import queryString from "query-string";
import Footer from "./partials/Footer/Footer";
import Header from "./partials/Header/Header";
import "./_MainSharedLayout.scss";

const MainSharedLayout = () => {
  const { search } = useLocation();
  const queryParams = queryString.parse(search);
  const shouldHideHeader: boolean = queryParams.mobile !== "hidden";
  return (
    <>
      {shouldHideHeader && <Header />}
      <div
        className="userOutlet "
        style={!shouldHideHeader ? { paddingTop: "0" } : undefined}
      >
        <Outlet />
      </div>
      <Footer />
    </>
  );
};

export default MainSharedLayout;

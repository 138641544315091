import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./_FavoriteDjCard.scss";
import { deleteFavoriteDj, postFavoriteDj } from "../../../../../api/Dj";
import favoriteIcon from "../../../../../assets/icons/heart.webp";
import ratingStar from "../../../../../assets/icons/star.svg";
interface FavoriteDjCardProps {
  name?: string;
  imagePath?: string;
  id?: number;
  rating?: number;
}

const FavoriteDjCard: FC<FavoriteDjCardProps> = ({
  rating,
  name,
  imagePath,
  id,
}) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(`/djs/${id}`);
  };
  const [favorite, setFavorite] = useState<boolean>(true);

  const deleteFavDj = async () => {
    try {
      await deleteFavoriteDj({ id });
    } catch (e) {
      console.log(e);
    }
  };
  const addFavDj = async () => {
    try {
      await postFavoriteDj({ djId: id });
    } catch (e) {
      console.log(e);
    }
  };

  const handleChildElementClickDelete = (e: any) => {
    e.stopPropagation();
    deleteFavDj();
  };
  const handleChildElementClickAdd = (e: any) => {
    e.stopPropagation();
    addFavDj();
  };

  return (
    <div className="favoritedjcard" onClick={handleNavigate}>
      <img
        className={`favoriteIcon ${favorite ? "favorite" : "unfavorite"} `}
        onClick={(e) => {
          favorite && handleChildElementClickDelete(e);
          setFavorite((prev) => !prev);
          !favorite && handleChildElementClickAdd(e);
        }}
        src={favoriteIcon}
        alt=""
      />
      <img className="favoritedjcard-photo" src={imagePath} alt="" />
      <div className="favoritedjcard-info">
        <h3>{name}</h3>
        <div className="favoritedjcard-info-rating">
          <img src={ratingStar} alt="" />
          <span>{rating?.toFixed(1)}</span>
        </div>
      </div>
    </div>
  );
};

export default FavoriteDjCard;

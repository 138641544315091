import "./_AddProfilePic.scss";
import { Modal } from "react-bootstrap";
import { ChangeEvent, useState } from "react";
import uploadImg from "../../../assets/icons/uploadImg.png";
import { ProfilePicturePostModel } from "../../../interfaces/models/postModels/ProfilePicturePost.model";
import { UseConvertToBase64 } from "../../../hooks/UseConvertToBase64";
import { useUploadProfilePic } from "../../../react-query/hooks/User";

const AddProfilePic = (props: any) => {
  const [profilePhoto, setProfilePhoto] = useState<ProfilePicturePostModel>();
  const uploadFile = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files![0];
    const fileInBase64 = await UseConvertToBase64(file);
    setProfilePhoto({
      photo: {
        fileName: e.target.files![0].name,
        base64: fileInBase64 as string,
      },
    });
  };
  const { mutate: uploadProfilePic } = useUploadProfilePic();
  const uploadProfilePicture = () => {
    uploadProfilePic(profilePhoto!);
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="dashboardModal"
      onHide={() => {
        props.onHide();
        setProfilePhoto(undefined);
      }}
    >
      <Modal.Header>
        <h4>Télécharger une photo</h4>
      </Modal.Header>
      <Modal.Body>
        <div className="modalBody">
          <label className="imageInput modalItem">
            <input
              type="file"
              accept="image/png, image/jpeg"
              onChange={(e) => {
                uploadFile(e);
              }}
            />
            {!!profilePhoto ? (
              <div className="profilePhotoAdded">
                <img src={profilePhoto?.photo?.base64} alt="" />
              </div>
            ) : (
              <div className="imageInput-style">
                <img src={uploadImg} alt="" />
                <h6>
                  Déposez votre image ici, ou parcourez sur votre appareil
                </h6>
                <p>Format requis: JPG, PNG</p>
              </div>
            )}
          </label>
          <div className="modalBody-buttons modalItem">
            <button
              onClick={() => {
                props.onHide();
                setProfilePhoto(undefined);
              }}
            >
              Annuler
            </button>
            <button
              onClick={() => {
                uploadProfilePicture();
                props.onHide();
                setProfilePhoto(undefined);
              }}
              disabled={!profilePhoto}
            >
              Ajouter
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddProfilePic;

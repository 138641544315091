import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import building from "../../../../../assets/icons/building.svg";
import calendar from "../../../../../assets/icons/calendar.svg";
import location from "../../../../../assets/icons/location.svg";
import "./_FavoriteEveningCard.scss";
import {
  deleteFavoriteEvent,
  postFavoriteEvent,
} from "../../../../../api/Events";
import favoriteIcon from "../../../../../assets/icons/heart.webp";

interface FavoriteEveningCardProps {
  title?: string;
  address?: string;
  banner?: string;
  clubName?: string;
  schedule?: string;
  id?: number;
}

const FavoriteEveningCard: FC<FavoriteEveningCardProps> = ({
  title,
  id,
  address,
  banner,
  clubName,
  schedule,
}) => {
  const navigate = useNavigate();
  const [favorite, setFavorite] = useState<boolean>(true);
  const handleNavigate = () => {
    navigate(`/evenings/${id}`);
  };

  const localDate = new Date(schedule!);
  const localDateString = localDate.toLocaleDateString(undefined, {
    day: "numeric",
    month: "short",
    year: "numeric",
  });

  const localTimeString = localDate.toLocaleTimeString(undefined, {
    hour: "2-digit",
    minute: "2-digit",
  });
  const deleteFavoriteEvening = async () => {
    try {
      await deleteFavoriteEvent({ id });
    } catch (e) {
      console.log(e);
    }
  };

  const addFavoriteEvening = async () => {
    try {
      await postFavoriteEvent({ id });
    } catch (e) {
      console.log(e);
    }
  };

  const handleChildElementClickDelete = (e: any) => {
    e.stopPropagation();
    deleteFavoriteEvening();
  };
  const handleChildElementClickAdd = (e: any) => {
    e.stopPropagation();
    addFavoriteEvening();
  };

  return (
    <div className="favoriteeveningcard" onClick={handleNavigate}>
      <img
        className={`favoriteIcon ${favorite ? "favorite" : "unfavorite"} `}
        onClick={(e) => {
          favorite && handleChildElementClickDelete(e);
          setFavorite((prev) => !prev);
          !favorite && handleChildElementClickAdd(e);
        }}
        src={favoriteIcon}
        alt=""
      />
      <img className="favoriteeveningcard-photo" src={banner} alt="" />
      <div className="favoriteeveningcard-info">
        <h3>{title}</h3>
        <div className="favoriteeveningcard-info-section2">
          <div className="favoriteeveningcard-info-section2-item">
            <img src={building} alt="" />
            <span>{clubName}</span>
          </div>
          <div className="favoriteeveningcard-info-section2-item">
            <img src={calendar} alt="" />
            <span>
              {localDateString}, {localTimeString}
            </span>
          </div>
          <div className="favoriteeveningcard-info-section2-item">
            <img src={location} alt="" />
            <span>{address}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FavoriteEveningCard;

import './_LoadingSpinner.scss';

const LoadingSpinner=()=>{
    return(
        <div className="spinner-container">
            <div className="loading-spinner">
            </div>
        </div>
    )
}
export default LoadingSpinner;
import { createContext, useContext } from "react";

export interface LoginRegisterResetPasswordContextType {
  email: string;
  setEmail: (value: string) => void;
  languageId: number;
  setLanguageId: (value: number) => void;
  resetPasswordCode: string;
  setResetPasswordCode: (value: string) => void;
  page: number;
  setPage: (value: number) => void;
  gender: number;
  setGender: (value: number) => void;
  modalshow: boolean;
  setModalshow: (value: boolean) => void;
  defaultKey: string;
  setDefaultKey: (value: string) => void;
  photo?: string;
  setPhoto: (value: string) => void;
}
const LoginRegisterResetPasswordContextValues: LoginRegisterResetPasswordContextType =
  {
    email: "",
    setEmail: () => {},
    languageId: 1,
    setLanguageId: () => {},
    resetPasswordCode: "",
    setResetPasswordCode: () => {},
    page: 1,
    setPage: () => {},
    gender: 0,
    setGender: () => {},
    modalshow: false,
    setModalshow: () => {},
    defaultKey: "",
    setDefaultKey: () => {},
    photo: "login",
    setPhoto: () => {},
  };
export const LoginRegisterResetPasswordContext =
  createContext<LoginRegisterResetPasswordContextType>(
    LoginRegisterResetPasswordContextValues
  );

export const useLoginRegisterResetPasswordContext = () =>
  useContext(LoginRegisterResetPasswordContext);

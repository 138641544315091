import { NavLink, useNavigate } from "react-router-dom";
import winkImg from "../../../../assets/icons/winkImg.svg";
import likeImg from "../../../../assets/icons/likeImg.svg";
import drinkImg from "../../../../assets/icons/drinkImg.svg";
import profile from "../../../../assets/icons/profile.png";
import profilecalendar from "../../../../assets/icons/profilecalendar.png";
import profileheart from "../../../../assets/icons/profileheart.png";
import profilerecontre from "../../../../assets/icons/profilerecontre.png";
import profiledisconnect from "../../../../assets/icons/profiledisconnect.svg";
import notificationBell from "../../../../assets/icons/notificationBell.png";
import go from "../../../../assets/icons/go.svg";
import "./_ProfileNavbar.scss";
import { useAuthContext } from "../../../../context/AuthContext/AuthContext";
import { useEffect, useState } from "react";
import defaultProfile from "../../../../assets/photos/defaultProfile.png";
import uploadProfileImgIcon from "../../../../assets/icons/uploadProfileImgIcon.png";
import AddProfilePic from "../../../modals/AddProfilePic/AddProfilePic";
import { useWalletBalance } from "../../../../react-query/hooks/Wallet";
import { useUserProfile } from "../../../../react-query/hooks/User";
import Photo from "../../../modals/Photo/Photo";

const ProfileNavbar = () => {
  const navigate = useNavigate();
  const { data: { data: walletBalace } = {} } = useWalletBalance();
  const { data } = useUserProfile();
  const authCtx = useAuthContext();
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [photoSrc, setPhotoSrc] = useState<string>();
  const [modalPhotoShow, setModalPhotoShow] = useState<boolean>(false);

  useEffect(() => {
    authCtx?.setUserProfilePicture(data?.data?.profilePicture);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.data]);

  return (
    <div className="profilednavbar">
      <div className="profilednavbar-profile">
        <div className="profilednavbar-profile-photo">
          <div className="profilednavbar-profile-photo-upload">
            <img
              src={data?.data?.profilePicture || defaultProfile}
              alt="pic"
              className="profilePhoto"
              onClick={() => {
                setPhotoSrc(data?.data?.profilePicture);
                setModalPhotoShow(true);
              }}
            />
            <img
              src={uploadProfileImgIcon}
              alt=""
              className="icon"
              onClick={() => setModalShow(true)}
            />
          </div>

          <h3>{`${authCtx?.user?.firstName} ${authCtx?.user?.lastName}`}</h3>
        </div>
        <div className="profilednavbar-profile-wld">
          <div className="item">
            <img src={winkImg} alt="" />
            <div className="item-info">
              <h2>{walletBalace?.winks}</h2>
              <span>wink(s)</span>
            </div>
          </div>
          <div className="item">
            <img src={likeImg} alt="" />
            <div className="item-info">
              <h2>{walletBalace?.likes}</h2>
              <span>like(s)</span>
            </div>
          </div>
          <div className="item">
            <img src={drinkImg} alt="" />
            <div className="item-info">
              <h2>{walletBalace?.drinks}</h2>
              <span>drink(s)</span>
            </div>
          </div>
        </div>
      </div>

      <div className="profilednavbar-navbar">
        <div className="profilednavbar-navbar-part1">
          <NavLink to="" className="profilednavbar-navbar-item" end>
            <div className="firstpart">
              <div className="firstpart-icon">
                <img className="info-icon" src={profile} alt="" />
              </div>
              <h3>Mon Profil</h3>
            </div>
            <img className="go-icon" src={go} alt="" />
          </NavLink>
          <NavLink to="reservations" className="profilednavbar-navbar-item">
            <div className="firstpart">
              <div className="firstpart-icon">
                <img className="info-icon" src={profilecalendar} alt="" />
              </div>
              <h3>Mes Réservations</h3>
            </div>
            <img className="go-icon" src={go} alt="" />
          </NavLink>
          <NavLink to="favorites" className="profilednavbar-navbar-item">
            <div className="firstpart">
              <div className="firstpart-icon">
                <img className="info-icon" src={profileheart} alt="" />
              </div>
              <h3>Mes Favoris</h3>
            </div>
            <img className="go-icon" src={go} alt="" />
          </NavLink>
          <NavLink to="notifications" className="profilednavbar-navbar-item">
            <div className="firstpart">
              <div className="firstpart-icon">
                <img className="info-icon" src={notificationBell} alt="" />
              </div>
              <h3>Notifications</h3>
            </div>
            <img className="go-icon" src={go} alt="" />
          </NavLink>
          <NavLink to="recontre" className="profilednavbar-navbar-item">
            <div className="firstpart">
              <div className="firstpart-icon">
                <img className="info-icon" src={profilerecontre} alt="" />
              </div>
              <h3>Packs</h3>
            </div>
            <img className="go-icon" src={go} alt="" />
          </NavLink>
          <button
            className="profilednavbar-navbar-item logoutButton"
            onClick={() => {
              authCtx.logout();
              navigate("/");
            }}
          >
            <div className="firstpart">
              <img className="info-icon" src={profiledisconnect} alt="" />
              <h3>Déconnecter</h3>
            </div>
          </button>
        </div>
      </div>
      <AddProfilePic show={modalShow} onHide={() => setModalShow(false)} />
      <Photo
        photoSrc={photoSrc}
        show={modalPhotoShow}
        onHide={() => setModalPhotoShow(false)}
        firstItem={true}
        lastItem={true}
      />
    </div>
  );
};

export default ProfileNavbar;

import { useCallback, useEffect, useRef, useState } from "react";
import {
  BusinessEventBasicInfo,
  getBusinessEvents,
  GetBusinessEventsInput,
} from "../../../../api/Api";
import LoadingSpinner from "../../../../components/LoadingSpinner/LoadingSpinner";
import { useAuthContext } from "../../../../context/AuthContext/AuthContext";
import EventCard from "../../../components/shared/Cards/EventCard/EventCard";
import ClubsEveningsNoResults from "../../../components/shared/ClubsEveningsNoResults/ClubsEveningsNoResults";
import "./_EveningsSection.scss";

interface EveningsSectionProps {
  filterSearch: GetBusinessEventsInput;
}

function useEveningsSection({ filterSearch }: EveningsSectionProps) {
  const [eveningsData, setEveningsData] = useState<BusinessEventBasicInfo[]>(
    []
  );
  const [zeroItem, setZeroItem] = useState<number>();
  const [loading, setLoading] = useState<boolean>(true);
  const [hasMore, setHasMore] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const authCtx = useAuthContext();
  const observer = useRef<IntersectionObserver | null>(null);
  const lastItemElementRef = useCallback(
    (node: any) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const getBusinessEventData = async () => {
    try {
      const businessEventsResponse = await getBusinessEvents(
        {
          pageNumber: pageNumber,
          ...filterSearch,
        },
        authCtx?.location
      );
      setEveningsData([...eveningsData, ...businessEventsResponse?.data?.data]);
      setZeroItem(businessEventsResponse?.data?.metaData?.totalItemCount);
      setHasMore(businessEventsResponse?.data?.metaData?.hasNextPage);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const lngLatArray: any = [];
  eveningsData?.forEach((item, index) => {
    lngLatArray[index] = { lat: item.latitude, lng: item.longitude };
  });

  useEffect(() => {
    getBusinessEventData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterSearch, pageNumber]);

  useEffect(() => {
    setEveningsData((prev) => prev.splice(0, prev.length));
    setPageNumber(1);
  }, [filterSearch]);

  return {
    zeroItem,
    lngLatArray,
    renderItems: (
      <>
        {zeroItem === 0 ? (
          <ClubsEveningsNoResults />
        ) : (
          <>
            <div className="eveningssection">
              {eveningsData?.map((event) => (
                <div
                  className="eveningssection-item"
                  key={event.id}
                  ref={lastItemElementRef}
                >
                  <EventCard
                    id={event?.id}
                    photo={event?.banner}
                    title={event?.title}
                    club={event?.clubName}
                    address={event?.city}
                    date={event?.date}
                    distance={event?.distance}
                    isAlmostFull={event?.isAlmostFull}
                    isFreeForGirls={event?.isFreeForGirls}
                    isFreeForBoys={event?.isFreeForBoys}
                    hasShowCase={event?.hasShowCase}
                    isOpened={event?.isOpened}
                  />
                </div>
              ))}
            </div>
            {hasMore && <LoadingSpinner />}
          </>
        )}
      </>
    ),
  };
}

export default useEveningsSection;

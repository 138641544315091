import closeButton from "../../../assets/icons/closeButton.svg";
import "./_AddReview.scss";
import "../_modalStyle.scss";
import { Modal } from "react-bootstrap";
import Rating from "@mui/material/Rating";
import { useState } from "react";
import { postDjReview } from "../../../api/Dj";
import { postBusinessReview } from "../../../api/Business";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQueryClient } from "@tanstack/react-query";

interface reviewProp {
  comment: string;
  rating?: number;
  businessId?: number;
  djId?: number;
}
const schema = yup.object().shape({
  comment: yup.string().required("Required"),
});
const AddReview = (props: any) => {
  const queryClient = useQueryClient();
  const [error, setError] = useState<any>();
  const [value, setValue] = useState<number | null>(0);
  const [success, setSuccess] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<reviewProp>({ resolver: yupResolver(schema) });

  const addBusinessReview = async (data: reviewProp) => {
    try {
      const response: any = await postBusinessReview({
        ...data,
        rating: value,
        businessId: props.id,
      });
      setSuccess(response?.isSuccess);
      response?.isSuccess &&
        queryClient.fetchQuery([`business-club-${props.id}`]);
      response?.isSuccess && props.setReRender(true);
      delay();
    } catch (e: any) {
      setError(e?.response?.data?.errors[0]?.message);
    }
  };

  const addDjReview = async (data: reviewProp) => {
    try {
      const response: any = await postDjReview({
        ...data,
        rating: value,
        djId: props.id,
      });
      setSuccess(response?.isSuccess);
      response?.isSuccess && queryClient.fetchQuery([`dj-profile-${props.id}`]);
      response?.isSuccess && props.setReRender(true);
      delay();
    } catch (e: any) {
      setError(e?.response?.data?.errors[0]?.message);
    }
  };

  const delay = () => {
    setTimeout(() => {
      props.onHide();
    }, 4000);
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => {
        setError("");
        setValue(0);
        setSuccess(false);
        props.onHide();
        reset();
      }}
    >
      <Modal.Body>
        <div className="addreviewmodal">
          <div className="addreviewmodal-section1">
            <div className="addreviewmodal-section1-text">
              <div className="title">
                <h2>Écrire un avis</h2>
                <img src={closeButton} alt="" onClick={() => props.onHide()} />
              </div>
              <p>{`Comment évalueriez-vous ${props.name}?`}</p>
            </div>
          </div>
          <div className="addreviewmodal-section2">
            <form
              onSubmit={handleSubmit(
                props.djReview ? addDjReview : addBusinessReview
              )}
            >
              <div className="part1">
                <Rating
                  className="stars"
                  name="simple-controlled"
                  value={value}
                  onChange={(event, newValue) => {
                    setValue(newValue);
                  }}
                />
              </div>
              <div className="part2">
                <textarea
                  id="t1"
                  maxLength={100}
                  placeholder="Ajoutez un commentaire à votre note (max 100 lettres)…"
                  rows={4}
                  {...register("comment")}
                />
                {errors.comment && (
                  <p className="tw-text-red-600 mb-0">
                    {errors.comment.message}
                  </p>
                )}
              </div>
              <div className="part3">
                <button className="annuler" onClick={() => props.onHide()}>
                  Annuler
                </button>
                <button className="submit">Soumettre</button>
              </div>
            </form>
            <p className="errorReview">{error}</p>
            {success && <p className="successReview">Votre avis est envoyé!</p>}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddReview;

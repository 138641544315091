import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import { BasicResponse } from "../../interfaces/mainModels/BasicResponse.model";
import { MetaDataResponse } from "../../interfaces/mainModels/MetaDataResponse.model";
import {
  getEventProfile,
  getEventGallery,
  getEventTables,
  getEventParticipantDetails,
  getAllEventNames,
  getAllEventTables,
  getUsersName,
} from "../queries/events";
import {
  addEvent,
  addEventPhoto,
  addEventTable,
  addParticipant,
  deleteEvent,
  deleteEventPhoto,
  deleteEventTable,
  searchEvents,
  updateEvent,
  updateEventTable,
} from "../mutations/events";
import { EventResponseModel } from "../../interfaces/models/getModels/EventResponse.model";
import { EventResponsePhotoModel } from "../../interfaces/models/getModels/EventPhotoResponse.model";
import { TableResponseModel } from "../../interfaces/models/getModels/TableResponse.model";
import { EventPhotoPostModel } from "../../interfaces/models/postModels/EventPhotoPost.model";
import { EventTablePostModel } from "../../interfaces/models/postModels/EventTablePost.model";
import { TableUpdateModel } from "../../interfaces/models/updateModels/TableUpdate.model";
import { EventPostModel } from "../../interfaces/models/postModels/EventPost.model";
import { EventUpdateModel } from "../../interfaces/models/updateModels/EventUpdate.model";
import { EventParticipantDetailsModel } from "../../interfaces/models/getModels/EventParticipantDetails.model";
import { DjBasicInfo } from "../../api/Dj";
import { getdjsName } from "../queries/djsName";
import { EventReservationPostModel } from "../../interfaces/models/postModels/EventReservationPost.model";

interface searchInput {
  searchWord: string;
  pageNumber: number;
  pageSize: number;
}
interface user {
  id: string;
  fullName: string;
}

// -----GET-----
export const useEventQuery = (
  id: number,
  options?: Omit<
    UseQueryOptions<BasicResponse<EventResponseModel>>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<BasicResponse<EventResponseModel>> =>
  useQuery([`business-event-${id}`], () => getEventProfile(id), options);

export const useEventGalleryQuery = (
  id: number,
  pageNumber: number,
  options?: Omit<
    UseQueryOptions<BasicResponse<MetaDataResponse<EventResponsePhotoModel[]>>>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<BasicResponse<MetaDataResponse<EventResponsePhotoModel[]>>> =>
  useQuery(
    [`event-gallery-${id}-${pageNumber}`],
    () => getEventGallery(id, pageNumber),
    options
  );

export const useDjsNameQuery = (
  options?: Omit<
    UseQueryOptions<MetaDataResponse<DjBasicInfo[]>>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<MetaDataResponse<DjBasicInfo[]>> =>
  useQuery([`allDjs`], () => getdjsName(), options);

export const useUsersNameQuery = (
  searchWord?: string,
  options?: Omit<
    UseQueryOptions<MetaDataResponse<user[]>>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<MetaDataResponse<user[]>> =>
  useQuery(
    [`clubsName-${searchWord}`],
    () => getUsersName(searchWord),
    options
  );

export const useEventTablesQuery = (
  id: number,
  pageNumber: number,
  options?: Omit<
    UseQueryOptions<BasicResponse<MetaDataResponse<TableResponseModel[]>>>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<BasicResponse<MetaDataResponse<TableResponseModel[]>>> =>
  useQuery(
    [`event-tables-${id}-${pageNumber}`],
    () => getEventTables(id, pageNumber),
    options
  );

export const useAllEventTablesQuery = (
  id: number,
  options?: Omit<UseQueryOptions<BasicResponse<any>>, "queryKey" | "queryFn">
): UseQueryResult<BasicResponse<any>> =>
  useQuery(["all-event-tables"], () => getAllEventTables(id), options);

export const useEventParticipantDetailsQuery = (
  eventId: number,
  pageNumber: number,
  options?: Omit<
    UseQueryOptions<MetaDataResponse<EventParticipantDetailsModel[]>>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<MetaDataResponse<EventParticipantDetailsModel[]>> =>
  useQuery(
    [`eventParticipantDetails-${eventId}-${pageNumber}`],
    () => getEventParticipantDetails(eventId, pageNumber),
    options
  );

export const useEventNames = (
  searchWord: string
): UseQueryResult<{ id: number; label: string }[]> =>
  useQuery(["eventNames"], () => getAllEventNames(searchWord));

// -----DELETE------
export const useDeleteEventQuery = () => {
  return useMutation(["deleteEvent"], (eventId: number) =>
    deleteEvent(eventId)
  );
};

export const useDeleteEventPhotoQuery = (
  eventId: number,
  pageNumber: number
) => {
  const queryClient = useQueryClient();
  return useMutation(
    [`deletePhotoEvent-${eventId}`],
    (photoId: number) => deleteEventPhoto(photoId),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([
          `event-gallery-${eventId}-${pageNumber}`,
        ]),
    }
  );
};

export const useDeleteEventTableQuery = (
  eventId: number,
  pageNumber: number
) => {
  const queryClient = useQueryClient();
  return useMutation(
    [`deleteTableEvent-${eventId}`],
    (tableId: number) => deleteEventTable(tableId),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([
          `event-tables-${eventId}-${pageNumber}`,
        ]),
    }
  );
};

// -----POST------
export const usePostEventQuery = () => {
  return useMutation(["addEvent"], (data: EventPostModel) => addEvent(data));
};

export const usePostEventsQuery = () => {
  return useMutation((data: searchInput) => searchEvents(data));
};

export const usePostEventPhotoQuery = (
  eventId: number,
  pageNumber: number
): UseMutationResult<unknown, unknown, EventPhotoPostModel> => {
  const queryClient = useQueryClient();

  return useMutation(
    [`addPhotoEvent-${eventId}`],
    (data: EventPhotoPostModel) => addEventPhoto(data),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([
          `event-gallery-${eventId}-${pageNumber}`,
        ]),
    }
  );
};

export const usePostEventTableQuery = (eventId: number, pageNumber: number) => {
  const queryClient = useQueryClient();

  return useMutation(
    [`addTableEvent-${eventId}`],
    (data: EventTablePostModel) => addEventTable(data),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([
          `event-tables-${eventId}-${pageNumber}`,
        ]),
    }
  );
};
export const usePostEventParticipantQuery = (
  eventId: number,
  pageNumber: number
) => {
  const queryClient = useQueryClient();
  return useMutation(
    [`addPariticipantEvent-${eventId}`],
    ({
      clientId,
      data,
    }: {
      clientId: string | undefined;
      data: EventReservationPostModel;
    }) =>
      addParticipant(clientId, {
        ...data,
        eventId: eventId,
        ...(data.restaurantPeople !== 0 && {
          restaurantPeople: Number(data.restaurantPeople),
        }),
      }),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([
          `eventParticipantDetails-${eventId}-${pageNumber}`,
        ]),
    }
  );
};

// -----PUT------
export const usePutEventQuery = (eventId: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    [`updateEvent-${eventId}`],
    (data: EventUpdateModel) => updateEvent(data),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([`business-event-${eventId}`]),
    }
  );
};

export const usePutEventTableQuery = (eventId: number, pageNumber: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    [`updateTableEvent-${eventId}`],
    (data: TableUpdateModel) => updateEventTable(data),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([
          `event-tables-${eventId}-${pageNumber}`,
        ]),
    }
  );
};

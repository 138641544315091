import { FC, ReactElement, useState } from "react";
import { Dropdown } from "react-bootstrap";
import ellipsis from "../../../../assets/icons/ellipsis.svg";
import trash from "../../../../assets/icons/trash.png";
import edit from "../../../../assets/icons/edit.png";
import AddNewSchedule from "../../../modals/AddNewSchedule";
import { useDeleteClubScheduleQuery } from "../../../../react-query/hooks/Clubs";

interface ScheduleInfoProps {
  scheduleId: number;
  startTime: string;
  endTime: string;
  weekday: number;
  clubId: number;
}
const ScheduleInfo: FC<ScheduleInfoProps> = ({
  scheduleId,
  startTime,
  endTime,
  weekday,
  clubId,
}): ReactElement => {
  const [modalShow, setModalShow] = useState(false);
  const weekDays: string[] = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const { mutate: deleteClubSchedule } = useDeleteClubScheduleQuery(clubId);
  const handleDeleteSchedule = (scheduleId: number) => () => {
    deleteClubSchedule(scheduleId);
  };
  return (
    <tr className="scheduleinfo">
      <td>
        <p>{weekDays[weekday - 1]}</p>
      </td>
      <td>
        <p>{startTime.slice(0, 5)}</p>
      </td>
      <td>
        <p>{endTime.slice(0, 5)}</p>
      </td>
      <td className="actionColumn">
        <Dropdown
          className="d-inline actionColumn-action action lastElement"
          align={"end"}
        >
          <Dropdown.Toggle id="dropdown-autoclose-true">
            <img src={ellipsis} alt="" />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item href="#" onClick={() => setModalShow(true)}>
              <img src={edit} alt="" />
              <p>Éditer</p>
            </Dropdown.Item>
            <Dropdown.Item href="#" onClick={handleDeleteSchedule(scheduleId)}>
              <img src={trash} alt="" />
              <p>Effacer</p>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </td>
      <AddNewSchedule
        show={modalShow}
        onHide={() => setModalShow(false)}
        update
        scheduleId={scheduleId}
        weekDay={weekday}
        startTime={startTime}
        endTime={endTime}
        clubId={clubId}
      />
    </tr>
  );
};
export default ScheduleInfo;

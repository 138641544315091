import { useEffect, useState } from "react";
import ManagerNavbar from "../../components/Organisms/ManagerNavbar/ManagerNavbar";
import "./_ManagerSharedLayout.scss";
import { Offcanvas } from "react-bootstrap";
import LoginRegisterResetPassword from "../../../user/modals/LoginRegisterResetPassword/LoginRegisterResetPassword";
import { useAuthContext } from "../../../context/AuthContext/AuthContext";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import Page404 from "../../../user/pages/Page404/Page404";
import SubscriptionPacks from "../../components/Organisms/SubscriptionPacks/SubscriptionPacks";
import { useSubscriptionStatus } from "../../../react-query/hooks/Subscription";
import { Outlet } from "react-router-dom";
import MenuIcon from "../../../assets/icons/MenuIcon.png";
import { usePaymentContextContext } from "../../../context/PaymentContext/PaymentContext";

const ManagerSharedLayout = () => {
  const authCtx = useAuthContext();
  const paymentCtx = usePaymentContextContext();
  const [modalShow, setModalShow] = useState(true);
  const [show, setShow] = useState(false);

  const { data: { data } = {}, isLoading } = useSubscriptionStatus();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  if (authCtx.isLoading || isLoading)
    return (
      <div className="loading">
        <LoadingSpinner />
      </div>
    );
  return (
    <div>
      {authCtx.isAuthenticated ? (
        <div>
          {authCtx.decoded?.role === "Manager" ? (
            <div className="managerProfile container-am">
              <img
                src={MenuIcon}
                alt=""
                onClick={handleShow}
                className="open-navbar"
              />
              <div className="adminProfile-dashboard-1">
                <Offcanvas show={show} onHide={handleClose}>
                  <Offcanvas.Header closeButton></Offcanvas.Header>
                  <Offcanvas.Body>
                    <ManagerNavbar />
                  </Offcanvas.Body>
                </Offcanvas>
              </div>
              <div className="managerProfile-dashboard-2">
                <ManagerNavbar />
              </div>
              <div className="managerProfile-outlet">
                {data === null ? (
                  <div className="managerProfile-outlet-noSubscription">
                    <h2>
                      Vous avez besoin d'un abonnement pour voir cette page
                    </h2>
                    <p className="choose">Choisissez-en un pour continuer</p>
                    <SubscriptionPacks />
                    <div className="tw-mt-10 clubspaceoffersandrates-section2 container">
                      <p>
                        Facturation à l’utilisation : commission facturée à 1,49
                        € par entrée validée ou 0,99 € si vous cumulez plus de
                        50 entrées sur un événement.
                        <br />
                        Aucunes commissions les 2 premiers mois !
                      </p>
                    </div>
                  </div>
                ) : (
                  <Outlet />
                )}
              </div>
            </div>
          ) : (
            <Page404 />
          )}
        </div>
      ) : (
        <div>
          {
            <LoginRegisterResetPassword
              show={modalShow}
              onHide={() => setModalShow(false)}
            />
          }
        </div>
      )}
    </div>
  );
};

export default ManagerSharedLayout;

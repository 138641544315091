import { apiRequest } from "./Api";
import { responseBody } from "./Shared";

// ------User-----
export interface user {
  id: string;
  profilePicture: string;
  firstName: string;
  lastName: string;
  email: string;
  birthdate: string;
  gender: number;
  phoneNumber: string;
  language: number;
  isPublic: boolean;
  interestedInMale: boolean;
  interestedInFemale: boolean;
}

// ------Login------
export interface LoginResponse extends responseBody {
  data: {
    jwt: string;
    userData: user;
  };
}
export interface LoginInput {
  email: string;
  password: string;
}

// -----GoogleLogin-----
export interface GoogleInput {
  provider: string;
  idToken: string;
  languageId: 1;
}
// -----FacebookLogin-----
export interface FacebookInput {
  provider: string;
  idToken: string;
  languageId: 1;
}

// ------Register------
export interface RegisterInput {
  gender: number;
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  confirmPassword: string;
  birthdate: string;
}
export interface ConfirmationAccountInput {
  email: string;
  confirmationCode: string;
}
export interface ConfirmationAccountResponse {
  data: LoginResponse;
}
export interface ResendConfirmationCodeInput {
  email: string;
}

//-----forgottenPassword----
export interface ForgotPasswordInput {
  email: string;
  languageId: number;
}
export interface CheckResetPasswordCodeInput {
  email: string;
  resetPasswordCode: string;
}
export interface ResetPasswordInput {
  email: string;
  resetPasswordCode: string;
  newPassword: string;
  confirmNewPassword: string;
  languageId: number;
}

// --------Contact-----------
export interface ContactInput {
  name: string;
  email: string;
  body: string;
}

// -------apiRequests---------
export const login = async (data: LoginInput) =>
  apiRequest<LoginInput, LoginResponse>("post", "/Account/SignIn", data);

export const confrimAccound = async (data: ConfirmationAccountInput) =>
  apiRequest<ConfirmationAccountInput, ConfirmationAccountResponse>(
    "post",
    "/Account/ConfirmAccount",
    data
  );

export const resendConfrimCode = async (data: ResendConfirmationCodeInput) =>
  apiRequest<ResendConfirmationCodeInput>(
    "post",
    "/Account/ResendConfirmationCode",
    data
  );

export const forgotPassword = async (data: ForgotPasswordInput) =>
  apiRequest<ForgotPasswordInput>("post", "/Account/ForgotPassword", data);

export const checkResetPasswordCode = async (
  data: CheckResetPasswordCodeInput
) =>
  apiRequest<CheckResetPasswordCodeInput>(
    "post",
    "/Account/CheckResetPasswordCode",
    data
  );

export const resetPassword = async (data: ResetPasswordInput) =>
  apiRequest<ResetPasswordInput>("post", "/Account/ResetPassword", data);

export const contact = async (data: ContactInput) =>
  apiRequest<ContactInput>("post", "/Home/conntact", data);

export const googleLogin = async (data: GoogleInput) =>
  apiRequest<GoogleInput>("post", "/Account/google-signIn", data);
export const facebookLogin = async (data: FacebookInput) =>
  apiRequest<FacebookInput>("post", "/Account/facebook-signIn", data);

export const registerManager = async (data: RegisterInput) =>
  apiRequest<RegisterInput>("post", "/Account/SignUp/Manager", data);

import {FC} from 'react';
import personalizedShowcaseImage from '../../../../assets/photos/personalizedShowcaseImage.webp';
import './_PersonalizedShowcase.scss';
import '../_ClubSpaceWhy7NightSharedStyle.scss';

interface PersonalizedShowcaseProps {
}

 const PersonalizedShowcase: FC<PersonalizedShowcaseProps>=()=> {
  return (
    <div className='personalizedshowcase'>
      <div className='personalizedshowcase-content container'>
        <div className='personalizedshowcase-content-section1 clubspacewhy7nightsharedstyle'>
          <h3>Obtenez une vitrine personnalisée de votre club gratuitement</h3>
          <p>Une <span> vitrine complète et personnalisée</span> de votre club pour que les clients puissent y retrouver l’emplacement de votre établissement (géolocalisation), les tarifs, les avis, photos et bien d’autres informations importantes pour eux.
            Grâce à 7Night, tous les « nighteurs » de France peuvent <span> retrouver chaque évènement </span> de votre club afin de pouvoir y réserver simplement et gratuitement en un-clic 24h/24 et 7j/7.
          </p>
        </div>
        <div className='personalizedshowcase-content-section2'>
          <img src={personalizedShowcaseImage} alt="" />
        </div>
      </div>
    </div>
  );
}

export default PersonalizedShowcase;
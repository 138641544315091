import "./_NoEvents.scss";
import EventsEmpty from "../../../../assets/photos/ReservationEmpty.webp";

const NoEvents = () => {
  return (
    <div className="noevents">
      <img src={EventsEmpty} alt="" />
      <h3 className="noEvent">Aucuns événements à venir</h3>
    </div>
  );
};
export default NoEvents;

import { AccordionDetails, ClickAwayListener } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import DeleteIcon from "@mui/icons-material/Delete";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import { FC, useState } from "react";
import {
  useChangeDefaultPaymentMethod,
  useRemovePaymentMethod,
} from "../../../../react-query/hooks/paymentProfile";

interface paymentCardProps {
  cardId: string;
  cardNumber: string;
  expireMonth: number;
  expireYear: number;
  cardType: string;
  contry: string;
  isDefaultCard: boolean;
}
const PaymentCard: FC<paymentCardProps> = ({
  cardId,
  cardNumber,
  expireMonth,
  expireYear,
  cardType,
  contry,
  isDefaultCard,
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [openOptions, setOpenOptions] = useState<boolean>(false);
  const handleChange = () => {
    setExpanded((prev) => !prev);
  };
  const { mutate: removePaymentMethod, isSuccess: isRemovedSuccessFully } =
    useRemovePaymentMethod();
  const {
    mutate: changeDefaultPaymentMethod,
    isSuccess: isChangedDefaultSuccessFully,
  } = useChangeDefaultPaymentMethod();
  const handleRemovePaymentMethod = () => {
    removePaymentMethod({
      paymentMethodId: cardId,
    });
    setOpenOptions(false);
  };
  const handleChangeDefaultPaymentMethod = () => {
    changeDefaultPaymentMethod({
      paymentMethodId: cardId,
    });
    setOpenOptions(false);
  };
  const handleClickAway = () => {
    setOpenOptions(false);
  };
  const handleAccordionClickAway = () => {
    setExpanded(false);
  };
  return (
    <Accordion
      expanded={expanded}
      sx={{
        boxShadow: " 0 0 6px 0 rgba(0, 0, 0, 0.16)",
        borderRadius: "15px !important",
        borderTop: "none !important",
      }}
    >
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <ClickAwayListener onClickAway={handleAccordionClickAway}>
          <div className="paymentMethodsTitle">
            <div
              className="paymentMethodsTitle-part1"
              onClick={() => handleChange()}
            >
              {!expanded && (
                <KeyboardDoubleArrowRightIcon sx={{ color: "#932d85" }} />
              )}
              {expanded && (
                <KeyboardDoubleArrowDownIcon sx={{ color: "#932d85" }} />
              )}
              <div className="title">
                <div className="title-part1">
                  <p>
                    {cardType}
                    <span>**** **** **** {cardNumber}</span>
                  </p>
                  {isDefaultCard && <div className="default">Défaut</div>}
                </div>
                <p className="title-part2">{`Expire ${expireMonth}/${expireYear}`}</p>
              </div>
            </div>
            {!isDefaultCard && (
              <div className="paymentMethodsTitle-part2">
                <ClickAwayListener onClickAway={handleClickAway}>
                  <div>
                    <MoreHorizIcon
                      sx={{
                        color: "black",
                        backgroundColor: "white",
                        zIndex: "2",
                      }}
                      onClick={() => {
                        setOpenOptions((prev) => !prev);
                      }}
                    />

                    <div className={openOptions ? "options" : "hideOptions"}>
                      <div
                        className="firstItem item"
                        onClick={handleChangeDefaultPaymentMethod}
                      >
                        {" "}
                        <DoneIcon
                          sx={{ marginBottom: "0", color: "#777777" }}
                        />
                        <p>Définir par défaut</p>
                      </div>
                      <div className="item" onClick={handleRemovePaymentMethod}>
                        <DeleteIcon
                          sx={{
                            marginBottom: "0 !important",
                            color: "#777777",
                          }}
                        />
                        <p>Supprimer</p>
                      </div>
                    </div>
                  </div>
                </ClickAwayListener>
              </div>
            )}
          </div>
        </ClickAwayListener>
      </AccordionSummary>
      <AccordionDetails>
        <div
          style={{
            borderTop: "1px solid #e3e3e3",
            padding: "20px 20px 12px 20px",
          }}
          className="cardMethodInfos"
        >
          <div className="cardMethodInfo">
            <p>Numéro</p>
            <p>**** **** **** {cardNumber}</p>
          </div>
          <div className="cardMethodInfo">
            <p>Expire</p>
            <p>{`${expireMonth}/${expireYear}`}</p>
          </div>
          <div className="cardMethodInfo">
            <p>Le Type</p>
            <p>{cardType}</p>
          </div>
          <div className="cardMethodInfo">
            <p>Pays</p>
            <p>{contry}</p>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};
export default PaymentCard;

import { FC, ReactElement } from "react";
import {
  Chart as ChartJS,
  Title,
  Legend,
  Tooltip,
  LineElement,
  LineController,
  LinearScale,
  PointElement,
  CategoryScale,
  ChartData,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import zoomPlugin from "chartjs-plugin-zoom";
import { ReservationChartProps } from "./ReservationChart.props";
import { months } from "../../../../static/months";
import { chartOptions } from "../../../../utils/chartjs/chartjs";

ChartJS.register(
  Title,
  Legend,
  Tooltip,
  zoomPlugin,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  CategoryScale
);

export const ReservationChart: FC<ReservationChartProps> = ({
  data = [],
}): ReactElement => {
  const { labels, datasets }: ChartData = {
    labels: data
      .sort((a, b) => a.month - b.month)
      .map(({ month }) => months[month - 1]),
    datasets: [
      {
        label: "Reservation Sum",
        data: data.map(({ reservationSum }) => reservationSum),
        borderColor: "#932d85",
        backgroundColor: "#932d85",
        tension: 0.5,
      },
    ],
  };

  return (
    <Chart
      datasetIdKey="reservation-chart"
      type="line"
      data={{ labels, datasets }}
      options={chartOptions}
    />
  );
};

import { FC } from 'react';
import './_NotificationCard.scss';

interface NotificationCardProps{
    profilePicture?:string,
    title?:string,
    description?: string,
    date?: string,
    readed?: boolean,
    modal: boolean
}
const NotificationCard:FC<NotificationCardProps>=({profilePicture, title, description, date, readed, modal})=>{
    const todayDate = new Date();
    const todayDateString =todayDate.toLocaleDateString(undefined, {  
        day:   'numeric',
        month: 'short',
        year:  'numeric',
    });

    const localDate = new Date(date!);
    const localDateString = localDate.toLocaleDateString(undefined, {  
        day:   'numeric',
        month: 'short',
        year:  'numeric',
    });
    const localTimeString = localDate.toLocaleTimeString(undefined, {
        hour:   '2-digit',
        minute: '2-digit',
    });
    return(
        <div className="notification" style={ readed ?{backgroundColor: 'white'} :{backgroundColor: 'rgba(89, 174, 213, 0.04)'}}>
            <div className="photo">
                <img src={profilePicture} alt="" />
            </div>
            <div className="content">
                <div className={`content-part1 modal-${modal}`}>
                    <p>{title}</p>
                    <p>{description}</p>
                </div>
                <div className="content-part2">
                    {
                        (todayDateString===localDateString)
                        ?<p className='clock'>Today</p>
                        :<p className='clock'>{localDateString}</p>
                    }
                    <p className='clock'>{localTimeString}</p>
                    {!readed&&<div className='dot'></div>}
                </div>
            </div>
        </div>
    )
}
export default NotificationCard;
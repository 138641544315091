import "../_ChangeResetVerifyStyle.scss";
import * as Yup from "yup";
import { useLoginRegisterResetPasswordContext } from "../../../../context/LoginRegisterResetPasswordContext/LoginRegisterResetPasswordContext";
import { useState } from "react";
import { ForgotPasswordPostModel } from "../../../../interfaces/models/postModels/ForgotPasswordPost.model";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { forgotPassword } from "../../../../api/Account";

const schema = Yup.object().shape({
  email: Yup.string().email("Format d'email invalide").required("Obligatoire"),
});

const ResetPassword = () => {
  const LRRPCtx = useLoginRegisterResetPasswordContext();
  const [error, setError] = useState<any>();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<ForgotPasswordPostModel>({
    resolver: yupResolver(schema),
  });
  const handleResetPassword = async (
    ResetPasswordInputs: ForgotPasswordPostModel
  ) => {
    LRRPCtx.setEmail(ResetPasswordInputs.email);
    LRRPCtx.setPage(3);
    try {
      await forgotPassword({
        ...ResetPasswordInputs,
        languageId: LRRPCtx.languageId,
      });
    } catch (e: any) {
      setError(e.response?.data?.message);
    }
  };
  return (
    <div className="changeresetverifystyle">
      <div>
        <div className="changeresetverifystyle-info">
          <h3>Réinitialiser le mot de passe</h3>
        </div>
        <form onSubmit={handleSubmit(handleResetPassword)}>
          <div className="input">
            <input
              type="email"
              id="email"
              placeholder="exemple@address.com"
              {...register("email")}
            />
            {errors.email && <p className="error">{errors.email.message}</p>}
          </div>
          {!!error && <p className="error">{error}</p>}
          <button disabled={isSubmitting} type="submit" className="submit-btn">
            {isSubmitting ? "Soumettre..." : "Soumettre"}
          </button>
        </form>
      </div>
    </div>
  );
};
export default ResetPassword;

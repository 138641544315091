import { FC, useState } from "react";
import "./_ClubSpaceHome.scss";
import BoostClub from "../../page-sections/ClubSpaceHome/BoostClub/BoostClub";
import Possibilities from "../../page-sections/ClubSpaceHome/Possibilities/Possibilities";
import AppUsersNumber from "../../page-sections/shared/AppUsersNumber/AppUsersNumber";
import SocialMedia from "../../page-sections/ClubSpaceHome/SocialMedia/SocialMedia";
import ClubsAlreadyIn from "../../page-sections/shared/ClubsAlreadyIn/ClubsAlreadyIn";
import LoginRegisterResetPassword from "../../modals/LoginRegisterResetPassword/LoginRegisterResetPassword";
import { useLoginRegisterResetPasswordContext } from "../../../context/LoginRegisterResetPasswordContext/LoginRegisterResetPasswordContext";

interface ClubSpaceHomeProps {}

const ClubSpaceHome: FC<ClubSpaceHomeProps> = () => {
  const LRRPCtx = useLoginRegisterResetPasswordContext();
  const [modalShow, setModalShow] = useState(false);
  return (
    <div className="clubspacehome">
      <div className="clubspacehome-section1">
        <BoostClub />
      </div>
      <div className="clubspacehome-section2">
        <Possibilities />
      </div>
      <div className="clubspacehome-section3">
        <AppUsersNumber />
      </div>
      <div className="clubspacehome-section4">
        <SocialMedia />
      </div>
      <div className="clubspacehome-section5">
        <ClubsAlreadyIn />
      </div>
      <div className="clubspacehome-section6 container">
        <h2>Découvrez notre outil 7Night Manager !</h2>
        <h3>Essai gratuit - Sans engagement de durée</h3>
        <button
          onClick={() => {
            setModalShow(true);
            LRRPCtx.setDefaultKey("register");
          }}
        >
          Essayer dès maintenant!
        </button>
      </div>
      <LoginRegisterResetPassword
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </div>
  );
};

export default ClubSpaceHome;

import { FC, ReactElement } from "react";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

interface SubscriptionInfoProps {
  date: string;
  userEmail: string;
  subscriptionName: string;
  chargedAmount: number | null;
  bill?: string;
}
const SubscriptionInfo: FC<SubscriptionInfoProps> = ({
  date,
  userEmail,
  subscriptionName,
  chargedAmount,
  bill,
}): ReactElement => {
  const localDate = new Date(date);
  const localDateString = localDate.toLocaleDateString(undefined, {
    day: "numeric",
    month: "short",
    year: "numeric",
  });
  return (
    <tr className="subscriptioninfo">
      <td className="subscriptioninfo-date">
        <p>{localDateString}</p>
      </td>
      <td className="subscriptioninfo-user">
        <p>{userEmail}</p>
      </td>
      <td className="subscriptioninfo-pack">
        <p>{subscriptionName}</p>
      </td>
      <td className="subscriptioninfo-price">
        <p>{`${chargedAmount?.toFixed(2)} €`}</p>
      </td>
      {bill !== undefined && (
        <td style={{ textAlign: "center" }}>
          <a href={bill}>
            <FileDownloadIcon sx={{ color: "gray" }} />
          </a>
        </td>
      )}
    </tr>
  );
};
export default SubscriptionInfo;

import { ReactElement } from "react";
import { Modal } from "react-bootstrap";
import { useCancelSubscription } from "../../react-query/hooks/Subscription";
import "./_paymentModalsStyle.scss";

const CancelPayment = (props: any): ReactElement => {
  const {
    mutate: cancelSubscription,
    data: { data: cancelSubscriptionResponse } = {},
    isLoading: isCancelSubscriptionLoading,
    reset,
  } = useCancelSubscription();

  const cancelSubscriptionPack = () => {
    cancelSubscription(props.subscriptionId);
    setTimeout(() => {
      reset();
      props.onHide();
    }, 3000);
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="paymentModal"
      onHide={() => {
        reset();
        props.onHide();
      }}
    >
      <Modal.Body>
        <div className="modalBodyDowngrade">
          <h3 className="modalBodyDowngrade-title">Êtes-vous sûr?</h3>
          <div className="modalBodyDowngrade-buttons">
            <button
              onClick={() => props.onHide()}
              className="no"
              disabled={
                isCancelSubscriptionLoading ||
                cancelSubscriptionResponse?.isSuccess
              }
            >
              Non
            </button>
            <button
              onClick={cancelSubscriptionPack}
              className="yes"
              disabled={
                isCancelSubscriptionLoading ||
                cancelSubscriptionResponse?.isSuccess
              }
            >
              Oui
            </button>
          </div>
          {isCancelSubscriptionLoading && <p className="les">Chargement...</p>}
          {cancelSubscriptionResponse?.isSuccess && (
            <p className="les">{cancelSubscriptionResponse?.successMessage}</p>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default CancelPayment;

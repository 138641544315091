import { ReactElement, useState } from "react";
import { Modal } from "react-bootstrap";
import { useStripe } from "@stripe/react-stripe-js";
import { useQueryClient } from "@tanstack/react-query";
import "./_paymentModalsStyle.scss";

const RealPayment = (props: any): ReactElement => {
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<any>("");
  const stripe = useStripe();
  const queryClient = useQueryClient();
  const confirmCardPayment = async () => {
    setLoading(true);
    try {
      if (!stripe) {
        return;
      }
      const { error } = await stripe.confirmCardPayment(
        `${props.clientSecretCode}`,
        {
          payment_method: `${props.paymentMethod}`,
        }
      );
      if (error) {
        setError(error?.message);
      } else {
        setLoading(false);
        setSuccess(true);
        setTimeout(() => {
          queryClient.fetchQuery(["subscriptionStaus"]);
          setSuccess(false);
          props.onHide();
          setError("");
        }, 3000);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="paymentModal"
      onHide={() => {
        props.onHide();
        setError("");
      }}
    >
      <Modal.Body>
        <div className="modalBodyDowngrade">
          <h3 className="modalBodyDowngrade-title">Êtes-vous sûr?</h3>
          <div className="modalBodyDowngrade-buttons">
            <button
              onClick={() => props.onHide()}
              className="no"
              disabled={success}
            >
              Non
            </button>
            <button
              onClick={confirmCardPayment}
              className="yes"
              disabled={success}
            >
              Oui
            </button>
          </div>
          {loading && <p className="les">Chargement...</p>}
          {success && (
            <p className="les">Votre paiement a été effectué avec succès!</p>
          )}
          {error !== "" && <p>{error}</p>}
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default RealPayment;

import "./_NoPhotos.scss";
import PhotosEmpty from "../../../../assets/photos/PhotosEmpty.webp";

const NoPhotos = () => {
  return (
    <div className="nophotos">
      <h2>Photos</h2>
      <div className="nophotos-content">
        <img src={PhotosEmpty} alt="" />
        <h3 className="noPhoto">
          Pas de <span>photos</span>
        </h3>
      </div>
    </div>
  );
};
export default NoPhotos;

import { ChangeEvent, FC, ReactElement } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import defaultProfile from "../../../../assets/photos/defaultProfile.png";
import ellipsis from "../../../../assets/icons/ellipsis.svg";
import trash from "../../../../assets/icons/trash.png";
import participantIcon from "../../../../assets/icons/participant.webp";
import time from "../../../../assets/icons/time.webp";
import table from "../../../../assets/icons/table.webp";
import { Dropdown } from "react-bootstrap";
import clsx from "clsx";
import { useCancelReservationMutation } from "../../../../react-query/hooks/useReservation.mutation";
import { EventParticipantDetailsModel } from "../../../../interfaces/models/getModels/EventParticipantDetails.model";
import { ReservationStatusEnum } from "../../../../interfaces/enums/ReservationStatus.enum";
import { status, statusColors } from "../../../../static/reservationStatus";

interface EventParticipantInfoProps {
  participant: EventParticipantDetailsModel;
  onSelectParticipant?: (id: number, selected: boolean) => void;
}

export const EventParticipantInfo2: FC<EventParticipantInfoProps> = ({
  participant,
  onSelectParticipant,
}): ReactElement => {
  /**
   * hooks
   */
  const { search } = useLocation();

  /**
   * query string
   */
  const queryParams = queryString.parse(search);

  // destructure
  const {
    reservationId,
    firstName,
    lastName,
    email,
    age,
    gender,
    clubName,
    eventTitle,
    restaurantParticipantNumber,
    restaurantArrivalTime,
    eventReservationParticipants,
    eventArrivalTime,
    tableName,
    reservationStatus,
    profilePicture,
  } = participant;

  // react-query
  const { mutate: cancelReservation } = useCancelReservationMutation(
    reservationId,
    Number(queryParams.pageNumber)
  );

  // handlers
  const handleCancelReservation = () => cancelReservation();
  const handleSelecParticipant = (event: ChangeEvent<HTMLInputElement>) =>
    onSelectParticipant &&
    onSelectParticipant(
      participant?.reservationId || 0,
      event.currentTarget.checked
    );

  return (
    <tr
      className={clsx(
        "eventparticipantinfo2",
        participant?.isSelected && "tw-bg-neutral-50"
      )}
    >
      <td className="checkboxInput">
        <input
          type="checkbox"
          value={participant?.userId}
          checked={participant?.isSelected || false}
          onChange={handleSelecParticipant}
        />
      </td>
      <td>
        <div className="eventparticipantinfo2-user">
          <div className="eventparticipantinfo2-user-info">
            <div className="adminuserinfo avatar">
              <img src={profilePicture || defaultProfile} alt="img" />
            </div>
            <div className="eventparticipantinfo2-user-nameEmail tw-leading-4 tw-break-words tw-w-52">
              <p className="tw-font-bold tw-pb-0.5">{`${firstName} ${lastName}, ${
                age || 0
              }`}</p>
              <p className="tw-text-gray-500 tw-whitespace-pre-wrap">{email}</p>
              <p className="tw-text-gray-500">{gender}</p>
            </div>
          </div>
        </div>
      </td>
      <td>
        <p>{clubName}</p>
        <p>{eventTitle}</p>
      </td>
      <td>
        <div className="eventparticipantinfo2-info1">
          <div className="infoItems">
            <img src={participantIcon} alt="" />
            <p>{`${
              !!restaurantParticipantNumber ? restaurantParticipantNumber : 0
            } Participants`}</p>
          </div>
          <div className="infoItems">
            <img src={time} alt="" />
            <p>{restaurantArrivalTime?.slice(0, 5) || "-"}</p>
          </div>
        </div>
      </td>
      <td>
        <div className="eventparticipantinfo2-info2">
          <div className="infoItems">
            <img src={participantIcon} alt="" />
            <p>{`${
              !!eventReservationParticipants ? eventReservationParticipants : 0
            } Participants`}</p>
          </div>
          <div className="infoItems">
            <img src={time} alt="" />
            <p>{eventArrivalTime?.slice(0, 5)}</p>
          </div>
          <div className="infoItems">
            <img src={table} alt="" />
            <p>{tableName}</p>
          </div>
        </div>
      </td>
      <td className="eventparticipantinfo2-status actionColumn lastElement">
        <button className={`${statusColors[reservationStatus]}`}>
          {status[reservationStatus]}
        </button>
      </td>
      <td className="actionColumn">
        <Dropdown
          className="d-inline actionColumn-action action lastElement"
          align="end"
        >
          <Dropdown.Toggle id="dropdown-autoclose-true">
            <img src={ellipsis} alt="" />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              disabled={[
                ReservationStatusEnum.CANCELLED,
                ReservationStatusEnum.IS_PRESENT,
              ].includes(reservationStatus)}
              onClick={handleCancelReservation}
            >
              <img src={trash} alt="" />
              <p>Annuler réservation</p>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </td>
    </tr>
  );
};

import { FC, ReactElement, useState } from "react";
import trash from "../../../../assets/icons/trash.png";
import edit from "../../../../assets/icons/edit.png";
import MusicForm from "../../../modals/MusicForm";
import { MusicConfigurationInfoProps } from "./MusicConfigurationInfo.props";
import { ButtonBase } from "@mui/material";
import { useDeleteMusicTypeMutation } from "../../../../react-query/hooks/useConfiguration.mutation";

const MusicConfigurationInfo: FC<MusicConfigurationInfoProps> = ({
  musicType,
}): ReactElement => {
  // local state
  const [modalShow, setModalShow] = useState(false);

  // react-query
  const { mutate: deleteMusicType } = useDeleteMusicTypeMutation(musicType.id);

  // handlers
  const handleDeleteMusicType = () => deleteMusicType();

  return (
    <tr>
      <td>
        <p>{musicType.title}</p>
      </td>
      <td className="actionColumn tw-flex tw-justify-center tw-gap-5">
        <ButtonBase onClick={() => setModalShow(true)}>
          <img src={edit} alt="" />
        </ButtonBase>
        <ButtonBase onClick={handleDeleteMusicType}>
          <img src={trash} alt="" />
        </ButtonBase>
      </td>

      <MusicForm
        musicType={musicType}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </tr>
  );
};
export default MusicConfigurationInfo;

import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { facebookLogin } from "../../../../api/Account";
import facebookIcon from "../../../../assets/icons/facebookIcon.png";
import { useAuthContext } from "../../../../context/AuthContext/AuthContext";
import { useLoginRegisterResetPasswordContext } from "../../../../context/LoginRegisterResetPasswordContext/LoginRegisterResetPasswordContext";
import "./_GoogleFacebook.scss";

const LoginFacebook = () => {
  const authCtx = useAuthContext();
  const LRRPCtx = useLoginRegisterResetPasswordContext();
  const responseFacebook = (response: any) => {
    getUser(response?.accessToken as string);
  };

  const getUser = async (idToken: string) => {
    try {
      const response: any = await facebookLogin({
        provider: "Facebook",
        idToken: idToken,
        languageId: 1,
      });
      authCtx?.login(response);
      LRRPCtx.setModalshow(response?.isSuccess);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <FacebookLogin
      appId="594417075192464"
      autoLoad={false}
      fields="name,email,picture"
      callback={responseFacebook}
      render={(renderProps) => (
        <button onClick={renderProps.onClick} className="facebookStyle">
          <img src={facebookIcon} alt="" />
        </button>
      )}
    />
  );
};
export default LoginFacebook;

import { ReactElement } from "react";
import { useFetchAllContantsQuery } from "../../../../react-query/hooks/useConfiguration.query";
import ConstantsConfiguartionInfo from "../../Molecules/ConstantsConfiguartionInfo/ConstantsConfiguartionInfo";

const ConfigurationsConstants = (): ReactElement => {
  // react-query
  const { data: constants = [] } = useFetchAllContantsQuery();

  return (
    <div>
      <div className="dashboardResults noPagination">
        <table>
          <tr>
            <th>NOM</th>
            <th>ÉVALUER</th>
            <th className="lastElement actionColumn">ACTIONS</th>
          </tr>
          {constants.map((constant) => (
            <ConstantsConfiguartionInfo key={constant.id} constant={constant} />
          ))}
        </table>
      </div>
    </div>
  );
};
export default ConfigurationsConstants;

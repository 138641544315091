import Axios from "axios"
import { API_URL } from "../config/app";

export async function apiRequest<data = {}, response = unknown>(
  method: "get" | "delete" | "post" | "create" | "put",
  path: string,
  input?: data,
  options: any ={ 'Content-Type': 'application/json'}
) {
  const res = await Axios.request<response>({
    url: `${API_URL}${path}`,
    method: method,
    data: input,
    headers: options
  })
  return (res.data)
}

export * from "./Shared";
export * from "./Account";
export * from "./Business";
export * from "./Events";
export * from "./Dj";
export * from "./User";
export * from "./Reservation";
export * from "./Wallet";
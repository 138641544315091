import { FC, ReactElement } from "react";
import { ReservationChart } from "../../Molecules/ReservationChart/ReservationChart";
import { DashboardS1Props } from "./AdminDashboardS1.props";
import dashboardUsersIcon from "../../../../assets/icons/dashboardUsersIcon.png";
import dashboardClubsIcon from "../../../../assets/icons/dashboardClubsIcon.png";
import dashboardAttendeesIcon from "../../../../assets/icons/dashboardAttendeesIcon.png";
import dashboardEventsIcon from "../../../../assets/icons/dashboardEventsIcon.png";

const AdminDashboardS1: FC<DashboardS1Props> = ({
  data = [],
  userName,
  userNumber,
  clubs,
  reservations,
  events,
}): ReactElement => {
  return (
    <div className="dashboards1">
      <div className="dashboardSection">
        <h2>{`Bonjour ${userName}`}</h2>
        <p>Content de te revoir !</p>
      </div>
      <div className="dashboardSection satistics">
        <div className="satisticsItem satisticsItem1">
          <div className="satisticsItem-item">
            <p>{userNumber || 0}</p>
            <img src={dashboardUsersIcon} alt="" />
          </div>

          <h4>Utilisateurs</h4>
        </div>
        <div className="satisticsItem satisticsItem2">
          <div className="satisticsItem-item">
            <p>{clubs || 0}</p>
            <img src={dashboardClubsIcon} alt="" />
          </div>
          <h4>Clubs</h4>
        </div>
        <div className="satisticsItem satisticsItem3">
          <div className="satisticsItem-item">
            <p>{reservations || 0}</p>
            <img src={dashboardAttendeesIcon} alt="" />
          </div>

          <h4>Participants</h4>
        </div>
        <div className="satisticsItem satisticsItem4">
          <div className="satisticsItem-item">
            <p>{events || 0}</p>
            <img src={dashboardEventsIcon} alt="" />
          </div>

          <h4>Évènements</h4>
        </div>
      </div>
      <div className="dashboardSection grafic">
        <h3>Présence à l’événement</h3>
        <div className="chart">
          <ReservationChart data={data} />
        </div>
      </div>
    </div>
  );
};

export default AdminDashboardS1;

import { FC, ReactElement } from "react";
import noContent from "../../../../assets/photos/noContent.webp";
import "./_NoResults.scss";

interface NoResultsProps {
  text?: string;
  juridiction?: boolean;
}
export const NoResults: FC<NoResultsProps> = ({
  text,
  juridiction,
}): ReactElement => {
  return (
    <div className="noResults" style={juridiction ? { marginTop: "50px" } : {}}>
      <div className="noResults-content">
        <img src={noContent} alt="" />
        {text === undefined && !juridiction ? (
          <p>Pas de contenu </p>
        ) : (
          <p>{text}</p>
        )}
        {text === undefined && juridiction && (
          <ul>
            Pour valider votre inscription, vous devez fournir :
            <li>
              &#x2022; K-bis (de moins de 3 mois) ou extrait du registre du
              commerce
            </li>
            <li>
              &#x2022;Copie de la carte d’identité du dirigeant de votre société
            </li>
            <li>
              &#x2022; Si le créateur du compte n’est pas le gérant :
              Procuration d’inscription + copie de la carte d’identité du
              créateur
            </li>
          </ul>
        )}
      </div>
    </div>
  );
};

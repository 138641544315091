import "../TermsOfServices/_TermsOfServices.scss";
import Tabel from "../../../assets/photos/7night_tabel.png";
import goTop from "../../../assets/icons/goTop.png";

const PrivacyAndPolicy = () => {
  return (
    <div className="termsofservices">
      <div className="termsofservices-bgpp"></div>
      <div className="termsofservices-content">
        <h1>PRIVACY AND POLICY</h1>
        <div className="SectionsTitles">
          <p>Titre 1. CONDITIONS GENERALES DE VENTE ET D’UTILISATION</p>
          <p>Titre 2. CONDITIONS PARTICULIERES DE PUBLICATION DES PROMOTIONS</p>
          <p>Titre 3. CONDITIONS PARTICULIERES DU PACK SITE WEB</p>
          <p>Titre 4. CONDITIONS PARTICULIERES DE LOCATION DU MATERIEL</p>
          <p>
            Titre 5. CONDITIONS PARTICULIERES D’AIDE A L’INSTALLATION, DE
            MAINTENANCE ET DE REPARATION DU MATERIEL
          </p>
          <p>
            Titre 6 - CONDITIONS PARTICULIERES DU SERVICE D’EMPREINTES BANCAIRES
          </p>
          <p>Titre 7. POLITIQUE D'ENVOI (Module CRM)</p>
        </div>
        <div className="SectionA">
          <h2 style={{ color: "red" }} className="underline">
            Titre 1. CONDITIONS GENERALES DE VENTE ET D’UTILISATION
          </h2>
          <div className="SectionA content">
            <h3>Informations Générales</h3>
            <p>
              SLe présent contrat est souscrit avec 7NIGHT, dont le siège social
              est situé 45 rue fréderic joliot curie, 13013 Marseille,
              immatriculée au registre du commerce et des sociétés de Marseille
              sous le numéro B 900 318 858 et se compose des documents suivants
              :
            </p>
            <ul>
              <li>Conditions Particulières du bon de commande ;</li>
              <li>Conditions Générales de Vente et d’Utilisation ;</li>
              <li>Conditions Particulières de Publication des Promotions ; </li>
              <li>Conditions Particulières du Pack Site Web ; </li>
              <li>
                Conditions Particulières d’aide à l’Installation, de Maintenance
                et de Réparation du Matériel ;{" "}
              </li>
              <li>
                Conditions Particulières du service d’empreintes bancaires;
              </li>
              <li>Conditions Particulières - Politique d’Envoi (Module CRM)</li>
            </ul>
            <h3>(ci-après, le « Contrat »).</h3>
            <p>
              Les Conditions Particulières complètent les Conditions Générales
              de Vente et d’Utilisation et s’appliquent à la fourniture des
              services spécifiques choisis par le responsable du club (ci-après
              le ou les « Responsable(s) »). 7Night sera légitimement fondée à
              considérer que toute personne en contact avec 7NIGHT en ce qui
              concerne un élément de ce Contrat et/ou en ce qui concerne un des
              services fournis par 7NIGHT est habilité à représenter le
              responsable du club à ce titre. Tout engagement pris dans ces
              conditions sera réputé engager le responsable du club à l’égard de
              7NIGHT. <br />
              En cas de contradiction entre les dispositions des Conditions
              Particulières et celles des Conditions Générales de Vente et
              d’Utilisation, les dispositions des Conditions Particulières
              prévalent. En cas de contradiction entre les dispositions du bon
              de commande et celles des Conditions Particulières et/ou
              Conditions Générales de Vente et d’Utilisation, les dispositions
              du bon de commande prévalent. <br />
              7NIGHT se réserve le droit de modifier à tout moment tout ou
              partie des documents contractuels constituant le Contrat et en
              informera le Responsable du club au plus tard quinze (15) jours
              avant la prise d’effet de la modification. L’utilisation des
              services de 7NIGHT par le Responsable du club après la prise
              d’effet de la modification vaudra acceptation sans réserve par le
              Responsable du club des nouvelles conditions contractuelles. Si le
              Responsable du club n’accepte pas la modification, il devra, avant
              la prise d’effet de celle-ci, résilier le Contrat.
            </p>
            <h3>ARTICLE 1 - OBJET DU CONTRAT</h3>
            <p>
              7NIGHT édite en France et plusieurs autres pays un service de
              référencement, de recherche et de réservation de clubs accessible
              au public (ci-après le ou les « Utilisateur(s) ») sur les sites
              Internet et mobiles et les applications mobiles et tablette 7NIGHT
              (ci-après, le « Portail ») ainsi que sur les sites Internet et
              mobiles et applications mobiles et tablette des partenaires de
              7NIGHT (tels que TripAdvisor, Google, Facebook, Instagram, Apple,
              etc. ) (ci-après, les « Sites partenaires »). <br />
              De plus, 7NIGHT pour la France, ou des sociétés appartenant au
              même groupe de sociétés que 7NIGHT (ci-après, le « Réseau 7NIGHT
              ») pour les TFM FR_FR 2021_1 autres pays, mettent à la disposition
              des Responsables du clubs une application de gestion et
              d’optimisation des réservations et des contenus affichés sur le
              Portail accessible en ligne dénommée 7NIGHT MANAGER (ci-après, l’«
              Application »), hébergée sur les serveurs de 7NIGHT. <br />
              L’Application comprend le module de réservation de 7NIGHT (le
              "Module de Réservation"), qui est un widget graphique et textuel
              composé d'éléments statiques, tels que les marques ou le logo du
              Club et/ ou de 7NIGHT, et d'éléments dynamiques, qui comprennent
              du texte, des données de disponibilité et d'autres informations ou
              matériels spécifiques au Club et permettant aux Utilisateurs
              d’effectuer des réservations sur le site Internet et/ou mobile du
              Responsable du club. Le Module de Réservation peut également être
              intégré sur le site Internet du Club en version « marque blanche »
              ou « marque grise » aux conditions du présent Contrat. <br />
              Le présent Contrat s’applique à l’ensemble des services fournis
              par 7NIGHT au Club via l’Application, le Portail et les Sites
              partenaires. L’utilisation de ces services emporte acceptation de
              plein droit par le Club de la version du présent Contrat en
              vigueur au jour de l’utilisation desdits services.
            </p>
            <h3>ARTICLE 2 - ACCES A L’APPLICATION</h3>
            <p>
              Au titre du présent Contrat, 7NIGHT concède au Club un droit
              d’utilisation personnel, temporaire et non cessible à
              l’Application exclusivement destiné à la gestion des réservations
              de son/ses établissements (le « Club »), la gestion du
              référencement du Club sur le Portail et sur les Sites partenaires,
              et la gestion de la relation clients. <br />
              Le Responsable du club est seul responsable de l’utilisation et de
              la gestion de son droit d'accès à l’Application. Dès la
              souscription au présent Contrat par le Responsable du club, ce
              dernier pourra se connecter à l’Application via un ordinateur
              relié à Internet. <br />
              En souscrivant à l’Application le Responsable du club consent à
              l’inscription du Club au service en ligne de référencement, de
              recherche et de réservation édité par 7NIGHT sur le Portail et les
              Sites partenaires, sous réserve du respect par le Responsable du
              club des critères de sélection et de qualité édictés par 7NIGHT et
              sous réserve que le Club soit situé dans un pays visé par le
              Portail. La souscription par un Responsable du club à une version
              de l’Application ne constitue toutefois ni une condition, ni une
              garantie de référencement sur le Portail ou les Sites partenaires.
              La responsabilité de 7NIGHT ne pourra en aucun cas être recherchée
              en cas de non-référencement d’un Club ou si le Club est
              déréférencé en raison du non-respect des critères de qualité de
              7NIGHT. <br />
              Dans le cas où le Responsable du club choisit de ne pas s’inscrire
              au service en ligne de référencement, de recherche et de
              réservation édité par 7NIGHT sur le Portail ou certains des Sites
              partenaires, des frais supplémentaires ou des tarifs différenciés
              peuvent lui être appliqués tels que stipulés dans les conditions
              particulières du bon de commande. <br />
              Le Responsable du club dont le Club est référencé par 7NIGHT sur
              le Portail et sur les Sites partenaires peut créer et modifier les
              informations et contenus relatifs à son Club et est seul
              responsable de tels informations ou contenus.
            </p>
            <h3>
              ARTICLE 3 – UTILISATION DU PORTAIL ET/OU INSTALLATION DU MODULE DE
              RESERVATION SUR LE SITE INTERNET DU Responsable du club
            </h3>
            <h3>
              Clause 3.1 Portail, Module de Réservation et données à caractère
              personnel
            </h3>
            <p>
              Dans le cadre des réservations effectuées par les Utilisateurs,
              les données à caractère personnel de ces derniers (ci-après les «
              données à caractère personnel » ou « données personnelles ») au
              sens de la loi française 78-17 du 6 janvier 1978 et du règlement
              européen 2016/679 du 27 avril 2016 (ci-après, ensemble le « RGPD
              ») sont collectées, et plus généralement traitées, par 7NIGHT et
              le Responsable du club. Les rôles et responsabilités de chacun
              diffèrent en fonction des modalités de réservation proposées par
              7NIGHT.{" "}
            </p>
            <p>
              - conjointement responsables du traitement de données à caractère
            </p>
            <h3>3.1.1 Portail et gestion des données à caractère personnel </h3>
            <p>
              Le Portail permet aux Utilisateurs de réserver dans le club. Dans
              cette hypothèse, 7NIGHT et le Responsable du club sont : <br />
              personnel mis en œuvre à des fins de prise, de gestion et de suivi
              des réservations effectuées par les Utilisateurs via le Portail et
              les Sites partenaires; 2 - chacun respectivement responsable
              indépendant de son propre traitement pour ce qui concerne le
              traitement des données à caractère personnel collectées via le
              Portail et les sites partenaires et ayant pour finalité la gestion
              et suivi de leur relation avec les Utilisateurs, en ce incluant
              notamment la réalisation d’opérations commerciales, de
              communication, de sollicitation et de marketing. 7NIGHT peut
              également agir en qualité de sous-traitant lorsque le Responsable
              du club utilise l’Application pour la réalisation des opérations
              précitées
            </p>
            <h3>
              ARTICLE 4 – OBLIGATIONS - AUTORISATIONS DU RESPONSABLE DU CLUB
            </h3>
            <h3>Article 4.1 Obligations</h3>
            <p>
              Le Responsable du club s’engage à utiliser l’Application
              conformément aux conditions définies dans le présent Contrat. Le
              Responsable du club s’interdit, de reproduire, extraire,
              décompiler, réutiliser tout élément de l’architecture, du contenu
              or des données de l’Application, y compris à l'aide d'un robot,
              d'une araignée, d'un scraper, d’un logiciel espion, d’un
              enregistreur de frappe clavier ou de tout autre programme ou
              dispositif automatique ou procédé manuel, par quelque moyen que ce
              soit, sous quelque forme que ce soit et sur quelque support que ce
              soit. <br />
              Pour souscrire à l’Application, le Responsable du club doit
              posséder le matériel informatique adéquat dans son Club. Si tel
              n’est pas le cas, le Responsable du club s’engage à acquérir un
              tel matériel ou à le louer auprès de 7NIGHT. <br />
              Le Responsable du club est seul responsable de la gestion en temps
              réel de son stock de places. Le Responsable du club s’engage à
              intégrer, en temps réel, au sein de l’Application, l’ensemble des
              réservations qui ne lui seraient pas transmises automatiquement
              par le biais de l’Application ou à fermer à l’avance ses services
              à la réservation, sur l’Application, dès qu’il a connaissance de
              ses jours de fermeture à venir (congés ou jours fériés, notamment)
              ou dès qu’il est complet. Le Responsable du club s’engage à
              accepter toute réservation émanant de 7NIGHT dans la mesure des
              stocks de places disponibles indiqués par le Responsable du club
              sur l’Application. En cas d’abus, comme par exemple dans le cas de
              réservations annulées ou non-honorées par le Responsable du club,
              de manière répétée, 7NIGHT se réserve le droit de prendre les
              mesures exposées à l’article 9 ci-dessous. <br />
              Le Responsable du club demeure seul responsable de l'utilisation
              des identifiants le concernant et ne doit pas les divulguer aux
              tiers. En cas de perte des identifiants ou de divulgation des
              identifiants à des tiers, le Responsable du club s’engage à
              avertir sans délais 7NIGHT. Le Responsable du club s’engage à
              appliquer toute mesure de sécurité nécessaire, notamment
              concernant les identifiants afin d’éviter notamment toute
              divulgation des identifiants a des tiers. En particulier, le
              Responsable du club ne divulguera pas ses identifiants à des
              services de réservation tiers et ne renseignera pas l’adresse
              email ou le numéro de téléphone d’un service de réservation tiers
              dans les champs ‘contact’ de l’Application. <br />
              Le Responsable du club dont le Club est présent sur le Portail et
              les Sites Partenaires s’engage à fournir des informations sincères
              et exactes sur les services fournis aux Utilisateurs et à toujours
              tenir à jour lesdites informations. Le Responsable du club
              s’engage, en cas de Tarifs particulier et imposé lors de certains
              jours exceptionnels (tels que la saint Valentin, le jour de l’an,
              etc.), à indiquer dans sa fiche sur l’Application, dans les plus
              brefs délais, le détail du menu et ses conditions particulières.
              Le Responsable du club est entièrement responsable du contenu
              relatif à son Club et de leur mise à jour régulière par le biais
              de l’Application. Le Responsable du club intégrant le Module de
              Réservation sur le Site Internet du Club s’engage à respecter
              l’ensemble de la législation en vigueur applicable à son activité
              en ligne. <br />
              Le Responsable du club s’engage à respecter la vie privée des
              Utilisateurs et les dispositions du RGPD.
            </p>
            <h3>Article 4.2 Autorisations </h3>
            <p>
              Au fur et à mesure de leur remise, le Responsable du club cède à
              titre gratuit et non exclusif à 7NIGHT le droit d’exploitation en
              ligne et hors ligne, (incluant le droit de reproduction et le
              droit de de représentation) de l’ensemble des éléments visuels
              (photographies, etc.) et textuels originaux fournis par le
              Responsable du club, notamment au moyen de l’Application, sur tout
              type de support (notamment Portail, newsletters, Sites
              partenaires, catalogues, guides, etc.), pour le monde entier et
              pour toute la durée de protection des éléments visuels et textuels
              originaux par le droit d’auteur. La présente cession inclut le
              droit pour 7NIGHT d’exploiter les éléments visuels et textuels à
              des fins de référencement, d’information, de classement et/ou de
              réservation ; La présente cession inclut également le droit pour
              7NIGHT de concéder, conformément au périmètre des droits cédés au
              paragraphe précédent, des sous-licences d’exploitation des
              différents éléments visuels (photographies, etc.) ou textuels
              originaux fournis par le Responsable du club à 7NIGHT, aux
              sociétés du Réseau 7NIGHT et aux Sites partenaires. Le contenu
              fourni par le Responsable du club ou créé par ce dernier avec
              l’Application demeure la propriété du Responsable du club et reste
              sous sa responsabilité exclusive. <br />
              Au fur et à mesure de leur remise, le Responsable du club autorise
              contractuellement 7NIGHT à exploiter dans le monde entier et pour
              une durée de trois (3) ans à compter de la date de résiliation du
              présent contrat les différents éléments visuels (photographies,
              etc.) ou textuels non originaux fournis par le Responsable du
              club, notamment au moyen de l’Application, sur tout type de
              support (notamment Portail, newsletters, Sites partenaires,
              catalogues, guides, , etc.) à des fins de référencement,
              d’information, de classement et/ou de réservation. La présente
              autorisation fournie à titre non exclusif inclut le droit pour
              7NIGHT de transmettre aux sociétés du Réseau 7NIGHT et aux Sites
              Partenaires les fichiers relatifs aux différents éléments visuels
              (photographies, etc.) ou textuels non originaux fournis par le
              Responsable du club à des fins de référencement, d’information, de
              classement et de réservation. <br /> Le Responsable du club
              autorise également 7NIGHT aux sociétés du Réseau 7NIGHT et aux
              Sites Partenaires à référencer les récompenses et distinctions
              obtenues auprès d’organismes tiers. <br /> En particulier, le
              Responsable du club certifie que les contenus (tels que
              photographies, textes, etc.) qu’il télécharge au moyen de
              l’Application ou qu’il autorise 7NIGHT à télécharger sur
              l’Application et à afficher sur le Portail ou les Sites
              partenaires ne portent pas préjudice aux droits des tiers, qu’il
              est autorisé à les reproduire et/ou les diffuser au public sur
              Internet et qu’il dispose des droits nécessaires pour accorder une
              concession de ses droits conforme aux conditions prévues par les
              présentes Conditions Générales de Vente et d’Utilisation. Pour les
              images du Club incluant le personnel du Club, le Responsable du
              club déclare avoir recueilli l’accord de la (les) personne(s)
              photographiée(s) ou filmée(s), en vue de la publication et de la
              diffusion des images. Pour les images du Club incluant la
              clientèle, le Responsable du club utilisera des photographies de
              personnes non identifiables. Dans le cas contraire, 7NIGHT
              rejettera la photo ou procédera au floutage des personnes
              concernées. Le Responsable du club déclare être informé de cette
              contrainte et aucune réclamation sur les photographies ne pourra
              être effectuée par le Responsable du club à ce titre. <br /> Le
              Responsable du club garantit 7NIGHT et les sociétés du Réseau
              7NIGHT contre toutes réclamations, revendications de propriété ou
              toutes actions judiciaires ou extra-judiciaires relatives aux
              contenus fournis par le Responsable du club et le Responsable du
              club s’engage à dédommager intégralement 7NIGHT et les sociétés du
              Réseau 7NIGHT de leur frais et pertes qui pourraient leur être
              causés de ce fait.
            </p>
            <h3>ARTICLE 5 – OBLIGATIONS DE 7NIGHT – CLASSEMENT</h3>
            <p>
              7NIGHT s’efforcera de mettre en œuvre les moyens nécessaires pour
              assurer la continuité et la qualité d’accès à l’Application
              conformément aux conditions définies aux articles 1 et 2 du
              présent Titre 1 et dans la limite des conditions prévues à
              l’article 11 du présent Titre 1. <br />
              7NIGHT utilise les critères suivants pour le classement «
              pertinence » sur le Portail : les promotions du Club, sa note
              moyenne sur les douze derniers mois, sa distance par rapport à
              l'adresse renseignée par l'Utilisateur, la disponibilité des
              tables, l’adhésion aux standards de qualité de 7NIGHT (annulations
              par le Club, réservation instantanée, etc.), et le référencement
              dans un guide. <br />
              7NIGHT demeure libre de ses campagnes et choix promotionnels et il
              ne peut en aucun cas lui être reproché un manque de visibilité
              d’un Club par le Responsable du club.
            </p>
            <h3>ARTICLE 6 – DONNEES A CARACTERE PERSONNEL</h3>
            <h3>
              Article 6.1 La prise, la gestion et le suivi des réservations{" "}
            </h3>
            <p>
              Dans le cadre de l’exécution du Contrat, un traitement de données
              à caractère personnel ayant pour finalité la prise, la gestion et
              le suivi des réservations est mis en œuvre. Les caractéristiques
              de ce traitement sont les suivantes, quel que soit le mode de
              réservation :
            </p>
            <ul>
              <li>
                les données à caractère personnel pouvant être traitées sont des
                données relatives aux coordonnées des Utilisateurs, et
                éventuellement à leur vie personnelle et/ou professionnelle, des
                informations d’ordre financier, mais également des données de
                connexion, données issues de l’utilisation de cookies, traceurs
                et autres technologies similaires, et plus généralement
                l’ensemble des données à caractère personnel qui seraient
                nécessaires pour l’exécution du présent Contrat, étant précisé
                que le Responsable du club peut paramétrer librement certains
                champs à renseigner dans l’Application, et demeure seul
                responsable et propriétaire des fichiers des données collectées
                de cette collecte et de leur contenu et, plus généralement, de
                tout contenu généré par lui ou à sa demande ;{" "}
              </li>
              <li>
                {" "}
                les personnes concernées sont les Utilisateurs et les clients
                direct du Responsable du club;
              </li>
              <li>
                {" "}
                les opérations réalisées sur les données à caractère personnel
                sont les suivantes : consultation, collecte, mise à jour,
                analyse, élaboration de statistiques, et plus généralement toute
                opération nécessaire à leur utilisation dans le cadre du
                Contrat.
              </li>
            </ul>
            <h3>6.1.1. Réservations sur le Portail </h3>
            <p>
              Dans le cadre de l’utilisation des services fournis par 7NIGHT, et
              au sens du RGPD, le Responsable du club et 7NIGHT sont
              conjointement responsables du traitement ayant pour finalité la
              prise, la gestion et le suivi des réservations effectuées sur le
              Portail ou via un Les rôles et responsabilités des parties dans ce
              cadre sont répartis comme suit.{" "}
            </p>
            <h3>
              6.1.1 a) Respect des principes applicables au traitement de
              données à caractère personnel{" "}
            </h3>
            <p>
              Le Responsable du club et 7NIGHT traitent des données à caractère
              personnel dans le cadre de la prise, de la gestion et du suivi des
              réservations, chacun dans la limite des rôles et responsabilités
              qui leur incombent, conformément aux stipulations prévues
              ci-après. <br />
              Il appartient au Responsable du club et à 7NIGHT qui s’y engagent,
              de s’assurer :
            </p>
            <ul>
              <li>
                que chacun ne traite les données à caractère personnel que dans
                le cadre permis par la loi et que pour les finalités visées dans
                la Politique de confidentialité de 7NIGHT telle qu’affichée sur
                le Portail et par le présent Contrat, et qu’elles ne sont pas
                traitées pour des finalités ultérieures incompatibles avec ces
                finalités ;
              </li>
              <li>
                que chacun ne conserve les données à caractère personnel sous
                une forme permettant l'identification des personnes concernées
                que pendant une durée n'excédant pas celle nécessaire au regard
                des finalités visées au présent Contrat.
              </li>
            </ul>
            <p>
              Il est expressément convenu entre les parties qu’il appartient
              exclusivement au Responsable du club, qui s’y engage, de s’assurer
              :{" "}
            </p>
            <ul>
              <li>
                que les données à caractère personnel collectées et traitées par
                le Responsable du club dans ce cadre sont adéquates,
                pertinentes, non excessives, objectives et limitées à ce qui est
                nécessaire au regard des finalités poursuivies, et que la
                collecte de telles données n’est pas illicite. Notamment, le
                Responsable du club s’engage à ne pas collecter et/ou traiter de
                données d’ordre financier telles que des numéros de carte
                bancaire. Il s’engage de même à ne pas collecter et/ou traiter,
                sauf en cas de stricte nécessité pour les finalités poursuivies
                et après avoir recueilli le consentement préalable, exprès,
                spécifique et informé de la personne concernée, de données à
                caractère personnel nécessitant une protection particulière,
                telles que des opinions ou activités religieuses ou politiques,
                des données sur la santé, ou encore des données concernant la
                vie ou l’orientation sexuelle des personnes concernées ;
              </li>
              <li>
                de la qualité, de l’actualité, de la mise à jour et de
                l’exactitude de ces données dans l’Application;
              </li>
              <li>
                que seuls des destinataires strictement justifiés et
                proportionnés par rapport à la finalité du traitement peuvent
                effectivement avoir accès aux données à caractère personnel.
              </li>
            </ul>
            <p>
              Le Responsable du club libère 7NIGHT de toute revendication
              concernant les actes ou omissions du Responsable du club et/ou de
              son personnel et émanant de personnes concernées dont les données
              sont traitées pour des finalités de prise, de gestion et de suivi
              des réservations effectuées sur le Portail ou via un module en «
              marque grise ».{" "}
            </p>
            <h3>6.1.1 b) Sécurité et confidentialité</h3>
            <p>
              7NIGHT garantit qu’elle met en œuvre toutes les mesures techniques
              et organisationnelles destinées à assurer la sécurité, et
              notamment la confidentialité, de ces données à caractère personnel
              auxquelles elle pourrait accéder ou qui pourraient lui être
              communiquées ou plus généralement qu’elle pourrait traiter dans le
              cadre de l’exécution du Contrat. <br />
              Les moyens, mis en œuvre par 7NIGHT, destinés à assurer la
              sécurité et la confidentialité des données à caractère personnel
              sont spécifiés conformes à l’état de l’art. <br /> Réciproquement,
              le Responsable du club s’engage à mettre en œuvre toutes les
              mesures techniques et organisationnelles destinées à assurer la
              sécurité et la confidentialité des données d’une performance au
              moins équivalente.
            </p>
            <h3>6.1.1 c) Sous-traitance </h3>
            <p>
              Il est expressément convenu entre les parties que 7NIGHT est
              autorisée à avoir recours à des sous-traitants pour mener des
              activités de traitement spécifiques sur les données à caractère
              personnel collectées dans le cadre de l’exécution du Contrat en
              vue de la prise, la gestion et le suivi des réservations. <br />
              Il est expressément convenu entre les parties que le Responsable
              du club n’est pas autorisé à avoir recours à des sous-traitants
              pour mener des activités de traitement spécifiques sur les données
              à caractère personnel collectées dans le cadre de l’exécution du
              Contrat en vue de la prise, la gestion et le suivi des
              réservations.
            </p>
            <h3>6.1.1 d) Coopération </h3>
            <p>
              Chacune des parties s’engagent à prêter assistance à l’autre
              partie, dans le cadre des obligations lui incombant aux termes du
              présent Contrat, en vue:
            </p>
            <ul>
              <li>
                du respect par l’autre partie de ses propres obligations en
                matière de sécurité et de confidentialité des données à
                caractère personnel traitées dans le cadre du présent Contrat ;
              </li>
              <li>
                {" "}
                de coopérer pour réaliser des analyses d’impact des traitements
                sur la protection des données à caractère personnel si la nature
                des traitements l’exige et de l’éventuelle consultation
                préalable de l’autorité de contrôle qui peut être nécessaire le
                cas échéant. Aussi, les parties s’engagent à se communiquer
                mutuellement toute information en leur possession qui serait
                nécessaire à cette fin dans les limites permises par la loi;
              </li>
              <li>
                de l’information des personnes concernées relative aux
                traitements de données à caractère personnel les concernant.
                Pour ce faire, 7NIGHT porte à la connaissance des personnes
                concernées une information lors de la collecte des données ;
              </li>
              <li>
                de la gestion des demandes d’exercice des droits reconnus aux
                personnes concernées par GDPR (droit d’accès, de rectification,
                d’effacement et à la portabilité desdites données, droit
                d’opposition et droit à la limitation du traitement, droit de ne
                pas faire l’objet d’une décision individuelle automatisée, y
                compris le profilage) si de telles demandes concernent les deux
                parties ;
              </li>
            </ul>
            <p>
              Lorsque de telles demandes sont adressées au Responsable du club,
              ce dernier en informe 7NIGHT par email à l’adresse{" "}
              <a href="mailto:support@7NIGHT.fr">support@7NIGHT.fr</a>
              sans délai, et au plus tard dans les 72 heures, après en avoir eu
              connaissance, et communique à cette occasion à 7NIGHT tous les
              éléments en sa possession qui seraient utiles ; <br /> Par
              ailleurs, le Responsable du club s’engage à répercuter les
              conséquences d’une demande le concernant dans l’ensemble des
              outils, applications et bases de données qu’il utilise ;
            </p>
            <ul>
              <li>
                du respect de l’obligation de notification à l'autorité de
                contrôle et d’information de la personne concernée en cas de
                violation de données à caractère personnel, à savoir toute
                violation de la sécurité entraînant accidentellement ou de
                manière illicite la destruction, la perte, l’altération, la
                divulgation ou l’accès non autorisé à des données à caractère
                personnel faisant l’objet d’un traitement. <br />
                7NIGHT prend en charge ces obligations de notification et
                d’information. Le Responsable du club ne procédera pas lui-même
                à cette notification à l'autorité de contrôle ni à l’information
                des personnes concernées. Toutefois, il s’engage à informer
                7NIGHT, par email à{" "}
                <a href="mailto:support@7NIGHT.fr">support@7NIGHT.fr</a>,
                immédiatement après qu’il en a eu connaissance, de toute
                violation de données à caractère personnel. Cette information de
                7NIGHT par le Responsable du club devra s’accompagner de la
                communication de tous les éléments et notamment de la
                documentation) en possession du Responsable du club qui seraient
                utiles pour 7NIGHT en vue de procéder à la notification et à
                l’information précitées lorsqu’elles sont requises. En tout état
                de cause, le Responsable du club s’engage à communiquer à
                7NIGHT, immédiatement à première demande, par email à{" "}
                <a href="mailto:support@7NIGHT.fr">support@7NIGHT.fr</a>, toute
                information en sa possession qui seraient utiles pour 7NIGHT en
                vue de procéder à la notification et à l’information précitées
                lorsqu’elles sont requises.
              </li>
            </ul>
            <h3>6.1.1 e) Vérifications </h3>
            <p>
              7NIGHT pourra procéder à toute vérification, notamment dans
              l’Application qui lui paraîtrait utile pour constater le respect
              par le Responsable du club de ses obligations en matière de
              protection des données à caractère personnel lors de l’utilisation
              par le Responsable du club de l’Application.
            </p>
            <h3>
              6.1.2. Réservations via le Module de Réservation en « marque
              blanche » ou directement auprès du Responsable du club
            </h3>
            <p>
              Dans le cadre de l’utilisation des services fournis par 7NIGHT, et
              au sens du RGPD, le Responsable du club est responsable du
              traitement de données à caractère personnel ayant pour finalité la
              prise, la gestion et le suivi des réservations effectuées
              directement auprès de lui ou via un Module de Réservation en «
              marque blanche ». Dans cette hypothèse, 7NIGHT peut quant à elle,
              en qualité de sous-traitant, pour le compte du Responsable du club
              être amenée à procéder à des traitements de telles données ou y
              avoir accès. <br />
              Les rôles et responsabilités des parties dans ce cadre sont
              répartis comme suit.
            </p>
            <h3>
              6.1.2 a) Respect des principes applicables au traitement de
              données à caractère personnel{" "}
            </h3>
            <p>
              Le Responsable du club accepte la portée et les limites des
              fonctionnalités de l’Application telle que proposée par 7NIGHT et
              comprend qu’il donne ses instructions relatives à l’exécution du
              présent Contrat, et notamment aux traitements de données à
              caractère personnel devant être mis en œuvre dans ce cadre pour
              son compte par 7NIGHT par le biais de son utilisation des
              différentes fonctions de l’Application. Il appartient en outre au
              Responsable du club, qui s’y engage, de s’assurer :
            </p>
            <ul>
              <li>
                {" "}
                du caractère licite, loyal et transparent de la collecte et du
                traitement des données à caractère personnel (notamment
                information des personnes concernées, voire recueil du
                consentement desdites personnes concernées lorsque celui-ci est
                requis en particulier en raison de la finalité ou des modalités
                du traitement ou encore des données collectées et traitées). A
                cet égard, le Responsable du club configure via l’Application la
                mention de recueil de consentement qu’il jugera utile à porter à
                la connaissance des personnes concernées lors d’opérations de
                collecte de données à caractère personnel, via les Modules de
                Réservation en « marque blanche » ;
              </li>
              <li>
                {" "}
                que ces données ne sont traitées que pour les finalités prévues
                au présent Contrat, et qu’elles ne sont pas traitées pour des
                finalités ultérieures incompatibles avec ces finalités ;
              </li>
              <li>
                que les données à caractère personnel collectées et traitées
                dans le cadre de la prise, de la gestion et du suivi des
                réservations sont adéquates, pertinentes, non excessives,
                objectives et limitées à ce qui est nécessaire au regard des
                finalités poursuivies, et que la collecte de telles données
                n’est pas illicite. Notamment, le Responsable du club s’engage à
                ne pas collecter et/ou traiter de données d’ordre financier
                telles que des numéros de carte bancaire. Il s’engage de même à
                ne pas collecter et/ou traiter, sauf en cas de stricte nécessité
                pour les finalités poursuivies et après avoir recueilli le
                consentement préalable, exprès, spécifique et informé de la
                personne concernée, de données à caractère personnel nécessitant
                une protection particulière, telles que des opinions ou
                activités religieuses ou politiques, des données sur la santé,
                ou encore des données concernant la vie ou l’orientation
                sexuelle des personnes concernées ;
              </li>
              <li>
                de la qualité, de l’actualité, de la mise à jour et de
                l’exactitude de ces données ; le Responsable du club s’engageant
                à effectuer les mises à jour, corrections, voire suppression,
                des données à caractère personnel traitées dans l’Application;
              </li>
              <li>
                {" "}
                que les données à caractère personnel ne sont conservées sous
                une forme permettant l'identification des personnes concernées
                que pendant une durée n'excédant pas celle nécessaire au regard
                des finalités visées dans le présent Contrat ;
              </li>
              <li>
                {" "}
                que les habilitations aux données à caractère personnel sont
                strictement limitées à des utilisateurs qui ont la nécessité
                d’en connaître, sur la base de la règle du moindre privilège.{" "}
              </li>
            </ul>
            <p>
              Le Responsable du club libère 7NIGHT de toute revendication
              concernant les actes ou omissions du Responsable du club et/ou de
              son personnel et émanant de personnes concernées dont les données
              sont traitées pour des finalités de prise, de gestion et de suivi
              des réservations effectuées directement auprès de lui ou via un
              Module de Réservation en « marque blanche ».
            </p>
            <p>
              En sa qualité de responsable de traitement, le Responsable du club
              s’engage à mettre à disposition de 7NIGHT l’ensemble des
              informations et éléments nécessaires en vue du respect par 7NIGHT
              de ses propres obligations en matière de protection des données à
              caractère personnel.
            </p>
            <h3>6.1.2 b) Sécurité et confidentialité </h3>
            <p>
              7NIGHT garantit qu’elle met en œuvre toutes les mesures techniques
              et organisationnelles destinées à assurer la sécurité, et
              notamment la confidentialité, de ces données à caractère personnel
              auxquelles elle pourrait accéder ou qui pourraient lui être
              communiquées ou qu’elle pourrait traiter dans le cadre de
              l’exécution du Contrat. Aussi, 7NIGHT s’engage à prendre toutes
              les mesures techniques et organisationnelles appropriées, compte
              tenu de l’état des connaissances, des coûts de mise en œuvre et de
              la nature, de la portée, du contexte et des finalités des
              traitements de données à caractère personnel, qui seraient
              nécessaires au respect par elle-même et par son personnel de ces
              obligations de sécurité, et notamment à :
            </p>
            <ul>
              <li>
                ne pas traiter, consulter lesdites données à caractère personnel
                à d’autres fins que l’exécution des obligations qui lui
                incombent en vue de l’exécution des prestations pour le compte
                du Responsable du club au titre du Contrat ;
              </li>
              <li>
                {" "}
                ne traiter, consulter ces données à caractère personnel que dans
                le cadre des instructions licites et documentées du Responsable
                du club (étant précisé que les parties reconnaissent la notion
                d’instruction documentée comme étant acquise lorsque 7NIGHT agit
                dans le cadre de l’exécution du Contrat), y compris en ce qui
                concerne les transferts de données à caractère personnel vers un
                pays tiers ou à une organisation internationale, à moins qu’elle
                ne soit tenue d'y procéder en vertu du droit de l'Union
                européenne ou d’un Etat membre auquel elle est soumise ; dans ce
                cas, 7NIGHT informera le Responsable du club de cette obligation
                avant le traitement des données à caractère personnel, sauf si
                le droit concerné interdit une telle information pour des motifs
                importants d’intérêt public ;
              </li>
              <li>
                veiller à ce que les personnes autorisées à traiter les données
                à caractère personnel soient soumises à une obligation
                conventionnelle ou légale appropriée de confidentialité.
              </li>
            </ul>
            <p>
              Au terme du Contrat le Responsable du club peut procéder à
              l’export des données clients, y compris les données à caractère
              personnel dont il est le responsable de traitement par le bais de
              la fonction ‘Export’ de l’Application. 7NIGHT effacera les données
              traitées pour le compte du Responsable du club dans un délai
              raisonnable à moins que le droit de l’Union européenne ou la
              législation française n’exige la conservation par 7NIGHT de ces
              données à caractère personnel. <br />
              Les moyens, mis en œuvre par 7NIGHT, destinés à assurer la
              sécurité et la confidentialité des données à caractère personnel
              sont conformes à l’état de l’art. <br />
              Réciproquement, le Responsable du club s’engage à mettre en œuvre
              toutes les mesures techniques et organisationnelles destinées à
              assurer la sécurité et la confidentialité des données d’une
              performance au moins équivalente. <br />
              7NIGHT et le Responsable du club s’engage à maintenir ces moyens
              tout au cours de l’exécution du Contrat et à défaut, à en informer
              immédiatement l’autre partie. En tout état de cause, 7NIGHT et le
              Responsable du club s’engagent en cas de changement des moyens
              visant à assurer la sécurité et la confidentialité de ces données
              à caractère personnel, à les remplacer par des moyens d’une
              performance équivalente.
            </p>
            <h3>6.1.2 c) Sous-traitance</h3>
            <p>
              Il est expressément convenu entre les parties que 7NIGHT est
              autorisée à avoir recours à des sous-traitants pour mener des
              activités de traitement spécifiques sur les données à caractère
              personnel collectées dans le cadre de l’exécution du Contrat en
              vue de la prise, la gestion et le suivi des réservations. <br />
              7NIGHT fournira au Responsable du club, sur demande, une liste de
              ses sous-traitants. Le Responsable du club disposera alors d’un
              délai de trente (30) jours à compter de la date de cette
              information pour présenter ses objections qui devront porter sur
              des points de vie privée ou de sécurité. <br />
              7NIGHT s’engage à ce que les sous-traitants auxquels elle aurait
              recours respectent les obligations du présent Contrat à la charge
              de 7NIGHT s’engage à conclure à cette fin un contrat écrit avec
              chacun de ses sous-traitants <br />
              Par ailleurs, le Responsable du club est également autorisé à
              avoir recours à des sous-traitants pour mener des activités de
              traitement spécifiques sur les données à caractère personnel
              collectées dans le cadre de l’exécution du Contrat en vue de la
              prise, la gestion et le suivi des réservations, sous réserve que
              ces sous-traitants n’exercent pas une activité concurrente de
              7NIGHT, à l’appréciation de 7NIGHT, et sous réserve que le Club ne
              permette pas à ses sous-traitants d’accéder à l’Application ou aux
              communications émanant de 7NIGHT et destinées au seul Responsable
              du club. <br />
              Le Responsable du club informera, par email à{" "}
              <a href="mailto:support@7NIGHT.fr">support@7NIGHT.fr</a>, 7NIGHT
              de tout recours ou changement envisagé concernant l’ajout ou
              remplacement de sous-traitants. <br />
              Le Responsable du club s’engage à ce que les sous-traitants
              auxquels il aurait recours respectent les obligations du présent.
              Le Responsable du club s’engage à conclure à cette fin un contrat
              écrit avec chacun de ses sous-traitants, étant précisé qu’en cas
              de non-respect par un sous-traitant de ses obligations en matière
              de protection des données à caractère personnel, le Responsable du
              club demeurera pleinement responsable à l’égard de 7NIGHT.
            </p>
            <h3>6.1.2 d) Coopération</h3>
            <p>
              7NIGHT, en sa qualité de sous-traitant, s’engage à prêter
              assistance au Responsable du club, dans le cadre des obligations
              lui incombant aux termes du présent Contrat, en vue :
            </p>
            <ul>
              <li>
                du respect par le Responsable du club de ses propres obligations
                en matière de sécurité et de confidentialité des données à
                caractère personnel ;
              </li>
              <li>
                de la réalisation des analyses d’impact des traitements sur la
                protection des données à caractère personnel si la nature des
                traitements l’exige et de l’éventuelle consultation préalable de
                l’autorité de contrôle qui peut être nécessaire le cas échéant.
                A cet égard, il est précisé que l’obligation de réalisation de
                telles analyses d’impact ou encore de consultation de l’autorité
                de contrôle dans les hypothèses prévues par les dispositions
                applicables n’incombe pas à 7NIGHT. Toutefois, 7NIGHT
                communiquera au Responsable du club, sur demande écrite de
                celui-ci et moyennant des frais raisonnables, toute information
                en sa possession demandée par le Responsable du club et qui
                serait nécessaire pour le Responsable du club au respect par ce
                dernier desdites obligations ;
              </li>
              <li>
                de la gestion des demandes d’exercice des droits reconnus aux
                personnes concernées par la réglementation en matière de
                protection des données à caractère personnel (droit d’accès, de
                rectification, d’effacement et à la portabilité desdites
                données, droit d’opposition et droit à la limitation du
                traitement, droit de ne pas faire l’objet d’une décision
                individuelle automatisée, y compris le profilage). <br />
                La réponse à de telles demandes n’est pas de la responsabilité
                de 7NIGHT. Aussi, celle-ci ne répondra pas elle-même à ce type
                de demandes. 7NIGHT communiquera au Responsable du club, sur
                demande écrite de celui-ci et moyennant des frais raisonnables,
                toute information en sa possession demandée par le Responsable
                du club et qui serait nécessaire en vue du traitement des
                demandes d’exercice de leurs droits par les personnes concernées
                ;
              </li>
              <li>
                du respect de l’obligation de notification à l'autorité de
                contrôle et d’information de la personne concernée en cas de
                violation de données à caractère personnel, à savoir toute
                violation de la sécurité entraînant accidentellement ou de
                manière illicite la destruction, la perte, l’altération, la
                divulgation ou l’accès non autorisé à des données à caractère
                personnel faisant l’objet d’un traitement. <br />
                Ces obligations ne sont pas de la responsabilité de 7NIGHT.
                Aussi, celle-ci ne procédera pas elle-même à cette notification
                à l'autorité de contrôle ni à l’information des personnes
                concernées. Toutefois, elle informera le Responsable du club,
                par email à l’adresse renseignée dans le compte du Responsable
                du club, dans les meilleurs délais après qu’il en a eu
                connaissance, de toute violation de données à caractère
                personnel. 7NIGHT communiquera également au Responsable du club,
                sur demande écrite de celui-ci, toute information en sa
                possession demandée par le Responsable du club et qui serait
                nécessaire pour lui en vue de procéder à la notification et à
                l’information précitées lorsqu’elles sont requises.
              </li>
            </ul>
            <p>
              7NIGHT s’engage en outre à informer le Responsable du club si,
              selon elle, une instruction constitue une violation des
              dispositions du droit de l’Union européenne ou d’autres
              dispositions du droit des Etats membres relatives à la protection
              des données à caractère personnel auquel 7NIGHT serait soumise.
            </p>
            <h3>6.1.2 e) Audits </h3>
            <p>
              Le Responsable du club dispose du droit de procéder à toute
              vérification permise par le règlement 2016/679 pour constater le
              respect par 7NIGHT de ses obligations en matière de protection des
              données à caractère personnel, notamment au moyen d’audits aux
              frais du Responsable du club, en ce compris les frais internes à
              7NIGHT encourus afin de répondre à cette demande, notamment les
              journées de travail de son personnel. Ces audits pourront être
              réalisés par un tiers indépendant certifié mandaté à cette fin,
              non concurrent de 7NIGHT et désigné d’un commun accord entre les
              parties (ci-après l’« Auditeur »). Dans ce cadre, 7NIGHT mettra à
              la disposition de l’Auditeur les informations nécessaires pour
              permettre la réalisation de l’audit. <br />
              S’agissant de ces audits, les principes suivants devront être
              respectés : le Responsable du club ne demandera qu’un (1) audit
              par année contractuelle, sauf si 7NIGHT manque gravement à ses
              obligations, auquel cas le Responsable du club pourra demander un
              audit supplémentaire. Le Responsable du club donnera à 7NIGHT par
              lettre recommandée avec avis de réception un préavis d’au moins
              soixante (60) jours et inclura un plan détaillé de sa demande dans
              cette notification.
            </p>
            <p>
              Les principes suivants s’appliqueront en toutes circonstances :{" "}
            </p>
            <ul>
              <li>
                Il est expressément convenu que ne seront pas soumis aux audits
                : toute donnée financière ou donnée à caractère personnel qui ne
                concerne pas le Responsable du club, toute information dont la
                divulgation serait susceptible d’affecter la sécurité des
                systèmes et/ou données de 7NIGHT (par exemple risque pour la
                confidentialité des informations) ou d’autres clients de 7NIGHT
                (et notamment les autres Restaurateurs), ou encore le code
                source des programmes informatiques utilisés dans le cadre de la
                fourniture des services ;
              </li>
              <li>
                {" "}
                la durée des audits ne dépassera pas trois (3) jours ouvrables ;
              </li>
              <li>
                {" "}
                l’Auditeur ne pourra pas faire copie de document, fichier,
                donnée ou information, en tout ou partie, ni prendre des photos,
                numériser, ou capter des enregistrements sonores, vidéo ou
                informatiques ; l’Auditeur ne pourra pas non plus demander que
                tout ou partie de ces éléments lui soient fournis ou envoyés ;
                7NIGHT pourra organiser une montrée de documents sensibles dans
                une salle sécurisée (black room) ;
              </li>
              <li>
                l’Auditeur ne pourra être admis sur un site de 7NIGHT ou d’un de
                ses sous-traitants qu’après déclaration par le Responsable du
                club de son identité ;{" "}
              </li>
              <li>
                le Responsable du club devra s’assurer de la probité de
                l’Auditeur, et le Responsable du club garantit à 7NIGHT que
                l’Auditeur respectera les obligations de confidentialité
                mentionnées dans le présent Contrat, et plus généralement une
                confidentialité la plus absolue des éléments dont il pourrait
                avoir connaissance dans le cadre de l’audit;{" "}
              </li>
              <li>
                L’audit devra se dérouler pendant les heures d’ouverture
                normales des bureaux de 7NIGHT et seront conduites de façon à ne
                gêner ni la fourniture des services proposés par 7NIGHT ni toute
                autre de ses activités au bénéfice de ses autres clients,
                lesquels resteront en tous cas de figure prioritaires sur la
                réalisation de l’audit ;
              </li>
              <li>
                7NIGHT pourra à tout moment interrompre l’auditsi la fourniture
                des services ou de toute autre de ses activités effectuées au
                bénéfice de ses autres clients exige que les ressources et/ou
                les moyens occupés par les audits soient mobilisés à d’autres
                fins.
              </li>
            </ul>
            <h3>
              Article 6.2 La gestion de la relation avec les Utilisateurs
              (incluant notamment les opérations de marketing direct){" "}
            </h3>
            <h3>
              6.2.1 Les obligations des parties en qualité de responsables
              indépendants de leur propre traitement{" "}
            </h3>
            <p>
              Dans le cadre de l’utilisation des services fournis par 7NIGHT, et
              au sens du RGPD, le Responsable du club et 7NIGHT sont chacun
              responsables indépendants de leur propre traitement ayant pour
              finalité la gestion de la relation avec les Utilisateurs, en ce
              incluant notamment la réalisation d’opérations commerciales, de
              communication, de sollicitation et de marketing, et pourront ainsi
              être amenés à procéder à des traitements de données à caractère
              personnel ou y avoir accès à cette fin et pour la durée du
              Contrat. <br />
              Chaque partie s’engage dans ce cadre à respecter les principes et
              obligations qui lui sont applicables en matière de protection des
              données à caractère personnel, notamment le RGPD. <br />
              Notamment, le Responsable du club s’engage à respecter la
              réglementation applicable en matière de prospection directe à
              l’attention des personnes concernées, notamment s’agissant des
              modalités de collecte des données, d’information, voire de recueil
              du consentement, des personnes concernées, et en garantit 7NIGHT.
              Le Responsable du club libère 7NIGHT de toute revendication
              émanant de personnes concernées dont les données sont traitées par
              le Responsable du club pour la gestion de ses relations avec les
              Utilisateurs, en ce incluant notamment les opérations de marketing
              direct. <br />
              Lorsque les données ont été collectées via un Module de
              Réservation en « marque grise » ou directement sur le Portail, le
              Responsable du club s’engage à respecter la Politique de
              Confidentialité de 7NIGHT affichée sur le Portail. <br />
              Lorsque les données ont été collectées via un Module de
              Réservation en « marque blanche » ou directement par le
              Responsable du club, ce dernier s’interdit d’utiliser les marques
              et logos de 7NIGHT sur ses supports de communications commerciales
              adressés à ses clients et/ou prospects. <br />
              En tout état de cause, chaque partie ne peut en aucun cas être
              tenue pour responsable du traitement de données à caractère
              personnel effectué pour son propre compte par l’autre partie aux
              fins de gestion de la relation avec les Utilisateurs, en ce
              incluant notamment la réalisation d’opérations commerciales, de
              communication, de sollicitation et de marketing.
            </p>
            <h3>
              6.2.2 Les spécificités liées à l’utilisation de l’Application par
              le Responsable du club pour son propre compte pour les finalités
              précitées{" "}
            </h3>
            <p>
              Dans le cadre de l’utilisation des services fournis par 7NIGHT, et
              au sens du RGPD, le Responsable du club est responsable du
              traitement de données à caractère personnel ayant pour finalité la
              gestion de la relation avec les Utilisateurs, en ce incluant la
              réalisation d’opérations commerciales, de communication, de
              sollicitation et de marketing. Dans cette hypothèse, si le
              Responsable du club utilise à cette fin l’Application, 7NIGHT peut
              quant à elle, en qualité de sous-traitant, être amenée à procéder
              à des traitements de telles données ou y avoir accès pour le
              compte du Responsable du club. <br />
              Les rôles et responsabilités des parties dans ce cadre sont
              répartis comme suit.
            </p>
            <h3>
              6.2.2 a) Respect des principes applicables au traitement de
              données à caractère personnel
            </h3>
            <p>
              Le Responsable du club comprend qu’il donne ses instructions
              relatives à l’exécution du présent Contrat, et notamment aux
              traitements de données à caractère personnel devant être mis en
              œuvre dans ce cadre par 7NIGHT agissant en tant que sous-traitant
              par le biais de son utilisation des différentes fonctions de
              l’Application. <br />
              Il appartient en outre au Responsable du club, qui s’y engage, de
              s’assurer :
            </p>
            <ul>
              <li>
                {" "}
                du caractère licite, loyal et transparent de la collecte et du
                traitement des données à caractère personnel (notamment
                information des personnes concernées, voire recueil du
                consentement desdites personnes concernées lorsque celui-ci est
                requis en particulier en raison de la finalité ou des modalités
                du traitement ou encore des données collectées et traitées),
                conformément aux modalités visées dans les stipulations
                précitées qui diffèrent selon le mode de réservation ;
              </li>
              <li>
                que ces données ne sont traitées que pour les finalités prévues
                au présent Contrat, et qu’elles ne sont pas traitées pour des
                finalités ultérieures incompatibles avec ces finalités ;
              </li>
              <li>
                que les données à caractère personnel collectées et traitées
                dans le cadre de la gestion de la relation avec les Utilisateurs
                sont adéquates, pertinentes, non excessives, objectives et
                limitées à ce qui est nécessaire au regard des finalités
                poursuivies, et que la collecte de telles données n’est pas
                illicite. Notamment, le Responsable du club s’engage à ne pas
                collecter et/ou traiter de données d’ordre financier telles que
                des numéros de carte bancaire. Il s’engage de même à ne pas
                collecter et/ou traiter, sauf en cas de stricte nécessité pour
                les finalités poursuivies et après avoir recueilli le
                consentement préalable, exprès, spécifique et informé de la
                personne concernée, de données à caractère personnel nécessitant
                une protection particulière, telles que des opinions ou
                activités religieuses ou politiques, des données sur la santé,
                ou encore des données concernant la vie ou l’orientation
                sexuelle des personnes concernées ;
              </li>
              <li>
                de la qualité, de l’actualité, de la mise à jour et de
                l’exactitude de ces données ; le Responsable du club s’engage
                aux mises à jour, corrections, voire suppressions, des données à
                caractère personnel traitées dans l’Application
              </li>
              <li>
                {" "}
                que les données à caractère personnel ne sont conservées sous
                une forme permettant l'identification des personnes concernées
                que pendant une durée n'excédant pas celle nécessaire au regard
                des finalités visées dans le présent Contrat ;
              </li>
              <li>
                {" "}
                que les habilitations aux données à caractère personnel sont
                strictement limitées à des utilisateurs qui ont la nécessité
                d’en connaître, sur la base de la règle du moindre privilège.{" "}
              </li>
            </ul>
            <p>
              Le Responsable du club libère 7NIGHT de toute revendication
              émanant de personnes concernées dont les données sont traitées
              s’agissant des opérations liées à la gestion des Utilisateurs.{" "}
              <br />
              En sa qualité de responsable de traitement, le Responsable du club
              s’engage à mettre à disposition de 7NIGHT l’ensemble des
              informations et éléments nécessaires en vue du respect par 7NIGHT
              de ses propres obligations en matière de protection des données à
              caractère personnel.
            </p>
            <h3>6.2.2 b) Sécurité et confidentialité </h3>
            <p>
              Dans la limite des activités de 7NIGHT agissant en tant que
              soustraitant, les obligations de sécurité et de confidentialité de
              7NIGHT et du Responsable du club sont celles visées au paragraphe
              6.1.2 b).
            </p>
            <h3>6.2.2 c) Sous-traitance</h3>
            <p>
              Dans la limite des activités de 7NIGHT agissant en tant que
              soustraitant et dans le cadre du traitement de données à caractère
              personnel ayant pour finalité la gestion de la relation avec les
              Utilisateurs, en ce incluant la réalisation d’opérations
              commerciales, de communication, de sollicitation et de marketing,
              les droits et obligations de 7NIGHT et du Responsable du club sont
              ceux visés au paragraphe 6.1.2c)
            </p>
            <h3>6.2.2 d) Coopération </h3>
            <p>
              Dans la limite des activités de 7NIGHT agissant en tant que
              sous-traitant, les droits et obligations de 7NIGHT et du
              Responsable du club en matière de coopération sont ceux visés au
              paragraphe 6.1.2 d).
            </p>
            <h3>6.2.2 e) Audits </h3>
            <p>
              Dans la limite des activités de 7NIGHT agissant en tant que
              soustraitant, les droits et obligations de 7NIGHT et du
              Responsable du club en matière de vérifications, de contrôles et
              d’audits sont ceux visés au paragraphe 6.1.2 e).
            </p>
            <h3>ARTICLE 7 – CONDITIONS FINANCIERES – PAIEMENT</h3>
            <p>
              Les tarifs initiaux des abonnements et services fournis par 7NIGHT
              sont indiqués sur le bon de commande et/ou sur l’ordre d’insertion
              de promotions, le cas échéant. L’utilisation de l’Application
              donne lieu à facturation par 7NIGHT au Responsable du club selon
              le barème des prix en vigueur au jour de l’utilisation de
              l’Application. Cette facturation est hebdomadaire, bimensuelle,
              mensuelle ou trimestrielle en fonction des services fournis.{" "}
              <br />
              S’agissant des commissions par entrée, 7NIGHT déterminera le
              nombre d'entrées ouvrant droit à facturation pour la période
              concernée sur la base du nombre d'entrées réservés, diminué des
              annulations des Utilisateurs effectuées sur le Portail ou
              enregistrées dans l’Application par le Responsable du club
              agissant de bonne foi au cours de la période concernée, diminué
              également des défections de l’Utilisateur « no show » enregistrées
              dans l’Application par le Responsable du club, conformément aux
              conditions de l’Article 8 ci-dessous. <br />
              Le Responsable du club accepte de recevoir ses factures sous
              format électronique. <br />
              Ces factures sont payables à réception. En cas d’incident de
              paiement, les frais bancaires y afférent seront à la charge du
              Responsable du club. <br />
              Des pénalités de retard sont dues à défaut de règlement le jour
              suivant la date de paiement figurant sur la facture, et sans qu'un
              rappel soit nécessaire. Passée la date d'échéance, 7NIGHT
              contactera le Responsable du club pour recouvrer sa créance. En
              cas d'échec de la démarche de recouvrement amiable, une société de
              recouvrement interviendra. Toute somme non payée à sa date
              d’exigibilité entraînera pour le Responsable du club une pénalité
              de retard de trois (3) fois le taux d'intérêt légal de ladite date
              d’exigibilité TFM FR_FR 2021_1 jusqu’à la date effective du
              paiement ainsi que le paiement d’une indemnité forfaitaire de
              quarante (40) euros due au titre des frais de recouvrement. <br />
              7NIGHT se réserve le droit de facturer le Responsable du club à
              l’avance et de percevoir les paiements en avance avant de
              continuer à fournir les services de 7NIGHT. <br />
              Sous réserve d’un préavis de trente (30) jours, 7NIGHT pourra
              suspendre la fourniture de tout ou partie des services de 7NIGHT
              au Responsable du club jusqu’au complet paiement des sommes dues
              par le Responsable du club, ou résilier le présent Contrat.{" "}
              <b>
                En cas de retards de paiement répétés de la part du Responsable
                du club, la suspension ou la résiliation du Contrat se fera sans
                préavis.
              </b>
            </p>
            <h3>
              ARTICLE 8 – GESTION DES ANNULATIONS – FACTURATION RECTIFICATIVE{" "}
            </h3>
            <p>
              Pour les Responsable des clubs présents sur le Portail ou les
              Sites partenaires, dans le cas où une réservation prise sur le
              Portail ou les Sites partenaires est annulée par l’Utilisateur
              directement auprès du Responsable du club ou dans le cas d’une
              défection de l’Utilisateur « no show », le Responsable du club,
              agissant de bonne foi, informera 7NIGHT par le biais de
              l’Application, dès que possible et dans tous les cas, dans le
              délai imposé dans l’Application. <br />
              Sans préjudice de tout autre droit et recours dont 7NIGHT dispose
              par ailleurs, de manière générale ou en application de l’article 9
              cidessous, toute fausse déclaration de défection « no show »,
              d’annulation, ou toute fausse modification de la réservation, sera
              considérée comme un manquement à une obligation essentielle du
              Contrat et pourra entraîner, outre l’application des mesures
              décrites à l’article 9 cidessous, l’émission par 7NIGHT d’une
              facture rectificative au Responsable du club, qui s’engage à la
              payer promptement, portant sur les commissions par couvert dues au
              titre des réservations faussement déclarées annulées, affectées
              par une défection « no show » de l’Utilisateur ou modifiées.
            </p>
            <h3>ARTICLE 9 - EFFET – DUREE – SUSPENSION - RESILIATION </h3>
            <p>
              Le présent Contrat est conclu, soit, pour une durée minimale qui
              est indiquée dans le bon de commande puis renouvelé tacitement
              pour une durée indéterminée, soit, pour une durée indéterminée, si
              aucune durée minimale n’est précisée dans le bon de commande.{" "}
              <br />
              Sous réserve de la durée minimale indiquée dans le bon de
              commande, le Contrat est résiliable à tout moment par l’une ou
              l’autre partie sans indemnité, par lettre recommandée avec accusé
              de réception adressée avec un préavis d’au moins trente (30) jours
              , étant précisé que ce préavis ne peut expirer avant la fin de la
              durée minimale spécifiée dans le bon de commande (le cas échéant)
              et que si la résiliation intervient avant la fin de la durée
              minimale, l’abonnement pour la totalité de la durée minimale est
              dû. Si la résiliation intervient au cours d’un cycle de
              facturation, l’abonnement pour la totalité du cycle de facturation
              est dû. <br />
              Après l’expiration de la durée minimale, le Responsable du club
              pourra changer, s’il le souhaite, son offre d’abonnement à
              l’Application sans coût ni préavis. <br />
              En cas de passage d’une l’offre sans frais ou au prix moindre à
              une offre payante ou au prix plus élevé, les paiements dus par le
              Responsable du club au titre de l’abonnement seront calculés au
              prorata du nombre de jours restant du cycle de facturation en
              utilisant l’offre payante ou à prix plus élevé. <br />
              En cas de passage de l’offre payante ou au prix plus élevé à une
              offre sans frais ou au prix moindre, les paiements dus par le
              Responsable du club au titre de l’abonnement à l’offre payante ou
              au prix plus élevé sont dus pour l’ensemble du cycle de
              facturation. <br />
              Le Responsable du club reconnaît que les Utilisateurs peuvent
              réserver des tables dans le Club jusqu'à la date de résiliation du
              présent Contrat et le cas échéant, pour des dates de repas pouvant
              être postérieures à ladite date de résiliation du Contrat. <br />
              Par conséquent, sauf si ces réservations pour des dates de repas
              postérieures à la date de résiliation effective du Contrat sont
              annulées par 7NIGHT, il appartient au Responsable du club de
              conserver les détails de ces réservations et de les honorer.
              7NIGHT facturera le Responsable du club pour ces réservations, et
              le Responsable du club s’engage à payer ces factures, dans les
              conditions prévues au présent Contrat. <br />
              En cas de non-respect par le Responsable du club d’une ou de
              plusieurs dispositions importantes du présent Contrat ou si 7NIGHT
              suspecte que le Responsable du club adopte une conduite déloyale,
              de mauvaise foi, contraire à la loi ou a commis une fraude, 7NIGHT
              se réserve le droit de suspendre ou limiter son accès à
              l’Application ou à tout autre service proposé par 7NIGHT le temps
              nécessaire au respect par le Responsable du club de ses
              obligations. <br />
              Le déréférencement, le déclassement ou le fait que le Club ait été
              rendu non-réservable sur le Portail et les Sites Partenaires sera
              notifié au Responsable du club par 7NIGHT par l’envoi d’une simple
              lettre ou par email et, en cas d’absence d’atteinte des critères
              de publication (en particulier, note minimum, nombre minimum de
              réservations, etc.), 7NIGHT se réserve également le droit de
              déréférencer le Club, de le déclasser ou de le rendre
              non-réservable. <br />
              Outre son droit de déréférencement, de suspension ou de limitation
              d’accès à l’Application, en cas de non-respect par le Responsable
              du club des dispositions du présent Contrat, 7NIGHT peut mettre en
              demeure par écrit (éventuellement par courrier électronique) le
              Responsable du club de se conformer aux dispositions
              contractuelles concernées dans un délai de trente (30) jours. Si à
              l’expiration de ce délai, le Responsable du club ne s’est toujours
              pas conformé au présent Contrat, 7NIGHT pourra définitivement
              mettre fin au présent Contrat sans préavis ni indemnité par écrit
              (éventuellement par courrier électronique). <br />
              En cas de faute grave du Responsable du club (notamment diffusion
              de contenus illicites, non-paiement après plusieurs relances des
              frais d’abonnement ou des commissions dues, fraude, manquements
              graves auprès des Utilisateurs, etc.), 7NIGHT se réserve le droit
              de résilier sans préavis ni indemnité le présent Contrat par écrit
              (éventuellement par courrier électronique) et d’annuler les
              réservations en cours avec le Club concerné. <br />
              En toute hypothèse, les droits et obligations découlant des
              articles 4.2 et 6 du présent Titre 1 survivront à l’expiration du
              présent contrat. Toutes les sanctions, y compris la résiliation du
              Contrat, que 7NIGHT peut imposer au titre du présent article 9
              sont sans préjudice de tout autre droit et recours dont 7NIGHT
              dispose par ailleurs.
            </p>
            <h3>ARTICLE 10 – QUALITE</h3>
            <p>
              Le Responsable du club s’engage à offrir aux Utilisateurs
              réservant via le Portail ou les Sites Partenaires strictement le
              même choix, les mêmes quantités et les mêmes prestations et tarifs
              (avant réduction éventuelle) qu’à ses autres clients. Pour les
              Clubs présents sur le Portail ou les Sites Partenaires, 7NIGHT
              souhaite s’assurer de la conformité et de la qualité des
              prestations proposées par le Responsable du club avec les
              engagements du Responsable du club au titre du présent Contrat.{" "}
              <br />
              A cette fin, 7NIGHT (ou toute autre personne mandatée par elle)
              pourra tester anonymement les prestations du Responsable du club
              au cours de la validité du Contrat. Le Responsable du club sera
              informé du test à l’issue de celui-ci. En outre, le Portail
              propose une fonctionnalité grâce à laquelle les Utilisateurs
              peuvent poster des avis quant à la qualité des services fournis
              par un Club. Cette fonctionnalité est exclusivement réservée aux
              Utilisateurs. Aucun Responsable du club n’est donc autorisé à
              faire usage de cette fonctionnalité, directement ou par le biais
              d’un tiers, pour poster un avis sur son Club ou celui d’un autre
              Responsable du club. Seule 7NIGHT est en droit de modérer ces
              avis. Le Responsable du club dispose d’un droit de réponse quant
              aux avis qui concernent son Club. Il est interdit au Responsable
              du club d’exercer toute forme de pression à l’égard d’un
              Utilisateur. Si le Responsable du club déroge au présent article
              7NIGHT se réserve le droit de limiter ou suspendre l’accès à
              l’Application, de déréférencer ou de rendre non-réservable le
              Club, ou de résilier le Contrat de plein droit sous réserve d’un
              préavis adressé au Responsable du club d’au moins trente (30)
              jours, sauf en cas de dérogations répétées où la suspension ou
              résiliation se fera sans préavis. <br />
              Toutes les sanctions, y compris la résiliation du Contrat, que
              7NIGHT peut imposer au titre du présent article 10 sont sans
              préjudice de tout autre droit et recours dont 7NIGHT dispose par
              ailleurs.
            </p>
            <h3>ARTICLE 11 – RESPONSABILITE / GARANTIE </h3>
            <p>
              Dans la mesure permise par la loi, l’Application est fournie ‘en
              l’état’ et ‘telle que disponible’. Ni 7NIGHT, ni aucune société du
              Réseau 7NIGHT n’est en aucun cas responsable si, en cas
              d’indisponibilité liée (i) à des opérations de maintenance, (ii)
              au réseau Internet ou (iii) pour toute autre raison qui lui sont
              indépendantes, le Responsable du club ne peut accéder à
              l’Application, ou les Utilisateurs ne peuvent accéder au Portail
              ou aux Sites partenaires. En toute hypothèse, le montant des
              dommages-intérêts pouvant être réclamé à 7NIGHT ou à une société
              du Réseau 7NIGHT sera limité au montant de l’abonnement
              effectivement perçu par 7NIGHT (hors commissions sur réservation)
              au titre des douze (12) derniers mois d’exécution du présent
              Contrat avec le Responsable du club. <br />
              Le Responsable du club reconnaît que pour l’ensemble des contenus
              qu’il fournit au moyen de l’Application, le rôle de 7NIGHT se
              limite à celui d’un simple hébergeur. En outre, en sa qualité de
              simple hébergeur, la responsabilité de 7NIGHT ne peut pas être
              recherchée pour des actes découlant de sa prestation de mise en
              relation entre les Restaurateurs et les Utilisateurs. Enfin, dans
              le cadre de la fourniture d’un accès à l’Application, 7NIGHT agit
              en simple qualité de prestataire technique et ne pourra en aucun
              cas voir sa responsabilité engagée pour les actes d’usage ou
              d’exploitation de l’Application par le Responsable du club ou
              réalisé à partir des identifiants du Responsable du club. La
              responsabilité de 7NIGHT ne peut pas être engagée en ce qui
              concerne les contenus (notamment les avis des Utilisateurs)
              postés, stockés ou chargés par le Responsable du club ou par tout
              tiers ou en ce qui concerne toute perte ou dommage à ce titre. La
              responsabilité de 7NIGHT ne peut pas être engagée pour tout
              contenu incorrect, injurieux, diffamatoire, trompeur, obscène,
              pornographique ou grossier ou pour toute information ou omission
              trompeuse. En tant que fournisseur de support, la responsabilité
              de 7NIGHT ne peut pas être engagée pour toute déclaration ou
              contenu fournis par ses Utilisateurs sur un forum de discussion
              public, sur une page web personnelle ou sur toute autre support.
              Nonobstant le fait que 7NIGHT n’a pas d’obligation générale de
              surveillance des contenus postés et édités sur tout support,
              7NIGHT se réserve néanmoins le droit discrétionnaire de retirer,
              surveiller, traduire ou modifier tout contenu posté ou stocké sur
              le Portail, sur les Sites Partenaires ou dans l’Application, à
              tout moment et pour toute raison, ou de faire exécuter ces actions
              par un tiers au nom et pour son compte. <br />
              Le Responsable du club s’engage à utiliser l’Application dans le
              respect de la législation en vigueur, notamment en matière de
              droit de la consommation (notamment concernant la prohibition des
              pratiques commerciales trompeuse et des pratiques commerciales
              déloyales ou agressives), de droit des données personnelles, de
              droit de la propriété intellectuelle, de droit à l’image et de
              droit du commerce électronique. En conséquence, le Responsable du
              club s’engage à indemniser 7NIGHT et toute autre société du Réseau
              7NIGHT en cas de réclamation, action ou demande d’indemnisation
              d’un Utilisateur, d’un tiers ou d’une autorité publique découlant
              de ou en relation avec l’utilisation par le Responsable du club de
              l’Application, d’information, de contenu, d’un acte ou d’une
              omission du Responsable du club en violation des dispositions du
              présent Contrat ou des dispositions législatives en vigueur.
            </p>
            <h3>ARTICLE 12 – UTILISATION DES MARQUES ET LOGOS 7NIGHT</h3>
            <p>
              Toutes utilisation par le Responsable du club des marques et des
              logos appartenant à 7NIGHT est soumise au consentement préalable
              écrit de 7NIGHT, sauf dans le cadre de la dérogation suivante :
              7NIGHT autorise le Responsable du club à utiliser, reproduire et
              diffuser les marques et/ou logos de 7NIGHT sur le site web du Club
              dans le strict cadre de l’exploitation du Module de Réservation en
              « marque grise » de l’Application. Une telle exploitation des
              marques et logos de 7NIGHT par le Responsable du club devra être
              effectuée conformément aux instructions, lignes directrices et/ou
              charte d’usage des marques et logos de 7NIGHT. Tous les autres
              droits de 7NIGHT relatifs à ses marques et logos sont réservés.
            </p>
            <h3>ARTICLE 13 – DIVERS </h3>
            <p>
              <b>Eléments de preuve </b> <br />
              Il est expressément convenu entre les parties que, les données
              conservées sur support électronique dans le système informatique
              de 7NIGHT ont force probante entre les parties. Dans l'hypothèse
              où elles seraient produites dans toute procédure contentieuse ou
              autre, elles seront considérées recevables, valables et opposables
              entre les parties et de la même manière, dans les mêmes conditions
              et avec la même force probante que tout document qui serait
              établi, reçu ou conservé par écrit, sauf erreur manifeste de
              7NIGHT. <br />
              <b>Relation </b> <br />
              Aucune relation de joint-venture, de partenariat, d'emploi ou
              d'agence n'existe entre le Responsable du club et 7NIGHT. <br />
              <b>Absence de cession </b> <br />
              Le Responsable du club ne peut céder ses droits dans le cadre du
              présent Contrat à un tiers que si 7NIGHT donne expressément son
              accord préalable par écrit. <br />
              <b>Modification du bon de commande </b> <br />A l’exception des
              changements de tarif qui pourront être mis à jour unilatéralement
              par 7NIGHT, les modifications apportées au bon de commande ne
              seront contractuelles que si elles sont acceptées par les deux
              parties. Si le Responsable du club n’accepte pas le changement de
              tarif, il devra dansles soixante (60) jours de la notification du
              changement de tarif mettre fin au présent Contrat, cesser de
              recevoir les services concernés par le changement ou changer son
              type d’abonnement à l’Application sans coût ni préavis par lettre
              recommandée avec avis de réception. <br />
              <b>Invalidité partielle </b> <br />
              Dans le cas où une des dispositions du présent Contrat serait ou
              deviendrait nulle, illégale, inopposable ou inapplicable d’une
              manière quelconque, la validité, la légalité ou l’application des
              autres dispositions du Contrat n’en seraient aucunement affectées
              ou altérées et la provision concernée sera considérée comme
              modifiée dans les limites nécessaires pour la rendre valide,
              conforme à la loi, opposable et/ou applicable. <br />
              <b>Confidentialité </b> <br />
              7NIGHT et le Responsable du club s'engagent à ne pas révéler et à
              exiger de ses agents et prestataires respectifs qu'ils ne révèlent
              pas les informations et le savoir transmis qui sont identifiés par
              la partie émettrice comme confidentiels ou qui, étant donnée la
              nature des circonstances de la divulgation, doivent être traités
              comme confidentiels. L'ensemble des modalités et conditions du
              présent Contrat sera considéré comme confidentiel et ne sera pas
              divulgué (sauf aux sociétés du Réseau 7NIGHT ainsi qu’aux avocats
              et aux comptables concernés des deux parties) sans l'accord écrit
              préalable de l'autre partie. <br />
              <b>Exécution et intégralité du Contrat </b> <br />
              Les parties reconnaissent et acceptent que la conclusion et la
              conservation du Contrat soit sous forme dématérialisée. Sauf
              indication contraire, le présent Contrat constitue l'entente
              complète entre les parties et remplace tout accord et/ou entente
              antérieurs entre les parties. <br />
              <b>Notifications </b> <br />
              Toutes les notifications adressées à 7NIGHT concernant une demande
              ou une affaire juridique doivent se faire par écrit à 7NIGHT, à
              l'attention de : ƒ <br />{" "}
              <b>Procédure de plainte et médiation </b> <br />
              Pendant toute la durée du présent Contrat, une procédure interne
              de traitement des plaintes sera mise en place. Si le Responsable
              du club souhaite soulever une plainte en rapport avec le présent
              Contrat, il doit en informer 7NIGHT par courrier électronique à
              l'adresse <a href="mailto:support@7NIGHT.fr">support@7NIGHT.fr</a>
              . A réception de la plainte, 7NIGHT ouvrira une enquête interne et
              fournira au Responsable du club une réponse et une décision dès
              que possible. Si le Responsable du club est établi dans l'Union
              européenne (un " Responsable du club de l’UE ") et estime que le
              litige n'est pas entièrement résolu par la procédure interne de
              plainte de 7NIGHT, alors, à la discrétion du Responsable du club
              de l’UE, le litige peut être soumis au Centre for Effective
              Dispute Resolution ("CEDR") pour médiation. Même si la médiation
              est un processus volontaire, 7NIGHT et le Responsable du club de
              l'UE s'accordent tous deux à participer de bonne foi à toute
              tentative de médiation, et à le faire conformément au modèle de
              procédure de médiation du CEDR. A moins que le Responsable du club
              de l’UE et 7NIGHT n'en décident autrement dans les quatorze (14)
              jours suivant la notification du litige, le médiateur sera nommé
              par le CEDR. Pour initier la médiation, le Responsable du club de
              l’UE doit informer 7NIGHT qu'il soumet le litige à la médiation
              par e-mail à{" "}
              <a href="mailto:support@7NIGHT.fr">support@7NIGHT.fr</a> (avec
              "Médiation initiée" dans l'objet). Une copie de cette demande doit
              être envoyée au CEDR. Toute tentative de parvenir à un accord via
              la médiation relativement au règlement d’un litige est sans
              préjudice au droit du Responsable du club de l'UE ou de 7NIGHT
              d'engager une procédure judiciaire à tout moment avant, pendant ou
              après le processus de médiation. <br />
              <b>Droit applicable et juridiction </b> <br />
              Le présent Contrat et l’utilisation du Portail et/ou de
              l’Application par le Responsable du club, ainsi que toute
              réclamation entre le Responsable du club et 7NIGHT sont régis, et
              doivent être interprétés selon le droit français, nonobstant les
              règles de conflits de loi applicables. En accédant ou en utilisant
              l’Application et/ou le Portail, le Responsable du club renonce à
              toute réclamation qui pourrait intervenir en vertu des lois
              d'autres pays ou territoires. En cas de litige, le Responsable du
              club doit porter toute réclamation, action ou contestation qu’il
              aurait à l'égard de 7NIGHT ou relative au présent Contrat
              exclusivement devant les tribunaux de Paris, France. Aucune
              disposition du présent Contrat ne limite le droit de 7NIGHT
              d'intenter une quelconque réclamation, action ou litige contre le
              Responsable du club afin de lutter contre la fraude, de recouvrer
              des factures impayées ou de protéger tout droit de propriété
              intellectuelle ou toute obligation de confidentialité en rapport
              avec le présent Contrat devant tout autre tribunal compétent.
            </p>
            <h2 style={{ color: "red" }} className="underline">
              Titre 2. CONDITIONS PARTICULIERES DE PUBLICATION DES PROMOTIONS
            </h2>
            <h3>ARTICLE 1 – OBJET</h3>
            <p>
              Les présentes Conditions particulières, complétant les Conditions
              Générales de Vente et d’Utilisation, s’appliquent à la fourniture
              par 7NIGHT au Responsable du club d'un service de publication des
              promotions sur le Portail et les Sites partenaires (ciaprès, les «
              Promotion(s) ») dont les modalités sont décrites dans le bon de
              commande, un ordre d’insertion ou par tout autre moyen, y compris
              par email, utilisé pour documenter l’accord concernant les
              Promotions entre le Responsable du club et 7NIGHT (ci-après, les «
              Conditions de(s) Promotion(s) »). En cas de contradiction entre
              les dispositions du présent Titre 2. Conditions particulières de
              publication de promotions et celles du Titre 1. Conditions
              générales de vente et d’utilisation, les dispositions du présent
              titre 2 prévalent.
            </p>
            <h3>
              ARTICLE 2 – OBLIGATIONS DE CONFORMITE, DE QUALITE, DE VALEUR ET
              D’EGALITE DE TRAITEMENT ENTRE LES UTILISATEURS
            </h3>
            <p>
              Les parties reconnaissent que la qualité des prestations de
              restauration du Responsable du club, la conformité de ces
              prestations à la gamme de Promotions de 7NIGHT et l’égale qualité
              de traitement des Utilisateurs bénéficiant d’une Promotion
              constituent des éléments déterminants dans le cadre du présent
              Contrat. En cas de qualité insatisfaisante des prestations du
              Responsable du club, en cas de prestations partielles ou non
              conformes au détail de la Promotion ou en cas de traitement non
              équitable des Utilisateurs bénéficiant de la Promotion (ex :
              annulation de réservations prises avec Promotion, quantités
              différentes, carte spéciale) selon l’appréciation discrétionnaire
              de 7NIGHT pourra procéder à la dépublication de la Promotion. En
              cas de manquements répétés aux présentes obligations de
              conformité, de qualité et d’égalité de traitement, 7NIGHT pourra
              procéder de plein droit et sans mise en demeure préalable à la
              dépublication du Club du Portail. <br />
              Dans tous les cas, le Responsable du club sera informé du motif de
              la dépublication par email. <br />
              Les Promotions doivent correspondre à celles stipulées sur le
              catalogue des promotions de 7NIGHT. Ces Promotions elles doivent
              avoir une valeur matérielle et tangible selon l’appréciation
              discrétionnaire de 7NIGHT se réserve également le droit de refuser
              ou d’annuler la Promotion en question si le Responsable du club
              n’utilise pas ou n’utilise pas correctement l’Application pour
              gérer les réservations, ou si le Responsable du club ne remplit
              pas ses obligations au titre du Titre 1 du Contrat, par exemple si
              le Responsable du club n’honore pas les réservations.
            </p>
            <h3>ARTICLE 3 – GARANTIE</h3>
            <p>
              Le Responsable du club reconnaît que le rôle de 7NIGHT se limite à
              celui d’une simple plateforme. En conséquence, le Responsable du
              club s’engage à respecter en toutes circonstances la législation
              applicable en vigueur et à garantir 7NIGHT et toute autre sociétés
              du Réseau 7NIGHT contre toute plainte, réclamation, action ou
              demande d’indemnisation d’un Utilisateur, d’un tiers ou d’une
              autorité publique portant sur une information, un contenu ou un
              acte du Responsable du club qui ne serait pas conforme au contenu
              de la Promotion, à une disposition légale ou qui causerait un
              préjudice à un Utilisateur ou à un tiers.
            </p>
            <h3>ARTICLE 4 – ADAPTATION </h3>
            <p>
              Le Responsable du club est informé du fait que 7NIGHT peut être
              amenée à modifier à tout moment sa stratégie commerciale aux fins
              de s’adapter à l’évolution du marché et au positionnement de ses
              concurrents. En conséquence, 7NIGHT se réserve le droit de
              solliciter une modification de toute Promotion du Responsable du
              club qui ne répondrait pas aux nouveaux critères édictés par
              7NIGHT. En cas de refus du Responsable du club d’adapter sa
              Promotion aux nouveaux critères de 7NIGHT sera autorisée à
              dépublier la Promotion du Responsable du club sans que ladite
              dépublication ne donne droit à aucune indemnité au bénéfice du
              Responsable du club. Le Responsable du club est informé que les
              modalités d’affichage de la Promotion sur le Portail peuvent
              varier en fonction de l’attractivité de la Promotion. Si 7NIGHT
              considère qu’une Promotion n’est plus attractive pour
              l’Utilisateur, 7NIGHT se réserve le droit de modifier ou
              déréférencer toute Promotion qui aurait perdu tout ou partie de
              son attractivité sous réserve d’un préavis d’au moins quinze (15)
              jours. 7NIGHT se réserve également le droit de refuser ou
              d’annuler une Promotion si le Responsable du club n’utilise pas ou
              n’utilise pas correctement l’Application pour gérer les
              réservations ou si le Responsable du club ne remplit pas ses
              obligations au titre du Titre 1 du Contrat, par exemple si le
              Responsable du club n’honore pas les réservations. Le Responsable
              du club demeure libre de modifier à tout moment la Promotion
              publiée pour une durée indéterminée.
            </p>
            <h3>ARTICLE 5 – DUREE DE LA PROMOTION</h3>
            <p>
              Le Responsable du club peut choisir de publier la Promotion
              pendant une durée déterminée ou indéterminée. La durée minimale de
              la Promotion est de trente-deux (32) jours consécutifs ou, dans le
              cas d’opérations spéciales, festivals, etc., la durée minimale
              s’appliquant à l’opération spéciale telle que communiquée au
              Responsable du club par 7NIGHT. Dans le cas où la Promotion est
              publiée pour une durée indéterminée, chacune des parties est libre
              de mettre fin à la Promotion à tout moment après l’expiration de
              la durée minimale par écrit (y compris par email), en respectant
              un préavis de sept (7) jours. Dans le cas où la Promotion est
              publiée pour une durée déterminée, le Responsable du club peut
              décider de la renouveler.
            </p>
            <h3>ARTICLE 6 – DIVERS</h3>
            <p>
              Le Responsable du club est également tenu au respect des
              conditions suivantes :
            </p>
            <ul>
              <li>
                Ne pas augmenter les tarifs avant ou pendant la durée de la
                Promotion.
                <ul>
                  <li>
                    Appliquer la Promotion à l’Utilisateur qui se présente à son
                    Club à l’heure de la réservation ou au plus tard 15 minutes
                    après, dans la mesure où cet Utilisateur respecte par
                    ailleurs les Conditions de la Promotion. Cependant, en cas
                    de réservation avec Promotion d’un groupe d’Utilisateurs, le
                    Responsable du club s’engage à appliquer la Promotion aux
                    Utilisateurs de ce groupe qui respectent les Conditions de
                    la Promotion, même si un ou plusieurs Utilisateurs de ce
                    groupe ne respectent pas les Conditions de la Promotion,
                    sous la stricte réserve que l’ensemble des Utilisateurs de
                    ce groupe soient arrivés au Club à l’heure de la réservation
                    ou au plus tard 15 minutes après. Si l’ensemble des
                    Utilisateurs du groupe n’est pas arrivé au-delà de 15
                    minutes après l’heure de la réservation, le Responsable du
                    club pourra ne pas appliquer la Promotion à tous les
                    Utilisateurs de ce groupe.
                  </li>
                </ul>
              </li>
              <li>
                En cas de non application de la Promotion pour quelque raison
                que ce soit quand l’Utilisateur respecte les Conditions de la
                Promotion, le Club s’engage à rembourser à l’Utilisateur (ou à
                7NIGHT si celle-ci prend en charge le remboursement à
                l’Utilisateur, dans ce cas le Responsable du club sera facturé
                en fin de mois) la différence entre le montant du repas payé par
                ce dernier et le montant du repas que celui-ci aurait dû payer
                en application des Conditions de la Promotion.{" "}
              </li>
              <li>
                Respecter l’ensemble des clauses des présentes Conditions
                Particulières tant que la Promotion est proposée sur le Portail
                et sur les Sites partenaires et également en cas de
                renouvellement de celle-ci.{" "}
              </li>
              <li>
                Sauf avis contraire, le Responsable du club est informé et
                accepte que 7NIGHT puisse diffuser la Promotion sur les Sites
                partenaires.
              </li>
            </ul>
            <h2 className="underline" style={{ color: "red" }}>
              Titre 3. CONDITIONS PARTICULIERES DU PACK SITE WEB
            </h2>
            <h3>ARTICLE 1 – OBJET</h3>
            <p>
              Les présentes Conditions Particulières, complétant les Conditions
              Générales de Vente et d’Utilisation, s’appliquent à la fourniture
              par 7NIGHT ou une société du Réseau 7NIGHT au Responsable du club
              d'un service de réalisation et d’hébergement de site internet
              (ci-après « Site Web ») pour les Clubs souhaitant disposer d’un
              site internet pour les besoins de leur activité professionnelle
              (ciaprès « Pack Site Web»). En cas de contradiction entre les
              dispositions du présent Titre 3. Conditions Particulières du Pack
              Site Web et celles du titre 1. Conditions Générales de Vente et
              d’Utilisation, les dispositions du présent Titre 3 prévalent.{" "}
              <br />
              Le Site Web est configuré en fonction des informations concernant
              le Club (notamment nom et adresse, tarifs, compositions, prix,
              moyens de paiement, horaires d’ouverture, disponibilités de
              réservation, services associés, …) fournies par le Responsable du
              club et disponibles sur l’Application (ci-après les « Contenus du
              Club »).
            </p>
            <p>
              Le Pack Site Web comprend les caractéristiques principales
              suivantes :{" "}
            </p>
            <ul>
              <li>
                Configuration du Site Web et intégration/synchronisation des
                Contenus du Club par 7NIGHT ;
              </li>
              <li>Affichage sur mobile, tablette et PC ;</li>
              <li>
                Outil d’édition permettant de personnaliser les différents
                éléments du Site Web comme par exemple le thème, les couleurs,
                le fond, la typographie, etc. ;
              </li>
              <li>
                Gestion multilingue des éléments de navigation du Site Web (dans
                la limite des langues disponibles);
              </li>
              <li>
                Intégration du Module de Réservation 7NIGHT sur le Site Web en
                marque grise par défaut ;
              </li>
              <li>Hébergement du Site Web ;</li>
              <li>Allocation d’un nom de domaine</li>
            </ul>
            <h3>ARTICLE 2 – CONDITIONS D’ELIBIBILITE AU PACK SITE WEB</h3>
            <p>
              Pour pouvoir bénéficier du Pack Site Web, le Responsable du club
              doit respecter l’ensemble des conditions suivantes :
            </p>
            <ul>
              <li>disposer d’un accès à l’Application ;</li>
              <li>
                respecter l’ensemble des obligations mises à sa charge par le
                Contrat ;
              </li>
              <li>disposer d’un accès Internet haut débit ;</li>
              <li>disposer d’un équipement informatique viable.</li>
            </ul>
            <p>
              Pour des raisons techniques, la création du Site Web et son
              hébergement sont indissociables. Ainsi, il n’est pas possible de
              créer un Site Web à partir du Pack Site Web pour héberger sur un
              autre serveur que celui de 7NIGHT ou d’héberger au moyen du Pack
              Site Web des sites internet créés autrement qu’à l’aide du Pack
              Site Web.{" "}
            </p>
            <h3>ARTICLE 3 – HEBERGEMENT DU SITE WEB</h3>
            <p>
              Le Site Web sera hébergé sur les serveurs de fournisseurs de
              7NIGHT s’efforcera d’exécuter le service Pack Site Web. Le
              Responsable du club reconnaît que l’accès au serveur peut être
              interrompu par 7NIGHT afin d’assurer la maintenance des matériels
              et logiciels nécessaires à l’hébergement du Site Web. <br />
              L’hébergement du Site Web est un hébergement « mutualisé »,
              c’està-dire que les sites internet de plusieurs restaurateurs sont
              hébergés sur le même serveur. En conséquence, tout non-respect des
              conditions applicables pourra causer un préjudice non seulement à
              7NIGHT mais également aux autres restaurateurs dont les sites web
              sont hébergés sur le même serveur ou sur d’autres serveurs de
              7NIGHT et/ou de ses fournisseurs.
            </p>
            <h3>ARTICLE 4 – ACCESSIBILITE DU SITE WEB ET NOM DE DOMAINE </h3>
            <p>
              Le Site Web est accessible à partir d’une adresse du type «
              restaurantname.7NIGHT.rest », « restaurantname.lafourchette.rest »
              ou « restaurantname.eltenedor.rest » (ci-après « Nom de domaine
              7NIGHT »). Le Nom de domaine 7NIGHT est enregistré au nom de
              7NIGHT et appartient à 7NIGHT. Le Site Web peut également être
              accessible à partir d’un nom de domaine dont le Responsable du
              club est titulaire (ci-après « Option Nom de domaine Club »), dès
              lors que le Responsable du club a établi ou fait établir une
              redirection de ce nom de domaine vers le Site Web. Le cas échéant,
              lorsque le Club choisit l’Option Nom de domaine Club, il déclare
              que le nom de domaine sous lequel pourra être consulté le Site
              Web, ne contrevient ni aux droits de tiers ni à aucun
              réglementation applicable ou stipulation contractuelle. 7NIGHT se
              réserve le droit discrétionnaire de requérir du Club de ne plus
              rediriger son nom de domaine vers le Site Web.
            </p>
            <h3>Article 5 – CONDITIONS D’UTILISATION DU PACK SITE WEB</h3>
            <p>
              La création du Site Web nécessite la collaboration du Responsable
              du club. Le Responsable du club pourra modifier, éditer, ajouter
              et mettre à jour les Contenus du Club sur le Site Web via
              l’Application et personnaliser l’apparence du Site Web au moyen de
              l’outil d’édition. A cet égard, il est rappelé que le Responsable
              du club est responsable de conserver la confidentialité des
              identifiants lui permettant de se connecter à l’Application.{" "}
              <br />
              Le Responsable du club s’engage à fournir des Contenus du Club
              exacts, complets et à jour et reconnaît que 7NIGHT ne réalise
              aucune vérification des Contenus du Club dans le cadre du Pack
              Site Web au regard notamment des droits de tiers et l’exactitude
              du contenu. <br />
              Le Responsable du club a la qualité d’éditeur du Site Web et
              assume dès lors toute responsabilité et conséquence résultant de
              cette qualité. A ce titre, le Responsable du club doit faire
              apparaître le nom de son directeur de publication, complétera et
              tiendra à jour les mentions légales du Site Web afin de répondre à
              l’obligation d’identification qui incombe au Responsable du club
              en qualité d’éditeur du Site Web. <br />
              Dans le cas où le Responsable du club opte pour l’Option Nom de
              domaine Club, le Responsable du club déclare et s’engage à détenir
              les droits nécessaires pour utiliser le nom de domaine sous lequel
              le Site Web est accessible et à ce que cette utilisation ne
              contrevienne pas aux droits de tiers, à la législation ou
              réglementation applicable, ou aux obligations du Responsable du
              club à l’égard des intermédiaires, registres et/ou autorités de
              tutelle. <br />
              Le Responsable du club s’engage à veiller à ne pas utiliser le
              Pack Site Web de manière illicite ou déloyale et à respecter les
              obligations légales, réglementaires ou contractuelles applicables
              à ses propres services et ses activités. A ce titre, il appartient
              au Responsable du club d’adapter, de modifier et de compléter les
              mentions légales et conditions d’utilisation types du Site Web qui
              ne sont fournies par 7NIGHT qu’à titre purement indicatif. Les
              informations contenues dans les mentions légales et conditions
              d’utilisation types du Site Web sont d'ordre général et ne peuvent
              pas remplacer le conseil d’un professionnel du droit. 7NIGHT ne
              fournit en aucune manière des prestations de conseil juridique et
              décline toute responsabilité au titre de l’utilisation des
              mentions légales et conditions d’utilisation types du Site Web.{" "}
              <br />
              De même, le Responsable du club s’interdit de s’introduire dans
              les systèmes de 7NIGHT et/ou de ses fournisseurs ou dans les
              systèmes ou sites internet d’autres restaurateurs.
            </p>
            <h3>ARTICLE 6 - PRIX </h3>
            <p>
              Le Pack Site Web est gratuit pour le Responsable du club ayant
              souscrit à l’Application. Des tarifs pourront éventuellement être
              applicables sous réserve d’un préavis d’au moins quinze (15) jours
              au Responsable du club, notamment en fonction de l'évolution des
              services proposés, l'évolution du réseau, de la technique et/ou
              des contraintes légales.{" "}
            </p>
            <h3>ARTICLE 7 - PROPRIETE INTELLECTUELLE </h3>
            <p>
              7NIGHT est et demeure titulaire de tous les droits de propriété
              intellectuelle attachés aux éléments du Site Web qui sont mis à
              disposition du Responsable du club dans le cadre du Pack Site Web,
              en ce compris notamment l’architecture, les éléments graphiques,
              les logiciels créés ou utilisés, les textes, sons, images, photos,
              vidéos, dessins, marques, logos (ci-après les « Eléments du Site
              Web »). 7NIGHT concède au Responsable du club une licence
              non-exclusive et personnelle d’utilisation des Eléments du Site
              Web, ainsi que du Nom de domaine 7NIGHT (sauf en cas d’Option Nom
              de domaine Club), pour la durée des présentes Conditions
              Particulières, pour le monde entier et pour les seuls les besoins
              de l’utilisation du Pack Site Web et dans la limite des termes et
              conditions définis au présent Titre 3. Conditions Particulières du
              Pack Site Web. <br />
              Le Responsable du club reconnaît qu’il n’acquiert aucun droit de
              propriété intellectuelle sur les Eléments du Site Web ni sur le
              Nom de domaine 7NIGHT mis à sa disposition par 7NIGHT dans le
              cadre de l’exécution du Pack Site Web. <br />
              Le Responsable du club est et demeure seul propriétaire des
              Contenus du Club. Pour l’exécution du Pack Site Web, le
              Responsable du club concède à 7NIGHT, à titre non exclusif et dans
              les conditions définies ci-après, les droits d’exploitation
              afférents aux Contenus du Club en vue de représenter publiquement,
              rendre accessible, diffuser ou communiquer sur le Site Web les
              Contenus du Club pour le monde entier, à compter de l'entrée en
              vigueur du présent Contrat pour la durée du Contrat. <br />
              Les droits concédés comprennent :
            </p>
            <ul>
              <li>
                Le droit de représenter et reproduire et de fixer les Contenus
                du Club en tout ou partie, indépendamment ou ensemble, par tous
                procédés techniques et notamment par numérisation et mise en
                mémoire informatique sur le Site Web ;
              </li>
              <li>
                le droit de communication au public et de mise à disposition du
                public des Contenus du Club, en ce compris,{" "}
                <ul>
                  <li>
                    Le droit de moduler, compresser, décompresser tout ou partie
                    des Contenus du Club ;
                  </li>
                  <li>
                    Le droit d’adapter, arranger, intégrer, traduire les
                    Contenus du Club dans la mesure nécessaire aux contraintes
                    ergonomiques, techniques et graphiques nécessaires à leur
                    diffusion sur le Site Web.{" "}
                  </li>
                </ul>
              </li>
            </ul>
            <h3>ARTICLE 8 – DUREE ET RESILIATION </h3>
            <p>
              Les présentes Conditions Particulières entreront en vigueur à la
              date de mise en service du Pack Site Web pour une durée
              indéterminée. Chaque Partie pourra à tout moment résilier les
              présentes Conditions Particulières par écrit, en respectant un
              préavis de trente (30) jours. Les Parties conviennent que la
              résiliation des Conditions Générales de Vente et d’Utilisation
              pour quelque raison que ce soit, entraîne automatiquement la
              résiliation des présentes Conditions Particulières. La résiliation
              des présentes Conditions Particulières, pour quelque raison que ce
              soit, entraîne la cessation par 7NIGHT de tous les services
              fournis dans le cadre du Pack Site Web tels que décrits dans les
              présentes Conditions Particulières.
            </p>
            <h3>ARTICLE 9 – RESPONSABILITE – GARANTIES </h3>
            <p>
              La responsabilité de 7NIGHT ou de toute société du Réseau 7NIGHT
              ne saurait être engagée si son serveur est indisponible pour des
              raisons de force majeure, la défaillance de longue durée du réseau
              public de distribution d’électricité, la défaillance du réseau
              public des télécommunications ou la perte de connectivité Internet
              dues aux opérateurs publics et privés dont dépend 7NIGHT. Le
              Responsable du club accepte et reconnaît qu’il est éditeur du Site
              Web au sens de la Loi n°2004-575 du 21 juin 2004 pour la confiance
              dans l’économie numérique. A ce titre, le Responsable du club
              assume seul la responsabilité éditoriale du Site Web. Le
              Responsable du club demeure seul et personnellement responsable de
              l’utilisation, l’édition, l’exploitation, la diffusion des
              Contenus du Club, et de son nom de domaine (en cas d’Option Nom de
              Domaine Club), ainsi que de la bonne exécution des obligations
              mises à sa charge dans le cadre des présentes Conditions
              Particulières et sera responsable à ce titre de ses actes,
              déclarations, omissions, obligations, défaillances. En
              particulier, le Responsable du club déclare et garantit que
              l’ensemble des Contenus du Clubne:
            </p>
            <ul>
              <li>viole aucune loi ou règlement applicable ;</li>
              <li>
                n’est en aucun cas constitutif de contrefaçon de droit d’auteur,
                brevet, marque, secret de fabrique, dessins et modèle, base de
                données ou d’aucun autre droit de propriété intellectuelle
                appartenant à un tiers (enregistré ou non) ;
              </li>
              <li>
                ne viole ou n’est pas susceptible de violer aucun droit de
                tiers, ou aucune obligation à l’égard d’un tiers, en ce compris,
                notamment la réglementation en matière de publicité ;
              </li>
              <li>
                n’est pas de nature trompeuse ou propre à induire en erreur ;
              </li>
              <li>
                n’est pas de nature diffamatoire, injurieuse ou calomnieuse ;{" "}
              </li>
              <li>
                respecte les réglementations en vigueur et toutes autres
                réglementations applicables en matière de protection des données
                personnelles ;
              </li>
              <li>
                n’est pas illicite, notamment, contraire à l’ordre public, ayant
                un caractère incitant à la haine ou portant atteinte à la
                dignité humaine, diffamant, menaçant, discriminatoire, violent,
                etc.
              </li>
            </ul>
            <p>
              Il est convenu que 7NIGHT ou toute société du Réseau 7NIGHT ne
              pourra, en aucun cas, être tenue responsable des conséquences
              liées à l'exploitation et à la diffusion par le Site Web de
              l'intégration au sein dudit Site Web des Contenus du Club lui
              ayant été communiquées par le Responsable du club. Le Responsable
              du club garantit 7NIGHT et toute société du Réseau 7NIGHT et les
              indemnisera des éventuelles conséquences (y compris notamment, les
              frais d’avocat, d’expertise et autres frais irrépétibles et
              dépens) résultant directement ou indirectement à toute action,
              réclamation, revendication, éviction, quelle qu’en soit la nature
              notamment contractuelle, délictuelle ou quasidélictuelle, engagées
              par les tiers contre 7NIGHT ou toute société du Réseau 7NIGHT du
              fait de l’utilisation du Site Web par le Responsable du club et
              notamment du fait de son nom de domaine (en cas d’Option Nom de
              domaine Club) et/ou des Contenus du Club. <br />
              Il est entendu que le Responsable du club est seul responsable du
              référencement et de l’indexation du Site Web. 7NIGHT s’est limitée
              à encoder le Site Web conformément aux bonnes pratiques de codage
              permettant l’indexation du contenu du Site Web sur les moteurs de
              recherche. 7NIGHT ne procède pas ni ne garantit le référencement
              ou l’indexation du Site Web. <br />
              Il est précisé que, sauf dans la mesure ou 7NIGHT ou toute société
              du Réseau 7NIGHT ont causé ou contribué au dommage, préjudice ou
              perte, celles-ci ne sont pas responsable, ni à l’égard du
              Responsable du club, ni à l’égard des tiers, desdits dommages,
              préjudices et pertes pouvant survenir en raison de/du :
            </p>
            <ul>
              <li>dysfonctionnement du réseau internet ;</li>
              <li>
                l’utilisation par le Responsable du club de matériel
                informatique non adapté, défectueux ou non à jour ;
              </li>
              <li>
                la défaillance des systèmes de transmission entre le Responsable
                du club et les services 7NIGHT ;
              </li>
              <li>
                la panne du serveur, en raison de l’impossibilité de garantir
                l’absence de défaut ou de panne inhérente aux logiciels utilisés
                pour administrer le serveur ;
              </li>
              <li>
                I’utilisation du Site Web non conforme à la réglementation
                applicable, en violation des droits de tiers ou contraires aux
                dispositions du présent Contrat ;
              </li>
              <li>
                blocage de l’accès au Pack Site Web en raison de (i) la présence
                d’un élément illicite ou signalé comme illicite par notification
                en application des dispositions de la loi pour la confiance dans
                l’économie numérique du 21 juin 2004; ou (ii)toute action d’un
                organisme de contrôle, d’une agence gouvernementale ou de la
                FOURCHETTE ou d’une société du Réseau 7NIGHT suite à une
                obligation imposée ou permise par la loi applicable;
              </li>
              <li>
                non-respect par le Club des réglementations en vigueur et toutes
                autres réglementations applicables en matière de protection des
                données personnelles ;
              </li>
              <li>
                l’utilisation abusive des identifiants permettant d’accéder à
                l’Application ;
              </li>
              <li>
                la non-conformité, l’inexactitude, le caractère incomplet des
                mentions légales et conditions d’utilisation types du Site Web ;
              </li>
              <li>
                l’utilisation des mentions légales et conditions d’utilisation
                types du Site Web ;
              </li>
              <li>
                l’utilisation du nom de domaine du Club dans le cadre de
                l’Option Nom de domaine Club ;
              </li>
              <li>
                la contamination du Site Web par un virus, l’intrusion ou la
                malveillance du fait de tiers ou du Responsable du club sur le
                Site Web, le site d’un tiers ou le serveur de 7NIGHT ;
              </li>
              <li>
                tout événement échappant au contrôle raisonnable de 7NIGHT ;{" "}
              </li>
              <li>
                non-respect du Contrat par le Responsable du club, son personnel
                ou ses sous-traitants.
              </li>
            </ul>
            <h2 className="underline" style={{ color: "red" }}>
              Titre 4. CONDITIONS PARTICULIERES DE LOCATION DU MATERIEL
            </h2>
            <h3>ARTICLE 1 – OBJET</h3>
            <p>
              Les présentes Conditions Particulières, complétant les Conditions
              Générales de Vente et d’Utilisation, s’appliquent à la location
              par 7NIGHT au Responsable du club du matériel dont la désignation
              est précisée sur le bon de livraison ainsi qu’à ses accessoires
              éventuels (ci-après, le « Matériel »). En cas de contradiction
              entre les dispositions du présent Titre 4. Conditions
              Particulières de Location du Matériel et celles du Titre 1.
              Conditions Générales de Vente et d’Utilisation, les dispositions
              du présent Titre 4 prévalent.
            </p>
            <h3>ARTICLE 2 – LIVRAISON ET MISE A DISPOSITION DU MATERIEL </h3>
            <p>
              Le Matériel est réputé conforme à la réglementation en vigueur et
              délivré au Responsable du club en bon état de marche. Le
              Responsable du club s’engage à signer le bon de livraison qui lui
              sera soumis à la livraison du Matériel. Il appartient au
              Responsable du club de vérifier la conformité du Matériel. En cas
              de manque ou d’avaries apparentes à la livraison, le Responsable
              du club s’engage à (i) notifier toutes réserves auprès du livreur
              ; et (ii) les confirmer dans les vingt-quatre (24) heures suivant
              la réception du Matériel à 7NIGHT. La prise de possession du
              Matériel sans réserve transfère la garde juridique du Matériel au
              Responsable du club qui en assume la pleine responsabilité. Le
              Matériel reste cependant à tout moment la propriété de 7NIGHT.
            </p>
            <h3>ARTICLE 3 – CONDITIONS D’UTILISATION</h3>
            <p>
              Le Matériel est destiné exclusivement à l’utilisation de
              l’Application dans la salle de Club du Responsable du club. Le
              Matériel doit rester dans la salle de Club du Responsable du club.
              Toute utilisation différente du Matériel est prohibée. Le
              Responsable du club s’engage à ne pas installer de logiciel
              supplémentaire sur le Matériel. Le Responsable du club doit
              confier le Matériel à un personnel qualifié et le maintenir
              constamment en bon état de marche. Le Responsable du club s’engage
              à prendre soin du Matériel. La location étant conclue « intuitu
              personae » avec le Responsable du club, il est interdit à ce
              dernier de sous-louer et/ou de prêter le matériel sans l’accord
              préalable écrit de 7NIGHT. En cas de non-respect des obligations
              détaillées dans le présent article constaté par 7NIGHT réserve le
              droit de réclamer la restitution immédiate du Matériel dans les
              conditions prévues à l’article 7 du présent Titre 4.
            </p>
            <h3>ARTICLE 4 – CONDITIONS FINANCIERES </h3>
            <p>
              Les tarifs de location de Matériel sont indiqués sur le bon de
              commande. 7NIGHT est susceptible d’appliquer au Responsable du
              club un dépôt de garantie et le Responsable du club accepte, en ce
              cas, de payer ce dépôt de garantie. Ce dépôt de garantie sera
              restitué au Responsable du club à l’expiration de son Contrat avec
              7NIGHT, déduction faite des coûts de réparation ou de remplacement
              éventuels.{" "}
            </p>
            <h3>ARTICLE 5 – DUREE </h3>
            <p>
              Sauf dispositions contraires dans le bon de commande qui peut
              imposer une durée minimale plus longue, la location de Matériel
              est conclue pour une durée minimale de trois (3) mois, qui prend
              effet à compter de la date de signature du Contrat, et il sera
              reconduit automatiquement pour des périodes de trois (3) mois.
              Chaque partie peut résilier la location du Matériel avec un
              préavis d’au moins trois (3) mois. En cas de résiliation du
              Contrat, tout trimestre entamé est dû et si la résiliation
              intervient avant la fin de la durée initiale le prix de
              l’abonnement pour la totalité de la durée minimale est dû.
            </p>
            <h3>ARTICLE 6 – RESPONSABILITES – ASSURANCES</h3>
            <p>
              7NIGHT déclare transférer au Responsable du club la garde
              juridique et matérielle du Matériel loué pendant la durée de la
              location du Matériel. Sauf dans la mesure où 7NIGHT a causé ou
              contribué à l’arrêt ou la panne du Matériel, celle-ci ne peut pas
              être tenu responsable à l’égard des tiers des conséquences
              matérielles ou immatérielles d’un arrêt ou d’une panne du
              Matériel. Le Responsable du club ne peut employer le Matériel pour
              un autre usage que celui auquel il est normalement destiné, ni
              l’utiliser dans des conditions différentes de celles de l’Article
              3 du présent Titre 4 ou encore enfreindre les règles de sécurité
              fixées tant par la législation que par le constructeur et/ou
              7NIGHT. <br />
              Le Responsable du club est responsable des dommages causés aux
              tiers (responsabilité civile) par le Matériel loué pendant la
              durée de la location. Le Responsable du club est responsable des
              pertes ou dommages causés au Matériel (notamment bris, incendie,
              vol…) pendant la durée de la location. Le Responsable du club
              souscrit à cet effet, une assurance couvrant le Matériel.
            </p>
            <h3>ARTICLE 7 – RESTITUTION DU MATERIEL </h3>
            <p>
              Au plus tôt de la fin du Contrat (ou de l’expiration de la
              location du Matériel), le Responsable du club est tenu de rendre
              le Matériel en bon état à 7NIGHT compte tenu de l’usure normale
              inhérente à la durée de location. Cette restitution doit se faire
              dans un délai de quinze (15) jours suivant la date de fin du
              Contrat ou de la location du Matériel, en renvoyant le Matériel
              selon la procédure qui sera communiquée par 7NIGHT. A défaut de
              restitution du Matériel et/ou si 7NIGHT constate que le Matériel
              n’a pas été utilisé par le Responsable du club conformément à
              l’article 3 du présent Titre 4 ou si 7NIGHT constate que le
              Matériel est endommagé, des prestations de remise en état ou de
              remplacement du Matériel seront facturées au Responsable du club.
              En cas de non-restitution du Matériel, le Matériel manquant sera
              facturé à sa valeur neuve, selon le tarif en vigueur du Matériel à
              la date de la non-restitution. <br />
              En cas de retard dans la restitution du Matériel, le Responsable
              du club reste redevable des frais de location au prorata temporis
              du nombre de jours séparant la date supposée de restitution et la
              remise effective du Matériel à 7NIGHT. <br />
              En cas de mise en liquidation du Club, le Responsable du club est
              tenu de signaler immédiatement à tout mandataire agissant en
              qualité de liquidateur, que le Matériel n’entre pas dans le champ
              des actifs du Club et qu’il ne pourra donc faire l’objet d’une
              quelconque vente. Le Responsable du club s’engage donc à rendre le
              Matériel en bon état à 7NIGHT, compte tenu de l’usure normale
              inhérente à la durée de location dans les meilleurs délais et
              selon la procédure qui sera communiquée par 7NIGHT.
            </p>
            <h2 style={{ color: "red" }} className="underline">
              Titre 5. CONDITIONS PARTICULIERES D’AIDE A L’INSTALLATION, DE LA
              MAINTENANCE ET DE REPARATION DU MATERIEL
            </h2>
            <h3>ARTICLE 1 – OBJET</h3>
            <p>
              Les présentes Conditions Particulières, complétant les Conditions
              Générales de Vente et d’Utilisation, s’appliquent à la fourniture
              par 7NIGHT au Responsable du club d'un service d’aide à
              l’installation, de maintenance, de réparation et de remplacement
              du Matériel mis à sa disposition par 7NIGHT. En cas de
              contradiction entre les dispositions du présent Titre 5.
              Conditions Particulières d’Installation et de Maintenance du
              Matériel et celles du Titre 1. Conditions Générales de Vente et
              d’Utilisation, les dispositions du présent Titre 5 prévalent.
            </p>
            <h3>
              ARTICLE 2 – OBLIGATIONS DE 7NIGHT <br />
              ARTICLE 2.1 – Aide à l’Installation
            </h3>
            <p>
              Sur demande du Responsable du club, 7NIGHT ou son prestataire
              pourra aider à installer le Matériel dans le Club (ci-après, les «
              Prestations d’aide à l’Installation ») en prodiguant à distance
              des conseils d’installation. 7NIGHT ou son prestataire s’efforcera
              de conseiller au mieux le Responsable du club quant au lieu et aux
              modalités d’installation du Matériel.
            </p>
            <h3>ARTICLE 2.2 – Maintenance</h3>
            <p>
              7NIGHT ou son prestataire s’efforcera de mettre en œuvre les
              moyens nécessaires pour effectuer, sur demande du Responsable du
              club, des opérations de maintenance du Matériel (ci-après, les «
              Prestations de maintenance »). <br />
              Les Prestations de maintenance comprennent la vérification, les
              réglages et mises au point du Matériel. Elles seront réalisées à
              distance, par téléphone et prise en main à distance du Matériel.
              7NIGHT ou son prestataire déterminera la meilleure option
              d’intervention.
            </p>
            <h3>ARTICLE 2.3 – Réparation et remplacement </h3>
            <p>
              Sur demande du Responsable du club, 7NIGHT ou son prestataire
              s’efforcera de réparer toute panne ou anomalie de fonctionnement
              affectant le Matériel. Avant toute intervention, 7NIGHT réalisera
              un diagnostic par téléphone selon les informations données par le
              Responsable du club. <br />
              7NIGHT ou son prestataire s’efforcera de remplacer toutes pièces
              et composants endommagés ou défectueux à l'origine de la panne ou
              de l'anomalie de fonctionnement et à remplacer, dans la limite de
              ses stocks disponibles, le Matériel dont l'état normal d'usure ne
              permet plus d'en assurer le bon fonctionnement. <br />
              Ceci ne s’appliquera pas si la panne est dû à une mauvaise
              utilisation, négligence ou à une utilisation non conforme du
              matériel, tel que décrits notamment au Titre 4. Conditions
              Particulières de Location du Matériel et à l’article 5 du présent
              Titre. <br />
              En cas de nécessité de remplacement ou en cas de maintenance
              lourde, 7NIGHT pourra exiger du Responsable du club de réexpédier
              le Matériel selon la procédure communiquée par 7NIGHT.
            </p>
            <h3>
              ARTICLE 3 – OBLIGATIONS DU Responsable du club <br />
              ARTICLE 3.1 – Collaboration{" "}
            </h3>
            <p>
              Le Responsable du club est informé que les Prestations d’aide à
              l’Installation, les Prestations de maintenance et les prestations
              de réparation et de remplacement nécessitent une collaboration
              régulière entre lui-même et 7NIGHT ou son prestataire. <br />
              A ce titre, le Responsable du club s'engage à mettre à la
              disposition de 7NIGHT ou de son prestataire les moyens nécessaires
              à la bonne exécution de sa mission et, notamment un correspondant
              qui sera son interlocuteur unique et dont le remplaçant devra être
              connu à l'avance pour pallier toute défaillance ou absence du
              correspondant désigné. <br />
              Le Responsable du club s’engage à suivre les recommandations de
              7NIGHT ou de son prestataire, relatives au lieu de stockage et aux
              modalités d’installation du Matériel.
            </p>
            <h3>ARTICLE 3.2 – Accès au Matériel </h3>
            <p>
              Le Responsable du club se rendra disponible pour aider 7NIGHT ou
              son prestataire à effectuer les réparations à distance.
            </p>
            <h3>ARTICLE 3.3 – Devoir d’information</h3>
            <p>
              Le Responsable du club s’engage à informer 7NIGHT ou son
              prestataire de toute évolution portant sur le Matériel qui
              remettrait en cause les Prestations d’aide à l’Installation, les
              prestations de maintenance ou les prestations de réparation ou de
              remplacement.
            </p>
            <h3>ARTICLE 3.4 – Protection des données</h3>
            <p>
              Le Responsable du club devra avant toute intervention de 7NIGHT ou
              son prestataire et de manière régulière : (a) exécuter les
              procédures de sauvegarde des données et fichiers et, (b), exécuter
              les programmes destinés à éviter toute perte, destruction ou
              altération des données du Club.
            </p>
            <h3>ARTICLE 4 – CONTACT</h3>
            <p>
              Pour toute demande de Prestations d’aide à l’Installation, de
              Prestations de maintenance, réparation ou remplacement, le
              Responsable du club devra contacter 7NIGHT du lundi au dimanche de
              09h00 à 22h00 heure de Paris.
            </p>
            <h3>ARTICLE 5 – LIMITES D’INTERVENTION DE 7NIGHT</h3>
            <p>
              Les Prestations de maintenance ne pourront être réalisées par
              7NIGHT ou son prestataire dans les situations suivantes :
            </p>
            <ul>
              <li>
                Modification de la configuration du Matériel et/ou adjonction de
                logiciel ou progiciel non compatible avec le logiciel de base;{" "}
              </li>
              <li>
                Utilisation de consommables non compatibles avec le Matériel ;{" "}
              </li>
              <li>
                Utilisation d'un courant électrique inapproprié ou de lignes
                téléphoniques défectueuses ;
              </li>
              <li>
                Défaillance de la climatisation ou variation du courant
                électrique ;
              </li>
              <li>Non-respect par le Responsable du club du Contrat ; </li>
              <li>
                Accident, acte de vandalisme, faute intentionnelle ou non
                intentionnelle, dégât des eaux, incendie, acte de sabotage ou
                tout événement de force majeure.
              </li>
            </ul>
            <h3>ARTICLE 6 – CONDITIONS FINANCIERES</h3>
            <p>
              Au-delà de deux (2) réparations/sinistres par an, les réparations
              et remplacements nécessitant des coûts additionnels de main
              d’œuvre et /ou de pièces détachées seront facturés au Responsable
              du club en supplément des frais de location du Matériel, sur
              présentation par 7NIGHT ou son prestataire d’un devis si : le
              Matériel n’a pas été utilisé par le Responsable du club en
              conformité avec les conditions du présent Contrat ; ou si une
              faute du Responsable du club est constatée par 7NIGHT ou son
              prestataire (par exemple, chute du Matériel, substance renversée
              sur le Matériel, câble d’alimentation sectionné, virus
              informatique présent dans le Matériel à la suite de consultation
              de sites ou applications autres que 7NIGHT MANAGER, etc.).
            </p>
            <h3>ARTICLE 7 – RESPONSABILITE </h3>
            <p>
              7NIGHT s’efforcera de mettre en œuvre les moyens nécessaires pour
              l’exécution des Prestations de maintenance. <br />
              7NIGHT ne pourra en aucun cas être tenue pour responsable de tous
              retards, défaillances, inexécutions, dommages ou préjudices en cas
              de (i) non-respect par le Responsable du club d’obligations lui
              incombant, (ii) de défaillances de tiers quels qu’ils soient, ou
              (iii) des conséquences dommageables en découlant, notamment en
              raison de non-conformités, défauts, dysfonctionnements, anomalies
              ou vices de fabrication du Matériel. <br />
              7NIGHT ne pourra être tenu pour responsable de tous retards,
              défaillances, inexécutions, dommages ou préjudice à raison (i)
              d'un défaut d'installation, de paramétrage ou d'utilisation du
              Matériel par le Responsable du club, (ii) de l'intervention d'un
              tiers à la demande du Responsable du club sur le Matériel ou sur
              son système d'information, ou (iii) de l'adjonction sans l'accord
              préalable exprès de 7NIGHT, par le Responsable du club ou par un
              tiers d'un logiciel ou d'un nouveau matériel. La responsabilité de
              7NIGHT ne saurait être recherchée par le Responsable du club pour
              la destruction de ses données ou fichiers qu'il lui appartient de
              sauvegarder. <br />
              Pendant les Prestations d’aide à l’Installation, les Prestations
              de Maintenance, et le cas échéant les prestations de réparation,
              le Responsable du club demeure gardien du Matériel. <br />
              En tout état de cause, la responsabilité civile contractuelle de
              7NIGHT, à raison de tout dommage résultant de l’inexécution ou de
              la mauvaise exécution de ses obligations au titre des conditions
              particulières du présent Titre 5, est expressément limitée à un
              montant maximal global égal à 500 euros (cinq cent euros).
            </p>
            <h2 style={{ color: "red" }} className="underline">
              Titre 6 - CONDITIONS PARTICULIERES DU SERVICE D’EMPREINTES
              BANCAIRES
            </h2>
            <h3>
              IMPORTANT : LE PRESENT SERVICE EST EXCLUSIVEMENT RESERVE AUX CLUBS
              REPONDANT AUX CRITERES D’ELIGIBILITE CUMULATIFS SUIVANTS :{" "}
            </h3>
            <h3>Module de Réservation en « marque blanche » </h3>
            <ul>
              <li> Abonnement Pro + ;</li>
              <li>
                Responsable du club utilisant de manière effective l’outil de
                réservation 7NIGHT MANAGER (insertion, réception et validation
                des réservations via 7NIGHT MANAGER).
              </li>
            </ul>
            <h3>Module de Réservation en « marque grise » </h3>
            <ul>
              <li>Abonnement Pro + ;</li>
              <li>
                Responsable du club utilisant de manière effective l’outil de
                réservation 7NIGHT MANAGER (insertion, réception et validation
                des réservations via 7NIGHT MANAGER).
              </li>
            </ul>
            <p>
              7NIGHT se réserve le droit de modifier lesdits critères en
              fonction de l’évolution du service.
            </p>
            <h3>ARTICLE 1 - OBJET</h3>
            <p>
              Les présentes Conditions Particulières complètent les Conditions
              Générales de Vente et d’Utilisation et s’appliquent à la
              fourniture d’une solution de services d’empreintes bancaires
              (ci-après, la « Solution ») via le partenaire de 7NIGHT, ledit
              partenaire ayant la qualité de prestataire de services de paiement
              (ci-après le « Partenaire PSP »). <br />
              En cas de contradiction entre les dispositions du présent Titre 6.
              Conditions Particulières du Service d’Empreinte Bancaire et celles
              du Titre 1. Conditions Générales de Vente et d’Utilisation, les
              dispositions du présent Titre 6 prévalent.
            </p>
            <h3>ARTICLE 2 – DESCRIPTION DE LA SOLUTION Article</h3>
            <h3>Article 2.1 La Solution </h3>
            <p>
              La Solution est intégrée à l’Application et comprend des
              prestations en matière de :
            </p>
            <ul>
              <li>Contrôle de la fraude ;</li>
              <li>Empreinte bancaire ;</li>
              <li>Paiement ;</li>
              <li>Remboursement.</li>
            </ul>
            <p>
              La Solution aide le Responsable du club à optimiser l’allocation
              de ses tables et son système de réservation en accompagnant ce
              dernier d’un mécanisme de compensation financière en cas de
              non-présentation ou d’annulation tardive. Si, en l’absence
              d’annulation préalable ou dans le délai défini, l’Utilisateur ne
              se présente pas à la date et l’heure réservés, dans le temps
              imparti, le Responsable du club pourra alors, sous réverse
              d’approvisionnement de la carte, débiter l’Utilisateur du montant
              de l’empreinte bancaire décidé par le Responsable du club, sur la
              carte bancaire renseignée par l’Utilisateur lors de la
              réservation. <br />
              La Solution permet la confirmation d’une réservation uniquement
              après que le Partenaire PSP a reçu confirmation de l’existence de
              la carte bancaire utilisée, de sa validité et du score de fraude
              attribué par le Partenaire PSP à l’Utilisateur (voir article 4 du
              présent titre 6 ci-après). Le Partenaire PSP vérifie ensuite que
              la carte bancaire utilisée par l’Utilisateur est toujours valable
              à la date du repas choisie par l’Utilisateur. La confirmation de
              réservation est envoyée à l’Utilisateur par email. <br />
              La Solution prend en charge toute la chaîne de paiement, y compris
              les processus techniques (traitement du paiement et acquisition
              des sommes), de réconciliation et de règlement. <br />
              Les caractéristiques et options attachées à la Solution sont
              définies par 7NIGHT, en lien avec le Partenaire PSP. <br />
              Pour l’Utilisateur, la Solution est compatible desktop, mobile et
              tablette en langage Html et accessible, par le Responsable du club
              à partir de l’Application en version desktop.
            </p>
            <h3>Article 2.2 Le Partenaire PSP </h3>
            <p>
              Le Partenaire PSP de 7NIGHT prend en charge l’ensemble de la mise
              en œuvre de la Solution et constitue l’interlocuteur unique de
              7NIGHT pour gérer les services d’acheminement technique de
              paiement (« Processing ») et d’acquisition (« Acquiring »). En cas
              de problème technique rencontré par le Responsable du club, ce
              dernier devra s’adresser à 7NIGHT qui répercutera cette demande à
              son Partenaire PSP. Cette Solution à interlocuteur unique permet :
            </p>
            <ul>
              <li>
                Une réduction du nombre d’étapes dans le flux des transactions ;
              </li>
              <li>Une limitation du nombre de points d’échec ;</li>
              <li>Une optimisation des taux d’autorisation ;</li>
              <li>Une tarification compétitive ;</li>
              <li>Une optimisation du temps de disponibilité ;</li>
              <li>Une optimisation du taux de conversion.</li>
            </ul>
            <p>
              La Solution est conforme aux normes de sécurité et de stabilité
              PCI DSS niveau 1.
            </p>
            <h3>
              Article 2.3 Modalités applicables au service d’empreintes
              bancaires
            </h3>
            <p>
              Les empreintes bancaires sont directement effectuées par
              l’Utilisateur et par carte bancaire (Visa, Mastercard) lors de la
              réservation en ligne. Les cartes de paiement acceptées sont
              susceptibles d’évoluer, sur décision des entités émettrices
              desdits moyens de paiement. <br />
              Sauf disposition contraire entre 7NIGHT et le Responsable du club,
              le montant maximum prélevé par réservation en ligne est limité à
              300 Euros par couvert. <br />
              Le Responsable du club dispose de sept (7) jours à compter du jour
              du repas (le jour du repas étant inclus dans le décompte des sept
              (7) jours) pour effectuer une demande de prélèvement du montant de
              l’empreinte bancaire par le biais de l’Application. Toute demande
              de prélèvement effectuée hors délai par le Responsable du club
              sera rejetée. 7NIGHT décline toute responsabilité en cas de
              demande tardive de prélèvement effectuée par le Responsable du
              club ou en cas de carte non-approvisionnée. <br />
              Selon la banque émettrice de l’Utilisateur, le prélèvement sur le
              relevé de l’Utilisateur peut apparaître sous le nom du Club,
              ‘LaFourchette’ ou de « 7NIGHT ». <br />
              Le Responsable du club peut choisir de rembourser l’Utilisateur
              dans les trente (30) jours suivant sa demande de prélèvement.
              Passé ce délai, le montant total sera transféré au Responsable du
              club.
            </p>
            <h3>ARTICLE 3 – OBLIGATIONS DU Responsable du club</h3>
            <h3>Article 3.1 Obligations générales </h3>
            <p>
              L’utilisation de la Solution par le Responsable du club est
              exclusivement réservée à la gestion du service d’empreinte
              bancaire dans le cadre des réservations. <br />
              Le Responsable du club s’engage à fournir à 7NIGHT avant la
              fourniture de la Solution son K-bis et ses statuts, et dès
              l’adoption de la Solution, les informations « KYC » de
              connaissance du client (vigilance et surveillance des activités
              bancaires dans le cadre de la lutte contre le blanchiment
              d’argent). Le Responsable du club s’engage en outre à mettre à
              jour et transmettre ces informations sous deux (2) jours ouvrés à
              compter de leur date de modification. <br />
              Le Responsable du club s’engage à recourir au protocole sécurisé «
              https » pour toutes les pages de ses services en ligne intégrant
              la Solution. Le Responsable du club prend en charge la gestion de
              tout litige survenant avec l’Utilisateur (annulation carte, carte
              refusée, carte nonapprovisionnée…). 7NIGHT ne saurait être
              impliquée, d’une quelconque façon, dans la gestion d’un tel litige
              entre l’Utilisateur et le Responsable du club. En cas de litige
              entre le Responsable du club et un Utilisateur et/ou toute tierce
              partie (en ce inclus les autorités administratives) en lien avec
              la Solution et/ou les Conditions d’utilisation y afférentes, la
              responsabilité de 7NIGHT ne pourra en aucun cas être recherchée
              par le Responsable du club sauf en cas de faute prouvée de 7NIGHT.
              Dans ce dernier cas, les dispositions de l’article 11 du Titre 1.
              Conditions Générales de Vente et d’Utilisation s’appliqueront.
            </p>
            <h3>
              Article 3.2 Obligations spécifiques au Module de Réservation «
              marque blanche »{" "}
            </h3>
            <p>
              Dans le cadre de son activité de réservation, le Responsable du
              club s’engage à respecter l’ensemble de la réglementation
              applicable, et en particulier l’ensemble des obligations de
              transparence et de loyauté à l’égard des consommateurs (en ce
              inclus le respect des informations précontractuelles et le respect
              des règles de formalisation des contrats en ligne), la
              réglementation applicable au traitement des données personnelles,
              la réglementation applicable à l’utilisation des moyens de
              paiement ainsi que l’ensemble des réglementations applicables au
              respect du droit des tiers (Ex : propriété intellectuelle, vie
              privée, infractions au droit de la presse). 7NIGHT met à la
              disposition du Responsable du club un modèle de Conditions
              d’utilisation du service d’empreintes bancaires intégré à la
              Solution et dont certaines clauses sont paramétrables par le
              Responsable du club. Le Responsable du club reconnaît avoir pris
              connaissance des Conditions d’utilisation comprises dans la
              Solution et s’engage à en respecter les dispositions. 7NIGHT se
              réserve par ailleurs le droit de mettre à jour les Conditions
              d’utilisation intégrées à la Solution, afin d’y intégrer les
              évolutions législatives et réglementaires applicables. 7NIGHT
              informera le Responsable du club de ces modifications par email.
              Le Responsable du club est libre d’adapter les champs
              paramétrables du modèle de Conditions d’utilisation,
              l’exploitation dudit modèle étant en toute hypothèse placée sous
              le contrôle et la responsabilité exclusive du Responsable du club.
            </p>
            <h3>ARTICLE 4 – FRAUDE</h3>
            <p>
              Toutes les transactions effectuées via la Solution sont contrôlées
              par l’outil de contrôle des fraudes du Partenaire PSP. Au titre de
              cet examen, le Partenaire PSP peut être amené à bloquer la
              transaction. Le Partenaire PSP se réserve également le droit
              discrétionnaire d’annuler toute transaction frauduleuse ou qui
              implique une autre activité potentiellement criminelle, et ce,
              même si l’outil de contrôle des fraudes n’a pas bloqué la
              transaction. <br />
              Le Responsable du club est toutefois informé du fait que l’outil
              de contrôle des fraudes du Partenaire PSP ainsi que les procédures
              de sécurité additionnelles de sécurité mises en place par le
              Partenaire PSP et/ou 7NIGHT ne peuvent pas garantir une totale
              prévention contre les fraudes. <br />
              En cas de litige relatif à une transaction effectuée via la
              Solution impliquant une fraude, le Responsable du club est informé
              du fait que le titulaire de la carte peut, via une rétro
              facturation (‘Chargeback’), obtenir le remboursement auprès de sa
              banque des sommes frauduleusement versées dans les délais légaux
              impartis.
            </p>
            <h3>ARTICLE 5 – CONDITIONS FINANCIERES</h3>
            <p>
              Le Responsable du club est facturé mensuellement par 7NIGHT des
              frais attachés à la Solution. Les prix (par transaction,
              notifications ou par entrée) et frais de charge-back éventuels
              sont détaillés dans le bon de commande du Responsable du club.{" "}
              <br />
              En cas de contestation ou fraudes, les frais par transaction
              resteront dus à 7NIGHT par le Responsable du club et aucun
              remboursement des frais déjà payé ne sera accordé. Au regard du
              caractère multidevise de la Solution, des frais de change sont
              susceptibles d’être appliqués et refacturés au Responsable du club
              par 7NIGHT. <br />
              Ces conditions financières sont modifiables à tout moment par
              7NIGHT, notamment pour répercuter tout changement dans les prix
              pratiqués par le Partenaire PSP. Toute mise en place de nouvelles
              conditions financières fera l’objet d’une information préalable du
              Responsable du club par 7NIGHT. Ladite information préalable peut
              être effectuée par email et interviendra au moins trente (30)
              jours avant l’entrée en vigueur des nouvelles conditions
              financières.
            </p>
            <h3>ARTICLE 6 – ANNULATION D’UNE RESERVATION</h3>
            <p>
              Par défaut, il est expressément prévu entre les parties que
              l’Utilisateur est autorisé à annuler sa réservation sans frais
              jusqu’à vingt-quatre (24) heures avant la date du repas (heure
              locale de la ville du Club). Ce délai de vingt-quatre (24) heures
              peut être amené à évoluer dans le futur, en fonction d’un certain
              nombre de paramètres, mais ceci devra être discuté entre 7NIGHT et
              le Responsable du club, et surtout, l’Utilisateur devra clairement
              être informé des conditions de délai d’annulation sans frais, si
              différentes de vingt-quatre (24) heures. 7NIGHT ne saurait en
              aucun cas être responsable, de quelque façon que ce soit,
              directement ou indirectement, de toute annulation de réservation
              décidée par le Responsable du club ou de tout litige entre
              l’Utilisateur relatif à une annulation.
            </p>
            <h3>ARTICLE 7 – DONNEES A CARACTERE PERSONNEL </h3>
            <p>
              Les données bancaires fournies par l’Utilisateur sont stockées
              directement par le Partenaire PSP qui crypte lesdites données aux
              fins de traitement et les traite conformément à la réglementation
              applicable. Ni 7NIGHT ni le Responsable du club ne collectent ou
              ne conservent les données bancaires des Utilisateurs.
            </p>
            <h3>ARTICLE 8 – SUSPENSION / RESILIATION DE LA SOLUTION </h3>
            <h3>Article 8.1 Résiliation de la Solution </h3>
            <p>
              7NIGHT ou le Responsable du club peuvent résilier à tout moment la
              fourniture de la Solution au Responsable du club, sous réserve du
              respect d’un préavis d’au moins trente (30) jours. Ladite
              résiliation de la Solution ne mettra pas fin au Contrat conclu
              entre 7NIGHT et le Responsable du club. <br />
              7NIGHT se réserve le droit de suspendre la fourniture de la
              Solution en cas de taux de « charge-back » mensuel (taux de
              transactions contestées et remboursées par rapport au nombre total
              de transactions opérées pour le compte du Responsable du club)
              supérieur à 0,5% du volume total des transactions effectuées pour
              le Responsable du club. Ce taux de charge-back est modifiable à
              tout moment par 7NIGHT, afin de répercuter tout changement décidé
              par le Partenaire PSP. <br />
              En cas de cessation ou de suspension de la Solution, la Solution
              est désactivée et les réservations en ligne par les Utilisateurs
              deviennent impossibles à effectuer avec une empreinte bancaire.{" "}
              <br />
              Dans l’hypothèse où l’empreinte bancaire a été réalisée avant la
              cessation ou suspension de la Solution et lorsque la cessation ou
              la suspension de la Solution intervient avant la date de
              réservation, l’empreinte bancaire est annulée et 7NIGHT prévient
              l’Utilisateur de la cessation ou de la suspension de la Solution.{" "}
              <br />
              Dans l’hypothèse où l’empreinte bancaire a été réalisée avant la
              cessation de la Solution et lorsque la cessation de la Solution
              intervient après la date de réservation, l’empreinte bancaire
              reste effective.
            </p>
            <h3>Article 8.2 Fin du contrat avec le Partenaire PSP </h3>
            <p>
              En cas de cessation du contrat entre 7NIGHT et le Partenaire PSP,
              la Solution ne sera plus disponible pour le Responsable du club et
              7NIGHT ne sera redevable d’aucune obligation prévue aux présentes
              Conditions Particulières.
            </p>
            <h2 className="underline" style={{ color: "red" }}>
              Titre 8 – POLITIQUE D’ENVOI (MODULE CRM)
            </h2>
            <p>
              La lutte contre les notifications non désirés (spam) et les
              fraudes réunit l’ensemble des acteurs du secteur (opérateurs,
              prestataires de service en ligne, prestataires de service
              d’emailing et organisations antispam). Des lois de lutte contre le
              spam ont été mises en place dans certains pays et 7NIGHT a érigé
              ses propres règles pour protéger sa réputation et celle des clubs
              qui utilisent ses systèmes. Ces règles permettent à 7NIGHT
              d’identifier les « spammeurs » et contribuent à améliorer la
              délivrabilité et à construire sa réputation de société soucieuse
              des droits de ses utilisateurs.
            </p>
            <p>
              Les indicateurs identifiés ci-dessous sont un bon reflet du
              respect de la “qualité des envois” des communications des
              Restaurateurs. Le respect des règles ci-dessous permettra aux
              Restaurateurs de rester en deçà des seuils indiqués comme
              acceptables et d’éviter des sanctions, la suspension, voire, dans
              certains cas, la fermeture du compte « module CRM ». Toute action
              de ce type sera précédée d’un avertissement à moins que le risque
              ne soit jugé critique pour la sécurité de l'infrastructure de
              7NIGHT et/ou de ses sous-traitants.
            </p>
            <div className="tableImg">
              <img src={Tabel} alt="" />
            </div>
            <p className="italic">
              *7NIGHT se réserve le droit de mettre à jour sans préavis les
              paramètres des limites acceptables d’envois.{" "}
            </p>
            <h3>Définitions:</h3>
            <ul>
              <li>
                Désabonnements – Destinataires qui se désabonnent, car ils ne
                souhaitent plus recevoir de communication. De nombreuses raisons
                expliquent que les destinataires puissent vouloir se désabonner
                : certains ne sont plus intéressés par le contenu des emails,
                d’autres ont une boîte de réception surchargée d’emails
                différents, et d’autres encore attendaient un contenu différent
                de celui qui leur est adressé.
              </li>
              <li>
                Blocages – Notifications qui restent sur les serveurs à cause
                d’une erreur permanente. Il s’agit d’adresses Notifications qui
                ont renvoyé des Notifications auparavant car elles n’existent
                plus, ne sont plus valides ou qui ont signalé le message en tant
                que spam. Cela inclut également toutes les adresses
                Notifications d’où proviennent des plaintes de destinataires ou
                qui ont été détectées comme spam par les filtres en place.
              </li>
              <li>
                Plaintes pour Spam – Nombre de destinataires qui ont signalé
                l’email d’un Responsable du club comme spam.
              </li>
              <li>
                Détections de « Spamtrap » – Après une certaine période
                d’inactivité, les fournisseurs de messagerie utilisent les
                adresses email pour en faire des pièges à spam qui détectent les
                auteurs de mails utilisant des listes obsolètes ou achetées à
                des sociétés tierces.
              </li>
              <li>
                Plainte pour utilisation injustifiée – Lorsqu’un destinataire
                signale un Responsable du club comme lui ayant envoyé un message
                sans son consentement.
              </li>
            </ul>
            <h3>LES 7 COMMANDEMENTS</h3>
            <p>
              Voici les 7 commandements à respecter pour optimiser la
              délivrabilité des emails, éviter toute limitation du taux d’envoi
              d’emails ou la suspension du compte « module CRM » du Responsable
              du club et aider 7NIGHT à long terme dans la lutte contre les
              spams.
            </p>
            <h3>
              1 – TOUS LES INDICATEURS D’ENVOI DOIVENT RESTER SOUS LES SEUILS
              FIXÉS
            </h3>
            <p>
              L’entretien régulier de la liste de contacts gérée par le
              Responsable du club est l’assurance d’un échange avec un public
              intéressé et dont les adresses email sont actives. Bien que ce
              critère puisse varier selon les sociétés, un client est identifié
              comme intéressé lorsqu’il a ouvert ou cliqué sur un email dans les
              3 à 6 derniers mois. Entretenir une liste de clients qui lisent
              régulièrement les contenus et s’intéressent à aux messages réduira
              le nombre d’emails du Responsable du club marqués comme « bounce »
              ou bloqués. Même si la suppression d’adresses email inactives ou
              incorrectes raccourcira une liste de contacts, elle augmentera
              considérablement sa délivrabilité à long terme. D’autres consignes
              à garder en tête sont : donner à l’email un objet qui en reflète
              le contenu réel et éviter les termes promotionnels ou des mots
              associés aux spams.
            </p>
            <h3>
              2 – L’ACHAT OU L’ENVOI A DES LISTES DE CONTACTS TIERCES SONT
              INTERDITS
            </h3>
            <p>
              L’envoi d’emails à des listes de contacts achetées, échangées ou
              louées à des sociétés tierces est strictement interdit. Les
              adresses email utilisées doivent toujours être des adresses dont
              le titulaire a explicitement choisi de recevoir de communications
              de la part du Responsable du club. L’utilisation de listes
              achetées, louées ou récupérées auprès d’une société tierce est
              prohibée par la loi dans la plupart des pays, et est absolument
              interdite sur l’Application.
            </p>
            <h3>
              3 – LES EMAILS NE PEUVENT ÊTRE ENVOYÉS QU’À DES DESTINATAIRES
              AYANT EXPLICITEMENT AUTORISÉ LEUR RÉCEPTION (SAUF EMAILS
              TRANSACTIONNELS)
            </h3>
            <p>
              Tous les contacts de la liste du Responsable du club doivent avoir
              accepté de manière claire et explicite de recevoir les
              communications du Responsable du club et le Responsable du club
              doit être à tout moment en mesure d’en apporter la preuve. Cette
              permission doit être donnée via une relation préexistante ou un
              système d’opt-in qui décrit clairement le sujet de l’abonnement
              sur un formulaire en ligne (module de réservation) ou hors ligne
              contenant une case à cocher. Cette case ne doit pas être cochée
              par défaut. Le consentement à l’envoi d’email doit toujours être
              donné indépendamment du traitement possible pour d’autres
              finalités, comme la gestion des réservations. Si le Responsable du
              club coche la case ‘d’opt-in’ dans l’Application, il garantit à
              7NIGHT avoir obtenu le consentement express du client concerné.
            </p>
            <h3>
              4 – LES RESPONSABLES DE CLUBS DOIVENT POUVOIR APPORTER LA PREUVE
              DE CONSENTEMENT DE LEURS DESTINATAIRES EN CAS DE PLAINTE
            </h3>
            <p>7NIGHT prend très au sérieux les plaintes pour spam.</p>
            <p>
              A tout moment, le Responsable du club doit être capable de fournir
              les informations à propos des adresses email que le Responsable du
              club a collecté en direct (par téléphone, au Club, etc.) à qui ils
              ont envoyé des notifications à partir du module « CRM » de
              l’Application, incluant :
            </p>
            <ul>
              <li>
                Quand et comment cette adresse email a-t-elle été récupérée ?
                <ul className="nestedUl">
                  <li className="paddingLeft">Date et heure</li>
                  <li className="paddingLeft">
                    Participation à un tirage au sort ou à un concours ?
                  </li>
                  <li className="paddingLeft">
                    Relation préexistante avec le destinataire ?
                  </li>
                  <li className="paddingLeft">Autre</li>
                </ul>
              </li>
              <li>
                Dans le cas d’inscriptions en ligne :
                <ul className="nestedUl">
                  <li className="paddingLeft">
                    URL du site où l’adresse a été collectée
                  </li>
                  <li className="paddingLeft">
                    Adresse IP utilisée par le propriétaire de l’adresse email
                  </li>
                  <li className="paddingLeft">
                    Le type d’opt-in utilisé (simple, confirmation, double
                    optin)
                  </li>
                  <li className="paddingLeft">
                    En cas de double opt-in : date et heure de la confirmation
                  </li>
                </ul>
              </li>
              <li>
                Existe-t-il une déclaration explicite de consentement ou
                d'acceptation pour l'adresse email ? (cette information pouvant
                être fournie sous forme de capture d'écran)
                <ul className="nestedUl">
                  <li className="paddingLeft">Texte de la déclaration</li>
                  <li className="paddingLeft">
                    Est-ce que l’utilisateur a la possibilité de donner son
                    consentement explicitement et séparément de toute autre
                    finalité de traitement?
                  </li>
                </ul>
              </li>
              <li>
                S’il n’y a pas d’opt-in explicite, mais qu’une relation
                préalable existait avec le destinataire :
                <ul className="nestedUl">
                  <li className="paddingLeft">
                    Nature de la relation (ex : a réservé et diné au Restaurant
                    at la date X)
                  </li>
                  <li className="paddingLeft">
                    Comment le client a-t-il été informé lors de l'obtention de
                    l'adresse email (quel message lui a été transmis l'informant
                    que l'adresse email pourrait être utilisée à des fins de
                    marketing et qu'il pourrait se désabonner à tout moment) ?
                  </li>
                </ul>
              </li>
            </ul>
            <p>
              Notez que la totale coopération du Responsable du club sera
              demandée dans la fourniture de ces informations. Les Restaurateurs
              incapables de fournir à 7NIGHT les preuves d’agrément légal de
              leurs destinataires, dans le cas où ces derniers feraient remonter
              aux équipes 7NIGHT des plaintes pour abus, risquent de voir compte
              « Module CRM » suspendu voire, dans certains cas, clôturé.
            </p>
            <h3>
              5 – UN LIEN DE DÉSABONNEMENT DOIT FIGURER DANS CHAQUE EMAIL
              MARKETING
            </h3>
            <p>
              Tous les emails marketing doivent comporter un lien clair et
              concis permettant aux destinataires de se désabonner et ainsi ne
              plus recevoir aucune communication future. Ce lien doit être
              facile à reconnaître, à lire et à comprendre par tous. Le
              désabonnement doit être effectif immédiatement, et aucun email ne
              doit plus être envoyé à ce destinataire à compter de la demande de
              désabonnement.
            </p>
            <h3>
              6 – LE NOM DU Club ET LE STATUT DU Responsable du club DOIVENT
              CLAIREMENT ÊTRE INDIQUÉS DANS CHAQUE EMAIL
            </h3>
            <p>
              Les champs « De », « À » et « Répondre à » doivent identifier
              clairement et précisément le nom de domaine du Responsable du club
              et son adresse email. Il est important de procéder aux envois
              depuis un domaine créé plus d’un mois auparavant, avec un nom de
              domaine public et identifiable qui renvoie aux pages
              d’informations légales du Responsable du club.
            </p>
            <h3>
              7 – SEULES DES INFORMATIONS LÉGITIMES PEUVENT ÊTRE ENVOYÉES{" "}
            </h3>
            <p>
              Le Responsable du club ne doit pas : a) envoyer d’emails qui
              contiennent, promeuvent, référencent ou redirigent vers des
              activités ou des contenus illicites, illégales, calomnieuses ou
              diffamatoires. Le même principe s’applique à la violence à l’égard
              d’un individu ou d’un groupe, et à tout élément allant à
              l’encontre des droits de l’homme en général ; ou b) envoyer
              d’email au nom ou pour le compte de tiers (y compris leur
              clients). De plus, le contenu des emails du Responsable du club
              doit porter sur l’activité de son/ses Club(s). De manière
              générale, aucune information ou aucun contenu considéré de manière
              souveraine par 7NIGHT comme inadapté ou nuisant à la réputation de
              7NIGHT, de ses affiliés, partenaires, clients ou utilisateurs ne
              doit figurer dans les emails du Responsable du club.
            </p>
            <div className="go" onClick={() => window.scrollTo(0, 0)}>
              <img src={goTop} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PrivacyAndPolicy;

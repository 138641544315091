export const status: Record<string, string> = {
  1: "En attendant",
  2: "Manqué",
  3: "Annulé",
  4: "Confirmé",
  5: "Est présent",
};

export const statusColors: Record<string, string> = {
  3: "Cancelled",
  4: "Confirmed",
  5: "Present",
};

import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import { BasicResponse } from "../../interfaces/mainModels/BasicResponse.model";
import { MetaDataResponse } from "../../interfaces/mainModels/MetaDataResponse.model";
import { WalletHistoryResponseModel } from "../../interfaces/models/getModels/WalletHistoryResponse.model";
import { WalletResponseModel } from "../../interfaces/models/getModels/WalletResponse.model";
import { getWalletBalance, getWalletHistory } from "../queries/wallet";

export const useWalletHistoryQuery = (
  userId?: string,
  pageNumber?: number,
  options?: Omit<
    UseQueryOptions<MetaDataResponse<WalletHistoryResponseModel[]>>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<MetaDataResponse<WalletHistoryResponseModel[]>> =>
  useQuery(
    [`business-events-pageNumber-${pageNumber}`],
    () => getWalletHistory(userId, pageNumber),
    options
  );

export const useWalletBalance = (
  options?: Omit<UseQueryOptions<BasicResponse<any>>, "queryKey" | "queryFn">
): UseQueryResult<BasicResponse<WalletResponseModel>> =>
  useQuery(["walletBalance"], () => getWalletBalance(), options);

import axios from "../../initializations/axios";

export const getDjProfile = async (
  id: number,
  location?: { latitude?: number; longitude?: number } | undefined
) => {
  const { data } = await axios.get(`/Dj/profile/${id}`, {
    headers: { Location: `${location?.latitude},${location?.longitude}` },
  });
  return data;
};

import { useParams } from "react-router-dom";
import { deleteFavoriteDj, postFavoriteDj } from "../../../api/Dj";
import EventCard from "../../components/shared/Cards/EventCard/EventCard";
import "./_DJ.scss";
import favoriteIcon from "../../../assets/icons/heart.webp";
import Rating from "@mui/material/Rating";
import DjReviews from "../../page-sections/shared/Reviews/DjReviews";
import NoReviews from "../../components/shared/NoReviews/NoReviews";
import NoEvents from "../../components/shared/NoEvents/NoEvents";
import { useAuthContext } from "../../../context/AuthContext/AuthContext";
import { useDjProfile } from "../../../react-query/hooks/Djs";
import { useEffect, useState } from "react";

const DJ = () => {
  const [location, setLocation] = useState<
    { latitude?: number; longitude?: number } | undefined
  >({});
  const getLocation = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      setLocation({
        latitude: position?.coords?.latitude,
        longitude: position?.coords?.longitude,
      });
    });
  };
  const { isAuthenticated } = useAuthContext();
  let { djId } = useParams<{ djId?: string }>();
  let id = parseInt(djId!);
  const { data: { data: djData } = {}, refetch } = useDjProfile(id, location);
  const addFavoriteDj = async () => {
    try {
      await postFavoriteDj({ djId: id });
      refetch();
    } catch (e) {
      console.log(e);
    }
  };

  const removeFavoriteDj = async () => {
    try {
      await deleteFavoriteDj({ id });
      refetch();
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    getLocation();
  }, []);
  return (
    <div className="dj">
      <div className="dj-mainPhoto">
        <div
          className="dj-mainPhoto-underCover"
          style={{
            backgroundImage: `url(${djData?.imagePath})`,
          }}
        ></div>
        <img src={djData?.imagePath} alt="" />
      </div>
      <div className="container">
        <div className="dj-content">
          <div className="dj-content-section1">
            <p>
              Accueil <span>{">"}</span> Djs <span>{">"}</span>
              <span className="name">{djData?.name}</span>
            </p>
          </div>
          <div className="dj-content-section2">
            <div className="dj-content-section2-part1">
              <h3>{djData?.name}</h3>
              <div className="dj-content-section2-part1-rating">
                <Rating
                  name="read-only"
                  className="mui-stars"
                  value={Number(djData?.rating)}
                  readOnly
                  precision={0.5}
                />
                <span className="totalRating">({djData?.totalRatings})</span>
              </div>
            </div>
            {isAuthenticated && (
              <div className="dj-content-section2-part2">
                <img
                  src={favoriteIcon}
                  alt=""
                  onClick={() => {
                    !djData?.isFavorite && addFavoriteDj();
                    djData?.isFavorite && removeFavoriteDj();
                  }}
                  className={`${
                    djData?.isFavorite ? "favorite" : "unfavorite"
                  }`}
                />
              </div>
            )}
          </div>
          <div className="dj-content-section3">
            <h2>Évènements de ce club</h2>
            {djData?.events.length === 0 ? (
              <NoEvents />
            ) : (
              <div className="djevenings">
                {djData?.events?.map((dj) => {
                  return (
                    <div className="djevenings-item">
                      <EventCard
                        key={dj.id}
                        id={dj.id}
                        photo={dj.banner}
                        title={dj.title}
                        club={dj.clubName}
                        address={dj.address}
                        date={dj.date}
                        distance={dj.distance}
                        isAlmostFull={dj?.isAlmostFull}
                        isFreeForGirls={dj?.isFreeForGirls}
                        isFreeForBoys={dj?.isFreeForBoys}
                        hasShowCase={dj?.hasShowCase}
                        isOpened={dj.isOpened}
                      />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <div className="dj-content-section4">
            <h2>Commentaires</h2>
            {djData?.totalRatings === 0 ? (
              <NoReviews
                id={id}
                name={djData?.name}
                clubReview={false}
                djReview={true}
              />
            ) : (
              <DjReviews
                id={id}
                rating={djData?.rating}
                numberOfRatings={djData?.totalRatings}
                name={djData?.name}
                clubReview={false}
                djReview={true}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DJ;

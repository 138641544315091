import './_SkeletonEventCard.scss';

const SkeletonEventCard = () => {
    return (
        <section className='skeletoneventcard'>
          <div className='skeletoneventcard-img'>
            <div className="img"></div>         
          </div>
          <div className='skeletoneventcard-content'>
              <div className="h2"></div>
              <div className='info-item'> 
                <div className="img"></div>
                <span className='address'></span>
              </div>
              <div className='info-item'> 
                <div className="img"></div>
                <span className='address'></span>
              </div>
              <div className='info-item'> 
                <div className="img"></div>
                <span className='address'></span>
              </div>
          </div>
        </section>
    )
}

export default SkeletonEventCard;
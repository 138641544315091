import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import { BasicResponse } from "../../interfaces/mainModels/BasicResponse.model";
import { DjProfileResponseModel } from "../../interfaces/models/getModels/DjProfileResponse.model";
import { getDjProfile } from "../queries/djs";

export const useDjProfile = (
  id: number,
  location?: { latitude?: number; longitude?: number } | undefined,
  options?: Omit<
    UseQueryOptions<BasicResponse<DjProfileResponseModel>>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<BasicResponse<DjProfileResponseModel>> =>
  useQuery([`dj-profile-${id}`], () => getDjProfile(id, location), options);

import { useEffect, useState } from "react";

export const useDebounceValue = (value: string, delay: number = 1000) => {
  // local state
  const [debounceValue, setDebounceValue] = useState(value);

  // effects
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebounceValue(value);
    }, delay);

    return () => clearTimeout(handler);
  }, [value, delay]);

  return debounceValue;
};

import './_SkeletonReservationCard.scss';

const SkeletonReservationCard=()=>{
    return(
      <div className='skeletonreservationcard'>
      <div className='skeletonreservationcard-photo'></div>
      <div className='skeletonreservationcard-info'>
          <div className="h3"></div>
          <div className='skeletonreservationcard-info-section2'>
              <div className='skeletonreservationcard-info-section2-item'>
              </div>
              <div className='skeletonreservationcard-info-section2-item'>
              </div>
              <div className='skeletonreservationcard-info-section2-item'>
              </div>
          </div>
          <div className='skeletonreservationcard-info-section3'>
            <div className='icon'></div>
            <div className="button"></div>
          </div>
      </div>
  </div>
    )
}
export default SkeletonReservationCard;
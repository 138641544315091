import { ReactElement, useEffect } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import { useAuthContext } from "../../../context/AuthContext/AuthContext";
import { ManagerDashboardResponseModel } from "../../../interfaces/models/getModels/ManagerDashboardResponse.model";
import { useManagerDashboardQuery } from "../../../react-query/hooks/useDashboard.query";
import ManagerDashboardS1 from "../../components/Organisms/DashboardS1/ManagerDashboardS1";
import ManagerDashboardS2 from "../../components/Organisms/DashboardS2/ManagerDashboardS2";
import { MangerDashboardPayloadModel } from "../../../interfaces/models/getModels/ManagerDashboardPayload.model";

const ManagerDashboard = (): ReactElement => {
  const { search } = useLocation();
  const parsedQuery = queryString.parse(
    search
  ) as unknown as MangerDashboardPayloadModel;

  // context
  const { user: { firstName = "" } = ({} = {}) } = useAuthContext() || {};

  // react-query
  const {
    data: managerData = {} as ManagerDashboardResponseModel,
    isLoading,
    refetch,
  } = useManagerDashboardQuery({
    startDate: parsedQuery.startDate,
    endDate: parsedQuery.endDate,
  });

  useEffect(() => {
    refetch();
  }, [refetch, parsedQuery]);

  console.log(managerData);

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <div className="dashboard">
      <div className="dashboard-section1">
        <ManagerDashboardS1
          data={{
            userName: firstName,
            ...managerData,
          }}
        />
      </div>
      <div className="dashboard-section2">
        <ManagerDashboardS2
          data={{
            numberOfBusinesses: managerData.numberOfBusinesses,
            numberOfClients: managerData.numberOfClients,
            numberOfEvents: managerData.numberOfEvents,
          }}
        />
      </div>
    </div>
  );
};

export default ManagerDashboard;

import "./_Card.scss";
import loactionIcon from "../../../../assets/icons/location.svg";
import { Link } from "react-router-dom";
import { FC } from "react";

interface ClubCardProps {
  id: number;
  address: string;
  banner: string;
  name: string;
}
const ClubCard: FC<ClubCardProps> = ({ id, address, banner, name }) => {
  return (
    <section className="dashboardcard">
      <Link to={`${id}?tab=clubdetails`}>
        <div className="dashboardcard-img">
          <img src={banner} alt="" />
        </div>
        <div className="dashboardcard-content">
          <h2>{name}</h2>
          <div className="dashboardcard-content-item">
            <img src={loactionIcon} alt="" />
            <span>{address}</span>
          </div>
        </div>
      </Link>
    </section>
  );
};

export default ClubCard;

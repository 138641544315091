import { ReactElement } from "react";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import { useAuthContext } from "../../../context/AuthContext/AuthContext";
import { useAdminDashboardQuery } from "../../../react-query/hooks/useDashboard.query";
import AdminDashboardS1 from "../../components/Organisms/DashboardS1/AdminDashboardS1";
import AdminDashboardS2 from "../../components/Organisms/DashboardS2/AdminDashboardS2";

export const AdminDashboard = (): ReactElement => {
  const { user: { firstName = "", lastName = "" } = ({} = {}) } =
    useAuthContext() || {};

  const {
    data: {
      userNumber,
      clubs,
      reservations,
      events,
      reservationsPerMonth,
      users,
    } = {},
    isLoading,
  } = useAdminDashboardQuery();

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="dashboard">
          <div className="dashboard-section1">
            <AdminDashboardS1
              data={reservationsPerMonth}
              userName={firstName}
              userNumber={userNumber}
              clubs={clubs}
              reservations={reservations}
              events={events}
            />
          </div>
          <div className="dashboard-section2">
            <AdminDashboardS2 data={users} />
          </div>
        </div>
      )}
    </>
  );
};

export default AdminDashboard;

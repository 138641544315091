import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ConstantModel } from "../../interfaces/models/getModels/Constant.model";
import { MusicTypeModel } from "../../interfaces/models/getModels/MusicType.model";
import { DjFormPayload } from "../../interfaces/models/postModels/DjFormPayload.model";
import { PackageFormPayload } from "../../interfaces/models/postModels/PackageFormPayload.model";
import {
  addDj,
  addMusicType,
  deleteDj,
  deleteMusicType,
  editContant,
  editDj,
  editMusicType,
  editPackage,
} from "../queries/configurations";

// Packages
export const useEditPackageMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ["edit-package"],
    (payload: PackageFormPayload) => editPackage(payload),
    {
      onSuccess: () => queryClient.invalidateQueries(["packages"]),
    }
  );
};

// Djs
export const useAddDjMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(["add-dj"], (payload: DjFormPayload) => addDj(payload), {
    onSuccess: () => queryClient.invalidateQueries(["djs-1"]),
  });
};

export const useEditDjMutation = (id: number, pageNumber: number) => {
  const queryClient = useQueryClient();

  return useMutation(
    [`edit-dj-${id}`],
    (payload: DjFormPayload) => editDj({ id, ...payload }),
    {
      onSuccess: () => queryClient.invalidateQueries([`djs-${pageNumber}`]),
    }
  );
};

export const useDeleteDjMutation = (id: number, pageNumber: number) => {
  const queryClient = useQueryClient();

  return useMutation(["delete-dj"], () => deleteDj(id), {
    onSuccess: () => queryClient.invalidateQueries([`djs-${pageNumber}`]),
  });
};

// Music Types
export const useAddMusicTypeMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ["add-musicType"],
    (title: string) => addMusicType(title),
    {
      onSuccess: () => queryClient.invalidateQueries(["musicTypes"]),
    }
  );
};

export const useEditMusicTypeMutation = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation(
    [`edit-musicType-${id}`],
    (payload: MusicTypeModel) => editMusicType(payload),
    {
      onSuccess: () => queryClient.invalidateQueries(["musicTypes"]),
    }
  );
};

export const useDeleteMusicTypeMutation = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation(["delete-musicType"], () => deleteMusicType(id), {
    onSuccess: () => queryClient.invalidateQueries(["musicTypes"]),
  });
};

// Constants
export const useEditConstantMutation = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation(
    [`edit-constant-${id}`],
    (payload: Omit<ConstantModel, "key">) => editContant(payload),
    {
      onSuccess: () => queryClient.invalidateQueries(["constants"]),
    }
  );
};

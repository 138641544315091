import { ReactElement } from "react";
import { Tab, Tabs } from "react-bootstrap";
import "./_EventiStyle.scss";
import EventAttendees from "../../components/Organisms/EventAttendees/EventAttendees";
import EventDetails from "../../components/Organisms/Details/EventDetails";
import EventGallery from "../../components/Organisms/Gallery/EventGallery";
import EventTables from "../../components/Organisms/Tables/EventTables";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import queryString from "query-string";

const Eventi = (): ReactElement => {
  let { eventId } = useParams<{ eventId?: string }>();

  const navigate = useNavigate();

  const { search } = useLocation();

  const queryParams = queryString.parse(search);

  const handleTabSelect = (value: string | null) =>
    navigate({ search: `tab=${value}` });

  const activeKey = queryParams.tab ? String(queryParams.tab) : "packs";

  return (
    <div className="event">
      <div className="dashboardTitle">
        <h3>Évènement</h3>
      </div>
      <Tabs
        defaultActiveKey={activeKey}
        onSelect={handleTabSelect}
        transition={false}
        id="noanim-tab-example"
        className="dashboardNav"
      >
        <Tab eventKey="about" title="Détails" mountOnEnter unmountOnExit>
          <EventDetails id={eventId || ""} />
        </Tab>
        <Tab eventKey="gallery" title="Photos" mountOnEnter unmountOnExit>
          <EventGallery id={eventId || ""} />
        </Tab>
        <Tab eventKey="tables" title="Tables" mountOnEnter unmountOnExit>
          <EventTables id={eventId || ""} />
        </Tab>
        <Tab
          eventKey="participants"
          title="Participants"
          mountOnEnter
          unmountOnExit
        >
          <EventAttendees id={eventId || ""} />
        </Tab>
      </Tabs>
    </div>
  );
};
export default Eventi;

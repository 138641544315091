import { FC } from "react";
import "./_DownloadApp.scss";
import downloadAppPhoto from "../../../../assets/photos/downloadAppPhoto.webp";
import GoogleAppPhoto from "../../../../assets/photos/GooglePlay.jpg";
import AppStorePhoto from "../../../../assets/photos/AppStore.jpg";

interface DownloadAppProps {}

const DownloadApp: FC<DownloadAppProps> = () => {
  return (
    <section className="downloadappsection">
      <div className="downloadappsection-section1">
        <ul className="downloadappsection-section1-list">
          <li>
            RÉSERVE GRATUITEMENT <span className="textColor">EN UN-CLIC</span>{" "}
            📲
          </li>
          <li>
            MATCH{" "}
            <span className="textColor">
              LES PERSONNES QUE TU AS CROISÉES DANS TA SOIRÉE !
            </span>
            ❤️{" "}
          </li>
          <li>
            OFFRE{" "}
            <span className="textColor">
              UN VERRE À LA PERSONNE QUI TE PLAIT
            </span>
            🎁{" "}
          </li>
          <li>
            <span className="textColor">REÇOIS DES </span> PROMOTIONS ! 💶
          </li>
        </ul>
        <div className="downloadappsection-section1-images">
          <a href="https://play.google.com/store/apps/details?id=com.innos.sevennight">
            <img src={GoogleAppPhoto} alt="" />
          </a>
          <a href="https://apps.apple.com/fr/app/7night/id1573893968?platform=iphone">
            <img src={AppStorePhoto} alt="" />
          </a>
        </div>
      </div>
      <div className="downloadappsection-section2">
        <img src={downloadAppPhoto} alt="" className="pho" />
      </div>
      <div className="downloadappsection-section3">
        <a href="https://play.google.com/store/apps/details?id=com.innos.sevennight">
          <img src={GoogleAppPhoto} alt="" />
        </a>
        <a href="https://apps.apple.com/fr/app/7night/id1573893968?platform=iphone">
          <img src={AppStorePhoto} alt="" />
        </a>
      </div>
    </section>
  );
};

export default DownloadApp;

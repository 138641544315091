import objectToQueryString from "../utils/objectToQueryString";
import { apiRequest } from "./Api";
import { responseBody, metaData } from "./Shared";

// ---------DJ-----------
export interface DjBasicInfo {
  id: number;
  name: string;
  imagePath: string;
  rating: number;
  totalRatings: number;
  isFavorite: boolean;
  insertedDate: string;
}

// ------DJ-Profile-----
export interface GetDJsProfileInput {
  id?: string;
}
export interface GetDJsProfileResponse {
  data: {
    id: number;
    name: string;
    imagePath: string;
    rating: number;
    totalRatings: number;
    userReview: null;
    events: {
      isFavorite: boolean;
      longitude: number;
      latitude: number;
      city: string;
      country: string;
      clubName: string;
      hasShowCase: true;
      averageScore: null;
      banner: string;
      insertedDate: string;
      id: number;
      title: string;
      address: string;
      date: string;
      distance: number;
    }[];
    insertedDate: string;
    isFavorite: boolean;
  };
}

//-------DjSearchInput--------
export interface GetDJsInput {
  searchKeyWord?: string;
}
export interface GetSearchDJsInput {
  inputWord?: string;
}
export interface GetDJsResponse {
  data: DjBasicInfo[];
  metaData: metaData;
}

//------DjFavorite------
export interface favoriteDjInput {
  id?: number;
}
// ----DjReview-----
export interface addDjReviewInput {
  djId: number;
  rating: number | null;
  comment: string;
}
export interface upDateDjReviewInput {
  djReviewId: number;
  rating: number;
  comment: string;
}
export interface djReview {
  djReviewId: number;
  djName: string;
  rating: number;
  comment: string;
  firstName: string;
  email: string;
  userId: string;
  insertedDate: string;
  djId: number;
}
export interface getDjReviewsResponse extends responseBody {
  data: {
    data: djReview[];
    metaData: metaData;
  };
}

// -------apiRequests---------
export const getDjs = async (data: GetDJsInput, params: any) =>
  apiRequest<GetDJsInput, GetDJsResponse>(
    "post",
    `/Dj/search?${objectToQueryString(params)}`,
    data
  );

export const getFavoritesDjs = async (params: any) =>
  apiRequest<GetDJsProfileResponse>(
    "get",
    `/Dj/favorite/my?${objectToQueryString(params)}&pageSize=3`
  );
export const deleteFavoriteDj = async (data: { id?: number }) =>
  apiRequest<responseBody>("delete", `/Dj/favorite/${data.id}`);
export const postFavoriteDj = async (data: { djId?: number }) =>
  apiRequest<{ djId?: number }, responseBody>("post", "/Dj/favorite", data);
export const postDjReview = async (data: addDjReviewInput) =>
  apiRequest<addDjReviewInput, responseBody>(
    "post",
    "/DjReview/AddDjReview",
    data
  );
export const getDjReviews = async (data: {
  id?: number;
  pageNumber?: number;
}) =>
  apiRequest<getDjReviewsResponse>(
    "get",
    `/DjReview/${data.id}/?${data.pageNumber}`
  );
export const deleteDjReview = async (data: { id?: number }) =>
  apiRequest<{ id?: number }, responseBody>("delete", `/DjReview/${data.id}`);
export const updateDjReview = async (data: upDateDjReviewInput) =>
  apiRequest<upDateDjReviewInput, responseBody>(
    "put",
    "/DjReview/UpdateDjReview",
    data
  );

import { ChangeEvent, ReactElement, useEffect, useState } from "react";
import GoogleMapComponent from "../../../../components/GoogleMapComponent/GoogleMapComponent";
import uploadCoverImg from "../../../../assets/icons/ImgEdit.png";
import uploadImg from "../../../../assets/icons/uploadImg.png";
import { ClubPostModel } from "../../../../interfaces/models/postModels/ClubPost.model";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { UseConvertToBase64 } from "../../../../hooks/UseConvertToBase64";
import { useGoogleAutoFill } from "../../../../components/GoogleAutoFill/GoogleAutoFill";
import {
  useClubTypesQuery,
  usePostClubQuery,
} from "../../../../react-query/hooks/Clubs";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../../../context/AuthContext/AuthContext";
import { useFetchAllMusicTypesQuery } from "../../../../react-query/hooks/useConfiguration.query";
import Select from "react-select";

const schema = yup.object().shape({
  banner: yup.mixed().required("Obligatoire"),
  name: yup.string().required("Obligatoire"),
  description: yup.string().required("Obligatoire"),
  restaurantMaxPlaceNumber: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value)),
  minAge: yup.number().transform((value) => (isNaN(value) ? undefined : value)),
  maxAge: yup.number().transform((value) => (isNaN(value) ? undefined : value)),
  isRestaurant: yup
    .boolean()
    .typeError("Vous devez choisir une option")
    .required("Obligatoire"),
  patio: yup
    .boolean()
    .typeError("Vous devez choisir une option")
    .required("Obligatoire"),
  phone: yup.string().required("Obligatoire"),
  address: yup.string().required("Obligatoire"),
  musicTypes: yup.array().of(yup.number()),
});

const AddClubsDetails = (): ReactElement => {
  const authCtx = useAuthContext();
  const role = authCtx.decoded?.role?.toLowerCase();
  const navigate = useNavigate();
  const [errorFileType, setErrorFileType] = useState<string>();
  const uploadFile = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files![0];
    const fileInBase64 = await UseConvertToBase64(file);
    if (
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/jpeg"
    ) {
      setPhoto(fileInBase64);
      setValue("banner", {
        base64: fileInBase64,
        fileName: e.target.files![0].name,
      });
    } else {
      setErrorFileType("Type de fichier non pris en charge!");
    }
  };
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<ClubPostModel>({
    resolver: yupResolver(schema),
  });

  const { address, coordinates, render, cityContry } = useGoogleAutoFill("");

  const {
    mutate: addClub,
    data: { data } = {},
    isSuccess,
    isLoading,
  } = usePostClubQuery();

  const { data: clubMusicTypes = [] } = useFetchAllMusicTypesQuery();
  const { data: { data: clubsTypes } = {} } = useClubTypesQuery();
  const [photo, setPhoto] = useState<string | unknown>();

  const [selectedMusicTypes, setSelectedMusicTypes] =
    useState<[{ value: string; label: string }]>();
  const [selectedClubsTypes, setSelectedClubsTypes] = useState<any>();
  const handleSelectedMusic = (data: any) => {
    setSelectedMusicTypes(data);
  };
  const handleSelectedClubTypes = (data: any) => {
    setSelectedClubsTypes(data);
  };
  const musicOptions = clubMusicTypes?.map((type) => ({
    value: type.id.toString(),
    label: type.title,
  }));
  const clubTypesOptions = clubsTypes?.map((type) => ({
    value: type.id.toString(),
    label: type.name,
  }));
  let musicArray = selectedMusicTypes?.map((type) => Number(type.value));
  let clubTypesArray = selectedClubsTypes?.map((type: any) =>
    Number(type.value)
  );
  const handlePostClub = (clubDetails: ClubPostModel) => {
    addClub({
      ...clubDetails,
      address: address,
      city: cityContry?.city!,
      country: cityContry?.country!,
      latitude: coordinates.lat,
      longitude: coordinates.lng,
      musicTypes: musicArray,
      businessTypes: clubTypesArray,
    });
  };
  const lngLatArray = [
    {
      lat:
        (data?.latitude === undefined ? null : data?.latitude) ||
        coordinates.lat,
      lng:
        (data?.longitude === undefined ? null : data?.longitude) ||
        coordinates.lng,
    },
  ];

  useEffect(() => {
    isSuccess &&
      navigate(`/${role}/clubs/${data?.data?.id}?tab=clubpricing`, {
        replace: true,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    setValue("address", address);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);
  return (
    <>
      <form className="details" onSubmit={handleSubmit(handlePostClub)}>
        {!!photo && (
          <div
            className="details-photo detailsSection"
            style={{ backgroundImage: `url(${photo})` }}
          >
            <label className="detailImgInput">
              <input
                type="file"
                onChange={(e) => uploadFile(e)}
                accept="image/png, image/jpg, image/jpeg"
              />
              <div className="imageInput-style">
                <img src={uploadCoverImg} alt="" />
              </div>
            </label>
          </div>
        )}
        {!photo && (
          <div className="details-emptyphoto detailsSection">
            <label className="emptyImg">
              <input type="file" onChange={(e) => uploadFile(e)} />
              <div className="emptyImg-text">
                <img src={uploadImg} alt="" />
                <h6>
                  Déposez votre image ici, ou{" "}
                  <span>parcourez sur votre appareil</span>
                </h6>
                <p>Format requis: JPG, PNG</p>
                {errors.banner && (
                  <p className="tw-text-red-600 mb-0">
                    {errors.banner.message}
                  </p>
                )}
                <p className="tw-text-red-600 mb-0">{errorFileType}</p>
              </div>
            </label>
          </div>
        )}
        <div className="details-info detailsSection">
          <h3>Informations</h3>
          <div className="detailsInputs">
            <div className="detailsInput">
              <label htmlFor="clubName">Nom du club</label>
              <input
                type="text"
                id="clubName"
                placeholder="Entrez le nom du club"
                {...register("name")}
              />
              {errors.name && (
                <p className="tw-text-red-600 mb-0">{errors.name.message}</p>
              )}
            </div>
            <div className="detailsInput">
              <label htmlFor="resNumberPlaces">
                Nombre de places au restaurant (facultatif)
              </label>
              <input
                type="text"
                id="resNumberPlaces"
                {...register("restaurantMaxPlaceNumber")}
              />
              {errors.restaurantMaxPlaceNumber && (
                <p className="tw-text-red-600 mb-0">
                  {errors.restaurantMaxPlaceNumber.message}
                </p>
              )}
            </div>
            <div className="detailsInput">
              <label htmlFor="Phone">Téléphone d’un responsable</label>
              <input type="text" id="Phone" {...register("phone")} />
              {errors.phone && (
                <p className="tw-text-red-600 mb-0">{errors.phone.message}</p>
              )}
            </div>
          </div>
          <div className="detailsInput">
            <label htmlFor="description">Description du club</label>
            <textarea
              rows={7}
              cols={10}
              id="description"
              maxLength={1000}
              placeholder="Entrez une description du club (max. 1000 caractères)"
              {...register("description")}
            />
            {errors.description && (
              <p className="tw-text-red-600 mb-0">
                {errors.description.message}
              </p>
            )}
          </div>
        </div>
        <div className="details-infoForClient detailsSection">
          <h3>Autres informations importantes pour les clients</h3>
          <div className="detailsInputs">
            <div className="detailsInput">
              <label htmlFor="hasRestaurant">
                Le club possède un restaurant ?
              </label>
              <div className="radioInput">
                <input
                  type="radio"
                  id="yesRestaurant"
                  value="true"
                  {...register("isRestaurant")}
                />
                <label htmlFor="yesRestaurant">Oui</label>
              </div>
              <div className="radioInput">
                <input
                  type="radio"
                  id="noRestaurant"
                  value="false"
                  {...register("isRestaurant")}
                />
                <label htmlFor="noRestaurant">Non</label>
              </div>
              {errors.isRestaurant && (
                <p className="tw-text-red-600 mb-0">
                  {errors.isRestaurant.message}
                </p>
              )}
            </div>
            <div className="detailsInput">
              <label>Le club possède un patio ?</label>
              <div className="radioInput">
                <input
                  type="radio"
                  id="yesPatio"
                  value="true"
                  {...register("patio")}
                />
                <label htmlFor="yesPatio">Oui</label>
              </div>
              <div className="radioInput">
                <input
                  type="radio"
                  id="noPatio"
                  value="false"
                  {...register("patio")}
                />
                <label htmlFor="noPatio">Non</label>
              </div>
              {errors.patio && (
                <p className="tw-text-red-600 mb-0">{errors.patio.message}</p>
              )}
            </div>
            <div className="detailsInput">
              <label>Quel est l’âge moyen de votre clientèle ?</label>
              <div className="ageInput">
                <label htmlFor="minAge">Entre</label>
                <input
                  id="minAge"
                  type="number"
                  placeholder="âge"
                  {...register("minAge")}
                />
                <label htmlFor="maxAge">Et</label>
                <input
                  id="maxAge"
                  type="number"
                  placeholder="âge"
                  {...register("maxAge")}
                />
              </div>
              {errors.minAge && (
                <p className="tw-text-red-600 mb-0">{errors.minAge.message}</p>
              )}
              {errors.maxAge && (
                <p className="tw-text-red-600 mb-0">{errors.maxAge.message}</p>
              )}
            </div>
            <div className="detailsInput" style={{ zIndex: "4" }}>
              <label>Style(s) de musique</label>
              <Select
                value={selectedMusicTypes}
                isMulti
                options={musicOptions}
                onChange={handleSelectedMusic}
                placeholder="Sélectionner"
              />
            </div>
            <div className="detailsInput" style={{ zIndex: "4" }}>
              <label>Type(s) de clubs</label>
              <Select
                value={selectedClubsTypes}
                isMulti
                options={clubTypesOptions}
                onChange={handleSelectedClubTypes}
                placeholder="Sélectionner"
              />
            </div>
          </div>
        </div>
        <div className="details-address detailsSection">
          <h3>Adresse du club</h3>
          <div className="detailsInputs">
            <div className="addressInput">
              <label htmlFor="address">Adresse</label>
              {render}
              {address.length === 0 && errors.address && (
                <p className="tw-text-red-600 mb-0">{errors.address.message}</p>
              )}
            </div>
          </div>
        </div>
        <div className="details-map detailsSection">
          <GoogleMapComponent marksObject={lngLatArray} />
        </div>
        <div className="details-save detailsSection">
          <div></div>
          <div>
            <button type="button" onClick={() => reset()}>
              Jeter
            </button>
            <button type="submit">
              {isLoading ? "Attendez..." : "Suivant"}
            </button>
          </div>
        </div>
      </form>
    </>
  );
};
export default AddClubsDetails;

export const userGenders = [
  { id: 1, title: "Garçon" },
  { id: 2, title: "Fille" },
  { id: 3, title: "Non précisé" },
];

export const usersAge = Array.from({ length: 65 }, (_, i) => i + 16);

export const usersAgeFrom = Array.from({ length: 65 }, (_, i) => i + 16);

export const userStatuses = [
  { id: 1, title: "Actif" },
  { id: 2, title: "Bloqué" },
];

export const attendeStatuses = [
  { id: 3, title: "Annulé" },
  { id: 4, title: "Confirmé" },
  { id: 5, title: "Est présent" },
];

import {FC} from 'react';
import boostClubsImage from '../../../../assets/photos/boostClubsImage.webp';
import './_BoostClubs.scss';
import '../_ClubSpaceWhy7NightSharedStyle.scss';

interface BoostClubsProps {
}

 const BoostClubs: FC<BoostClubsProps>=()=> {
  return (
    <div className='boostclubs'>
      <div className='boostclubs-content container'>
        <div className='boostclubs-content-section1 clubspacewhy7nightsharedstyle'>
          <h3>Boostez la visibilité en ligne de votre club</h3>
          <p>En plus d’entraîner une perte de revenus, les espaces vides ont également un effet dissuasif pour les clients qui hésitent à rester dans votre club.</p>
          <p><span>Améliorez le taux d’occupation</span> de votre établissement grâce aux outils marketing de 7Night, tel que des <span>notifications personnalisées</span> pour promouvoir un showcase ou bien simplement votre club à l’heure que vous souhaitez en ciblant chaque client <span>avec des critères bien précis</span> tel que l’âge, le sexe, à quelle évènement était-il présent et bien d’autres encore.</p>
        </div>
        <div className='boostclubs-content-section2'>
          <img src={boostClubsImage} alt="" />
        </div>
      </div>
    </div>
  );
}

export default BoostClubs;
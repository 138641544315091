import { ReactElement } from "react";
import "./_SubscriptionPacks.scss";
import {
  useSubscriptionPackets,
  useSubscriptionStatus,
} from "../../../../react-query/hooks/Subscription";
import LoadingSpinner from "../../../../components/LoadingSpinner/LoadingSpinner";
import Pack from "../../../../user/components/shared/SubscriptionPack/Pack";

const SubscriptionPacks = (): ReactElement => {
  const {
    data: { data } = {},
    isLoading: isLoadingStatus,
    refetch,
  } = useSubscriptionStatus();
  const { data: { data: packets } = {}, isLoading: isLoadingPackets } =
    useSubscriptionPackets();
  const localDate = new Date(data?.expireDate!);
  const localDateString = localDate.toLocaleDateString(undefined, {
    day: "numeric",
    month: "short",
    year: "numeric",
  });
  const basicSubscription: boolean = data?.name === "Basic";
  const proSubscription: boolean = data?.name === "Pro";
  const dateOfExpire: string = `Expire ${localDateString}`;
  const prices = packets?.map((pack) => {
    return pack?.pricing[0].price;
  });

  return (
    <>
      {isLoadingStatus || isLoadingPackets ? (
        <LoadingSpinner />
      ) : (
        <div className="subscriptionpacks">
          {packets?.map((packet) => {
            return (
              <Pack
                linkToManagerProfile={false}
                curentSubscription={data?.name === packet?.name}
                hasSubscription={basicSubscription || proSubscription}
                dateOfExpire={
                  basicSubscription || proSubscription
                    ? dateOfExpire
                    : undefined
                }
                trialDays={packet?.pricing[0]?.trialDays}
                name={packet?.name}
                price={packet?.pricing[0]?.price}
                id={packet?.id}
                subscriptionId={data?.id}
                key={packet?.id}
                status={data?.status}
                refetch={refetch}
                prices={prices}
              />
            );
          })}
        </div>
      )}
    </>
  );
};
export default SubscriptionPacks;

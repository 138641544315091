import { useEffect, useMemo, useState } from "react";
import {
  favoritesEveningArray,
  getFavoritesBusinessEvent,
} from "../../../../api/Events";
import FavoriteEveningCard from "../../../components/ProfilePages/ProfileFavorites/FavoriteEveningCard/FavoriteEveningCard";
import NoFavorites from "../../../components/ProfilePages/ProfileFavorites/NoFavorites/NoFavorites";
import SkeletonFavoriteEventCard from "../../../components/ProfilePages/ProfileFavorites/SkeletonFavoriteEventCard/SkeletonFavoriteEventCard";
import "./_ProfileFavorites.scss";
import MuiPagination from "../../../../components/MuiPagination/MuiPagination";

const EveningsFavorites = () => {
  const [eveningsFavoritesData, setEveningsFavoritesData] =
    useState<favoritesEveningArray[]>();
  const [pageNumber, setPageNumber] = useState<number | undefined>();
  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState(1);

  const params = useMemo(() => ({ pageNumber: page }), [page]);

  const getFavoritesBusinessEventsData = async () => {
    try {
      const response: any = await getFavoritesBusinessEvent(params);
      setEveningsFavoritesData(response?.data?.data);
      setPageNumber(response?.data?.metaData?.pageCount);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getFavoritesBusinessEventsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const showSkeleton = (n: number) => {
    return [...Array(n)].map((e, i) => (
      <div key={`${2000 + i}`}>
        <SkeletonFavoriteEventCard />
      </div>
    ));
  };
  return (
    <>
      {eveningsFavoritesData?.length === 0 ? (
        <div className="profilefavorites-empty">
          <NoFavorites
            text={
              "Ajoutez des èvènements à vos favoris pour les retrouver facilement !"
            }
          />
        </div>
      ) : (
        <>
          {loading && showSkeleton(3)}
          <div>
            {eveningsFavoritesData?.map((evening) => (
              <FavoriteEveningCard
                title={evening.title}
                address={evening.address}
                banner={evening.banner}
                clubName={evening.clubName}
                schedule={evening.date}
                id={evening.id}
                key={evening.id}
              />
            ))}
          </div>
          <div className="profileFavorites-pagination">
            <MuiPagination
              pagesNumber={pageNumber!}
              page={page}
              setPage={setPage}
            />
          </div>
        </>
      )}
    </>
  );
};

export default EveningsFavorites;

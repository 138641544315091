import axios from "../../initializations/axios";
import { EditPaymentMethodPostModel } from "../../interfaces/models/postModels/EditPaymentPost.model";

export const removePaymentMethod = async (
  removePaymentMethodInput: EditPaymentMethodPostModel
) => {
  const data = await axios.post(
    "PaymentProfile/removePaymentMethod",
    removePaymentMethodInput
  );
  return data;
};

export const changeDefaultPaymentMethod = async (
  changeDefaultPaymentMethodInput: EditPaymentMethodPostModel
) => {
  const data = await axios.post(
    "PaymentProfile/changeDefaultPaymentMethod",
    changeDefaultPaymentMethodInput
  );
  return data;
};

export const addPaymentMethod = async () => {
  const data = await axios.post("PaymentProfile/addPaymentMethod");
  return data;
};

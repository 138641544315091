import { ReactElement } from "react";
import { Tab, Tabs } from "react-bootstrap";
import "./_EventiStyle.scss";
import AddEventDetails from "../../components/Organisms/Add/AddEventDetails";

const AddEvent = (): ReactElement => {
  return (
    <div className="event">
      <div className="dashboardTitle">
        <h3>Event</h3>
      </div>
      <Tabs
        defaultActiveKey="about"
        transition={false}
        id="noanim-tab-example"
        className="dashboardNav"
      >
        <Tab eventKey="about" title="Détails" disabled>
          <AddEventDetails />
        </Tab>
        <Tab eventKey="gallery" title="Photos" disabled></Tab>
        <Tab eventKey="tables" title="Tables" disabled></Tab>
        <Tab eventKey="participants" title="Participants" disabled></Tab>
      </Tabs>
    </div>
  );
};
export default AddEvent;

import { useState } from "react";
import { Outlet } from "react-router-dom";
import AdminNavbar from "../../components/Organisms/AdminNavbar/AdminNavbar";
import "./_AdminSharedLayout.scss";
import { Offcanvas } from "react-bootstrap";
import LoginRegisterResetPassword from "../../../user/modals/LoginRegisterResetPassword/LoginRegisterResetPassword";
import { useAuthContext } from "../../../context/AuthContext/AuthContext";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import Page404 from "../../../user/pages/Page404/Page404";
import MenuIcon from "../../../assets/icons/MenuIcon.png";

const AdminSharedLayout = () => {
  const authCtx = useAuthContext();
  const [modalShow, setModalShow] = useState(true);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  if (authCtx.isLoading)
    return (
      <div className="loading">
        <LoadingSpinner />
      </div>
    );
  return (
    <div>
      {authCtx.isAuthenticated ? (
        <div>
          {authCtx.decoded?.role === "Admin" ? (
            <div className="adminProfile container-am">
              <img
                src={MenuIcon}
                alt=""
                onClick={handleShow}
                className="open-navbar"
              />
              <div className="adminProfile-dashboard-1">
                <Offcanvas show={show} onHide={handleClose}>
                  <Offcanvas.Header closeButton></Offcanvas.Header>
                  <Offcanvas.Body>
                    <AdminNavbar />
                  </Offcanvas.Body>
                </Offcanvas>
              </div>
              <div className="adminProfile-dashboard-2">
                <AdminNavbar />
              </div>
              <div className="adminProfile-outlet">
                <Outlet />
              </div>
            </div>
          ) : (
            <Page404 />
          )}
        </div>
      ) : (
        <div>
          {
            <LoginRegisterResetPassword
              show={modalShow}
              onHide={() => setModalShow(false)}
            />
          }
        </div>
      )}
    </div>
  );
};

export default AdminSharedLayout;

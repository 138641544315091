import { FC, ReactElement, useState } from "react";
import { Dropdown } from "react-bootstrap";
import ellipsis from "../../../../assets/icons/ellipsis.svg";
import trash from "../../../../assets/icons/trash.png";
import edit from "../../../../assets/icons/edit.png";
import AddNewTarif from "../../../modals/AddNewTarif";
import { useDeleteClubServiceQuery } from "../../../../react-query/hooks/Clubs";

interface TarifInfoProps {
  serviceId: number;
  serviceTypeName: string;
  serviceName: string;
  price: number;
  clubId: number;
  currentPage: number;
  serviceTypeId: number;
}
const TarifInfo: FC<TarifInfoProps> = ({
  serviceId,
  serviceTypeName,
  serviceName,
  price,
  clubId,
  currentPage,
  serviceTypeId,
}): ReactElement => {
  const [modalShow, setModalShow] = useState(false);

  const { mutate: deleteClubService } = useDeleteClubServiceQuery(
    clubId,
    currentPage
  );

  const handleDeleteService = (serviceId: number) => () => {
    deleteClubService(serviceId);
  };

  return (
    <tr>
      <td>
        <p>{serviceTypeName}</p>
      </td>
      <td>
        <p>{serviceName}</p>
      </td>
      <td>
        <p>{`${price} €`}</p>
      </td>
      <td className="actionColumn">
        <Dropdown
          className="d-inline actionColumn-action action lastElement"
          align={"end"}
        >
          <Dropdown.Toggle id="dropdown-autoclose-true">
            <img src={ellipsis} alt="" />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item href="#" onClick={() => setModalShow(true)}>
              <img src={edit} alt="" />
              <p>Éditer</p>
            </Dropdown.Item>
            <Dropdown.Item href="#" onClick={handleDeleteService(serviceId)}>
              <img src={trash} alt="" />
              <p>Effacer</p>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </td>
      <AddNewTarif
        show={modalShow}
        onHide={() => setModalShow(false)}
        update
        price={price}
        name={serviceName}
        serviceTypeId={serviceTypeId}
        serviceId={serviceId}
        clubId={clubId}
        pageNumber={currentPage}
      />
    </tr>
  );
};
export default TarifInfo;

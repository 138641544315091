import "./_SubscriptionPack.scss";
import subcriptionGreenTick from "../../../../assets/icons/subcriptionGreenTick.png";
import subcriptionGrayTick from "../../../../assets/icons/subcriptionGrayTick.png";
import { FC, memo, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuthContext } from "../../../../context/AuthContext/AuthContext";
import LoginRegisterResetPassword from "../../../modals/LoginRegisterResetPassword/LoginRegisterResetPassword";
import { useUpdatePreviewSubscription } from "../../../../react-query/hooks/Subscription";
import UpDownGradePayment from "../../../../dashboard/modals/UpDownGradePayment";
import CancelPayment from "../../../../dashboard/modals/CancelPayment";
import { usePaymentContextContext } from "../../../../context/PaymentContext/PaymentContext";
import ReactivateSubscription from "../../../../dashboard/modals/ReactivateSubscription";
import { useLoginRegisterResetPasswordContext } from "../../../../context/LoginRegisterResetPasswordContext/LoginRegisterResetPasswordContext";
import { Elements } from "@stripe/react-stripe-js";
import PaymentCards from "../../../../dashboard/modals/PaymentCards";
import { subscribe } from "../../../../react-query/mutations/subscription";
import { addPaymentMethod } from "../../../../react-query/mutations/paymentProfile";
import LoadingSpinner from "../../../../components/LoadingSpinner/LoadingSpinner";
import { loadStripe } from "@stripe/stripe-js";
import { REACT_APP_STRIPE_CONFIG_CODE } from "../../../../config/app";
import RealPayment from "../../../../dashboard/modals/RealPayment";

interface basicPackProps {
  hasSubscription?: boolean;
  dateOfExpire?: string;
  curentSubscription?: boolean;
  linkToManagerProfile?: boolean;
  name?: string;
  price?: number;
  id?: number;
  subscriptionId?: number;
  status?: string;
  refetch?: any;
  prices?: number[];
  trialDays: number;
}

const Pack: FC<basicPackProps> = ({
  hasSubscription,
  dateOfExpire,
  curentSubscription,
  linkToManagerProfile,
  name,
  price,
  id,
  subscriptionId,
  status,
  refetch,
  prices,
  trialDays,
}) => {
  const authCtx = useAuthContext();
  const LRRPCtx = useLoginRegisterResetPasswordContext();
  const paymentCtx = usePaymentContextContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [paymentCardsModalShow, setPaymentCardModalShow] = useState(false);
  const [upDownGradeModalShow, setUpDownGradeModalShow] =
    useState<boolean>(false);
  const [cancelPaymentModalShow, setCancelPaymentModalShow] =
    useState<boolean>(false);
  const [reactiveSubscriptionModalShow, setReactiveSubscriptionModalShow] =
    useState<boolean>(false);
  const [realPaymentShow, setRealPaymentShow] = useState<boolean>(false);
  const [paymentMethod, setPaymentMethod] = useState<unknown>();
  const [paymentLoading, setPaymentLoading] = useState<boolean>(false);
  const [clientSecretCode, setClientSecretCode] = useState<unknown>("");

  const handleSubscribe = async () => {
    try {
      setLoading(true);
      const response: any = await subscribe(id);
      if (response?.errors !== null) {
        subscribePack(response);
      }
      if (response?.data !== null && response.isSuccess === true) {
        if (response?.data?.trial === false) {
          setClientSecretCode(response?.data?.clientSecret);
          setPaymentMethod(response?.data?.paymentMethod);
          setRealPaymentShow(true);
        }
      }
      if (response.isSuccess) {
        setLoading(false);
        paymentCtx.setIsPaymentCardAdded(false);
        setTimeout(() => {
          refetch();
          setPaymentLoading(false);
        }, 3000);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const addNewPaymentMethod = async () => {
    try {
      setLoading(true);
      const response: any = await addPaymentMethod();
      setClientSecretCode(response?.data?.data?.clientSecret);
      !!response?.data?.data?.clientSecret && setPaymentCardModalShow(true);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };
  const subscribePack = (response: any) => {
    if (response?.errors[0].code === "UserHasNoDefaultPaymentMethod") {
      addNewPaymentMethod();
    }
  };
  const {
    mutate: updatePreviewSubscription,
    data: { data: updatePeviewResponse } = {},
  } = useUpdatePreviewSubscription();

  const updatePreviewSubscriptionPack = () => {
    updatePreviewSubscription(id);
    setUpDownGradeModalShow(true);
  };
  const stripePromise = loadStripe(`${REACT_APP_STRIPE_CONFIG_CODE}`);
  const options = {
    clientSecret: `${clientSecretCode}`,
  };

  return (
    <Elements
      stripe={stripePromise}
      options={options}
      key={`clientSecretCode-${clientSecretCode}`}
    >
      {paymentLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="subscriptionPack">
          <div
            className={`subscriptionPack-upline ${
              name === "Basic" ? "upline1" : "upline2"
            }`}
          ></div>
          <div className="subscriptionPack-content">
            <div className="subscriptionPack-content-title">
              <h3 className={`${name === "Basic" ? "basic" : "pro"}`}>
                {name}
              </h3>
              <p>
                {`€${price}`}
                <span>/par mois</span>
              </p>
              <h6 className="tw-text-xs">
                <strong>Sans engagement</strong>
              </h6>
            </div>
            <div className="subscriptionPack-content-offerts">
              <div className="subscriptionPack-content-offerts-part1">
                <div className="subscriptionPack-content-offerts-item">
                  <img src={subcriptionGreenTick} alt="" />
                  <p>Vitrine personnalisée</p>
                </div>
                <div className="subscriptionPack-content-offerts-item">
                  <img src={subcriptionGreenTick} alt="" />
                  <p>Visibilité sur l'application et le site web 7night</p>
                </div>
                <div className="subscriptionPack-content-offerts-item">
                  <img src={subcriptionGreenTick} alt="" />
                  <p>Réservation en ligne 24h/24 et 7j/7 </p>
                </div>
                <div className="subscriptionPack-content-offerts-item">
                  <img src={subcriptionGreenTick} alt="" />
                  <p>Fichier clients</p>
                </div>
                <div className="subscriptionPack-content-offerts-item">
                  <img src={subcriptionGreenTick} alt="" />
                  <p>
                    Bouton de réservation Google, Facebook et lien instagram
                  </p>
                </div>
              </div>
              <div className="subscriptionPack-content-offerts-part2">
                <div className="subscriptionPack-content-offerts-item">
                  <img src={subcriptionGreenTick} alt="" />
                  <p>Système de rencontre pour vos clients</p>
                </div>
                <div className="subscriptionPack-content-offerts-item">
                  <img src={subcriptionGreenTick} alt="" />
                  <p>
                    Augmentation des ventes de consommations grâce au système de
                    rencontre
                  </p>
                </div>

                {name === "Basic" ? (
                  <div className="subscriptionPack-content-offerts-item">
                    <img src={subcriptionGrayTick} alt="" />
                    <p style={{ color: "#a8a8a8" }}>
                      Envoyez des notifications personnalisées et ciblées à vos
                      clients
                    </p>
                  </div>
                ) : (
                  <div className="subscriptionPack-content-offerts-item">
                    <img src={subcriptionGreenTick} alt="" />
                    <p>
                      Envoyez des notifications personnalisées et ciblées à vos
                      clients
                    </p>
                  </div>
                )}
              </div>
            </div>
            {linkToManagerProfile ? (
              <div className="subscriptionPack-content-button">
                {authCtx?.isAuthenticated ? (
                  <button>
                    <Link to="/manager/subscription">SÉLECTIONNER</Link>
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      setModalShow(true);
                      LRRPCtx.setDefaultKey("register");
                    }}
                  >
                    SÉLECTIONNER
                  </button>
                )}
              </div>
            ) : hasSubscription ? (
              curentSubscription ? (
                status === "CancelsAtPeriodEnd" ? (
                  <div className="subscriptionPack-content-button reactivate">
                    <button
                      className="currentSub"
                      onClick={() => setReactiveSubscriptionModalShow(true)}
                    >
                      Réactiver
                    </button>
                  </div>
                ) : (
                  <div className="subscriptionPack-content-button cancel">
                    <button
                      className="currentSub"
                      onClick={() => setCancelPaymentModalShow(true)}
                    >
                      Annuler
                    </button>
                  </div>
                )
              ) : status === "CancelsAtPeriodEnd" ? (
                <div className="subscriptionPack-content-button">
                  <button onClick={updatePreviewSubscriptionPack}>
                    {name === "Basic"
                      ? "Réactiver et Rétrograder"
                      : "Réactiver et Mise à niveau"}
                  </button>
                </div>
              ) : (
                <div className="subscriptionPack-content-button">
                  <button onClick={updatePreviewSubscriptionPack}>
                    {name === "Basic" ? "Rétrograder" : "Mise à niveau"}
                  </button>
                </div>
              )
            ) : (
              <div className="subscriptionPack-content-button">
                <button onClick={handleSubscribe}>
                  {loading ? "Chargement..." : "SÉLECTIONNER"}
                </button>
              </div>
            )}
            {dateOfExpire !== undefined && curentSubscription && (
              <div className="expires">{dateOfExpire}</div>
            )}
            {!hasSubscription && trialDays === 60 && (
              <div className="free">2 mois gratuits</div>
            )}
            {status === "CancelsAtPeriodEnd" && curentSubscription && (
              <div className="canceled">
                Annulation en <br /> fin de période
              </div>
            )}
            {status === "active" && curentSubscription && (
              <div className="canceled">Actif</div>
            )}
            {status === "trialing" && curentSubscription && (
              <div className="canceled">Essai</div>
            )}
          </div>
          <LoginRegisterResetPassword
            show={modalShow}
            onHide={() => setModalShow(false)}
          />
          <UpDownGradePayment
            show={upDownGradeModalShow}
            onHide={() => setUpDownGradeModalShow(false)}
            packedId={id}
            priceToAdd={name === "Basic" ? "downgrade" : updatePeviewResponse}
            prices={prices}
          />
          <CancelPayment
            show={cancelPaymentModalShow}
            onHide={() => setCancelPaymentModalShow(false)}
            subscriptionId={subscriptionId}
          />
          <ReactivateSubscription
            show={reactiveSubscriptionModalShow}
            onHide={() => setReactiveSubscriptionModalShow(false)}
            subscriptionId={subscriptionId}
          />
          <PaymentCards
            show={paymentCardsModalShow}
            onHide={() => setPaymentCardModalShow(false)}
            onSuccessCardAdded={handleSubscribe}
            refetch={null}
            setPaymentLoading={setPaymentLoading}
          />
          <RealPayment
            show={realPaymentShow}
            onHide={() => setRealPaymentShow(false)}
            clientSecretCode={clientSecretCode}
            paymentMethod={paymentMethod}
          />
        </div>
      )}
    </Elements>
  );
};
export default Pack;

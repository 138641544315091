import { FC } from "react";
import winkImg from "../../../../../assets/icons/winkImg.svg";
import likeImg from "../../../../../assets/icons/likeImg.svg";
import drinkImg from "../.././../../../assets/icons/drinkImg.svg";
import "./_ChooseRecontre.scss";
import { usePaymentContextContext } from "../../../../../context/PaymentContext/PaymentContext";
import { getClientClientId } from "../../../../../api/Wallet";

interface ChooseRecontreProps {
  id: number;
  productId: string;
  name: string;
  winks: number;
  likes: number;
  drinks: number;
  price: number;
}

const ChooseRecontre: FC<ChooseRecontreProps> = ({
  id,
  name,
  winks,
  likes,
  drinks,
  price,
}) => {
  const { productId, setProductId, setProductPrice, setClientSecretCode } =
    usePaymentContextContext();
  const getClientId = async (product: number) => {
    try {
      const response: any = await getClientClientId(product);
      setClientSecretCode(response?.data);
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <>
      <div
        className={`chooserecontre ${productId === id ? "checkedDiv" : ""}`}
        onClick={() => {
          setProductId(id);
          setProductPrice(price);
          getClientId(id);
        }}
      >
        <input
          type="radio"
          name="packages"
          id={`package-${id}`}
          checked={id === productId}
          onChange={() => {
            setProductId(id);
            setProductPrice(price);
          }}
        />
        <div className={"chooserecontre-part1"}>
          <div className="chooserecontre-part1-1">
            <label
              className="chooserecontre-part1-1-title"
              htmlFor={`package-${id}`}
            >
              {`Pack ${id}`}
            </label>
            <div className="chooserecontre-part1-1-info">
              <div className="chooserecontre-part1-1-info-wld">
                <div className="item">
                  <img src={winkImg} alt="" />
                  <span>{`${winks} wink(s)`}</span>
                </div>
                <div className="item">
                  <img src={likeImg} alt="" />
                  <span>{`${likes} like(s)`}</span>
                </div>
                <div className="item">
                  <img src={drinkImg} alt="" />
                  <span>{`${drinks} drink(s)`}</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`chooserecontre-part1-2 ${
              productId === id ? "checkedDiv-price" : ""
            }`}
          >
            <span>{`${price}€`}</span>
          </div>
        </div>
      </div>
    </>
  );
};
export default ChooseRecontre;

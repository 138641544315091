import { FC } from "react";
import "../_CardsSharedStyle.scss";
import "./_ClubCard.scss";
import ratingStar from "../../../../../assets/icons/star.svg";
import loactionIcon from "../../../../../assets/icons/location.svg";
import { Link } from "react-router-dom";

interface ClubCardProps {
  id: number;
  photo: string;
  name: string;
  averageRating: number;
  address: string;
  city: string;
  distance?: number;
  isOpened: boolean;
}

const ClubCard: FC<ClubCardProps> = ({
  name,
  averageRating,
  photo,
  address,
  id,
  city,
  distance,
  isOpened,
}) => {
  return (
    <section className="clubcard cardssharedstyle">
      <Link to={`/nightclubs/${id}`}>
        <div className="clubcard-img cardssharedstyle-img">
          <img src={photo} alt="" />
        </div>
        {isOpened && (
          <div className="badges">
            <p className="badges-green">OUVERT AUJOURD'HUI</p>
          </div>
        )}
        <div className="clubcard-content cardssharedstyle-content">
          <div>
            <h2>{name}</h2>
            <div className="info-item">
              <img className="info-item-location" src={loactionIcon} alt="" />
              {distance === (undefined || null) ? (
                <span className="address">{city}</span>
              ) : (
                <span className="address">{`${city} (${distance?.toFixed(
                  1
                )} km)`}</span>
              )}
            </div>
          </div>
          <div className="rating-info-item">
            <img className="ratingstar" src={ratingStar} alt="" />
            <h3>
              <b>{averageRating?.toFixed(1)}</b>
            </h3>
          </div>
        </div>
      </Link>
    </section>
  );
};

export default ClubCard;

import { apiRequest } from "./Api";
import { favoritesBusinessArray } from "./Business";
import { metaData, responseBody } from "./Shared";
import { favoritesEveningArray } from "./Events";
import { DjBasicInfo } from "./Dj";

// ------UserProfileVisibility-----
export interface userProfileVisibilityResponse extends responseBody {
  data: boolean;
}

// ------UserSwitchProfileVisibility-----
export interface userSwitchProfileVisibilityResponse extends responseBody {
  data: boolean;
}

// ------UserProfile-----
export interface userProfileResponse {
  userProfile: {
    id: string;
    profilePicture: string;
    firstName: string;
    lastName: string;
    email: string;
    birthdate: string;
    gender: number;
    phoneNumber: string;
    language: number;
    isPublic: boolean;
    interestedInMale: boolean;
    interestedInFemale: boolean;
  };
  message: string;
  success: boolean;
}

// -----UserProfileUpdate------
export interface updateUserProfileInputs {
  name?: string;
  lastName?: string;
  birthdate?: string;
  gender?: string | number;
  interestedInMale?: boolean;
  interestedInFemale?: boolean;
}

// ----ChangePassword-----
export interface changePasswordInput {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}
export interface changePasswordResponse {
  message: string;
  success: boolean;
}

// ----ChangeProfilePhoto----
export interface changeProfilePhotoInput {
  photo: {
    fileName: string;
    base64: string;
  };
}
export interface changeProfilePhotoResponse {
  profilePicture: string;
  message: string;
  success: boolean;
}

// ----AllFavorites----
export interface GetAllFavoritesResponse extends responseBody {
  data: {
    data: (favoritesBusinessArray | favoritesEveningArray | DjBasicInfo)[];
    metaData: metaData;
  };
}

// -----Notifications-----
export interface notification {
  notificationType: string;
  id: number;
  title: string;
  description: string;
  isRead: boolean;
  insertedDate: string;
  metaData: {
    reservationId: string;
    firstName: string;
    lastName: string;
    profilePicture: string;
    reactionType: string;
    senderId: string;
    age: string;
    badge: string;
    type: string;
  };
  from: string;
}
export interface getAllNotifications extends responseBody {
  data: {
    data: notification[];
    metaData: metaData;
  };
}

// -------apiRequests---------
export const getUserProfileVisibility = async () =>
  apiRequest<userProfileVisibilityResponse>("get", "/User/ProfileVisibility");
export const toggleProfileVisibility = async () =>
  apiRequest<userSwitchProfileVisibilityResponse>(
    "put",
    "/User/SwitchProfileVisibility"
  );
export const getProfileVisibility = async () =>
  apiRequest<userProfileVisibilityResponse>("get", "/User/profileVisibility");
export const getUserProfile = async () =>
  apiRequest<userProfileResponse>("get", "/User/UserProfile");
export const updateUserProfile = async (data: updateUserProfileInputs) =>
  apiRequest<updateUserProfileInputs, responseBody>(
    "post",
    "/User/UpdateUserProfile",
    data
  );
export const changePassword = async (data: changePasswordInput) =>
  apiRequest<changePasswordInput, changePasswordResponse>(
    "post",
    "/User/ChangePassword",
    data
  );
export const changeProfilePhoto = async (data: changeProfilePhotoInput) =>
  apiRequest<changeProfilePhotoInput, changeProfilePhotoResponse>(
    "post",
    "/User/ChangeProfilePicture",
    data
  );
export const deleteProflePhoto = async () =>
  apiRequest<responseBody>("post", "/User/DeleteProfilePicture");
export const getAllFavorites = async () =>
  apiRequest<GetAllFavoritesResponse>("get", "/User/allFavorite");
export const getUserNotifications = async () =>
  apiRequest<getAllNotifications>("get", "/Notification/my");
export const putAsReaded = async (data: { id: number }) =>
  apiRequest<responseBody>("put", `/Notification/read/${data.id}`);
export const putAllAsReaded = async () =>
  apiRequest<responseBody>("post", "/Notification/MarkAllAsRead");
export const areNotificationsOn = async () =>
  apiRequest<responseBody>("get", "/Notification/AreNotificationsOn");
export const switchNotifications = async () =>
  apiRequest<responseBody>("put", "/Notification/SwitchNotifications");

import axios from "../../initializations/axios";
import { UserNotificationPayloadModel } from "../../interfaces/models/postModels/NotificationPayload.model";

export const sendNotificationUserManager = async (
  payload: UserNotificationPayloadModel
) => await axios.post("UserManager/sendNotification", payload);

export const blockUserManager = async (id: string) =>
  await axios.put(`UserManager/block/${id}`);

export const unblockUserManager = async (id: string) =>
  await axios.put(`UserManager/unblock/${id}`);

export const deleteUserManager = async (id: string) =>
  await axios.delete(`UserManager/DeleteUser/${id}`);

import { ChangeEvent, ReactElement, useState } from "react";
import { Modal } from "react-bootstrap";
import uploadImg from "../../assets/icons/uploadImg.png";
import { UseConvertToBase64 } from "../../hooks/UseConvertToBase64";
import { usePostEventPhotoQuery } from "../../react-query/hooks/Events";

interface photosProps {
  base64: unknown;
  fileName: string;
}
const AddNewEventPhoto = (props: any): ReactElement => {
  const [photos, setPhotos] = useState<photosProps[]>([]);
  const uploadFile = async (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files!;
    for (let i = 0; i < files.length; i++) {
      const fileInBase64 = await UseConvertToBase64(files[i]);
      setPhotos((prev) => [
        ...prev,
        {
          base64: fileInBase64,
          fileName: `${files[i]?.name}`,
        },
      ]);
    }
  };
  const {
    mutate: addEventPhoto,
    isLoading,
    isSuccess,
  } = usePostEventPhotoQuery(props.eventId, props.pageNumber);
  const handlePostPhoto = () => {
    addEventPhoto({ eventId: props.eventId, image: photos });
    setTimeout(() => {
      props.onHide();
    }, 3000);
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="dashboardModal"
      onHide={() => {
        props.onHide();
        setPhotos([]);
      }}
    >
      <Modal.Header>
        <h4>Télécharger une photo</h4>
      </Modal.Header>
      <Modal.Body>
        <div className="modalBody">
          <label className="imageInput modalItem">
            <input
              type="file"
              multiple
              accept="image/png, image/jpeg"
              onChange={(e) => {
                uploadFile(e);
              }}
            />
            <div className="imageInput-style">
              <img src={uploadImg} alt="" />
              <h6 style={{ textAlign: "center" }}>
                Déposez votre image ici, ou parcourez sur votre appareil
              </h6>
              <p>Format requis: JPG, PNG</p>
            </div>
          </label>
          {photos?.length > 0 && (
            <div className="photosAdded">
              {photos?.map((photo, index) => {
                return (
                  <img
                    src={photo.base64 as string}
                    alt="photoUploaded"
                    key={index}
                  />
                );
              })}
            </div>
          )}
          <div className="modalBody-buttons modalItem">
            <button
              onClick={() => {
                props.onHide();
                setPhotos([]);
              }}
            >
              Annuler
            </button>
            <button
              onClick={() => {
                handlePostPhoto();
                props.onHide();
                setPhotos([]);
              }}
              disabled={!(photos.length > 0) || isLoading}
            >
              Ajouter
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default AddNewEventPhoto;

import { FC } from "react";
import { Link } from "react-router-dom";
import { UsersProps } from "./AdminDashboardS2.props";
import defaultProfile from "../../../../assets/photos/defaultProfile.png";

const AdminDashboardS2: FC<UsersProps> = ({ data = [], ...rest }) => {
  return (
    <div className="dashboards2">
      <h5>Utilisateurs</h5>
      {data.slice(3).map(({ profilePic, firstName, lastName, email, id }) => (
        <div key={id} className="dashboards2-user">
          <img src={profilePic || defaultProfile} alt="profilePic" />
          <div className="item">
            <h6>{`${firstName} ${lastName}`}</h6>
            <p>{email}</p>
          </div>
        </div>
      ))}
      <div className="dashboards2-link">
        <Link to="users">Voir Plus</Link>
      </div>
    </div>
  );
};

export default AdminDashboardS2;

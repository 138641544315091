import { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Navbar, Nav, Dropdown } from "react-bootstrap";
import defaultProfile from "../../../assets/photos/defaultProfile.png";
import mainLogo from "../../../assets/icons/mainLogo.png";
import dashboardIcon from "../../../assets/icons/dashboardIcon.png";
import bellIcon from "../../../assets/icons/bellIcon.svg";
import navProfileIcon from "../../../assets/icons/navProfileIcon.svg";
import disconnectIcon from "../../../assets/icons/disconnectIcon.svg";
import "./_Header.scss";
import LoginRegisterResetPassword from "../../modals/LoginRegisterResetPassword/LoginRegisterResetPassword";
import { useAuthContext } from "../../../context/AuthContext/AuthContext";
import { useLoginRegisterResetPasswordContext } from "../../../context/LoginRegisterResetPasswordContext/LoginRegisterResetPasswordContext";
import { Link } from "react-router-dom";
import Notifications from "../../components/shared/Notifications/Notifications";

const Header = () => {
  const [modalShow, setModalShow] = useState(false);
  const [expanded, setExpanded] = useState<boolean>(false);
  const authCtx = useAuthContext();
  const LRRPCtx = useLoginRegisterResetPasswordContext();
 
  const shareData = {
    title: "7Night - Réservez dans les meilleurs boites de nuit de France",
    text: "Trouve les plus belles soirées en club, reçois des promotions et bien d’autres avantages avec notre partie rencontre. Réservation facile.",
    url: "https://www.7night.fr/",
  };
  const shareLink = async () => {
    try {
      await navigator.share(shareData);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <header>
      <Navbar
        collapseOnSelect
        bg="light"
        expand="xl"
        style={{ zIndex: "4" }}
        expanded={expanded}
      >
        <NavLink to="/" className="mainLogo">
          <img src={mainLogo} alt="" />
        </NavLink>
        <div className="navbar-togglerMenu">
          {authCtx.user !== undefined && (
            <div className="navbar-togglerMenu-icons">
              <Dropdown className="notifications">
                <Dropdown.Toggle
                  id="dropdown-autoclose-true "
                  className="notifications-button"
                >
                  <img src={bellIcon} alt="" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="notifications-items">
                  <Notifications />
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={() => setExpanded(expanded ? false : true)}
          />
        </div>
        <Navbar.Collapse className="navbar-links" id="responsive-navbar-nav">
          <div></div>
          <Nav className="navbar-links-a">
            {authCtx.user !== undefined && (
              <>
                <NavLink
                  to="/profile"
                  className="profileNavItem"
                  onClick={() => setExpanded(false)}
                >
                  Profil
                </NavLink>

                {(authCtx?.decoded?.role === "Admin" ||
                  authCtx?.decoded?.role === "Manager") && (
                  <NavLink
                    to={`/${authCtx?.decoded?.role?.toLowerCase()}`}
                    className="profileNavItem"
                    onClick={() => setExpanded(false)}
                  >
                    Tableau de bord
                  </NavLink>
                )}
                <hr />
              </>
            )}
            <NavLink
              to="/"
              end
              data-toggle="collapse"
              onClick={() => setExpanded(false)}
            >
              Accueil
            </NavLink>
            <NavLink to="/nightclubs" onClick={() => setExpanded(false)}>
              Boites de nuit
            </NavLink>
            <NavLink to="/evenings" onClick={() => setExpanded(false)}>
              Évènements
            </NavLink>
            <NavLink to="/djs" onClick={() => setExpanded(false)}>
              DJs
            </NavLink>
            <NavLink to="/contact" onClick={() => setExpanded(false)}>
              Contact
            </NavLink>
            {authCtx.user !== undefined && (
              <>
                <hr />
                <button
                  className="profileShareItem"
                  onClick={() => {
                    shareLink();
                    setExpanded(false);
                  }}
                >
                  Partager à un ami
                </button>
                <Link
                  to="/"
                  className="disconnectButton"
                  onClick={() => {
                    authCtx.logout();
                    LRRPCtx.setModalshow(false);
                    setExpanded(false);
                    LRRPCtx.setPage(1);
                  }}
                >
                  Déconnecter
                </Link>
              </>
            )}
          </Nav>
          <Nav className="navbar-links-b">
            {authCtx.user === undefined ? (
              <div className="navbar-links-b-unAuth">
                <Dropdown className="d-inline" autoClose="outside">
                  <Dropdown.Toggle id="dropdown-autoclose-true">
                    Espace Club
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => setExpanded(false)}>
                      <NavLink
                        to="clubspace"
                        className="dropdown-item"
                        tabIndex={0}
                      >
                        Accueil
                      </NavLink>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setExpanded(false)}>
                      <NavLink
                        to="why-7night"
                        className="dropdown-item"
                        tabIndex={0}
                      >
                        Pourquoi 7Night ?
                      </NavLink>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setExpanded(false)}>
                      <NavLink
                        to="offers-and-rates"
                        className="dropdown-item"
                        tabIndex={0}
                      >
                        Offres et tarifs
                      </NavLink>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <button
                  className="login-btn"
                  onClick={() => {
                    setModalShow(true);
                    setExpanded(false);
                  }}
                >
                  Se Connecter
                </button>
              </div>
            ) : (
              <div className="navbar-links-b-profile">
                <button className="shareToFriends" onClick={() => shareLink()}>
                  Partager à un ami
                </button>
                <Dropdown className="notifications" autoClose="outside">
                  <Dropdown.Toggle
                    id="dropdown-autoclose-true "
                    className="notifications-button"
                  >
                    <img src={bellIcon} alt="" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="notifications-items">
                    <Notifications />
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown className="profileNav">
                  <Dropdown.Toggle
                    id="dropdown-autoclose-true "
                    className="profileNav-button"
                  >
                    <img
                      src={authCtx?.userProfilePicture || defaultProfile}
                      alt=""
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="profileNav-items">
                    <Dropdown.Item>
                      <NavLink
                        to="profile"
                        className="dropdown-item"
                        tabIndex={0}
                      >
                        <img src={navProfileIcon} alt="" />
                        <span>Profil</span>
                      </NavLink>
                    </Dropdown.Item>
                    {(authCtx?.decoded?.role === "Admin" ||
                      authCtx?.decoded?.role === "Manager") && (
                      <Dropdown.Item>
                        <Link to={`/${authCtx?.decoded?.role?.toLowerCase()}`}>
                          <img src={dashboardIcon} alt="" />
                          <span>Tableau de bord</span>
                        </Link>
                      </Dropdown.Item>
                    )}
                    <Dropdown.Item>
                      <Link
                        to="/"
                        onClick={() => {
                          authCtx.logout();
                          LRRPCtx.setModalshow(false);
                          LRRPCtx.setPage(1);
                        }}
                      >
                        <img src={disconnectIcon} alt="" />
                        <span>Déconnecter</span>
                      </Link>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}
          </Nav>
        </Navbar.Collapse>
        <LoginRegisterResetPassword
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      </Navbar>
    </header>
  );
};

export default Header;

import axios from "../../initializations/axios";
import { EventPhotoPostModel } from "../../interfaces/models/postModels/EventPhotoPost.model";
import { EventPostModel } from "../../interfaces/models/postModels/EventPost.model";
import { EventReservationPostModel } from "../../interfaces/models/postModels/EventReservationPost.model";
import { EventTablePostModel } from "../../interfaces/models/postModels/EventTablePost.model";
import { EventUpdateModel } from "../../interfaces/models/updateModels/EventUpdate.model";
import { TableUpdateModel } from "../../interfaces/models/updateModels/TableUpdate.model";

interface searchInput {
  searchWord: string;
}

// -----DELETE-----
export const deleteEvent = async (id: number) => {
  const data = await axios.delete(`BusinessEvents/${id}`);
  return data;
};
export const deleteEventPhoto = async (id: number) =>
  await axios.delete(`BusinessEvents/gallery/${id}`);
export const deleteEventTable = async (id: number) =>
  await axios.delete(`EventTables/${id}`);

// -----POST-----
export const searchEvents = async (searchInput: searchInput) => {
  const { data } = await axios.post(
    "BusinessEvents/searchDashboard",
    searchInput
  );
  return data;
};
export const addEvent = async (data: EventPostModel) =>
  await axios.post("BusinessEvents", data);
export const addEventPhoto = async (data: EventPhotoPostModel) =>
  await axios.post("BusinessEvents/gallery", data);

export const addEventTable = async (data: EventTablePostModel) =>
  await axios.post("EventTables", data);

export const addParticipant = async (
  id: string | undefined,
  reservationData: EventReservationPostModel
) => await axios.post(`Reservation/addReservation/${id}`, reservationData);

// -----PUT-----
export const updateEvent = async (UpdateEventData: EventUpdateModel) => {
  const { status } = await axios.put("BusinessEvents", UpdateEventData);
  return status;
};
export const updateEventTable = async (data: TableUpdateModel) =>
  await axios.put("EventTables", data);

import "../Register/_Register.scss";
import * as Yup from "yup";
import { SignUpPostModel } from "../../../../interfaces/models/postModels/SignUpPost.model";
import { signUp } from "../../../../react-query/mutations/loginRegister";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLoginRegisterResetPasswordContext } from "../../../../context/LoginRegisterResetPasswordContext/LoginRegisterResetPasswordContext";

const RegisterManager = () => {
  const schema = Yup.object().shape({
    firstName: Yup.string().required("Obligatoire"),
    lastName: Yup.string().required("Obligatoire"),
    email: Yup.string()
      .email("Format d'email invalide")
      .required("Obligatoire"),
    password: Yup.string()
      .min(8, "Le mot de passe doit comporter 8+ caractères")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/,
        "Les mots de passe doivent contenir au moins une lettre majuscule : A-Z, un chiffre et des symboles : !, @, #, $, %"
      )
      .required("Aucun mot de passe fourni"),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Les mots de passe doivent correspondre"
    ),
    birthdate: Yup.string().required("Obligatoire"),
  });
  const [error, setError] = useState<any>();
  const LRRPCtx = useLoginRegisterResetPasswordContext();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<SignUpPostModel>({
    resolver: yupResolver(schema),
  });
  const handleSignUp = async (RegisterInputs: SignUpPostModel) => {
    delete RegisterInputs.confirmPassword;
    try {
      const response: any = await signUp(
        { ...RegisterInputs, language: LRRPCtx.languageId },
        `${LRRPCtx.defaultKey}`
      );
      response?.data.isSuccess && LRRPCtx.setEmail(RegisterInputs.email);
      response?.data.isSuccess && LRRPCtx.setPage(5);
    } catch (e: any) {
      setError(
        e.response?.data?.errors[0]?.message ||
          e.response?.data?.errors.Birthdate[0]
      );
    }
  };
  return (
    <div className="register">
      <form onSubmit={handleSubmit(handleSignUp)}>
        <div className="first-input">
          <div className="inputs">
            <input
              type="text"
              id="firstName"
              placeholder="Nom"
              {...register("firstName")}
            />
            {errors.firstName && (
              <p className="error">{errors.firstName.message}</p>
            )}
          </div>
          <div className="inputs">
            <input
              type="text"
              id="lastName"
              placeholder="Nom de famille"
              {...register("lastName")}
            />
            {errors.lastName && (
              <p className="error">{errors.lastName.message}</p>
            )}
          </div>
        </div>
        <div className="inputs">
          <input
            type="email"
            id="email"
            placeholder="Email"
            {...register("email")}
          />
          {errors.email && <p className="error">{errors.email.message}</p>}
        </div>
        <div className="inputs">
          <input
            type="password"
            id="password"
            placeholder="Mot de passe"
            {...register("password")}
          />
          {errors.password && (
            <p className="error">{errors.password.message}</p>
          )}
        </div>
        <div className="inputs">
          <input
            type="password"
            id="confirmPassword"
            placeholder="Confirmer le mot de passe"
            {...register("confirmPassword")}
          />
          {errors.confirmPassword && (
            <p className="error">{errors.confirmPassword.message}</p>
          )}
        </div>
        <div className="second-input">
          <div className="inputs">
            <input type="date" id="birthdate" {...register("birthdate")} />
            {errors.birthdate && (
              <p className="error">{errors.birthdate.message}</p>
            )}
          </div>
        </div>
        <button type="submit" className="register-btn" disabled={isSubmitting}>
          {isSubmitting
            ? "Patientez s'il vous plait..."
            : "S'inscrire en tant que manager"}
        </button>
        {!!error && (
          <p
            style={{
              color: "red",
              fontSize: "14px",
              padding: "0",
              paddingTop: "8px",
              textAlign: "center",
            }}
          >
            {error}
          </p>
        )}
      </form>
    </div>
  );
};

export default RegisterManager;

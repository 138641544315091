import { useState } from "react";
import { useLoginRegisterResetPasswordContext } from "../../../../context/LoginRegisterResetPasswordContext/LoginRegisterResetPasswordContext";
import { ConfirmAccountPostModel } from "../../../../interfaces/models/postModels/ConfirmAccountPost.model";
import {
  confrimAccount,
  resendConfirmationCode,
} from "../../../../react-query/mutations/loginRegister";
import "../_ChangeResetVerifyStyle.scss";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useAuthContext } from "../../../../context/AuthContext/AuthContext";

const schema = Yup.object().shape({
  confirmationCode: Yup.string()
    .min(6, "Le code a 6 chiffres")
    .max(6, "Le code a 6 chiffres")
    .required("Obligatoire"),
});
interface getNewCodeValues {
  email: string;
}
const ConfirmAccount = () => {
  const LRRPCtx = useLoginRegisterResetPasswordContext();
  const authCtx = useAuthContext();
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<any>();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<ConfirmAccountPostModel>({
    resolver: yupResolver(schema),
  });

  const getNewCodeValues = {
    email: LRRPCtx.email,
    languageId: LRRPCtx.languageId,
  };
  const getNewCode = async (getNewCodeValues: getNewCodeValues) => {
    try {
      await resendConfirmationCode(getNewCodeValues);
    } catch (e) {}
  };
  const handleConfirmAccount = async (
    ConfirmAccountInputs: ConfirmAccountPostModel
  ) => {
    try {
      const response: any = await confrimAccount({
        ...ConfirmAccountInputs,
        email: LRRPCtx.email,
      });
      setSuccess(response?.data?.isSuccess);
      authCtx.login(response?.data);
      setTimeout(() => {
        response?.data?.isSuccess &&
          LRRPCtx.setModalshow(response?.data?.isSuccess);
      }, 2000);
    } catch (e: any) {
      setError(e.response?.data?.message);
    }
  };
  return (
    <div className="changeresetverifystyle">
      <div>
        <div className="changeresetverifystyle-info">
          <h3>Confirmer le compte</h3>
          <p>
            Veuillez entrer le code à 6 chiffres qui vous a été envoyé à{" "}
            <span>{LRRPCtx.email}</span>
          </p>
        </div>
        <form onSubmit={handleSubmit(handleConfirmAccount)}>
          <label htmlFor="resetPasswordCode">Entrer le code</label>
          <div className="input">
            <input
              type="text"
              id="confirmationCode"
              {...register("confirmationCode")}
            />
            {errors.confirmationCode && (
              <p className="error">{errors.confirmationCode.message}</p>
            )}
          </div>
          <button disabled={isSubmitting} type="submit" className="submit-btn">
            {isSubmitting ? "Soumettre..." : "Soumettre"}
          </button>
        </form>
        {!!error && (
          <p className="error" style={{ textAlign: "center" }}>
            {error}
          </p>
        )}
        {success && (
          <p style={{ textAlign: "center", marginTop: "10px" }}>
            Votre compte est confirmé avec succès !
          </p>
        )}
      </div>
      <div className="changeresetverifystyle-bottom">
        <p>Vous n'avez pas reçu le code ?</p>
        <button onClick={() => getNewCode(getNewCodeValues)}>
          Obtenez un nouveau
        </button>
      </div>
    </div>
  );
};
export default ConfirmAccount;

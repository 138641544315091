import { ReactElement, useState } from "react";
import LoadingSpinner from "../../../../components/LoadingSpinner/LoadingSpinner";
import MuiPagination from "../../../../components/MuiPagination/MuiPagination";
import { useAuthContext } from "../../../../context/AuthContext/AuthContext";
import { useSubscriptionHistoryQuery } from "../../../../react-query/hooks/Subscription";
import SubscriptionInfo from "../../Molecules/SubscriptionInfo/SubscriptionInfo";
import { NoResults } from "../NoResults/NoResults";

const SubscriptionHistory = (): ReactElement => {
  const authCtx = useAuthContext();
  const [page, setPage] = useState<number>(1);
  const { data: { data, metaData } = {}, isLoading } =
    useSubscriptionHistoryQuery(authCtx?.user?.id, page);
  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="subscription">
          {data?.length! > 0 ? (
            <div className="dashboardResults">
              <table>
                <tr>
                  <th>DATE</th>
                  <th>UTILISATEUR</th>
                  <th>PACK/PRODUIT</th>
                  <th>PRIX</th>
                  <th style={{ textAlign: "center" }}>DOWNLOAD</th>
                </tr>
                {data?.map((subscription: any) => {
                  return (
                    <SubscriptionInfo
                      key={subscription.id}
                      date={subscription.date}
                      userEmail={subscription.email}
                      subscriptionName={subscription.productName}
                      chargedAmount={subscription.price}
                      bill={subscription.invoiceInPdf}
                    />
                  );
                })}
              </table>
              <div className="dashboardPagination">
                <MuiPagination
                  pagesNumber={metaData?.pageCount!}
                  page={page}
                  setPage={setPage}
                />
              </div>
            </div>
          ) : (
            <NoResults />
          )}
        </div>
      )}
    </>
  );
};
export default SubscriptionHistory;

import { FC, ReactElement, useEffect, useState } from "react";
import LoadingSpinner from "../../../../components/LoadingSpinner/LoadingSpinner";
import MuiPagination from "../../../../components/MuiPagination/MuiPagination";
import { useAuthContext } from "../../../../context/AuthContext/AuthContext";
import { useClubReviewQuery } from "../../../../react-query/hooks/Clubs";
import RateInfo from "../../Molecules/RateInfo/RateInfo";
import { NoResults } from "../NoResults/NoResults";

interface ClubPricingProps {
  id: string;
}
const ClubRate: FC<ClubPricingProps> = ({ id }): ReactElement => {
  const authCtx = useAuthContext();
  const [page, setPage] = useState(1);
  const { data: { data } = {}, isLoading } = useClubReviewQuery(+id, page);
  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div>
          {data?.data?.length! > 0 ? (
            <div className="dashboardResults">
              <table>
                <tr>
                  <th>UTILISATEUR</th>
                  <th>COMMENTAIRE</th>
                  <th>ÉTOILES</th>
                  {authCtx?.decoded?.role === "Admin" && (
                    <th className="lastElement actionColumn">ACTION</th>
                  )}
                </tr>
                {data?.data?.map((review) => {
                  return (
                    <RateInfo
                      key={review?.id}
                      reviewId={review?.id}
                      firstName={review?.firstName}
                      email={review?.email}
                      comment={review?.comment}
                      rating={review?.rating}
                      clubId={+id}
                      pageNumber={page}
                    />
                  );
                })}
              </table>
              <div className="dashboardPagination">
                <MuiPagination
                  pagesNumber={data?.metaData?.pageCount!}
                  page={page}
                  setPage={setPage}
                />
              </div>
            </div>
          ) : (
            <NoResults text="Aucun avis n’a été donné sur votre club pour le moment" />
          )}
        </div>
      )}
    </>
  );
};
export default ClubRate;

import "./_ProfilePersonalData.scss";
import * as yup from "yup";
import {
  getProfileVisibility,
  toggleProfileVisibility,
  updateUserProfile,
} from "../../../../api/User";
import { useEffect, useState } from "react";
import successIcon from "../../../../assets/icons/successIcon.png";
import { useUserProfile } from "../../../../react-query/hooks/User";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Switch from "@mui/material/Switch";
import visibilityIcon from "../../../../assets/icons/visibilityIcon.png";
import { useAuthContext } from "../../../../context/AuthContext/AuthContext";
import LoadingSpinner from "../../../../components/LoadingSpinner/LoadingSpinner";

interface updateProfileInputs {
  genderId?: number;
  name?: string;
  firstName?: string;
  lastName?: string;
  birthdate?: string;
  interestedInMale?: boolean;
  interestedInFemale?: boolean;
  phoneNumber?: string;
  businessName?: string;
  businessAddress?: string;
  businessVat?: string;
  siretNumber?: string;
}

const schema = yup.object().shape({
  genderId: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value)),
  name: yup.string().required("Obligatoire"),
  lastName: yup.string().required("Obligatoire"),
  birthdate: yup.string().required("Obligatoire"),
  interestedInMale: yup.boolean().required(),
  interestedInFemale: yup.boolean().required(),
  phoneNumber: yup.string(),
  businessName: yup.string(),
  businessAddress: yup.string(),
  businessVat: yup
    .string()
    .matches(
      /^[A-Z]{2}[0-9A-Z]{2,12}$/,
      `Le numéro de TVA n'est pas au bon format`
    )
    .transform((value) => (value === "" ? null : value))
    .nullable(),
  siretNumber: yup.string(),
});

const ProfilePersonalData = () => {
  const authCtx = useAuthContext();
  const [update, setUpdate] = useState<boolean>(false);
  const { data, refetch, isLoading } = useUserProfile({
    onSuccess: (data) =>
      reset({
        genderId: data?.data?.gender,
      }),
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<updateProfileInputs>({
    resolver: yupResolver(schema),
  });

  const [visibility, setVisibility] = useState<boolean>(false);
  const handleUpdateProfile = async (
    UpdateProfileInputs: updateProfileInputs
  ) => {
    try {
      await updateUserProfile(UpdateProfileInputs);
      setUpdate((prev) => !prev);
    } catch (e) {
      console.log(e);
    }
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    putProfileVisibility();
  };

  let birthday = data?.data?.birthdate?.slice(0, 10);

  const isProfileVisibility = async () => {
    try {
      const response: any = await getProfileVisibility();
      setVisibility(response?.data);
    } catch (e) {
      console.log(e);
    }
  };

  const putProfileVisibility = async () => {
    try {
      const response: any = await toggleProfileVisibility();
      setVisibility(response?.data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    isProfileVisibility();
  }, []);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.data, update]);

  if (isLoading) return <LoadingSpinner />;
  return (
    <div className="profilepersonaldata">
      <h3 className="profilepersonaldata-title">Personal Data</h3>
      <div className="profilenotifications-content-onOff">
        <img src={visibilityIcon} alt="" />
        <div className="profilenotifications-content-onOff-part2">
          <div>
            <h3>Être visible dans les listes des participants</h3>
          </div>
          <div>
            <Switch
              checked={visibility}
              size="small"
              onChange={handleChange}
              inputProps={{ "aria-label": "controlled" }}
            />
          </div>
        </div>
      </div>
      <form onSubmit={handleSubmit(handleUpdateProfile)}>
        <div className="profilepersonaldata-inputs-part1">
          <div className="input">
            <label htmlFor="">Prénom</label>
            <input
              type="text"
              defaultValue={data?.data?.firstName}
              id="name"
              {...register("name")}
            />
            {errors.name && (
              <p className="tw-text-red-600 mb-0">{errors.name.message}</p>
            )}
          </div>
          <div className="input">
            <label htmlFor="">Nom de famille</label>
            <input
              type="text"
              id="lastName"
              defaultValue={data?.data?.lastName}
              {...register("lastName")}
            />
            {errors.lastName && (
              <p className="tw-text-red-600 mb-0">{errors.lastName.message}</p>
            )}
          </div>
          <div className="input">
            <label htmlFor="">Date d'anniversaire</label>
            <input
              type="date"
              id="birthdate"
              defaultValue={birthday}
              {...register("birthdate")}
            />
            {errors.birthdate && (
              <p className="tw-text-red-600 mb-0">{errors.birthdate.message}</p>
            )}
          </div>
          <div className="input">
            <label htmlFor="">Email</label>
            <input type="email" disabled placeholder={data?.data?.email} />
          </div>
          <div className="input">
            <label htmlFor="">Téléphone</label>
            <input
              type="text"
              id="phoneNumber"
              defaultValue={data?.data?.phoneNumber}
              {...register("phoneNumber")}
            />
            {errors.phoneNumber && (
              <p className="tw-text-red-600 mb-0">
                {errors.phoneNumber.message}
              </p>
            )}
          </div>
          {authCtx?.decoded?.role === "Manager" && (
            <>
              <div className="input">
                <label htmlFor="businessName">Raison Sociale</label>
                <input
                  type="text"
                  id="businessName"
                  defaultValue={data?.data?.businessName}
                  {...register("businessName")}
                />
                {errors.businessName && (
                  <p className="tw-text-red-600 mb-0">
                    {errors.businessName.message}
                  </p>
                )}
              </div>
              <div className="input">
                <label htmlFor="businessAddress">Adresse de I'entreprise</label>
                <input
                  type="text"
                  id="businessAddress"
                  defaultValue={data?.data?.businessAddress}
                  {...register("businessAddress")}
                />
                {errors.businessAddress && (
                  <p className="tw-text-red-600 mb-0">
                    {errors.businessAddress.message}
                  </p>
                )}
              </div>
              <div className="input">
                <label htmlFor="siretNumber">Numéro de Siret</label>
                <input
                  type="text"
                  id="siretNumber"
                  defaultValue={data?.data?.siretNumber}
                  {...register("siretNumber")}
                />
                {errors.siretNumber && (
                  <p className="tw-text-red-600 mb-0">
                    {errors.siretNumber.message}
                  </p>
                )}
              </div>
              <div className="input">
                <label htmlFor="businessVat">Numéro de TVA</label>
                <input
                  type="text"
                  id="businessVat"
                  defaultValue={data?.data?.businessVat}
                  {...register("businessVat")}
                />
                {errors.businessVat && (
                  <p className="tw-text-red-600 mb-0">
                    {errors.businessVat.message}
                  </p>
                )}
              </div>
            </>
          )}
          {authCtx?.decoded?.role !== "Manager" && (
            <div className="input">
              <label htmlFor="">Genre</label>
              <select id="genderId" {...register("genderId")}>
                <option value="1">Garçon</option>
                <option value="2">Fille</option>
              </select>
            </div>
          )}
        </div>
        <div className="profilepersonaldata-inputs-part2">
          <label htmlFor="">Sélectionné le genre qui vous intéresse</label>
          <div className="gender-choises">
            <div className="choise">
              <input
                type="checkbox"
                id="interestedInMale"
                defaultChecked={data?.data?.interestedInMale}
                {...register("interestedInMale")}
              />
              <label htmlFor="interestedInMale">Garçon</label>
            </div>
            <div className="choise">
              <input
                type="checkbox"
                id="interestedInFemale"
                defaultChecked={data?.data?.interestedInFemale}
                {...register("interestedInFemale")}
              />
              <label htmlFor="interestedInFemale">Fille</label>
            </div>
          </div>
        </div>
        <div className="profilepersonaldata-inputs-part3">
          <button type="submit" disabled={isSubmitting}>
            {isSubmitting ? "Sauvegarder..." : "Sauvegarder"}
          </button>
        </div>
      </form>
      {update && (
        <div className="successMessage">
          <img src={successIcon} alt="" />
          <p>Vos données personnelles ont été mises à jour!</p>
        </div>
      )}
    </div>
  );
};

export default ProfilePersonalData;

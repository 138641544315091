import "./_ProfileNotifications.scss";
import {
  areNotificationsOn,
  getUserNotifications,
  notification,
  putAllAsReaded,
  putAsReaded,
  switchNotifications,
} from "../../../../api/User";
import { useEffect, useState } from "react";
import NotificationCard from "../../../components/ProfilePages/ProfileNotification/NotificationCard/NotificationCard";
import Switch from "@mui/material/Switch";
import bellIcon from "../../../../assets/icons/bellIcon.png";
import NoNotification from "../../../page-sections/ProfilePages/ProfileNotifications/NoNotification/NoNotification";
import LoadingSpinner from "../../../../components/LoadingSpinner/LoadingSpinner";

const ProfileNotifications = () => {
  const [notifications, setNotifications] = useState<notification[]>();
  const [isNotificationsOn, setIsNotificationsOn] = useState<boolean>();
  const [loading, setLoading] = useState<boolean>(true);

  const getAllProfileNotifications = async () => {
    try {
      const response: any = await getUserNotifications();
      setNotifications(response?.data?.data);
      response?.isSuccess && setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };
  const putNotificationAsReaded = async (id: number) => {
    try {
      await putAsReaded({ id });
    } catch (e) {
      console.log(e);
    }
  };
  const putNotificationsAsAllReaded = async () => {
    try {
      await putAllAsReaded();
    } catch (e) {
      console.log(e);
    }
  };

  const areUserNotificationsOn = async () => {
    try {
      const response: any = await areNotificationsOn();
      setIsNotificationsOn(response?.data);
    } catch (e) {
      console.log(e);
    }
  };

  const toggleNotifications = async () => {
    try {
      const response: any = await switchNotifications();
      setIsNotificationsOn(response?.data);
    } catch (e) {
      console.log(e);
    }
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    toggleNotifications();
  };
  const handlePutAllNotificationsAsReaded = () => {
    putNotificationsAsAllReaded();
    setLoading(true);
    setTimeout(() => {
      getAllProfileNotifications();
      setLoading(false);
    }, 500);
  };
  useEffect(() => {
    getAllProfileNotifications();
    areUserNotificationsOn();
  }, []);

  const readItems = notifications?.filter((item) => {
    return item?.isRead === true;
  });
  const unreadItems = notifications?.filter((item) => {
    return item?.isRead !== true;
  });

  return (
    <div className="profilenotifications">
      <div className="profilenotifications-content">
        <div className="profilenotifications-content-section1">
          <h2>Notifications</h2>
          <button onClick={handlePutAllNotificationsAsReaded}>
            Marquer tout comme lu
          </button>
        </div>
        <div className="profilenotifications-content-onOff">
          <img src={bellIcon} alt="" />
          <div className="profilenotifications-content-onOff-part2">
            <div>
              <h3>Notification push</h3>
            </div>
            <div>
              <Switch
                checked={isNotificationsOn}
                size="small"
                onChange={handleChange}
                inputProps={{ "aria-label": "controlled" }}
              />
            </div>
          </div>
        </div>
        {notifications?.length! > 0 ? (
          <>
            <div className="profilenotifications-content-section2">
              {!!unreadItems?.length && (
                <div className="part1">
                  <div className="title2">
                    <span>Non Lu</span>
                    <div className="notifNum">{unreadItems?.length}</div>
                  </div>
                  {loading && <LoadingSpinner />}
                  {unreadItems?.map((notification) => {
                    return (
                      <div
                        onClick={() => {
                          putNotificationAsReaded(notification?.id);
                          setLoading(true);
                          setTimeout(() => {
                            getAllProfileNotifications();
                            setLoading(false);
                          }, 500);
                        }}
                        className="notificationCard"
                      >
                        <NotificationCard
                          profilePicture={
                            notification?.metaData?.profilePicture
                          }
                          title={notification?.title}
                          description={notification?.description}
                          date={notification?.insertedDate}
                          readed={false}
                          modal={false}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
              {!!readItems?.length && (
                <div className="part2">
                  <div className="title2">
                    <span>Lu</span>
                    <div className="notifNum">{readItems?.length}</div>
                  </div>
                  {loading && <LoadingSpinner />}
                  {readItems?.map((notification) => {
                    return (
                      <div className="notificationCard">
                        <NotificationCard
                          profilePicture={
                            notification?.metaData?.profilePicture
                          }
                          title={notification?.title}
                          description={notification?.description}
                          date={notification?.insertedDate}
                          readed={true}
                          modal={false}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </>
        ) : (
          <NoNotification />
        )}
      </div>
    </div>
  );
};

export default ProfileNotifications;

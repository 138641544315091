import { FC, ReactElement } from "react";
import "./_EventParticipantInfo.scss";
import defaultPhoto from "../../../../assets/photos/defaultProfile.png";
import participant from "../../../../assets/icons/participant.webp";
import time from "../../../../assets/icons/time.webp";
import table from "../../../../assets/icons/table.webp";
import { status, statusColors } from "../../../../static/reservationStatus";

interface EventParticipantInfoProps {
  id: number;
  firstName: string;
  lastName: string;
  restaurantParticipantNumber: number;
  eventReservationParticipants: number;
  restaurantArrivalTime: string;
  eventArrivalTime: string;
  tableName: string;
  reservationStatus: number;
  email: string;
  profilePicture: string;
}

const EventParticipantInfo: FC<EventParticipantInfoProps> = ({
  id,
  firstName,
  lastName,
  restaurantParticipantNumber,
  restaurantArrivalTime,
  eventReservationParticipants,
  eventArrivalTime,
  tableName,
  email,
  reservationStatus,
  profilePicture,
}): ReactElement => {
  return (
    <tr className="eventparticipantinfo">
      <td>
        <div className="eventparticipantinfo-user">
          <div className="eventparticipantinfo-user-info">
            <div className="adminuserinfo avatar">
              <img src={profilePicture || defaultPhoto} alt="" />
            </div>
            <div className="eventparticipantinfo-user-nameEmail">
              <p>{`${firstName} ${lastName}`}</p>
              <p>{email}</p>
            </div>
          </div>
        </div>
      </td>
      <td>
        <div className="eventparticipantinfo-info1">
          <div className="infoItems">
            <img src={participant} alt="" />
            <p>{`${
              !!restaurantParticipantNumber ? restaurantParticipantNumber : 0
            } Participants`}</p>
          </div>
          <div className="infoItems">
            <img src={time} alt="" />
            <p>{restaurantArrivalTime?.slice(0, 5)}</p>
          </div>
        </div>
      </td>
      <td>
        <div className="eventparticipantinfo-info2">
          <div className="infoItems">
            <img src={participant} alt="" />
            <p>{`${
              !!eventReservationParticipants ? eventReservationParticipants : 0
            } Participants`}</p>
          </div>
          <div className="infoItems">
            <img src={time} alt="" />
            <p>{eventArrivalTime?.slice(0, 5)}</p>
          </div>
          <div className="infoItems">
            <img src={table} alt="" />
            <p>{tableName}</p>
          </div>
        </div>
      </td>
      <td className="eventparticipantinfo-status actionColumn lastElement">
        <button className={`${statusColors[reservationStatus]}`}>
          {status[reservationStatus]}
        </button>
      </td>
    </tr>
  );
};
export default EventParticipantInfo;

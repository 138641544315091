import { FC } from "react";
import Carousel from "react-bootstrap/Carousel";
import { getBusinessServicesResponse } from "../../../../api/Business";
import "./_NightclubPrices.scss";

interface NightclubPricesProps {
  services?: getBusinessServicesResponse;
}

const NightclubPrices: FC<NightclubPricesProps> = ({ services }) => {
  return (
    <div className="nightclubprices">
      <h3>Tarifs</h3>
      {services?.data?.data?.length !== 0 || undefined ? (
        <Carousel wrap={false} slide={false}>
          {services?.data?.data?.map((service, index) => {
            return (
              <Carousel.Item key={index}>
                <div className="nightclubprices-items">
                  <p>{service?.serviceType?.name}</p>
                  {service?.services?.map((item) => {
                    return (
                      <div className="nightclubprices-items-item">
                        <span>{item?.name}</span>
                      </div>
                    );
                  })}
                </div>
              </Carousel.Item>
            );
          })}
        </Carousel>
      ) : (
        <p className="noprice">Pas de tarifs</p>
      )}
    </div>
  );
};

export default NightclubPrices;

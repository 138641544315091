import { PAGE_SIZE } from "../../config/app";
import axios from "../../initializations/axios";

export const getEventProfile = async (id: number) => {
  const { data } = await axios.get(`BusinessEvents/profile/${id}`);
  return data;
};

export const getEventGallery = async (id: number, pageNumber: number) => {
  const { data } = await axios.get(
    `BusinessEvents/gallery/${id}?pageSize=${PAGE_SIZE}&pageNumber=${pageNumber}`
  );
  return data;
};
export const getUsersName = async (searchWord?: string) => {
  const { data } = await axios.get(
    `UserManager/getAllUserName?pageSize=50&pageNumber=1&searchWord=${searchWord}`
  );
  return data;
};
export const getEventTables = async (id: number, pageNumber: number) => {
  const { data } = await axios.get(
    `EventTables/all/${id}?pageSize=${PAGE_SIZE}&pageNumber=${pageNumber}`
  );
  return data;
};
export const getAllEventTables = async (id: number) => {
  const { data } = await axios.get(`Reservation/event/${id}`);
  return data;
};

export const getEventParticipantDetails = async (
  eventId: number,
  pageNumber: number
) => {
  const { data } = await axios.get(
    `BusinessEvents/eventParticipantDetails?EventId=${eventId}&pageSize=${PAGE_SIZE}&pageNumber=${pageNumber}`
  );

  return data;
};

export const getAllEventNames = async (searchWord: string) => {
  const { data } = await axios.get(
    `BusinessEvents/getAllEventNames${
      searchWord && `?searchWord=${searchWord}`
    }`
  );

  return data.data.map((d: any) => ({
    id: d.id,
    label: d.name,
  }));
};

import { FC, useEffect, useState } from "react";
import { useLoginRegisterResetPasswordContext } from "../../../context/LoginRegisterResetPasswordContext/LoginRegisterResetPasswordContext";
import LoginRegisterResetPassword from "../../modals/LoginRegisterResetPassword/LoginRegisterResetPassword";
import BoostClubs from "../../page-sections/ClubSpaceWhy7Night/BoostClubs/BoostClubs";
import BoostLensSales from "../../page-sections/ClubSpaceWhy7Night/BoostLensSales/BoostLensSales";
import CentralizeReservations from "../../page-sections/ClubSpaceWhy7Night/CentralizeReservations/CentralizeReservations";
import ObtainConcreteCF from "../../page-sections/ClubSpaceWhy7Night/ObtainConcreteCF/ObtainConcreteCF";
import PersonalizedShowcase from "../../page-sections/ClubSpaceWhy7Night/PersonalizedShowcase/PersonalizedShowcase";
import Reservation from "../../page-sections/ClubSpaceWhy7Night/Reservation/Reservation";
import "./_ClubSpaceWhy7Night.scss";

interface ClubSpaceWhy7NightProps {}

const ClubSpaceWhy7Night: FC<ClubSpaceWhy7NightProps> = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  const LRRPCtx = useLoginRegisterResetPasswordContext();
  const [modalShow, setModalShow] = useState(false);
  return (
    <div className="clubspacewhy7night">
      <div className="clubspacewhy7night-section1">
        <PersonalizedShowcase />
      </div>
      <div className="clubspacewhy7night-section2">
        <Reservation />
      </div>
      <div className="clubspacewhy7night-section3">
        <BoostClubs />
      </div>
      <div className="clubspacewhy7night-section4">
        <BoostLensSales />
      </div>
      <div className="clubspacewhy7night-section5">
        <ObtainConcreteCF />
      </div>
      <div className="clubspacewhy7night-section6">
        <CentralizeReservations />
      </div>
      <div className="clubspacewhy7night-section8 container">
        <h2>
          Vous souhaitez augmenter votre chiffre d’affaire, apporter de la
          valeur à votre club et attirer de nouveaux clients?
        </h2>
        <button
          onClick={() => {
            setModalShow(true);
            LRRPCtx.setDefaultKey("register");
          }}
        >
          Essayer dès maintenant!
        </button>
        <h4>Essai gratuit - Sans engagement de durée</h4>
      </div>
      <LoginRegisterResetPassword
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </div>
  );
};

export default ClubSpaceWhy7Night;

import { FC, ReactElement, useState } from "react";
import LoadingSpinner from "../../../../components/LoadingSpinner/LoadingSpinner";
import MuiPagination from "../../../../components/MuiPagination/MuiPagination";
import { useEventTablesQuery } from "../../../../react-query/hooks/Events";
import AddNewEventTable from "../../../modals/AddNewEventTable";
import EventTableInfo from "../../Molecules/TableInfo/EventTableInfo";
import { NoResults } from "../NoResults/NoResults";

interface eventId {
  id: string;
}
const EventTables: FC<eventId> = ({ id }): ReactElement => {
  const [page, setPage] = useState<number>(1);
  const [modalShow, setModalShow] = useState(false);
  const { data: { data } = {}, isLoading } = useEventTablesQuery(+id, page);

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div>
          <div className="configurationButton">
            <button onClick={() => setModalShow(true)}>+ Ajouter</button>
          </div>
          {data?.data?.length! > 0 ? (
            <div className="dashboardResults">
              <table>
                <tr>
                  <th>NOM</th>
                  <th>PRIX MINIMUM</th>
                  <th>PERSONNE MAXIMUM</th>
                  <th>TABLES DISPONIBLES</th>
                  <th className="lastElement actionColumn">ACTIONS</th>
                </tr>
                {data?.data?.map((table) => {
                  return (
                    <EventTableInfo
                      key={table.id}
                      tableId={table.id}
                      name={table.name}
                      price={table.price}
                      maxNumber={table.maxNumber}
                      numberOfTables={table.numberOfTables}
                      eventId={table.eventId}
                      pageNumber={page}
                      hasReservation={table.hasReservation}
                    />
                  );
                })}
              </table>
              <div className="dashboardPagination">
                <MuiPagination
                  pagesNumber={data?.metaData?.pageCount!}
                  page={page}
                  setPage={setPage}
                />
              </div>
            </div>
          ) : (
            <NoResults text="Ajoutez des tables, des Free accès… en plus de ce qui est déjà configuré dans votre club" />
          )}
          <AddNewEventTable
            show={modalShow}
            onHide={() => setModalShow(false)}
            eventId={+id}
            pageNumber={page}
            post
          />
        </div>
      )}
    </>
  );
};
export default EventTables;

import Pagination from "@mui/material/Pagination";
import { FC, useEffect } from "react";
import "./_muiPagination.scss";

interface muiPaginationProps {
  pagesNumber: number;
  page: number;
  setPage: (page: number) => void;
}
const MuiPagination: FC<muiPaginationProps> = ({
  pagesNumber,
  page,
  setPage,
}) => {
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);
  return (
    <div className="muiPagination">
      <Pagination
        page={page}
        count={pagesNumber}
        variant="outlined"
        shape="rounded"
        onChange={handleChange}
      />
    </div>
  );
};
export default MuiPagination;

import {FC} from 'react';
import boostLensSales from '../../../../assets/photos/boostLensSales.webp';
import './_BoostLensSales.scss';
import '../_ClubSpaceWhy7NightSharedStyle.scss';

interface BoostLensSalesProps {
}

 const BoostLensSales: FC<BoostLensSalesProps>=()=> {
  return (
    <div className='boostlenssales container'>
      <div className='boostlenssales-section1'>
        <img src={boostLensSales} alt="" />
      </div>
      <div className='boostlenssales-section2  clubspacewhy7nightsharedstyle'>
        <h3>Propulsez vos ventes de verres grâce à la partie rencontre</h3>
        <p><span>(vous réglez le problème de vos clients et vous augmenter votre chiffre d’affaire) </span><br />
          L’équipe 7Night est consciente du problème de timidité actuelle chez les jeunes et nous comprenons
          aussi qu’il est parfois difficile d’aller aborder une personne avec qui nous échangeons des regards
          pendant une soirée, d’autant plus ci celle-ci est entourée de ses amis !
          </p>
          <br />
        <p>C’est pour cela que 7Night est avant tout une plateforme de réservation en club, mais aussi une
          application de <span>rencontre qui permet de retrouver toutes les personnes que l’on a croisées durant 
          un évènement.</span> Les participants peuvent interagir en « matchant » entre eux, s’envoyer des messages 
          mais aussi <span>s’offrir des verres</span> afin de briser la glace !
          </p>
          <br />
        <p>Un vrai coup de pouce sur la vente de <span>vos consommations</span> et une énorme valeur ajoutée pour <span>vos clients!</span>
        </p>
      </div>
    </div>
  );
}

export default BoostLensSales;
import { ReactElement, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { ClubTablePostModel } from "../../interfaces/models/postModels/ClubTablePost.model";
import {
  usePostClubTableQuery,
  usePutClubTableQuery,
} from "../../react-query/hooks/Clubs";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { TableUpdateModel } from "../../interfaces/models/updateModels/TableUpdate.model";

const schema = yup.object().shape({
  name: yup.string().required("Obligatoire"),
  price: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .nullable(),
  maxNumber: yup
    .number()
    .positive()
    .integer()
    .typeError("Obligatoire")
    .required(),
  numberOfTables: yup
    .number()
    .positive()
    .integer()
    .typeError("Obligatoire")
    .required(),
});

const AddNewClubTable = (props: any): ReactElement => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = useForm<ClubTablePostModel>({
    resolver: yupResolver(schema),
  });

  const {
    mutate: addClubTable,
    isLoading: isAddLoading,
    isSuccess: isAddSuccess,
  } = usePostClubTableQuery(props.clubId, props.pageNumber);
  const {
    mutate: updateClubTable,
    isLoading: isUpdateLoading,
    isSuccess: isUpdateSuccess,
  } = usePutClubTableQuery(props.clubId, props.pageNumber);

  const handleTableSubmit = (data: ClubTablePostModel | TableUpdateModel) => {
    if (props.post) {
      return addClubTable({ ...data, businessId: props.clubId });
    }
    return updateClubTable({ ...data, id: props.tableId });
  };

  useEffect(() => {
    props.onHide();
    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful === true]);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="dashboardModal"
      onHide={() => {
        reset();
        props.onHide();
      }}
    >
      <Modal.Header>
        {props.post && <h4>Ajouter un nouveau type de réservation</h4>}
        {props.update && <h4>Tableau de mise à jour</h4>}
      </Modal.Header>
      <Modal.Body>
        <form className="modalBody" onSubmit={handleSubmit(handleTableSubmit)}>
          <div className="modalBody-Input modalItem">
            <label htmlFor="désignation">Désignation de la réservation</label>
            <input
              type="text"
              id="désignation"
              placeholder="Table simple, Table VIP, Free accés..."
              defaultValue={props.name}
              {...register("name")}
            />
            {errors.name && (
              <p className="tw-text-red-600 mb-0">{errors.name.message}</p>
            )}
          </div>
          <div className="modalBody-Input modalItem">
            <label htmlFor="désignation">
              Prix consommation min. pour une table (facultatif)
            </label>
            <input
              type="number"
              id="désignation"
              placeholder="€"
              defaultValue={props.price}
              {...register("price")}
            />
            {errors.price && (
              <p className="tw-text-red-600 mb-0">{errors.price.message}</p>
            )}
          </div>
          <div className="modalBody-Input modalItem">
            <label htmlFor="désignation">Personnes max. par réservation</label>
            <input
              type="number"
              id="désignation"
              placeholder="Personnes max."
              defaultValue={props.maxNumber}
              {...register("maxNumber")}
            />
            {errors.maxNumber && (
              <p className="tw-text-red-600 mb-0">{errors.maxNumber.message}</p>
            )}
          </div>
          <div className="modalBody-Input modalItem">
            <label htmlFor="désignation">
              Quantité de réservations disponibles par évènement
            </label>
            <input
              type="number"
              id="désignation"
              placeholder="Nombre de réservation"
              defaultValue={props.numberOfTables}
              {...register("numberOfTables")}
            />
            {errors.numberOfTables && (
              <p className="tw-text-red-600 mb-0">
                {errors.numberOfTables.message}
              </p>
            )}
          </div>
          <div className="modalBody-buttons modalItem">
            <button onClick={() => props.onHide()}>Annuler</button>
            {props.post && (
              <button type="submit" disabled={isAddLoading}>
                Ajouter
              </button>
            )}
            {props.update && (
              <button type="submit" disabled={isUpdateLoading}>
                Sauvegarder
              </button>
            )}
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};
export default AddNewClubTable;

import { FC } from "react";
import building from "../../../../assets/icons/building.svg";
import calendar from "../../../../assets/icons/calendar.svg";
import location from "../../../../assets/icons/location.svg";
import music from "../../../../assets/icons/music.svg";
import restaurant from "../../../../assets/icons/restaurant.svg";
import palma from "../../../../assets/icons/palma.svg";
import goIcon from "../../../../assets/icons/goIcon.svg";
import "./_EveningBasicInfo.scss";
import { Link } from "react-router-dom";

interface EveningInfoProps {
  eveningClubId?: number;
  eveningClub?: string;
  eveningDjId?: number;
  eveningDj?: string;
  eveningDate?: string;
  eveningAddress?: string;
  eveningEnterior?: boolean;
  eveningExterior?: boolean;
  eveningRestaurant?: boolean;
}

const EveningInfo: FC<EveningInfoProps> = ({
  eveningRestaurant,
  eveningClub,
  eveningClubId,
  eveningDj,
  eveningDjId,
  eveningDate,
  eveningAddress,
  eveningEnterior,
  eveningExterior,
}) => {
  const localDate = new Date(eveningDate!);
  const localDateString = localDate.toLocaleDateString(undefined, {
    day: "numeric",
    month: "short",
    year: "numeric",
  });

  const localTimeString = localDate.toLocaleTimeString(undefined, {
    hour: "2-digit",
    minute: "2-digit",
  });

  return (
    <div className="eveninginfo">
      {!!eveningClub && (
        <div className="item">
          <Link to={`/nightclubs/${eveningClubId}`} className="bgc">
            <img src={building} alt="" />
            <p>{eveningClub}</p>
            <img src={goIcon} alt="" className="goIcon" />
          </Link>
        </div>
      )}
      {!!eveningDj && (
        <div className="item">
          <Link to={`/djs/${eveningDjId}`} className="bgc">
            <img src={music} alt="" />
            <p>{eveningDj}</p>
            <img src={goIcon} alt="" className="goIcon" />
          </Link>
        </div>
      )}
      <div className="item">
        <img src={calendar} alt="" />
        <p>
          {localDateString}, {localTimeString}
        </p>
      </div>
      <div className="item">
        <img src={location} alt="" />
        <p>{eveningAddress}</p>
      </div>
      {eveningEnterior ||
        (eveningExterior && (
          <div className="item">
            <img src={palma} alt="" />
            {eveningEnterior && !eveningExterior && <p>Indoor</p>}
            {!eveningEnterior && eveningExterior && <p>Outdoor</p>}
            {eveningEnterior && eveningExterior && <p>Indoor/Outdoor</p>}
          </div>
        ))}
      <div className="item">
        <img src={restaurant} alt="" />
        {eveningRestaurant ? <p> Avec Restaurant</p> : <p> Sens Restaurant</p>}
      </div>
    </div>
  );
};

export default EveningInfo;

import closeButton from "../../../assets/icons/closeButton.svg";
import { Modal } from "react-bootstrap";
import "../_modalStyle.scss";
import "./_EveningsFilter.scss";

import dateIconOff from "../../../assets/icons/dateIconOff.svg";
import searchIconOff from "../../../assets/icons/searchIconOff.svg";
import locationIconOff from "../../../assets/icons/locationIconOff.svg";
import { GetBusinessEventsInput } from "../../../api/Api";
import { useEffect, useState } from "react";
import { getBusinessTypes, GetBusinessTypesResponse } from "../../../api/Api";
import { useGoogleAutoFill } from "../../../components/GoogleAutoFill/GoogleAutoFill";
import { useAuthContext } from "../../../context/AuthContext/AuthContext";

function useEveningsFilter(props: any) {
  const [secondSearch, setSecondSearch] = useState<GetBusinessEventsInput>({});
  const [morefilterInputs, setMoreFilterInputs] =
    useState<GetBusinessEventsInput>({
      radius: 10,
    } as GetBusinessEventsInput);
  const [businessTypesData, setBusinessTypesData] =
    useState<GetBusinessTypesResponse>();
  const { address, render: googleAutoFill } = useGoogleAutoFill("Localisation");
  const onChangeValueShowcase = (e: any) => {
    e.target.value === "yes"
      ? setMoreFilterInputs({ ...morefilterInputs, hasShowcase: true })
      : setMoreFilterInputs({ ...morefilterInputs, hasShowcase: false });
  };
  const authCtx = useAuthContext();
  const musicTypes = async () => {
    try {
      const data = await getBusinessTypes();
      setBusinessTypesData(data as GetBusinessTypesResponse);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    musicTypes();
  }, []);

  useEffect(() => {
    setMoreFilterInputs({ ...morefilterInputs, location: address });
  }, [address]);

  return {
    secondSearch,
    render: (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="eveningsmorefilter">
            <div className="eveningsmorefilter-part1">
              <div className="closeButton">
                <img src={closeButton} alt="" onClick={() => props.onHide()} />
              </div>
              <div className="title">
                <h3>Filters</h3>
              </div>
            </div>
            <div className="eveningsmorefilter-part2">
              <div className="eveningsmorefilter-part2-1">
                <div className="grid-item">
                  <label htmlFor="date">Date</label>
                  <div className="input">
                    <img src={dateIconOff} alt="" />
                    <input
                      type="date"
                      value={morefilterInputs.date || ""}
                      onChange={(e) =>
                        setMoreFilterInputs({
                          ...morefilterInputs,
                          date: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="grid-item">
                  <label htmlFor="search">Nom de la soirée</label>
                  <div className="input">
                    <img src={searchIconOff} alt="" />
                    <input
                      type="search"
                      value={morefilterInputs.searchWord || ""}
                      onChange={(e) =>
                        setMoreFilterInputs({
                          ...morefilterInputs,
                          searchWord: e.target.value,
                        })
                      }
                      placeholder="Recherche une soirée"
                    />
                  </div>
                </div>
                <div className="grid-item">
                  <label htmlFor="loaction">Où</label>
                  <div className="input">
                    <img
                      src={locationIconOff}
                      alt=""
                      className="googleAutofill"
                    />
                    {googleAutoFill}
                  </div>
                </div>
              </div>

              <div className="eveningsmorefilter-part2-2">
                <div className="input-1">
                  <div className="range-input-title">
                    <label htmlFor="rayon">Dans un rayon autour de </label>
                    <span>{`${
                      morefilterInputs?.radius === undefined
                        ? 50
                        : morefilterInputs?.radius
                    } km`}</span>
                  </div>
                  <div className="range-input">
                    <input
                      type="range"
                      min="5"
                      max="100"
                      value={morefilterInputs?.radius || 100}
                      disabled={
                        authCtx?.location?.latitude === undefined &&
                        authCtx?.location?.longitude === undefined
                      }
                      onChange={(e) =>
                        setMoreFilterInputs({
                          ...morefilterInputs,
                          radius: parseInt(e.target.value),
                        })
                      }
                    />
                  </div>
                </div>
                <div className="input-2">
                  <label htmlFor="">Type</label>
                  <div className="inputs">
                    {businessTypesData?.data?.map((type) => {
                      return (
                        <div className="inputs-input" key={type.id}>
                          <label htmlFor={`${type.name}-${type.id}`}>
                            <input
                              type="radio"
                              id={`${type.name}-${type.id}`}
                              name="bussinesType"
                              onChange={(e) => {
                                if (e.target.checked === true) {
                                  setMoreFilterInputs({
                                    ...morefilterInputs,
                                    businessType: type.id,
                                  });
                                } else if (e.target.checked === false) {
                                  setMoreFilterInputs({
                                    ...morefilterInputs,
                                    businessType: 0,
                                  });
                                }
                              }}
                            />
                            <span>{type.name}</span>
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              <div className="eveningsmorefilter-part2-3">
                <div className="grid-item">
                  <h3>Intérieur / Extérieur</h3>
                  <div className="input">
                    <input
                      type="checkbox"
                      id="interor"
                      onChange={(e) => {
                        if (e.target.checked === true) {
                          setMoreFilterInputs({
                            ...morefilterInputs,
                            interior: true,
                          });
                        } else if (e.target.checked === false) {
                          setMoreFilterInputs({
                            ...morefilterInputs,
                            interior: false,
                          });
                        }
                      }}
                    />
                    <label htmlFor="interor">En intérieur</label>
                  </div>
                  <div className="input">
                    <input
                      type="checkbox"
                      id="exterior"
                      onChange={(e) => {
                        if (e.target.checked === true) {
                          setMoreFilterInputs({
                            ...morefilterInputs,
                            exterior: true,
                          });
                        } else if (e.target.checked === false) {
                          setMoreFilterInputs({
                            ...morefilterInputs,
                            exterior: false,
                          });
                        }
                      }}
                    />
                    <label htmlFor="exterior">En extérieur</label>
                  </div>
                </div>
                <div className="grid-item">
                  <h3>Entrée gratuite</h3>
                  <div className="input">
                    <input
                      type="checkbox"
                      id="freeForGirls"
                      onChange={(e) => {
                        if (e.target.checked === true) {
                          setMoreFilterInputs({
                            ...morefilterInputs,
                            isFreeEntryForGirls: true,
                          });
                        } else if (e.target.checked === false) {
                          setMoreFilterInputs({
                            ...morefilterInputs,
                            isFreeEntryForGirls: false,
                          });
                        }
                      }}
                    />
                    <label htmlFor="freeForGirls">Entrée gratuite Filles</label>
                  </div>
                  <div className="input">
                    <input
                      type="checkbox"
                      id="freeForBoys"
                      onChange={(e) => {
                        if (e.target.checked === true) {
                          setMoreFilterInputs({
                            ...morefilterInputs,
                            isFreeEntryForBoys: true,
                          });
                        } else if (e.target.checked === false) {
                          setMoreFilterInputs({
                            ...morefilterInputs,
                            isFreeEntryForBoys: false,
                          });
                        }
                      }}
                    />
                    <label htmlFor="freeForBoys">Entrée gratuite Garçons</label>
                  </div>
                </div>
                <div className="grid-item" onChange={onChangeValueShowcase}>
                  <h3>Showcase</h3>
                  <div className="input">
                    <input
                      type="radio"
                      id="avecShowcase"
                      name="showcase"
                      value="yes"
                    />
                    <label htmlFor="avecShowcase">Avec showcase</label>
                  </div>
                  <div className="input">
                    <input
                      type="radio"
                      id="sansShowcase"
                      name="showcase"
                      value="no"
                    />
                    <label htmlFor="sansShowcase">Sans showcase</label>
                  </div>
                </div>
              </div>
              <div className="eveningsmorefilter-part2-4">
                <button onClick={() => props.onHide()}>Annuler</button>
                <button
                  onClick={() => {
                    setSecondSearch(morefilterInputs);
                    props.onHide();
                  }}
                >
                  Rechercher
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    ),
  };
}

export default useEveningsFilter;

import { FC, ReactElement, useState } from "react";
import { Dropdown } from "react-bootstrap";
import queryString from "query-string";
import ellipsis from "../../../../assets/icons/ellipsis.svg";
import black from "../../../../assets/photos/black.png";
import edit from "../../../../assets/icons/edit.png";
import trash from "../../../../assets/icons/trash.png";
import DjForm from "../../../modals/DjForm";
import { DjConfigurationInfoProps } from "./DjConfigurationInfo.props";
import { useDeleteDjMutation } from "../../../../react-query/hooks/useConfiguration.mutation";
import { useLocation } from "react-router-dom";

const DjConfigurationInfo: FC<DjConfigurationInfoProps> = ({
  dj,
}): ReactElement => {
  // local state
  const [modalShow, setModalShow] = useState(false);

  /**
   * hooks
   */
  const { search } = useLocation();

  /**
   * query string
   */
  const queryParams = queryString.parse(search);

  // react-query
  const { mutate: deleteDj } = useDeleteDjMutation(
    dj.id,
    Number(queryParams.pageNumber)
  );

  // handlers
  const handleDeleteDj = () => deleteDj();

  return (
    <tr className="djconfigurationInfo">
      <td className="avatar">
        <div className="tw-flex tw-items-center tw-gap-5">
          <img src={dj.imagePath || black} alt="" />
          <p>{dj.name}</p>
        </div>
      </td>
      <td className="actionColumn tw-align-middle">
        <Dropdown
          className="d-inline actionColumn-action action lastElement"
          align="start"
        >
          <Dropdown.Toggle id="dropdown-autoclose-true">
            <img src={ellipsis} alt="" />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => setModalShow(true)}>
              <img src={edit} alt="" />
              <p>Éditer</p>
            </Dropdown.Item>
            <Dropdown.Item onClick={handleDeleteDj}>
              <img src={trash} alt="" />
              <p>Effacer</p>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </td>

      <DjForm dj={dj} show={modalShow} onHide={() => setModalShow(false)} />
    </tr>
  );
};
export default DjConfigurationInfo;

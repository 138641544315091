import { FC, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import EventCard from "../../../components/shared/Cards/EventCard/EventCard";
import "./_HomeEveningsSuggestion.scss";
import { BusinessEventBasicInfo, getBusinessEvents } from "../../../../api/Api";
import SkeletonEventCard from "../../../components/shared/Cards/SkeletonEventCard/SkeletonEventCard";
import { useAuthContext } from "../../../../context/AuthContext/AuthContext";
import NoSuggestedClubEvent from "../../shared/NoSuggestedClubEvent";

interface HomeEveningsSuggestionProps {
  longitude?: number;
  latitude?: number;
}

const HomeEveningsSuggestion: FC<HomeEveningsSuggestionProps> = ({
  longitude,
  latitude,
}) => {
  const [eveningsData, setEveningsData] = useState<BusinessEventBasicInfo[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const [location, setLocation] = useState<boolean>(false);
  const authCtx = useAuthContext();
  const getBusinessEventData = async () => {
    try {
      const businessEventResponse = await getBusinessEvents(
        {
          longitude: longitude,
          latitude: latitude,
          radius: 10,
        },
        authCtx?.location
      );
      setEveningsData(businessEventResponse?.data?.data);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getBusinessEventData();
    setLocation(longitude !== undefined && latitude !== undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [longitude, latitude]);

  const showSkeleton = (n: number) => {
    return [...Array(n)].map((e, i) => (
      <div className="homeeveninglist-section2-item" key={`${2000 + i}`}>
        <SkeletonEventCard />
      </div>
    ));
  };

  const sortedBusinessEvents = eveningsData?.sort(function compareNumber(
    a: BusinessEventBasicInfo,
    b: BusinessEventBasicInfo
  ) {
    return b.averageScore - a.averageScore;
  });

  return (
    <div className="homeeveningssuggestion">
      <div className="homeeveningssuggestion-section1 container-home">
        <div className="homeeveningssuggestion-section1-part1">
          <h2>Nos suggestions du moment</h2>
        </div>
        <NavLink to="/evenings">VOIR PLUS</NavLink>
      </div>
      <h3 className="container-home">Évènements</h3>
      {location && eveningsData?.length !== 0 ? (
        <div className="homeeveningssuggestion-section2 container-home">
          {sortedBusinessEvents?.slice(0, 3)?.map((event) => (
            <div
              className="homeeveningssuggestion-section2-item"
              key={event.id}
            >
              <EventCard
                id={event?.id}
                photo={event?.banner}
                title={event?.title}
                club={event?.clubName}
                address={event?.city}
                date={event?.date}
                distance={event?.distance}
                isAlmostFull={event?.isAlmostFull}
                isFreeForGirls={event?.isFreeForGirls}
                isFreeForBoys={event?.isFreeForBoys}
                hasShowCase={event?.hasShowCase}
                isOpened={event?.isOpened}
              />
            </div>
          ))}
          {loading && showSkeleton(3)}
        </div>
      ) : (
        <NoSuggestedClubEvent
          event
          location={location}
          noItemInThatRatio={eveningsData?.length === 0}
        />
      )}
    </div>
  );
};

export default HomeEveningsSuggestion;

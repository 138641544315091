import { joiResolver } from "@hookform/resolvers/joi";
import { FC, ReactElement } from "react";
import { Modal, ModalProps } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { ConstantModel } from "../../interfaces/models/getModels/Constant.model";
import { useEditConstantMutation } from "../../react-query/hooks/useConfiguration.mutation";
import { ConstantFormSchema } from "../../validations/Constant.schema";

interface EditConstantProps extends ModalProps {
  constant: ConstantModel;
  onHide: () => void;
}

const EditConstant: FC<EditConstantProps> = ({
  constant,
  onHide,
  ...rest
}): ReactElement => {
  // hook form
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<{ value: string }>({
    resolver: joiResolver(ConstantFormSchema),
    defaultValues: { value: constant.value },
  });

  // react-query
  const { mutate: editConstant } = useEditConstantMutation(constant.id || 0);

  // handlers
  const handleHideModal = () => {
    reset();
    onHide();
  };

  const onSubmit = ({ value }: { value: string }) =>
    editConstant(
      { id: constant.id, name: constant.name, value },
      {
        onSuccess: () => {
          reset({ value });
          onHide();
        },
      }
    );

  return (
    <Modal
      {...rest}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="dashboardModal"
    >
      <Modal.Header>
        <h4>Offrir</h4>
      </Modal.Header>
      <Modal.Body as="form" onSubmit={handleSubmit(onSubmit)}>
        <div className="modalBody">
          <div className="modalBody-Input modalItem">
            <label htmlFor="value">{`${constant.name} to offer`}</label>
            <input {...register("value")} id="value" placeholder="Value" />

            {errors.value && (
              <p className="tw-text-red-700">{errors.value.message}</p>
            )}
          </div>
          <div className="modalBody-buttons modalItem">
            <button type="button" onClick={handleHideModal}>
              Annuler
            </button>
            <button type="submit">Sauvegarder</button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default EditConstant;

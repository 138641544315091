import { ReactElement } from "react";
import { Tab, Tabs } from "react-bootstrap";
import AddClubsDetails from "../../components/Organisms/Add/AddClubDetails";

import "./_ClubStyle.scss";

const AddClub = (): ReactElement => {
  return (
    <div className="adminclub">
      <div className="dashboardTitle">
        <h3>Club</h3>
      </div>
      <Tabs
        defaultActiveKey="clubdetails"
        transition={false}
        id="noanim-tab-example"
        className="dashboardNav"
      >
        <Tab eventKey="clubdetails" title="Détails">
          <AddClubsDetails />
        </Tab>
        <Tab eventKey="clubpricing" title="Tarifs" disabled></Tab>
        <Tab eventKey="clubtables" title="Réservations" disabled></Tab>
        <Tab eventKey="photos" title="Photos" disabled></Tab>
        <Tab eventKey="clubjuridictions" title="Juridictions" disabled></Tab>
        <Tab
          eventKey="clubschedules"
          title="Horaires d’ouverture"
          disabled
        ></Tab>
        <Tab eventKey="clubrate" title="Avis" disabled></Tab>
      </Tabs>
    </div>
  );
};
export default AddClub;

import { ChangeEvent, FC, ReactElement } from "react";
import { useLocation } from "react-router-dom";
import clsx from "clsx";
import black from "../../../../assets/photos/black.png";
import ellipsis from "../../../../assets/icons/ellipsis.svg";
import { Dropdown } from "react-bootstrap";
import block from "../../../../assets/icons/block.png";
import trash from "../../../../assets/icons/trash.png";
import { ClientInfoProps } from "./ClientInto.props";
import { getFullName } from "../../../../utils/getFullName";
import queryString from "query-string";
import {
  useBlockUserManagerMutation,
  useDeleteUserManagerMutation,
  useUnBlockUserManagerMutation,
} from "../../../../react-query/hooks/useUserManager.mutation";
import { UserManagerActionTypeEnum } from "../../../../interfaces/enums/UserManagerActionType.enum";
import defaultProfile from "../../../../assets/photos/defaultProfile.png";

const ClientInfo: FC<ClientInfoProps> = ({
  user,
  onSelectUser,
}): ReactElement => {
  /**
   * hooks
   */
  const { search } = useLocation();

  /**
   * query string
   */
  const queryParams = queryString.parse(search);

  // react-query
  const { mutate: deleteUser } = useDeleteUserManagerMutation(
    user?.id || "",
    queryParams["pageNumber"] as string
  );
  const { mutate: blockUser } = useBlockUserManagerMutation(
    user?.id || "",
    queryParams["pageNumber"] as string
  );
  const { mutate: unblockUser } = useUnBlockUserManagerMutation(
    user?.id || "",
    queryParams["pageNumber"] as string
  );

  // handlers
  const handleDeleteUser = () => deleteUser();

  const handleBlockUser = (actionType: UserManagerActionTypeEnum) => () => {
    if (actionType === UserManagerActionTypeEnum.BLOCK) blockUser();
    else unblockUser();
  };

  const handleSelectUser = (event: ChangeEvent<HTMLInputElement>) =>
    onSelectUser && onSelectUser(user?.id || "", event.currentTarget.checked);

  // This function is triggered if an error occurs while loading an image
  const handleImageError = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => (event.currentTarget.src = black);

  // constants
  const isUserBlocked = user?.isBlocked;

  const genders: Record<string, string> = {
    Male: "Garçon",
    Female: "Fille",
    Other: "Autres",
  };

  return (
    <tr className={clsx("clientinfo", user?.isSelected && "tw-bg-neutral-50")}>
      <td className="checkboxInput ">
        <input
          type="checkbox"
          value={user?.id}
          checked={user?.isSelected || false}
          onChange={handleSelectUser}
        />
      </td>
      <td className="clientinfo-user">
        <div className="avatar">
          <img
            src={user?.profilePicture || defaultProfile}
            alt="users profile"
            onError={handleImageError}
          />
        </div>
        <div>
          <p>{getFullName(user?.firstName, user?.lastName)}</p>
          <p>{user?.email}</p>
        </div>
      </td>
      <td className="clientinfo-gender">
        <p>{genders[user?.gender as string]}</p>
      </td>
      <td className="clientinfo-age">
        <p>{user?.age}</p>
      </td>
      <td className="clientinfo-status">
        <button className={`${isUserBlocked ? "blocked" : "present"}`}>
          {`${isUserBlocked ? "Bloqué" : "Actif"}`}
        </button>
      </td>
      <td className="actionColumn">
        <Dropdown
          className="d-inline actionColumn-action action lastElement"
          align="end"
        >
          <Dropdown.Toggle id="dropdown-autoclose-true">
            <img src={ellipsis} alt="" />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              onClick={handleBlockUser(
                user?.isBlocked
                  ? UserManagerActionTypeEnum.UNBLOCK
                  : UserManagerActionTypeEnum.BLOCK
              )}
            >
              <img src={block} alt="" />
              <p>{user?.isBlocked ? "Débloquer" : "Bloquer"}</p>
            </Dropdown.Item>
            <Dropdown.Item onClick={handleDeleteUser}>
              <img src={trash} alt="" />
              <p>Effacer</p>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </td>
    </tr>
  );
};
export default ClientInfo;

import { FC } from "react";
import NoSuggestionEventsClubs from "../../../assets/photos/NoSuggestionEventsClubs.png";

interface NoSuggestedClubEventProps {
  event?: boolean;
  location: boolean;
  noItemInThatRatio: boolean;
}
const NoSuggestedClubEvent: FC<NoSuggestedClubEventProps> = ({
  event,
  location,
  noItemInThatRatio,
}) => {
  return (
    <div
      className="container-home
    NoSuggestedClubEvent"
    >
      <img src={NoSuggestionEventsClubs} alt="" />
      <p className="p1">Pas {`${event ? "d'événement" : "de club"}`}</p>

      {!location && <p>"Veuillez activer votre localisation"</p>}

      {location && noItemInThatRatio && (
        <p className="p2">Il n’y a pas d’événement disponible sur 10km</p>
      )}
    </div>
  );
};
export default NoSuggestedClubEvent;

import {FC} from 'react';
import './_ProfileLoyalityHistory.scss';

interface ProfileLoyalityHistoryProps {
}

 const ProfileLoyalityHistory: FC<ProfileLoyalityHistoryProps>=()=> {
  return (
    <div className='profileloyalityhistory'>
      <h3 className='profileloyalityhistory-title'>Loyalty history</h3>
    </div>
  );
}

export default ProfileLoyalityHistory;
import React, { useContext } from "react";
import { LoginResponse, user } from "../../api/Api";
import { decodedJwt } from "../../api/Shared";

export interface AuthContextType {
  isAuthenticated: boolean;
  isLoading: boolean;
  user?: user;
  setUser?: (value: user) => void;
  error?: string | { [key: string]: string };
  checkAuthentication: () => void;
  login: (response: LoginResponse) => void;
  logout: () => void;
  decoded?: decodedJwt;
  userProfilePicture?: string;
  setUserProfilePicture: (value?: string) => void;
  location?: { latitude?: number; longitude?: number };
  setLocation: (value?: { latitude?: number; longitude?: number }) => void;
}

const voidFunction = () => {};

const AuthContextValues: AuthContextType = {
  isAuthenticated: false,
  isLoading: false,
  user: undefined,
  setUser: () => {},
  error: undefined,
  checkAuthentication: () => {},
  login: (response: LoginResponse) => {},
  logout: voidFunction,
  decoded: undefined,
  userProfilePicture: undefined,
  setUserProfilePicture: () => {},
  location: undefined,
  setLocation: () => {},
};

export const AuthContext =
  React.createContext<AuthContextType>(AuthContextValues);

export const useAuthContext = () => useContext(AuthContext);

import { ReactElement, useState, FC } from "react";
import { Dropdown } from "react-bootstrap";
import ellipsis from "../../../../assets/icons/ellipsis.svg";
import trash from "../../../../assets/icons/trash.png";
import edit from "../../../../assets/icons/edit.png";
import downloadIcon from "../../../../assets/icons/downloadIcon.png";
import AddNewDocument from "../../../modals/AddNewDocument";
import { useDeleteClubJuridictionQuery } from "../../../../react-query/hooks/Clubs";

interface JuridictionInfoProps {
  juridictionId: number;
  identifier: string;
  date: string;
  clubId: number;
  description: string;
  filePath: string;
}
const JuridictionInfo: FC<JuridictionInfoProps> = ({
  juridictionId,
  identifier,
  date,
  clubId,
  description,
  filePath,
}): ReactElement => {
  const [modalShow, setModalShow] = useState(false);
  const { mutate: deleteClubJurisdiction } =
    useDeleteClubJuridictionQuery(clubId);
  const handleDeleteJuridiction = (juridictionId: number) => () => {
    deleteClubJurisdiction(juridictionId);
  };
  const downloadFile = () => {
    window.location.href = `${filePath}`;
  };
  return (
    <tr className="tableinfo">
      <td>
        <p>{identifier}</p>
      </td>
      <td>
        <p>{description}</p>
      </td>
      <td>
        <p>{date.slice(0, 10)}</p>
      </td>
      <td className="actionColumn">
        <Dropdown
          className="d-inline actionColumn-action action lastElement"
          align={"end"}
        >
          <Dropdown.Toggle id="dropdown-autoclose-true">
            <img src={ellipsis} alt="" />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item href="#" onClick={() => setModalShow(true)}>
              <img src={edit} alt="" />
              <p>Éditer</p>
            </Dropdown.Item>
            <Dropdown.Item
              href="#"
              onClick={handleDeleteJuridiction(juridictionId)}
            >
              <img src={trash} alt="" />
              <p>Effacer</p>
            </Dropdown.Item>
            <Dropdown.Item href="#" onClick={downloadFile}>
              <img
                src={downloadIcon}
                alt=""
                style={{ width: "15px", height: "18px" }}
              />
              <p>Télécharger</p>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </td>
      <AddNewDocument
        show={modalShow}
        onHide={() => setModalShow(false)}
        identifier={identifier}
        description={description}
        juridictionId={juridictionId}
        update
      />
    </tr>
  );
};
export default JuridictionInfo;

import { ReactElement, useState, useEffect, ChangeEvent } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import uploadImg from "../../assets/icons/uploadImg.png";
import { UseConvertToBase64 } from "../../hooks/UseConvertToBase64";
import { JurisdictionPostModel } from "../../interfaces/models/postModels/JurisdictionPost.model";
import { JurisdictionUpdateModel } from "../../interfaces/models/updateModels/JurisdictionUpdate.model";
import {
  usePostClubJurisdictionQuery,
  usePutClubJurisdictionQuery,
} from "../../react-query/hooks/Clubs";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const schema = yup.object().shape({
  file: yup.mixed().required("Obligatoire"),
  identifier: yup.string().required("Obligatoire"),
  description: yup.string().required("Obligatoire"),
});
const AddNewDocument = (props: any): ReactElement => {
  const [fileName, setFileName] = useState<string>();
  const uploadFile = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files![0];
    const fileInBase64 = await UseConvertToBase64(file);
    setValue("file", {
      base64: fileInBase64,
      fileName: e.target.files![0].name,
    });
    setFileName(e.target.files![0].name);
  };

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { isSubmitSuccessful, errors },
  } = useForm<JurisdictionPostModel>({ resolver: yupResolver(schema) });

  const {
    mutate: addClubSchedule,
    isLoading: isAddLoading,
    isSuccess: isAddSuccess,
  } = usePostClubJurisdictionQuery(props.clubId);

  const {
    mutate: updateClubSchedule,
    isLoading: isUpdateLoading,
    isSuccess: isUpdateSuccess,
  } = usePutClubJurisdictionQuery(props.clubId);

  const handleJurisdictionSubmit = (
    data: JurisdictionPostModel | JurisdictionUpdateModel
  ) => {
    if (props.post) {
      return addClubSchedule({
        ...data,
        businessId: props.clubId,
      });
    }
    return updateClubSchedule({ ...data, id: props.juridictionId });
  };

  useEffect(() => {
    props.onHide();
    reset();
    setFileName("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful === true]);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="dashboardModal"
    >
      <Modal.Header>
        {props.post && <h4>Ajouter un document</h4>}
        {props.update && <h4>Document de mise à jour</h4>}
      </Modal.Header>
      <Modal.Body>
        <form
          className="modalBody"
          onSubmit={handleSubmit(handleJurisdictionSubmit)}
        >
          <div className="modalBody-Input modalItem">
            <label htmlFor="title">Nom du document</label>
            <input
              type="text"
              id="title"
              defaultValue={props.identifier}
              placeholder="Nom du document"
              {...register("identifier")}
            />
            {errors.identifier && (
              <p className="tw-text-red-600 mb-0">
                {errors.identifier.message}
              </p>
            )}
          </div>
          <div className="modalBody-Input modalItem">
            <label htmlFor="message">Description </label>
            <textarea
              rows={5}
              cols={10}
              minLength={1}
              maxLength={50}
              id="message"
              defaultValue={props.description}
              placeholder="Description"
              {...register("description")}
            />
            {errors.description && (
              <p className="tw-text-red-600 mb-0">
                {errors.description.message}
              </p>
            )}
          </div>
          <label className="imageInput modalItem">
            <input
              type="file"
              accept="application/msword, application/pdf"
              onChange={(e) => {
                uploadFile(e);
              }}
            />
            <div className="imageInput-style">
              <img src={uploadImg} alt="" />
              <h6 style={{ textAlign: "center" }}>
                Déposez votre dossier ici, ou parcourez sur votre appareil
              </h6>
              <p>Format requis: PDF, Word</p>
              <p>{fileName}</p>
              {errors.file && (
                <p className="tw-text-red-600 mb-0">{errors.file.message}</p>
              )}
            </div>
          </label>
          <div className="modalBody-buttons modalItem">
            <button
              onClick={() => {
                props.onHide();
                reset();
                setFileName("");
              }}
            >
              Annuler
            </button>
            {props.post && (
              <button type="submit" disabled={isAddLoading}>
                Ajouter
              </button>
            )}
            {props.update && (
              <button type="submit" disabled={isUpdateLoading}>
                Sauvegarder
              </button>
            )}
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};
export default AddNewDocument;

import { PAGE_SIZE } from "../../config/app";
import axios from "../../initializations/axios";

export const getSubscriptionStatus = async () => {
  const { data } = await axios.get("Subscription/my");
  return data;
};

export const getSubscriptionPackets = async () => {
  const { data } = await axios.get("Subscription/packets");
  return data;
};

export const getSubscriptionHistory = async (
  userId?: string,
  pageNumber?: number
) => {
  const { data } = await axios.get(
    `Subscription/historyOfPayments/${userId}?pageSize=${PAGE_SIZE}&pageNumber=${pageNumber}`
  );
  return data;
};

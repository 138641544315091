import {Outlet} from 'react-router-dom';

 const EveningsSharedLayout=()=> {
  return (
    <>
    <Outlet/>
    </>
  );
}

export default EveningsSharedLayout;
import { FC, ReactElement, useEffect, useState } from "react";
import LoadingSpinner from "../../../../components/LoadingSpinner/LoadingSpinner";
import MuiPagination from "../../../../components/MuiPagination/MuiPagination";
import {
  useEventParticipantDetailsQuery,
  useEventQuery,
} from "../../../../react-query/hooks/Events";
import AddParticipant from "../../../modals/AddParticipant";
import EventParticipantInfo from "../../Molecules/EventParticipantInfo/EventParticipantInfo";
import { NoResults } from "../NoResults/NoResults";

interface EventAttendeesProps {
  id: string;
}
const EventAttendees: FC<EventAttendeesProps> = ({ id }): ReactElement => {
  const [page, setPage] = useState(1);
  const [modalShow, setModalShow] = useState(false);
  const { data: { data, metaData } = {}, isLoading } =
    useEventParticipantDetailsQuery(+id, page);
  const { data: { data: eventData } = {} } = useEventQuery(+id);
  const localDate = new Date(eventData?.date!);
  const localTimeString = localDate.toLocaleTimeString(undefined, {
    hour: "2-digit",
    minute: "2-digit",
  });
  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div>
          <div className="configurationButton">
            <button onClick={() => setModalShow(true)}>+ Ajouter</button>
          </div>
          {data?.length! > 0 ? (
            <div className="dashboardResults">
              <table>
                <tr>
                  <th>NOM</th>
                  <th>RESTAURANT</th>
                  <th>ÉVÉNEMENT</th>
                  <th className="lastElement actionColumn">STATUT</th>
                </tr>
                {data?.map((participant) => {
                  return (
                    <EventParticipantInfo
                      key={participant.reservationId}
                      id={participant.reservationId}
                      firstName={participant.firstName}
                      lastName={participant.lastName}
                      email={participant.email}
                      restaurantParticipantNumber={
                        participant.restaurantParticipantNumber
                      }
                      eventReservationParticipants={
                        participant.eventReservationParticipants
                      }
                      restaurantArrivalTime={participant.restaurantArrivalTime}
                      eventArrivalTime={participant.eventArrivalTime}
                      tableName={participant.tableName}
                      reservationStatus={participant.reservationStatus}
                      profilePicture={participant?.profilePicture}
                    />
                  );
                })}
              </table>
              <div className="dashboardPagination">
                <MuiPagination
                  pagesNumber={metaData?.pageCount!}
                  page={page}
                  setPage={setPage}
                />
              </div>
            </div>
          ) : (
            <NoResults />
          )}
          <AddParticipant
            show={modalShow}
            onHide={() => setModalShow(false)}
            time={localTimeString.slice(0, 5)}
            eventId={id}
            pageNumber={page}
          />
        </div>
      )}
    </>
  );
};
export default EventAttendees;

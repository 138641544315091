import './_SkeletonFavoriteDjCard.scss';

const SkeletonFavoriteDjCard=()=>{
    return(
        <div className='skeletonfavoritedjcard'>
            <div className='skeletonfavoritedjcard-photo'></div>
            <div className='skeletonfavoritedjcard-info'>
                <div className="h3"></div>
            </div>
        </div>
    )
}
export default SkeletonFavoriteDjCard;
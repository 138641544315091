import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import { BasicResponse } from "../../interfaces/mainModels/BasicResponse.model";
import { UserProfileResponseModel } from "../../interfaces/models/getModels/UserProfileResponse.model";
import { ProfilePicturePostModel } from "../../interfaces/models/postModels/ProfilePicturePost.model";
import { uploadProfilePic } from "../mutations/user";
import { getUserProfile } from "../queries/user";

// ------GET------
export const useUserProfile = (
  options?: Omit<
    UseQueryOptions<BasicResponse<UserProfileResponseModel>>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<BasicResponse<UserProfileResponseModel>> =>
  useQuery(["user-profile"], () => getUserProfile(), options);

// -----POST------
export const useUploadProfilePic = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ["uploadProfilePic"],
    (profilePicData: ProfilePicturePostModel) =>
      uploadProfilePic(profilePicData),
    {
      onSuccess: async () => queryClient.invalidateQueries(["user-profile"]),
    }
  );
};

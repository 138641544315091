import objectToQueryString from "../utils/objectToQueryString";
import { apiRequest } from "./Api";
import { responseBody, metaData } from "./Shared";

//-------Business--------
export interface BusinessBasicInfo {
  longitude: number;
  latitude: number;
  distance: number;
  id: number;
  name: string;
  description: string;
  address: string;
  city: string;
  averageRating: number;
  banner: string;
  logo: string;
  maxAge: number;
  minAge: number;
  isMyFavorite: boolean;
  insertedDate: string;
  isOpened: boolean;
}

//-------BusinessInput--------
export interface GetBusinessInput {
  searchWord?: string;
  dayOfWeek?: number;
  radius?: number;
  latitude?: number;
  longitude?: number;
  businessType?: number;
  hasRestaurant?: boolean;
  minimumRating?: number;
  pageSize?: number;
  pageNumber?: number;
  patio?: boolean | null;
  location?: string;
}
export interface GetBusinessResponse {
  data: {
    data: BusinessBasicInfo[];
    metaData: metaData;
  };
}

// -----GetBusinessProfileInput-----
export interface GetBusinessProfileInput {
  Id?: string;
}
// -----GetBusinessProfileResponse-----
export interface schedule {
  id: number;
  businessId: number;
  weekDay: number;
  startTime: string;
  endTime: string;
  insertedDate: string;
}
export interface GetBusinessProfileResponse {
  data: {
    id: number;
    name: string;
    latitude: number;
    longitude: number;
    description: string;
    hasRestaurant: boolean;
    country: string;
    address: string;
    city: string;
    logo: string;
    banner: string;
    patio: boolean;
    reviewNumber: number;
    distance: number;
    maxAge: number;
    minAge: number;
    isMyFavorite: boolean;
    phoneNumber: string;
    insertedDate: string;
    insertedFrom: string;
    averageRating: number;
    gallery: string[];
    musicTypes: [
      {
        id: number;
        title: string;
        insertedDate: string;
      },
      {
        id: number;
        title: string;
        insertedDate: string;
      }
    ];
    events: {
      id: number;
      city: string;
      country: string;
      address: string;
      title: string;
      clubName: string;
      date: string;
      averageScore: number;
      banner: string;
      insertedDate: string;
      distance: string;
    }[];
    businessSchedules: schedule[];
    usersReview: number;
    businessType: {
      id: number;
      name: string;
      insertedDate: string;
    }[];
  };
}

// ----musicTypes----
export interface GetBusinessTypesResponse {
  data: {
    id: number;
    name: string;
    insertedDate: string;
  }[];
  metaData: metaData;
}

// ----FavoritesBusiness----
export interface GetFavoritesBusinessResponse extends responseBody {
  data: {
    data: favoritesBusinessArray;
    metaData: metaData;
  };
}
export interface favoritesBusinessArray {
  longitude: number;
  latitude: number;
  distance: number;
  id: number;
  name: string;
  description: string;
  address: string;
  city: string;
  averageRating: number;
  banner: string;
  logo: string;
  maxAge: number;
  minAge: number;
  isMyFavorite: boolean;
  insertedDate: string;
}

// -----BusinessRating-----
export interface addBusinessReviewInput {
  businessId: number;
  rating: number | null;
  comment: string;
}
export interface upDateBusinessReviewInput {
  id: number;
  rating: number;
  comment: string;
}
export interface businessReview {
  id: number;
  rating: number;
  comment: string;
  firstName: string;
  email: string;
  userId: string;
  insertedDate: string;
}
export interface getBusinessReviewsResponse extends responseBody {
  data: {
    data: businessReview[];
    metaData: metaData;
  };
}

// ----BusinessService----
export interface businessService {
  id: number;
  businessId: number;
  name: string;
  price: number;
  serviceType: {
    id: number;
    name: string;
    insertedDate: string;
  };
  insertedDate: string;
}
export interface getBusinessServiceResponse extends responseBody {
  data: businessService;
}
export interface getBusinessServicesResponse extends responseBody {
  data: {
    data: {
      serviceType: {
        id: number;
        name: string;
        insertedDate: string;
      };
      services: businessService[];
    }[];
    metaData: metaData;
  };
}

export interface postBusinessService {
  businessId: number;
  serviceType: number;
  name: string;
  price: number;
}

// -------apiRequests---------
export const getBusiness = async (
  data: GetBusinessInput,
  location?: { latitude?: number; longitude?: number } | undefined
) =>
  apiRequest<GetBusinessInput, GetBusinessResponse>(
    "post",
    "/Business/search",
    data,
    { Location: `${location?.latitude},${location?.longitude}` }
  );

export const getBusinessProfile = async (data: GetBusinessProfileInput) =>
  apiRequest<GetBusinessProfileInput, GetBusinessProfileResponse>(
    "get",
    `/Business/profile/${data.Id}`,
    data
  );

export const getBusinessTypes = async () =>
  apiRequest<GetBusinessTypesResponse>("get", "/BusinessTypes");

export const getFavoritesBusiness = async (params: any) =>
  apiRequest<GetFavoritesBusinessResponse>(
    "get",
    `/Business/favorite?${objectToQueryString(params)}&pageSize=3`
  );

export const postFavoriteBusiness = async (data: { id?: number }) =>
  apiRequest<responseBody>("post", `/Business/favorite/${data.id}`);

export const deleteFavoriteBusiness = async (data: { id?: number }) =>
  apiRequest<responseBody>("delete", `/Business/favorite/${data.id}`);

export const getBusinessReviews = async (data: {
  id?: number;
  pageNumber?: number;
}) =>
  apiRequest<getBusinessReviewsResponse>(
    "get",
    `/BusinessRatings/${data.id}/?${data.pageNumber}`
  );

export const deleteBusinessReview = async (data: { id?: number }) =>
  apiRequest<getBusinessReviewsResponse>(
    "delete",
    `/BusinessRatings/${data.id}`
  );

export const postBusinessReview = async (data: addBusinessReviewInput) =>
  apiRequest<addBusinessReviewInput, responseBody>(
    "post",
    "/BusinessRatings",
    data
  );

export const updateBusinessReview = async (data: upDateBusinessReviewInput) =>
  apiRequest<upDateBusinessReviewInput, responseBody>(
    "put",
    "/BusinessRatings",
    data
  );

export const getBusinessService = async (data: { id?: number }) =>
  apiRequest<getBusinessServiceResponse>("get", `/BusinessService/${data.id}`);

export const deleteBusinessService = async (data: { id?: number }) =>
  apiRequest<responseBody>("delete", `/BusinessService/${data.id}`);

export const getBusinessServices = async (data: { id?: number }) =>
  apiRequest<getBusinessServicesResponse>(
    "get",
    `/BusinessService/all/${data.id}?pageSize=500`
  );

import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import queryString from "query-string";
import { MetaDataResponse } from "../../interfaces/mainModels/MetaDataResponse.model";
import { UserModel } from "../../interfaces/models/getModels/User.model";
import { fetchAllUsers } from "../queries/userManager";

export const useUsersManagerQuery = (
  params: string,
  options?: Omit<
    UseQueryOptions<MetaDataResponse<UserModel[]>>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<MetaDataResponse<UserModel[]>> =>
  useQuery(
    [`users-${queryString.parse(params)["pageNumber"]}`],
    () => fetchAllUsers(params),
    options
  );

import { PAGE_SIZE } from "../../config/app";
import axios from "../../initializations/axios";

// -----GET-----

export const getMyClubs = async (pageNumber: number) => {
  const { data } = await axios.get(
    `Business/my?pageSize=${PAGE_SIZE}&pageNumber=${pageNumber}`
  );
  return data;
};

export const getClubsName = async (searchWord?: string) => {
  const { data } = await axios.get(
    `Business/getAllBusinessNames?pageSize=50&pageNumber=1&searchWord=${searchWord}`
  );
  return data;
};

export const getClubProfile = async (id: number) => {
  const { data } = await axios.get(`Business/profile/${id}`);
  return data;
};

export const getClubGallery = async (id: number, pageNumber: number) => {
  const { data } = await axios.get(
    `Business/gallery/${id}?pageSize=${PAGE_SIZE}&pageNumber=${pageNumber}`
  );
  return data;
};

export const getClubPricing = async (id: number, pageNumber: number) => {
  const { data } = await axios.get(
    `BusinessService/all/${id}?pageSize=${PAGE_SIZE}&pageNumber=${pageNumber}`
  );
  return data;
};

export const getAllClubServices = async (id: number) => {
  const { data } = await axios.get(
    `BusinessService/all/${id}?pageSize=1000&pageNumber=1`
  );
  return data;
};

export const getServicesType = async () => {
  const { data } = await axios.get("ServiceType?pageSize=1000&pageNumber=1");
  return data;
};

export const getClubReviews = async (id: number, pageNumber: number) => {
  const { data } = await axios.get(
    `BusinessRatings/${id}?pageSize=${PAGE_SIZE}&pageNumber=${pageNumber}`
  );
  return data;
};

export const getClubSchedules = async (id: number) => {
  const { data } = await axios.get(`Business/businessSchedule/${id}`);
  return data;
};

export const getClubJurisdictions = async (id: number) => {
  const { data } = await axios.get(`BusinessJurisdictions/all/${id}`);
  return data;
};

export const getClubTables = async (id: number, pageNumber: number) => {
  const { data } = await axios.get(
    `Table/all/${id}?pageSize=${PAGE_SIZE}&pageNumber=${pageNumber}`
  );
  return data;
};

export const getClubsTypes = async () => {
  const { data } = await axios.get("BusinessTypes");
  return data;
};

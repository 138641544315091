import { useEffect, useState } from "react";
import AppUsersNumber from "../../page-sections/shared/AppUsersNumber/AppUsersNumber";
import HomeClubsList from "../../page-sections/Home/HomeClubsList/HomeClubsList";
import HomeEveningsList from "../../page-sections/Home/HomeEveningsList/HomeEveningsList";
import "./_HomeSearchBar.scss";
import DownloadApp from "../../page-sections/shared/DownloadApp/DownloadApp";
import "./_Home.scss";
import location from "../../../assets/icons/location.svg";
import building from "../../../assets/icons/building.svg";
import { useGoogleAutoFill } from "../../../components/GoogleAutoFill/GoogleAutoFill";
import HomeEveningsSuggestion from "../../page-sections/Home/HomeEveningsSuggestion/HomeEveningsSuggestion";
import HomeClubsSuggestion from "../../page-sections/Home/HomeClubsSuggestion/HomeClubsSuggestion";
import { useAuthContext } from "../../../context/AuthContext/AuthContext";

const Home = () => {
  const authCtx = useAuthContext();
  const { address, render } = useGoogleAutoFill("");
  const [word, setWord] = useState<string>("");
  const [select, setSelect] = useState<string>("Clubs");
  const [selectInput, setSelectInput] = useState<string>(select);
  const [latitude, setLatitude] = useState<number>();
  const [longitude, setLongitude] = useState<number>();
  const successfullLookup = (position: {
    coords: { latitude?: number; longitude?: number };
  }) => {
    const { latitude, longitude } = position.coords;
    setLatitude(latitude);
    setLongitude(longitude);
    authCtx.setLocation({ latitude: latitude, longitude: longitude });
  };
  window.navigator.geolocation.getCurrentPosition(successfullLookup);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [latitude]);
  return (
    <div className="home">
      <div className="home-searchbar">
        <div className="homesearchbar">
          <div className="homesearchbar-mainPhoto"></div>
          <h1 className="container-searchbar">
            Votre prochaine soirée commence ici !
          </h1>
          <form
            className="homesearchbar-filter container-searchbar"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div className="homesearchbar-filter-section1">
              <img src={location} alt="" />
              <div className="homesearchbar-filter-section1-part2">
                <h5>Ville</h5>
                <div className="googleautofill">{render}</div>
              </div>
            </div>
            <div className="homesearchbar-filter-section2">
              <img src={building} alt="" />
              <select
                onChange={(e) => setSelect(e.target.value)}
                value={select}
              >
                <option value="Clubs">Rechercher un club</option>
                <option value="Evenings">Rechercher un évènement</option>
              </select>
            </div>
            <button
              className="filter-item"
              type="submit"
              onClick={() => {
                setWord(address);
                setSelectInput(select);
              }}
            >
              Rechercher
            </button>
          </form>
        </div>
      </div>
      {selectInput !== "Clubs" ? (
        <>
          <div className="home-clublist container-home">
            <HomeEveningsList input={word} />
          </div>
          <div className="home-eveningslist">
            <div className="home-eveningslist-section">
              <HomeEveningsSuggestion
                latitude={latitude}
                longitude={longitude}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="home-clublist container-home">
            <HomeClubsList input={word} />
          </div>
          <div className="home-eveningslist">
            <div className="home-eveningslist-section">
              <HomeClubsSuggestion latitude={latitude} longitude={longitude} />
            </div>
          </div>
        </>
      )}
      <div className="home-appusernumber ">
        <AppUsersNumber />
      </div>
      <div className="home-downloadapp container-home">
        <DownloadApp />
      </div>
    </div>
  );
};

export default Home;

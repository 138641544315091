import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { AxiosError } from "axios";
import { ErrorResponse } from "../../interfaces/mainModels/ErrorResponse.model";
import { ParticipantNotificationPayloadModel } from "../../interfaces/models/postModels/NotificationPayload.model";
import { notifyParticipants } from "../mutations/notifyParticipants";

export const useNotifyParticipantsMutation = () =>
  useMutation(
    ["notify-participants"],
    (payload: ParticipantNotificationPayloadModel) =>
      notifyParticipants(payload),
    {
      onError: (err) => {
        const error = err as AxiosError<ErrorResponse>;
        toast.error(
          error.response?.data.errors[0].message || "Something went wrong!",
          {
            style: {
              borderRadius: "10px",
              background: "#cc0000",
              color: "#fff",
            },
          }
        );
      },
    }
  );

import "./_NoReservation.scss";
import reservationEmpty from "../.././../../../assets/photos/ReservationEmpty.webp";
import { Link } from "react-router-dom";

const NoReservation = () => {
  return (
    <div className="noreservations">
      <div className="noreservations-content">
        <img src={reservationEmpty} alt="" />
        <h3>Aucune Réservation</h3>
        <p>Vos réservations apparaîtront ici</p>
        <Link to="/evenings">Voir les soirées</Link>
      </div>
    </div>
  );
};

export default NoReservation;

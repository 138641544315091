import { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext/AuthContext";
import { useDeleteClubQuery } from "../../react-query/hooks/Clubs";
import { useDeleteEventQuery } from "../../react-query/hooks/Events";

const DeleteEventClub = (props: any) => {
  const navigate = useNavigate();
  const authCtx = useAuthContext();

  const {
    mutate: deleteClub,
    data: dataClubStatus,
    isSuccess: isClubDeletetSuccesfully,
  } = useDeleteClubQuery();
  const {
    mutate: deleteEvent,
    data: dataEventStatus,
    isSuccess: isEventDeletetSuccesfully,
  } = useDeleteEventQuery();

  const handleDeleteClub = () => {
    deleteClub(props.id);
  };
  const handleDeleteEvent = () => {
    deleteEvent(props.id);
  };
  useEffect(() => {
    dataClubStatus?.status === 200 &&
      navigate(`/${authCtx?.decoded?.role.toLowerCase()}/clubs`);
    dataEventStatus?.status === 200 &&
      navigate(`/${authCtx?.decoded?.role.toLowerCase()}/events`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClubDeletetSuccesfully, isEventDeletetSuccesfully]);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="dashboardModal"
    >
      <Modal.Header>
        {props.deleteEvent && <h4>Supprimer un événement</h4>}
        {props.deleteClub && <h4>Supprimer un club</h4>}
      </Modal.Header>
      <Modal.Body>
        <div className="modalBody">
          <p className="areYouSure">Êtes-vous sûr?</p>
          <div className="modalBody-buttons modalItem">
            <button
              type="button"
              onClick={() => {
                props.onHide();
              }}
            >
              Annuler
            </button>
            {props.deleteEvent && (
              <button onClick={handleDeleteEvent} className="deleteBtn">
                Effacer
              </button>
            )}
            {props.deleteClub && (
              <button onClick={handleDeleteClub} className="deleteBtn">
                Effacer
              </button>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default DeleteEventClub;

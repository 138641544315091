import axios from "../../initializations/axios";
import { CheckResetPasswordCodePostModel } from "../../interfaces/models/postModels/CheckResetPasswordCodePost.model";
import { ConfirmAccountPostModel } from "../../interfaces/models/postModels/ConfirmAccountPost.model";
import { ForgotPasswordPostModel } from "../../interfaces/models/postModels/ForgotPasswordPost.model";
import { ResendConfrimationCodePostModel } from "../../interfaces/models/postModels/ResendConfirmationCodePost.model";
import { ResetPassswordPostModel } from "../../interfaces/models/postModels/ResetPasswordPost.model";
import { SignInPostModel } from "../../interfaces/models/postModels/SignInPost.model";
import { SignUpPostModel } from "../../interfaces/models/postModels/SignUpPost.model";

export const signIn = async (SignInInputs: SignInPostModel) => {
  const data = await axios.post("Account/SignIn", SignInInputs);
  return data;
};
export const signUp = async (
  SignUpInputs: SignUpPostModel,
  Role?: string | null
) => {
  const data = await axios.post(
    `Account/SignUp${Role === "register" ? "/Manager" : ""}`,
    SignUpInputs
  );
  return data;
};
export const confrimAccount = async (
  ConfirmAccountInputs: ConfirmAccountPostModel
) => {
  const data = await axios.post("Account/ConfirmAccount", ConfirmAccountInputs);
  return data;
};
export const resendConfirmationCode = async (
  ResendConfirmationCodeInputs: ResendConfrimationCodePostModel
) => {
  const data = await axios.post(
    "Account/ResendConfirmationCode",
    ResendConfirmationCodeInputs
  );
  return data;
};
export const forgotPassword = async (
  ForgotPasswordInputs: ForgotPasswordPostModel
) => {
  const data = await axios.post("Account/ForgotPassword", ForgotPasswordInputs);
  return data;
};
export const checkResetPasswordCode = async (
  CheckResetPasswordCodeInputs: CheckResetPasswordCodePostModel
) => {
  const data = await axios.post(
    "Account/CheckResetPasswordCode",
    CheckResetPasswordCodeInputs
  );
  return data;
};
export const resetPassword = async (
  ResetPasswordInputs: ResetPassswordPostModel
) => {
  const data = await axios.post("Account/ResetPassword", ResetPasswordInputs);
  return data;
};

import { ReactElement, useState } from "react";
import { useFetchAllMusicTypesQuery } from "../../../../react-query/hooks/useConfiguration.query";
import MusicForm from "../../../modals/MusicForm";
import MusicConfigurationInfo from "../../Molecules/MusicConfigurationInfo/MusicConfigurationInfo";

const ConfigurationsMusics = (): ReactElement => {
  // local state
  const [modalShow, setModalShow] = useState(false);

  // react-query
  const { data: musicTypes = [] } = useFetchAllMusicTypesQuery();

  return (
    <div>
      <div className="configurationButton">
        <button onClick={() => setModalShow(true)}>+ Ajouter</button>
      </div>
      <div className="dashboardResults">
        <table>
          <tr>
            <th>TYPE</th>
            <th className="lastElement actionColumn">ACTIONS</th>
          </tr>
          {musicTypes.map((musicType) => (
            <MusicConfigurationInfo key={musicType.id} musicType={musicType} />
          ))}
        </table>
      </div>
      <MusicForm show={modalShow} onHide={() => setModalShow(false)} />
    </div>
  );
};
export default ConfigurationsMusics;

import { FC, useEffect, useState } from "react";
import ClubCard from "../../../components/shared/Cards/ClubCard/ClubCard";
import QuotationMarks from "../../../../assets/icons/QuotationMarks.png";
import "./_ClubsAlreadyIn.scss";
import { BusinessBasicInfo, getBusiness } from "../../../../api/Business";
import SkeletonClubCard from "../../../components/shared/Cards/SkeletonClubCard/SkeletonClubCard";
import { useAuthContext } from "../../../../context/AuthContext/AuthContext";

interface ClubsAlreadyInProps {}

const ClubsAlreadyIn: FC<ClubsAlreadyInProps> = () => {
  const [businessData, setBusinessData] = useState<BusinessBasicInfo[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const authCtx = useAuthContext();
  const getBusinessData = async () => {
    try {
      const businessResponse = await getBusiness(
        { location: "" },
        authCtx?.location
      );
      setBusinessData(businessResponse?.data?.data);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getBusinessData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showSkeleton = (n: number) => {
    return [...Array(n)].map((e, i) => (
      <div className="clubsalreadyin-clubs-item" key={`${2000 + i}`}>
        <SkeletonClubCard />
      </div>
    ));
  };

  const sortedBusiness = businessData?.sort(function compareNumber(
    a: BusinessBasicInfo,
    b: BusinessBasicInfo
  ) {
    return b.averageRating - a.averageRating;
  });
  return (
    <div className="clubsalreadyin">
      <h4 className="container">Ils nous font déjà confiance !</h4>
      <div className="clubsalreadyin-clubs container">
        {sortedBusiness?.slice(0, 3)?.map((business) => (
          <div className="clubsalreadyin-clubs-item" key={business.id}>
            <ClubCard
              id={business?.id}
              photo={business?.banner}
              name={business?.name}
              averageRating={business?.averageRating}
              address={business?.address}
              city={business?.city}
              distance={business?.distance}
              isOpened={business?.isOpened}
            />
          </div>
        ))}
        {loading && showSkeleton(3)}
      </div>
      <div className="clubsalreadyin-reasons container">
        <img src={QuotationMarks} alt="" />
        <p>
          Avant 7Night, mes clients étaient mécontent lorsque l’on ne répondais
          pas au téléphone, ce qui les poussaient à réserver ailleurs.
          Aujourd’hui, c’est 90% de réservations par internet et mes clients
          peuvent réserver 24h/24 plusieurs jours en avance. De plus, je vois
          mon nombre de consommation augmenter grâce au système de rencontre
          permettant aux garçons d’offrir des verres aux filles pour les
          aborder.
        </p>
      </div>
    </div>
  );
};

export default ClubsAlreadyIn;

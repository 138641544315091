import {
  ChangeEvent,
  KeyboardEvent,
  ReactElement,
  useEffect,
  useState,
} from "react";
import dayjs from "dayjs";
import { TextField } from "@mui/material";
import uploadCoverImg from "../../../../assets/icons/ImgEdit.png";
import uploadImg from "../../../../assets/icons/uploadImg.png";
import {
  useDjsNameQuery,
  usePostEventQuery,
} from "../../../../react-query/hooks/Events";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { UseConvertToBase64 } from "../../../../hooks/UseConvertToBase64";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../../../context/AuthContext/AuthContext";
import { EventPostModel } from "../../../../interfaces/models/postModels/EventPost.model";
import Select from "react-select";
import { useClubsNameQuery } from "../../../../react-query/hooks/Clubs";

const schema = yup.object().shape({
  businessId: yup.number().required("Obligatoire"),
  banner: yup.mixed().required("Obligatoire"),
  dj: yup.number().transform((value) => (isNaN(value) ? undefined : value)),
  title: yup.string().required("Obligatoire"),
  description: yup.string().required("Obligatoire"),
  date: yup
    .date()
    .min(
      new Date(),
      "La date et l'heure doivent être postérieures à maintenant"
    )
    .typeError("Veuillez fournir une date valide")
    .required("Obligatoire"),
  maxParticipants: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value)),
  // minAge: yup
  //   .number()
  //   .typeError("L'âge minimum est requis")
  //   .required("Obligatoire"),
  // maxAge: yup
  //   .number()
  //   .typeError("L'âge maximum est requis")
  //   .required("Obligatoire"),
  isFreeEntryForBoys: yup.boolean().required("Obligatoire"),
  isFreeEntryForGirls: yup.boolean().required("Obligatoire"),
  phone: yup.string().required("Obligatoire"),
  hasShowcase: yup.boolean().required("Obligatoire"),
  enterior: yup.boolean().required("Obligatoire"),
  exterior: yup.boolean().required("Obligatoire"),
  isAlmostFull: yup.boolean().required("Obligatoire"),
  confirmationRadius: yup
    .number()
    .min(10, "Valeur min 10 et maximum 1000")
    .max(1000, "Valeur min 10 et maximum 1000")
    .transform((value) => (isNaN(value) ? undefined : value)),
});
const AddEventDetails = (): ReactElement => {
  const authCtx = useAuthContext();
  const role = authCtx.decoded?.role?.toLowerCase();
  const navigate = useNavigate();
  const [errorFileType, setErrorFileType] = useState<string>();
  const uploadFile = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files![0];
    const fileInBase64 = await UseConvertToBase64(file);
    if (
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/jpeg"
    ) {
      setPhoto(fileInBase64);
      setValue("banner", {
        base64: fileInBase64,
        fileName: e.target.files![0].name,
      });
    } else {
      setErrorFileType("Type de fichier non pris en charge!");
    }
  };
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<EventPostModel>({
    resolver: yupResolver(schema),
  });
  const [keyWordsAdded, setKeyWordsAdded] = useState<Array<string>>([]);
  const [key, setKey] = useState<string>("");
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setKey(e.target.value);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleAdd();
      e.preventDefault();
    }
  };
  const [clubsSearchWord, setClubSearchWord] = useState<string>("");
  const handleAdd = () => {
    key !== "" &&
      !keyWordsAdded.includes(key) &&
      setKeyWordsAdded((prev) => [...prev, key]);
    key !== "" && setKey("");
  };
  const deleteKeyWord = (keyword: string) => {
    setKeyWordsAdded((prev) => prev.filter((word) => word !== keyword));
  };
  const {
    mutate: addEvent,
    data: { data } = {},
    isSuccess,
    isLoading,
  } = usePostEventQuery();
  const { data: { data: djsList } = {} } = useDjsNameQuery();
  const { data: { data: clubsList } = {} } = useClubsNameQuery(clubsSearchWord);
  const [photo, setPhoto] = useState<string | unknown>();
  const handlePostEvent = (eventDetails: EventPostModel) => {
    addEvent({
      ...eventDetails,
      keyWords: keyWordsAdded,
      date: dayjs(eventDetails.date).format("YYYY-MM-DDTHH:mm:ssZ"),
    });
  };
  const handleEventChange = (e: any) => {
    setValue("businessId", e?.value!);
  };
  useEffect(() => {
    isSuccess &&
      navigate(`/${role}/events/${data?.data?.id}?tab=gallery`, {
        replace: true,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  return (
    <>
      <form className="details" onSubmit={handleSubmit(handlePostEvent)}>
        {!!photo && (
          <div
            className="details-photo detailsSection"
            style={{ backgroundImage: `url(${photo})` }}
          >
            <label className="detailImgInput">
              <input
                type="file"
                onChange={(e) => uploadFile(e)}
                accept="image/png, image/jpg, image/jpeg"
              />
              <div className="imageInput-style">
                <img src={uploadCoverImg} alt="" />
              </div>
            </label>
          </div>
        )}
        {!photo && (
          <div className="details-emptyphoto detailsSection">
            <label className="emptyImg">
              <input type="file" onChange={(e) => uploadFile(e)} />
              <div className="emptyImg-text">
                <img src={uploadImg} alt="" />
                <h6>
                  Déposez votre image ici, ou{" "}
                  <span>parcourez sur votre appareil</span>
                </h6>
                <p>Format requis: JPG, PNG</p>
                {errors.banner && (
                  <p className="tw-text-red-600 mb-0">
                    {errors.banner.message}
                  </p>
                )}
                <p className="tw-text-red-600 mb-0">{errorFileType}</p>
              </div>
            </label>
          </div>
        )}
        <div className="details-info detailsSection">
          <h3>Informations</h3>
          <div className="detailsInputs">
            <div className="detailsInput">
              <label htmlFor="clubName">Nom du club</label>
              <Select
                options={clubsList?.map(({ id, name }) => ({
                  value: id,
                  label: name,
                }))}
                onInputChange={(characterEntered) => {
                  setClubSearchWord(characterEntered);
                }}
                onChange={handleEventChange}
                isClearable={true}
                placeholder="Sélectionner"
              />

              {errors.businessId && (
                <p className="tw-text-red-600 mb-0">
                  {errors.businessId.message}
                </p>
              )}
            </div>
            <div className="detailsInput">
              <label htmlFor="title">Titre de l’évènement</label>
              <input
                type="text"
                id="title"
                placeholder="Maximum 50 caractères..."
                minLength={1}
                maxLength={50}
                {...register("title")}
              />
              {errors.title && (
                <p className="tw-text-red-600 mb-0">{errors.title.message}</p>
              )}
            </div>
            <div className="detailsInput">
              <label htmlFor="djName">Dj / Artistes (Facultatif)</label>
              <Select
                options={djsList?.map(({ id, name }) => ({
                  value: id,
                  label: name,
                }))}
                onChange={(e) => setValue("dj", e?.value!)}
                isClearable={true}
                placeholder="Sélectionner"
              />
              {errors.dj && (
                <p className="tw-text-red-600 mb-0">{errors.dj.message}</p>
              )}
            </div>
            <div className="detailsInput">
              <label htmlFor="title">Date et Heure de début</label>
              <TextField
                fullWidth
                variant="standard"
                type="datetime-local"
                defaultValue={data?.date}
                InputProps={{ disableUnderline: true }}
                {...register("date")}
              />
              {errors.date && (
                <p className="tw-text-red-600 mb-0">{errors.date.message}</p>
              )}
            </div>
            <div className="detailsInput">
              <label htmlFor="title"> Téléphone d’un responsable</label>
              <input
                type="text"
                id="title"
                placeholder="Un champ vide"
                defaultValue={data?.phone}
                {...register("phone")}
              />
              {errors.phone && (
                <p className="tw-text-red-600 mb-0">{errors.phone.message}</p>
              )}
            </div>
            <div className="detailsInput">
              <label htmlFor="title">Nombre de places</label>
              <input type="text" id="title" {...register("maxParticipants")} />
              {errors.maxParticipants && (
                <p className="tw-text-red-600 mb-0">
                  {errors.maxParticipants.message}
                </p>
              )}
            </div>
            <div className="detailsInput">
              <label htmlFor="confirmationRadius">
                Rayon de participation à un évènement
              </label>
              <input
                type="number"
                id="confirmationRadius"
                defaultValue={data?.confirmationRadius}
                placeholder="Recommandé entre 20 et 50 mètres"
                {...register("confirmationRadius")}
              />
              {errors.confirmationRadius && (
                <p className="tw-text-red-600 mb-0">
                  {errors.confirmationRadius.message}
                </p>
              )}
            </div>
            <div className="detailsInput">
              <label htmlFor="title">Mots-clés (Facultatif)</label>
              <input
                type="text"
                id="title"
                value={key}
                placeholder="Appuyez Entrée pour chaque mot-clé."
                onChange={(e) => handleChange(e)}
                onKeyDown={(e) => handleKeyDown(e)}
              />
            </div>
            <div className="detailsInput keywords">
              <label htmlFor="">Mots clés ajoutés</label>
              <div className="keywordsResults">
                {keyWordsAdded?.map((keyword: string, index) => {
                  return (
                    <button
                      type="button"
                      onClick={() => deleteKeyWord(keyword)}
                      key={index}
                    >
                      {keyword}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="details-infoForClient detailsSection">
          <h3>Autres informations importantes pour les clients</h3>
          <div className="detailsInputs">
            <div className="detailsInput">
              <label>Sélectionnez les caractéristiques de l'évènement : </label>
              <div className="checkboxInput">
                <input
                  type="checkbox"
                  id="interior"
                  value="true"
                  {...register("enterior")}
                />
                <label htmlFor="interior">En intérieur</label>
              </div>
              <div className="checkboxInput">
                <input
                  type="checkbox"
                  id="exterior"
                  value="true"
                  {...register("exterior")}
                />
                <label htmlFor="exterior">En extérieur</label>
              </div>
              {errors.enterior && (
                <p className="tw-text-red-600 mb-0">
                  {errors.enterior.message}
                </p>
              )}
              {errors.exterior && (
                <p className="tw-text-red-600 mb-0">
                  {errors.exterior.message}
                </p>
              )}
            </div>
            <div className="detailsInput">
              <div className="checkboxInput">
                <input
                  type="checkbox"
                  id="isFreeForGirls"
                  value="true"
                  {...register("isFreeEntryForGirls")}
                />
                <label htmlFor="isFreeForGirls">
                  Entrée gratuite pour les filles (inscrire les conditions en
                  description si nécessaire)
                </label>
              </div>
              <div className="checkboxInput">
                <input
                  type="checkbox"
                  id="isFreeForBoys"
                  value="true"
                  {...register("isFreeEntryForBoys")}
                />
                <label htmlFor="isFreeForBoys">
                  Entrée gratuite pour les garçons (inscrire les conditions en
                  description si nécessaire)
                </label>
                {errors.isFreeEntryForGirls && (
                  <p className="tw-text-red-600 mb-0">
                    {errors.isFreeEntryForGirls.message}
                  </p>
                )}
                {errors.isFreeEntryForBoys && (
                  <p className="tw-text-red-600 mb-0">
                    {errors.isFreeEntryForBoys.message}
                  </p>
                )}
              </div>
            </div>
            <div className="detailsInput">
              <div className="checkboxInput">
                <input
                  type="checkbox"
                  id="hasShowcase"
                  value="true"
                  {...register("hasShowcase")}
                />
                <label htmlFor="hasShowcase">Showcase</label>
              </div>
              <div className="checkboxInput">
                <input
                  type="checkbox"
                  id="isAlmostFull"
                  value="true"
                  {...register("isAlmostFull")}
                />
                <label htmlFor="isAlmostFull">Évènement bientôt complet</label>
              </div>

              {errors.hasShowcase && (
                <p className="tw-text-red-600 mb-0">
                  {errors.hasShowcase.message}
                </p>
              )}
              {errors.isAlmostFull && (
                <p className="tw-text-red-600 mb-0">
                  {errors.isAlmostFull.message}
                </p>
              )}
            </div>
            {/* <div className="detailsInput">
              <label>Age minimum et maximum des clients:</label>
              <div className="ageInput">
                <label htmlFor="minAge">Min</label>
                <input
                  type="number"
                  id="minAge"
                  placeholder="âge"
                  {...register("minAge")}
                />
                <label htmlFor="maxAge">Max</label>
                <input
                  type="number"
                  id="maxAge"
                  placeholder="âge"
                  {...register("maxAge")}
                />
              </div>
              {errors.maxAge && (
                <p className="tw-text-red-600 mb-0">{errors.maxAge.message}</p>
              )}
              {errors.minAge && (
                <p className="tw-text-red-600 mb-0">{errors.minAge.message}</p>
              )}
            </div> */}
          </div>
          <div className="detailsInput">
            <label htmlFor="description">Description</label>
            <textarea
              rows={7}
              cols={10}
              id="description"
              maxLength={1000}
              placeholder="Entrez une description de l’évènement (max. 1000 caractères)..."
              {...register("description")}
            />
            {errors.description && (
              <p className="tw-text-red-600 mb-0">
                {errors.description.message}
              </p>
            )}
          </div>
        </div>
        <div className="details-save">
          <div></div>
          <div>
            <button type="button" onClick={() => reset()}>
              Annuler
            </button>
            <button type="submit">
              {" "}
              {isLoading ? "Attendez..." : "Suivant"}
            </button>
          </div>
        </div>
      </form>
    </>
  );
};
export default AddEventDetails;

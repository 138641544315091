import { FC } from "react";
import "../_CardsSharedStyle.scss";
import "./_EventCard.scss";
import buildingIcon from "../../../../../assets/icons/building.svg";
import calendar from "../../../../../assets/icons/calendar.svg";
import loactionIcon from "../../../../../assets/icons/location.svg";
import { Link } from "react-router-dom";

interface EventCardProps {
  id: number;
  photo: string;
  title: string;
  club: string;
  address: string;
  date: string;
  distance: number;
  isAlmostFull: boolean;
  isFreeForGirls: boolean;
  isFreeForBoys: boolean;
  hasShowCase: boolean;
  isOpened: boolean;
}
var weekday = new Array(7);
weekday[0] = "Dimanche";
weekday[1] = "Lundi";
weekday[2] = "Mardi";
weekday[3] = "Wednesday";
weekday[4] = "Mercredi";
weekday[5] = "Vendredi";
weekday[6] = "Samedi";

const EventCard: FC<EventCardProps> = ({
  distance,
  photo,
  title,
  club,
  address,
  date,
  id,
  isAlmostFull,
  isFreeForBoys,
  isFreeForGirls,
  hasShowCase,
  isOpened,
}) => {
  const localDate = new Date(date);
  const localDateString = localDate.toLocaleDateString(undefined, {
    day: "numeric",
    month: "short",
    year: "numeric",
  });

  const localTimeString = localDate.toLocaleTimeString(undefined, {
    hour: "2-digit",
    minute: "2-digit",
  });

  const d = new Date(date);
  let day = d.getDay();

  return (
    <section className="eventcard cardssharedstyle">
      <Link to={`/evenings/${id}`}>
        <div className="eventcard-img cardssharedstyle-img">
          <img src={photo} alt="" />
        </div>
        <div className="badges2">
          {isAlmostFull && <p className="badges2-red">BIENTÔT COMPLET</p>}
          {isOpened && <p className="badges2-green">OUVERT AUJOURD'HUI</p>}
          {isFreeForGirls && (
            <p className="badges2-blue">ENTRÉE GRATUITE FILLE</p>
          )}
          {hasShowCase && <p className="badges2-pink">SHOWCASE</p>}

          {isFreeForBoys && (
            <p className="badges2-blue2">ENTRÉE GRATUITE GARÇON</p>
          )}
        </div>
        <div className="eventcard-content cardssharedstyle-content">
          <div className="eventcard-content-info">
            <h2>
              <b>{title}</b>
            </h2>
            <div className="info-item">
              <img src={buildingIcon} alt="" />
              <span>{club}</span>
            </div>
            <div className="info-item">
              <img src={calendar} alt="" />
              <div>
                <span>{weekday[day]}</span>
                <span>
                  {localDateString}, {localTimeString}
                </span>
              </div>
            </div>
            <div className="info-item">
              <img className="info-item-location" src={loactionIcon} alt="" />
              <span>
                {address}
                {distance !== null ? ` (${distance} km)` : null}
              </span>
            </div>
          </div>
        </div>
      </Link>
    </section>
  );
};

export default EventCard;

import { useEffect, useRef, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import {
  getWalletPackets,
  GetWalletPacketsResponse,
} from "../../../../api/Wallet";
import StripePayment from "../../../modals/StipePayment/StripePayment";
import ChooseRecontre from "../../../components/ProfilePages/ProfileRecontre/ChooseRecontre/ChooseRecontre";
import { usePaymentContextContext } from "../../../../context/PaymentContext/PaymentContext";
import "./_ProfileRecontre.scss";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { REACT_APP_STRIPE_CONFIG_CODE } from "../../../../config/app";
import ProfileWalletHistory from "../ProfileWalletHistory/ProfileWalletHistory";

const stripePromise = loadStripe(`${REACT_APP_STRIPE_CONFIG_CODE}`);

const ProfileRecontre = () => {
  const clientIdRef = useRef(false);
  const { clientSecretCode, setClientSecretCode } = usePaymentContextContext();
  const options = {
    clientSecret: `${clientSecretCode}`,
  };
  const [walletBalance, setWalletBalance] =
    useState<GetWalletPacketsResponse>();
  const [modalShow, setModalShow] = useState(false);
  const { productId, setProductId } = usePaymentContextContext();

  const getPackets = async () => {
    try {
      const response: any = await getWalletPackets();
      setWalletBalance(response);
    } catch (e) {
      console.log(e);
    }
  };

  const openStipeModal = () => {
    if (clientSecretCode !== undefined) {
      setModalShow(true);
    }
  };

  useEffect(() => {
    if (clientIdRef.current === false) {
      getPackets();
      setProductId(undefined);
    }
    return () => {
      clientIdRef.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Elements stripe={stripePromise} options={options} key={clientSecretCode}>
      <div className="profilerecontre">
        <h3 className="profilerecontre-title">Recontre</h3>
        <Tabs
          defaultActiveKey="recontre"
          transition={false}
          id="noanim-tab-example"
          className="profilerecontre-nav"
        >
          <Tab eventKey="recontre" title="Recontre" mountOnEnter unmountOnExit>
            <div className="profilerecontre-nav-part1">
              <h2>Soyez en avance sur vos soirées!</h2>
              <p>Choisissez une rencontre</p>
              <div className="profilerecontre-nav-part1-chooserecontre">
                {walletBalance?.data?.map((walletItem) => (
                  <div className="item" key={walletItem.id}>
                    <ChooseRecontre
                      id={walletItem.id}
                      productId={walletItem.productId}
                      name={walletItem.name}
                      winks={walletItem.winks}
                      likes={walletItem.likes}
                      drinks={walletItem.drinks}
                      price={walletItem.price}
                    />
                  </div>
                ))}
              </div>
              <div className="profilerecontre-nav-part1-buyIt">
                <button
                  onClick={() => {
                    openStipeModal();
                  }}
                  disabled={productId === undefined}
                >
                  Acheter maintenant
                </button>
              </div>
            </div>
          </Tab>
          <Tab eventKey="history" title="Historique" mountOnEnter unmountOnExit>
            <ProfileWalletHistory />
          </Tab>
        </Tabs>
        <StripePayment
          show={clientSecretCode !== "" && modalShow}
          onHide={() => {
            setModalShow(false);
            setProductId(undefined);
            setClientSecretCode("");
          }}
        />
      </div>
    </Elements>
  );
};

export default ProfileRecontre;

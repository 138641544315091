import {
  ChangeEvent,
  FC,
  KeyboardEvent,
  ReactElement,
  useEffect,
  useState,
} from "react";
import { TextField } from "@mui/material";
import Select from "react-select";
import dayjs from "dayjs";
import uploadCoverImg from "../../../../assets/icons/ImgEdit.png";
import LoadingSpinner from "../../../../components/LoadingSpinner/LoadingSpinner";
import {
  useDjsNameQuery,
  useEventQuery,
  usePutEventQuery,
} from "../../../../react-query/hooks/Events";
import successIcon from "../../../../assets/icons/successIcon.png";
import "./_Details.scss";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { EventUpdateModel } from "../../../../interfaces/models/updateModels/EventUpdate.model";
import { UseConvertToBase64 } from "../../../../hooks/UseConvertToBase64";
import deleteIcon from "../../../../assets/icons/deleteIcon.png";
import DeleteEventClub from "../../../modals/DeleteEventClub";

interface EventDetailsProps {
  id: string;
}
interface bannerModel {
  base64?: unknown;
  fileName?: string;
}
const schema = yup.object().shape({
  title: yup.string().required("Obligatoire"),
  description: yup.string().required("Obligatoire"),
  date: yup
    .date()
    .min(
      new Date(),
      "La date et l'heure doivent être postérieures à maintenant"
    )
    .typeError("Veuillez fournir une date valide")
    .required("Obligatoire"),
  maxParticipants: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value)),
  // minAge: yup
  //   .number()
  //   .typeError("L'âge minimum est requis")
  //   .required("Obligatoire"),
  // maxAge: yup
  //   .number()
  //   .typeError("L'âge maximum est requis")
  //   .required("Obligatoire"),
  isFreeEntryForBoys: yup.boolean().required("Obligatoire"),
  isFreeEntryForGirls: yup.boolean().required("Obligatoire"),
  phone: yup.string().required("Obligatoire"),
  hasShowcase: yup.boolean().required("Obligatoire"),
  enterior: yup.boolean().required("Obligatoire"),
  exterior: yup.boolean().required("Obligatoire"),
  isAlmostFull: yup.boolean().required("Obligatoire"),
  confirmationRadius: yup
    .number()
    .min(10, "Valeur min 10 et maximum 1000")
    .max(1000, "Valeur min 10 et maximum 1000")
    .transform((value) => (isNaN(value) ? undefined : value)),
});
const EventDetails: FC<EventDetailsProps> = ({ id }): ReactElement => {
  const [banner, setBanner] = useState<bannerModel>({} as bannerModel);

  const uploadFile = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files![0];
    const fileInBase64 = await UseConvertToBase64(file);
    setPhoto(fileInBase64);
    setBanner({ base64: fileInBase64, fileName: e.target.files![0].name });
  };

  const { data: { data } = {}, isLoading } = useEventQuery(+id);

  const { mutate: updateEvent, status: updateStatus } = usePutEventQuery(+id);
  const { data: { data: djsList } = {} } = useDjsNameQuery();

  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitSuccessful, errors },
  } = useForm<EventUpdateModel>({
    resolver: yupResolver(schema),
  });
  const [photo, setPhoto] = useState<string | unknown>(data?.banner);
  const [keyWordsAdded, setKeyWordsAdded] = useState<Array<string>>([]);
  const [modalShow, setModalShow] = useState(false);
  const [key, setKey] = useState<string>("");
  const [djId, setDjId] = useState<number | undefined>(data?.djId);
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setKey(e.target.value);
  };

  const handleAdd = () => {
    key !== "" &&
      !keyWordsAdded.includes(key) &&
      setKeyWordsAdded((prev) => [...prev, key]);
    key !== "" && setKey("");
  };
  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleAdd();
      e.preventDefault();
    }
  };
  const deleteKeyWord = (keyword: string) => {
    setKeyWordsAdded((prev) => prev.filter((word) => word !== keyword));
  };
  const handleUpdateEvent = (eventDetails: EventUpdateModel) => {
    updateEvent({
      ...eventDetails,
      id: +id,
      ...(!!banner.base64 && { banner }),
      keyWords: keyWordsAdded,
      ...(!!djId && { dj: djId }),
      ...(!djId && { dj: data?.djId }),
      date: dayjs(eventDetails.date).format("YYYY-MM-DDTHH:mm:ssZ"),
    });
  };
  useEffect(() => {}, [data]);
  useEffect(() => {
    setPhoto(data?.banner);
  }, [data?.banner]);
  useEffect(() => {
    if (!(data?.keyWords.includes("") && data?.keyWords.length === 1)) {
      data?.keyWords !== undefined && setKeyWordsAdded(data?.keyWords);
    }
  }, [data?.keyWords]);

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <form className="details" onSubmit={handleSubmit(handleUpdateEvent)}>
          <div
            className="details-photo detailsSection"
            style={{ backgroundImage: `url(${photo})` }}
          >
            <label className="detailImgInput">
              <input
                type="file"
                onChange={(e) => uploadFile(e)}
                accept="image/png, image/jpg, image/jpeg"
              />
              <div className="imageInput-style">
                <img src={uploadCoverImg} alt="" />
              </div>
            </label>
          </div>
          <div className="details-info detailsSection">
            <h3>Informations</h3>
            <div className="detailsInputs">
              <div className="detailsInput">
                <label htmlFor="name">Nom du club</label>
                <select id="name" name="cars">
                  <option>{data?.businessName}</option>
                </select>
              </div>
              <div className="detailsInput">
                <label htmlFor="title">Titre de l'événement</label>
                <input
                  type="text"
                  id="title"
                  placeholder="Titre de l'événement (max 50 lettres)..."
                  defaultValue={data?.title}
                  minLength={1}
                  maxLength={50}
                  {...register("title")}
                />
                {errors.title && (
                  <p className="tw-text-red-600 mb-0">{errors.title.message}</p>
                )}
              </div>
              <div className="detailsInput">
                <label htmlFor="djs">Dj / Artistes (Facultatif)</label>
                <Select
                  options={djsList?.map(({ id, name }) => ({
                    value: id,
                    label: name,
                  }))}
                  onChange={(e) => setDjId(e?.value!)}
                  placeholder={`${
                    data?.djName === null ? "Sélectionner" : data?.djName
                  }`}
                  isClearable={true}
                />
                {errors.dj && (
                  <p className="tw-text-red-600 mb-0">{errors.dj.message}</p>
                )}
              </div>
              <div className="detailsInput">
                <label htmlFor="date">Date et Heure de début</label>
                <TextField
                  fullWidth
                  variant="standard"
                  type="datetime-local"
                  defaultValue={data?.date}
                  InputProps={{ disableUnderline: true }}
                  {...register("date")}
                />
                {errors.date && (
                  <p className="tw-text-red-600 mb-0">{errors.date.message}</p>
                )}
              </div>
              <div className="detailsInput">
                <label htmlFor="phone"> Téléphone d’un responsable</label>
                <input
                  type="text"
                  id="phone"
                  placeholder="+123456789"
                  defaultValue={data?.phone}
                  {...register("phone")}
                />
                {errors.phone && (
                  <p className="tw-text-red-600 mb-0">{errors.phone.message}</p>
                )}
              </div>
              <div className="detailsInput">
                <label htmlFor="maxParticipants">Nombre de places</label>
                <input
                  type="text"
                  id="maxParticipants"
                  defaultValue={data?.maxParticipants}
                  {...register("maxParticipants")}
                />
                {errors.maxParticipants && (
                  <p className="tw-text-red-600 mb-0">
                    {errors.maxParticipants.message}
                  </p>
                )}
              </div>
              <div className="detailsInput">
                <label htmlFor="confirmationRadius">
                  Rayon(Facultatif) *mètre
                </label>
                <input
                  type="number"
                  id="confirmationRadius"
                  defaultValue={data?.confirmationRadius}
                  placeholder="Rayon de participation à un événement"
                  {...register("confirmationRadius")}
                />
                {errors.confirmationRadius && (
                  <p className="tw-text-red-600 mb-0">
                    {errors.confirmationRadius.message}
                  </p>
                )}
              </div>
              <div className="detailsInput">
                <label htmlFor="keywords">Mots-clés (Facultatif)</label>
                <input
                  type="text"
                  id="keywords"
                  value={key}
                  placeholder="Ajouter des mots clés"
                  onChange={(e) => handleChange(e)}
                  onKeyDown={(e) => handleKeyDown(e)}
                />
              </div>
              <div className="detailsInput keywords">
                <label htmlFor="">Mots clés ajoutés</label>
                <div className="keywordsResults">
                  {keyWordsAdded.map((keyword: string, index) => {
                    return (
                      <button
                        type="button"
                        onClick={() => deleteKeyWord(keyword)}
                        key={index}
                      >
                        {keyword}
                      </button>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="details-infoForClient detailsSection">
            <h3>Autres informations importantes pour les clients</h3>
            <div className="detailsInputs">
              <div className="detailsInput">
                <label>
                  Sélectionnez les caractéristiques de l'évènement :{" "}
                </label>
                <div className="checkboxInput">
                  <input
                    type="checkbox"
                    id="interior"
                    value="true"
                    defaultChecked={!!data?.enterior}
                    {...register("enterior")}
                  />
                  <label htmlFor="interior">En intérieur</label>
                </div>
                <div className="checkboxInput">
                  <input
                    type="checkbox"
                    id="exterior"
                    value="true"
                    defaultChecked={!!data?.exterior}
                    {...register("exterior")}
                  />
                  <label htmlFor="exterior">En extérieur</label>
                </div>
                {errors.enterior && (
                  <p className="tw-text-red-600 mb-0">
                    {errors.enterior.message}
                  </p>
                )}
                {errors.exterior && (
                  <p className="tw-text-red-600 mb-0">
                    {errors.exterior.message}
                  </p>
                )}
              </div>
              <div className="detailsInput">
                <div className="checkboxInput">
                  <input
                    type="checkbox"
                    id="isFreeEntryForGirls"
                    value="true"
                    defaultChecked={!!data?.isFreeForGirls}
                    {...register("isFreeEntryForGirls")}
                  />
                  <label htmlFor="isFreeEntryForGirls">
                    Entrée gratuite pour les filles (inscrire les conditions en
                    description si nécessaire)
                  </label>
                </div>
                <div className="checkboxInput">
                  <input
                    type="checkbox"
                    id="isFreeEntryForBoys"
                    value="true"
                    defaultChecked={!!data?.isFreeForBoys}
                    {...register("isFreeEntryForBoys")}
                  />
                  <label htmlFor="isFreeEntryForBoys">
                    Entrée gratuite pour les garçons (inscrire les conditions en
                    description si nécessaire)
                  </label>
                </div>
                {errors.isFreeEntryForGirls && (
                  <p className="tw-text-red-600 mb-0">
                    {errors.isFreeEntryForGirls.message}
                  </p>
                )}
                {errors.isFreeEntryForBoys && (
                  <p className="tw-text-red-600 mb-0">
                    {errors.isFreeEntryForBoys.message}
                  </p>
                )}
              </div>
              <div className="detailsInput">
                <div className="checkboxInput">
                  <input
                    type="checkbox"
                    id="hasShowcase"
                    value="true"
                    defaultChecked={!!data?.hasShowcase}
                    {...register("hasShowcase")}
                  />
                  <label htmlFor="hasShowcase">Showcase</label>
                </div>
                <div className="checkboxInput">
                  <input
                    type="checkbox"
                    id="isAlmostFull"
                    value="true"
                    defaultChecked={!!data?.isAlmostFull}
                    {...register("isAlmostFull")}
                  />
                  <label htmlFor="isAlmostFull">
                    Évènement bientôt complet
                  </label>
                </div>

                {errors.hasShowcase && (
                  <p className="tw-text-red-600 mb-0">
                    {errors.hasShowcase.message}
                  </p>
                )}
                {errors.isAlmostFull && (
                  <p className="tw-text-red-600 mb-0">
                    {errors.isAlmostFull.message}
                  </p>
                )}
              </div>
              {/* <div className="detailsInput">
                <label>Age minimum et maximum des clients:</label>
                <div className="ageInput">
                  <label htmlFor="minAge">Min</label>
                  <input
                    id="minAge"
                    type="number"
                    placeholder="âge"
                    defaultValue={data?.minAge}
                    {...register("minAge")}
                  />
                  <label htmlFor="maxAge">Max</label>
                  <input
                    id="maxAge"
                    type="number"
                    placeholder="âge"
                    defaultValue={data?.maxAge}
                    {...register("maxAge")}
                  />
                </div>
                {errors.maxAge && (
                  <p className="tw-text-red-600 mb-0">
                    {errors.maxAge.message}
                  </p>
                )}
                {errors.minAge && (
                  <p className="tw-text-red-600 mb-0">
                    {errors.minAge.message}
                  </p>
                )}
              </div> */}
            </div>
            <div className="detailsInput">
              <label htmlFor="description">Description</label>
              <textarea
                rows={7}
                cols={10}
                id="description"
                maxLength={1000}
                placeholder="Entrez une description du èvènement (max 1000 lettres)..."
                defaultValue={data?.description}
                {...register("description")}
              />
              {errors.description && (
                <p className="tw-text-red-600 mb-0">
                  {errors.description.message}
                </p>
              )}
            </div>
          </div>
          <div className="details-save">
            <button
              className="delete"
              type="button"
              onClick={() => setModalShow(true)}
            >
              <img src={deleteIcon} alt="" />
              <p>Supprimer l'événement</p>
            </button>
            <div>
              <button type="button" onClick={() => reset()}>
                Jeter
              </button>
              <button type="submit">Sauvegarder</button>
            </div>
          </div>
          {isSubmitSuccessful && updateStatus !== "error" && (
            <div className="successMessage detailsSection">
              <img src={successIcon} alt="" />
              <p>Vos modifications ont été mises à jour !</p>
            </div>
          )}
        </form>
      )}
      <DeleteEventClub
        show={modalShow}
        onHide={() => setModalShow(false)}
        deleteEvent
        id={id}
      />
    </>
  );
};
export default EventDetails;

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./user/pages/Home/Home";
import Evenings from "./user/pages/Evenings/Evenings";
import Evening from "./user/pages/Evening/Evening";
import Nightclubs from "./user/pages/Nightclubs/Nightclubs";
import Nightclub from "./user/pages/Nightclub/Nightclub";
import DJs from "./user/pages/DJs/DJs";
import DJ from "./user/pages/DJ/DJ";
import Contact from "./user/pages/Contact/Contact";
import Page404 from "./user/pages/Page404/Page404";
import MainSharedLayout from "./user/MainSharedLayout";
import EveningsSharedLayout from "./user/pages/Evenings/EveningsSharedLayout";
import DJsSharedLayout from "./user/pages/DJs/DJsSharedLayout";
import NightclubsSharedLayout from "./user/pages/Nightclubs/NightclubsSharedLayout";
import ProfileSharedLayout from "./user/pages/ProfilePages/ProfileSharedLayout";
import ProfilePersonalData from "./user/pages/ProfilePages/ProfilePersonalData/ProfilePersonalData";
import ProfileReservations from "./user/pages/ProfilePages/ProfileReservations/ProfileReservations";
import ProfileFavorites from "./user/pages/ProfilePages/ProfileFavorites/ProfileFavorites";
import ProfileLoyalityHistory from "./user/pages/ProfilePages/ProfileLoyalityHistory/ProfileLoyalityHistory";
import ProfileParticipationHistory from "./user/pages/ProfilePages/ProfileWalletHistory/ProfileWalletHistory";
import ProfileRecontre from "./user/pages/ProfilePages/ProfileRecontre/ProfileRecontre";
import ClubSpaceHome from "./user/pages/ClubSpaceHome/ClubSpaceHome";
import ClubSpaceWhy7Night from "./user/pages/ClubSpaceWhy7Night/ClubSpaceWhy7Night";
import ClubSpaceOffersAndRates from "./user/pages/ClubSpaceOffersAndRates/ClubSpaceOffersAndRates";
import ManagerSharedLayout from "./dashboard/Layouts/Manager/ManagerSharedLayout";
import ManagerDashboard from "./dashboard/pages/Dashboard/ManagerDashboard";
import ManagerSubscription from "./dashboard/pages/ManagerSubscription/ManagerSubscription";
import AdminSharedLayout from "./dashboard/Layouts/Admin/AdminSharedLayout";
import AdminDashboard from "./dashboard/pages/Dashboard/AdminDashboard";
import AdminUsers from "./dashboard/pages/AdminUsers/AdminUsers";
import AdminConfiguration from "./dashboard/pages/AdminConfiguration/AdminConfiguration";
import ProfileNotifications from "./user/pages/ProfilePages/ProfileNotifications/ProfileNotifications";
import TermsOfServices from "./user/pages/TermsOfServices/TermsOfServices";
import PrivacyAndPolicy from "./user/pages/PrivacyAndPolicy/PrivacyAndPolicy";
import Club from "./dashboard/pages/Club/Club";
import ClubsSharedLayout from "./dashboard/pages/Clubs/ClubsSharedLayout";
import EventsSharedLayout from "./dashboard/pages/Events/EventsSharedLayout";
import Events from "./dashboard/pages/Events/Events";
import Clubs from "./dashboard/pages/Clubs/Clubs";
import Eventi from "./dashboard/pages/Eventi/Eventi";
import { AuthContextProvider } from "./context/AuthContext/AuthContextProvider";
import AddClub from "./dashboard/pages/Club/AddClub";
import EventParticipants from "./dashboard/pages/EventParticipants/EventParticipants";
import AddEvent from "./dashboard/pages/Eventi/AddEvent";

const App = () => (
  <Router>
    <AuthContextProvider>
      <Routes>
        <Route path="/" element={<MainSharedLayout />}>
          <Route index element={<Home />} />

          <Route path="evenings" element={<EveningsSharedLayout />}>
            <Route index element={<Evenings />} />
            <Route path=":eveningId" element={<Evening />} />
          </Route>

          <Route path="nightclubs" element={<NightclubsSharedLayout />}>
            <Route index element={<Nightclubs />} />
            <Route path=":nightclubId" element={<Nightclub />} />
          </Route>

          <Route path="djs" element={<DJsSharedLayout />}>
            <Route index element={<DJs />} />
            <Route path=":djId" element={<DJ />} />
          </Route>

          <Route path="clubspace" element={<ClubSpaceHome />} />
          <Route path="why-7night" element={<ClubSpaceWhy7Night />} />
          <Route
            path="offers-and-rates"
            element={<ClubSpaceOffersAndRates />}
          />

          <Route path="contact" element={<Contact />} />

          <Route path="profile" element={<ProfileSharedLayout />}>
            <Route index element={<ProfilePersonalData />} />
            <Route path="reservations" element={<ProfileReservations />} />
            <Route path="favorites" element={<ProfileFavorites />} />
            <Route path="notifications" element={<ProfileNotifications />} />
            <Route
              path="loyalty-history"
              element={<ProfileLoyalityHistory />}
            />
            <Route
              path="participation-history"
              element={<ProfileParticipationHistory />}
            />
            <Route path="recontre" element={<ProfileRecontre />} />
          </Route>

          <Route path="*" element={<Page404 />} />

          <Route path="terms-of-services" element={<TermsOfServices />} />
          <Route path="privacy-and-policy" element={<PrivacyAndPolicy />} />
        </Route>

        {/* Manager Routes */}
        <Route path="/manager" element={<ManagerSharedLayout />}>
          <Route index element={<ManagerDashboard />} />
          <Route path="clients" element={<EventParticipants />} />
          <Route path="events" element={<EventsSharedLayout />}>
            <Route index element={<Events />} />
            <Route path=":eventId" element={<Eventi />} />
            <Route path="add" element={<AddEvent />} />
          </Route>
          <Route path="clubs" element={<ClubsSharedLayout />}>
            <Route index element={<Clubs />} />
            <Route path=":clubId" element={<Club />} />
            <Route path="add" element={<AddClub />} />
          </Route>
          <Route path="subscription" element={<ManagerSubscription />} />
          <Route path="*" element={<Page404 />} />
        </Route>

        {/* Admin Routes */}
        <Route path="/admin" element={<AdminSharedLayout />}>
          <Route index element={<AdminDashboard />} />
          <Route path="users" element={<AdminUsers />} />
          <Route path="clubs" element={<ClubsSharedLayout />}>
            <Route index element={<Clubs />} />
            <Route path=":clubId" element={<Club />} />
            <Route path="add" element={<AddClub />} />
          </Route>
          <Route path="events" element={<EventsSharedLayout />}>
            <Route index element={<Events />} />
            <Route path=":eventId" element={<Eventi />} />
            <Route path="add" element={<AddEvent />} />
          </Route>
          <Route path="attendees" element={<EventParticipants />} />
          <Route path="configuration" element={<AdminConfiguration />} />
          <Route path="*" element={<Page404 />} />
        </Route>
      </Routes>
    </AuthContextProvider>
  </Router>
);

export default App;

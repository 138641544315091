import { ReactElement } from "react";
import { useFetchAllPackagesQuery } from "../../../../react-query/hooks/useConfiguration.query";
import PackConfigurationInfo from "../../Molecules/PackConfigurationInfo/PackConfigurationInfo";

const ConfigurationsPacks = (): ReactElement => {
  // react-query
  const { data: packages = [] } = useFetchAllPackagesQuery();

  return (
    <div>
      <div className="dashboardResults noPagination">
        <table>
          <tr>
            <th>NOM</th>
            <th>NOMBRE DE LIKES</th>
            <th>NOMBRE DE WINKS</th>
            <th>NOMBRE DE DRINKS</th>
            <th>PRIX</th>
            <th className="lastElement actionColumn">ACTIONS</th>
          </tr>

          {packages.map((configPackage) => (
            <PackConfigurationInfo
              key={configPackage.id}
              configPackage={configPackage}
            />
          ))}
        </table>
      </div>
    </div>
  );
};
export default ConfigurationsPacks;

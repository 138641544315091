import {
  ChangeEvent,
  FC,
  KeyboardEvent,
  ReactElement,
  useEffect,
  useState,
} from "react";
import GoogleMapComponent from "../../../../components/GoogleMapComponent/GoogleMapComponent";
import uploadCoverImg from "../../../../assets/icons/ImgEdit.png";
import successIcon from "../../../../assets/icons/successIcon.png";
import "./_Details.scss";
import {
  useClubQuery,
  useClubTypesQuery,
  usePutClubQuery,
} from "../../../../react-query/hooks/Clubs";
import LoadingSpinner from "../../../../components/LoadingSpinner/LoadingSpinner";
import { ClubPostModel } from "../../../../interfaces/models/postModels/ClubPost.model";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { UseConvertToBase64 } from "../../../../hooks/UseConvertToBase64";
import { useGoogleAutoFill } from "../../../../components/GoogleAutoFill/GoogleAutoFill";
import deleteIcon from "../../../../assets/icons/deleteIcon.png";
import DeleteEventClub from "../../../modals/DeleteEventClub";
import { useFetchAllMusicTypesQuery } from "../../../../react-query/hooks/useConfiguration.query";
import Select from "react-select";

interface ClubDetailsProps {
  id: string;
}
interface ClubUpdateModel extends ClubPostModel {
  id: number;
}

const schema = yup.object().shape({
  name: yup.string().required("Obligatoire"),
  description: yup.string().required("Obligatoire"),
  restaurantMaxPlaceNumber: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value)),
  minAge: yup.number().transform((value) => (isNaN(value) ? undefined : value)),
  maxAge: yup.number().transform((value) => (isNaN(value) ? undefined : value)),
  isRestaurant: yup
    .boolean()
    .typeError("Vous devez choisir une option")
    .required("Obligatoire"),
  patio: yup
    .boolean()
    .typeError("Vous devez choisir une option")
    .required("Obligatoire"),
  phone: yup.string().required("Obligatoire"),
});

const ClubsDetails: FC<ClubDetailsProps> = ({ id }): ReactElement => {
  const uploadFile = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files![0];
    const fileInBase64 = await UseConvertToBase64(file);
    setPhoto(fileInBase64);
    setValue("banner", {
      base64: fileInBase64,
      fileName: e.target.files![0].name,
    });
  };
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { isSubmitSuccessful, errors },
  } = useForm<ClubUpdateModel>({
    resolver: yupResolver(schema),
  });

  const { data: { data } = {}, isLoading } = useClubQuery(+id);

  const { address, coordinates, render, cityContry } = useGoogleAutoFill(
    data?.address
  );

  const { mutate: updateClub } = usePutClubQuery(+id);
  const { data: clubMusicTypes = [] } = useFetchAllMusicTypesQuery();
  const { data: { data: clubsTypes } = {} } = useClubTypesQuery();
  const [modalShow, setModalShow] = useState(false);
  const [photo, setPhoto] = useState<string | unknown>(data?.banner);
  const [selectedMusicTypes, setSelectedMusicTypes] = useState<any>();
  const [selectedClubsTypes, setSelectedClubsTypes] = useState<any>();
  const musicOptions = clubMusicTypes?.map((type) => ({
    value: type.id.toString(),
    label: type.title,
  }));
  const clubTypesOptions = clubsTypes?.map((type) => ({
    value: type.id.toString(),
    label: type.name,
  }));
  const musicDefaultOptions = musicOptions?.map((df) => ({
    value: Number(df.value),
    label: df.label,
  }));
  const clubTypesDefaultOptions = clubTypesOptions?.map((df) => ({
    value: Number(df.value),
    label: df.label,
  }));
  let musicArray = selectedMusicTypes?.map((type: any) => Number(type.value));
  let clubTypeArray = selectedClubsTypes?.map((type: any) =>
    Number(type.value)
  );

  let defaultMusic = data?.musicTypes?.filter((type) => {
    return musicDefaultOptions?.map((df) => df.value === type.id);
  });
  let defaultClubTypes = data?.businessType?.filter((type) => {
    return clubTypesDefaultOptions?.map((df) => df.value === type.id);
  });
  const defaultMusicOptions2 = defaultMusic?.map((df) => ({
    value: df.id.toString(),
    label: df.title,
  }));
  const defaultClubsTypesOptions2 = defaultClubTypes?.map((df) => ({
    value: df.id.toString(),
    label: df.name,
  }));

  const handleSelectedMusic = (data: any) => {
    setSelectedMusicTypes(data);
  };
  const handleSelectedClubType = (data: any) => {
    setSelectedClubsTypes(data);
  };

  const handleUpdateClub = (clubDetails: ClubUpdateModel) => {
    updateClub({
      ...clubDetails,
      id: +id,
      ...(address && { address: address }),
      ...(!address && { address: data?.address }),
      ...(address && { city: cityContry?.city! }),
      ...(!address && { city: data?.city }),
      ...(address && { country: cityContry?.country! }),
      ...(!address && { country: data?.country }),
      latitude: coordinates.lat,
      longitude: coordinates.lng,
      musicTypes: musicArray,
      businessTypes: clubTypeArray,
    });
  };

  const lngLatArray = [
    {
      lat:
        (data?.latitude === undefined ? 0 : data?.latitude) || coordinates.lat,
      lng:
        (data?.longitude === undefined ? 0 : data?.longitude) ||
        coordinates.lng,
    },
  ];

  useEffect(() => {
    setPhoto(data?.banner);
  }, [data?.banner]);

  useEffect(() => {
    setSelectedMusicTypes(defaultMusicOptions2);
    setSelectedClubsTypes(defaultClubsTypesOptions2);
  }, []);

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <form className="details" onSubmit={handleSubmit(handleUpdateClub)}>
          <div
            className="details-photo detailsSection"
            style={{ backgroundImage: `url(${photo})` }}
          >
            <label className="detailImgInput">
              <input
                type="file"
                onChange={(e) => uploadFile(e)}
                accept="image/png, image/jpg, image/jpeg"
              />
              <div className="imageInput-style">
                <img src={uploadCoverImg} alt="" />
              </div>
            </label>
          </div>
          <div className="details-info detailsSection">
            <h3>Informations</h3>
            <div className="detailsInputs">
              <div className="detailsInput">
                <label htmlFor="clubName">Nom du club</label>
                <input
                  type="text"
                  id="clubName"
                  placeholder="Entrez le nom du club"
                  defaultValue={data?.name}
                  {...register("name")}
                />
                {errors.name && (
                  <p className="tw-text-red-600 mb-0">{errors.name.message}</p>
                )}
              </div>
              <div className="detailsInput">
                <label htmlFor="resNumberPlaces">
                  Nombre de places au restaurant (Facultatif)
                </label>
                <input
                  type="text"
                  id="resNumberPlaces"
                  defaultValue={data?.restaurantMaxPlaceNumber}
                  {...register("restaurantMaxPlaceNumber")}
                />
                {errors.restaurantMaxPlaceNumber && (
                  <p className="tw-text-red-600 mb-0">
                    {errors.restaurantMaxPlaceNumber.message}
                  </p>
                )}
              </div>
              <div className="detailsInput">
                <label htmlFor="Phone">Téléphone d’un responsable</label>
                <input
                  type="text"
                  id="Phone"
                  defaultValue={data?.phoneNumber}
                  {...register("phone")}
                />
                {errors.phone && (
                  <p className="tw-text-red-600 mb-0">{errors.phone.message}</p>
                )}
              </div>
            </div>
            <div className="detailsInput">
              <label htmlFor="description">Description du club</label>
              <textarea
                rows={7}
                cols={10}
                id="description"
                maxLength={1000}
                placeholder="Entrez une description du club (max. 1000 caractères)"
                defaultValue={data?.description}
                {...register("description")}
              />
              {errors.description && (
                <p className="tw-text-red-600 mb-0">
                  {errors.description.message}
                </p>
              )}
            </div>
          </div>
          <div className="details-infoForClient detailsSection">
            <h3>Autres informations importantes pour les clients</h3>
            <div className="detailsInputs">
              <div className="detailsInput">
                <label htmlFor="hasRestaurant">
                  Le club possède un restaurant ?
                </label>
                <div className="radioInput">
                  <input
                    type="radio"
                    id="yesRestaurant"
                    value="true"
                    defaultChecked={data?.hasRestaurant}
                    {...register("isRestaurant")}
                  />
                  <label htmlFor="yesRestaurant">Oui</label>
                </div>
                <div className="radioInput">
                  <input
                    type="radio"
                    id="noRestaurant"
                    value="false"
                    defaultChecked={data?.hasRestaurant === false}
                    {...register("isRestaurant")}
                  />
                  <label htmlFor="noRestaurant">Non</label>
                </div>
                {errors.isRestaurant && (
                  <p className="tw-text-red-600 mb-0">
                    {errors.isRestaurant.message}
                  </p>
                )}
              </div>
              <div className="detailsInput">
                <label>Le club possède un patio?</label>
                <div className="radioInput">
                  <input
                    type="radio"
                    id="yesPatio"
                    value="true"
                    defaultChecked={data?.patio === true}
                    {...register("patio")}
                  />
                  <label htmlFor="yesPatio">Oui</label>
                </div>
                <div className="radioInput">
                  <input
                    type="radio"
                    id="noPatio"
                    value="false"
                    defaultChecked={data?.patio === false}
                    {...register("patio")}
                  />
                  <label htmlFor="noPatio">Non</label>
                </div>
                {errors.patio && (
                  <p className="tw-text-red-600 mb-0">{errors.patio.message}</p>
                )}
              </div>
              <div className="detailsInput">
                <label>Quel est l’âge moyen de votre clientèle ?</label>
                <div className="ageInput">
                  <label htmlFor="minAge">Entre</label>
                  <input
                    id="minAge"
                    type="number"
                    placeholder="âge"
                    defaultValue={data?.minAge}
                    {...register("minAge")}
                  />
                  <label htmlFor="maxAge">Et</label>
                  <input
                    id="maxAge"
                    type="number"
                    placeholder="âge"
                    defaultValue={data?.maxAge}
                    {...register("maxAge")}
                  />
                </div>
                {errors.minAge && (
                  <p className="tw-text-red-600 mb-0">
                    {errors.minAge.message}
                  </p>
                )}
                {errors.maxAge && (
                  <p className="tw-text-red-600 mb-0">
                    {errors.maxAge.message}
                  </p>
                )}
              </div>
              <div className="detailsInput" style={{ zIndex: "4" }}>
                <label>Style(s) de musique</label>
                <Select
                  defaultValue={defaultMusicOptions2}
                  value={selectedMusicTypes}
                  isMulti
                  options={musicOptions}
                  onChange={handleSelectedMusic}
                  placeholder="Sélectionner"
                />
              </div>
              <div className="detailsInput" style={{ zIndex: "4" }}>
                <label>Type(s) de club</label>
                <Select
                  defaultValue={defaultClubsTypesOptions2}
                  value={selectedClubsTypes}
                  isMulti
                  options={clubTypesOptions}
                  onChange={handleSelectedClubType}
                  placeholder="Sélectionner"
                />
              </div>
            </div>
          </div>
          <div className="details-address detailsSection">
            <h3>Adresse du club</h3>
            <div className="detailsInputs">
              <div className="addressInput">
                <label htmlFor="address">Adresse</label>
                {render}
                {address.length === 0 && errors.address && (
                  <p className="tw-text-red-600 mb-0">
                    {errors.address.message}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="details-map detailsSection">
            <GoogleMapComponent marksObject={lngLatArray} />
          </div>
          <div className="details-save ">
            <button
              className="delete"
              type="button"
              onClick={() => setModalShow(true)}
            >
              <img src={deleteIcon} alt="" />
              <p>Supprimer club</p>
            </button>
            <div>
              <button type="button" onClick={() => reset()}>
                Jeter
              </button>
              <button type="submit">Sauvegarder</button>
            </div>
          </div>
          {isSubmitSuccessful && (
            <div className="successMessage detailsSection">
              <img src={successIcon} alt="" />
              <p>Vos modifications ont été mises à jour !</p>
            </div>
          )}
        </form>
      )}
      <DeleteEventClub
        show={modalShow}
        onHide={() => setModalShow(false)}
        deleteClub
        id={id}
      />
    </>
  );
};
export default ClubsDetails;

import { useCallback, useEffect, useRef, useState } from "react";
import {
  BusinessBasicInfo,
  getBusiness,
  GetBusinessInput,
} from "../../../../api/Api";
import LoadingSpinner from "../../../../components/LoadingSpinner/LoadingSpinner";
import { useAuthContext } from "../../../../context/AuthContext/AuthContext";
import ClubCard from "../../../components/shared/Cards/ClubCard/ClubCard";
import ClubsEveningsNoResults from "../../../components/shared/ClubsEveningsNoResults/ClubsEveningsNoResults";
import "./_ClubsSection.scss";

interface ClubsSectionProps {
  filterInputs: GetBusinessInput;
}

function useClubsSection({ filterInputs }: ClubsSectionProps) {
  const [businessData, setBusinessData] = useState<BusinessBasicInfo[]>([]);
  const [zeroItem, setZeroItem] = useState<number>();
  const [loading, setLoading] = useState<boolean>(true);
  const [hasMore, setHasMore] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const authCtx = useAuthContext();
  const observer = useRef<IntersectionObserver | null>(null);
  const lastItemElementRef = useCallback(
    (node: any) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const getBusinessData = async () => {
    try {
      const businessResponse = await getBusiness(
        {
          pageNumber: pageNumber,
          ...filterInputs,
        },
        authCtx?.location
      );
      setBusinessData([...businessData, ...businessResponse?.data?.data]);
      setZeroItem(businessResponse?.data?.metaData?.totalItemCount);
      setHasMore(businessResponse?.data?.metaData?.hasNextPage);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const lngLatArray: any = [];
  businessData?.forEach((item, index) => {
    lngLatArray[index] = { lat: item.latitude, lng: item.longitude };
  });

  useEffect(() => {
    getBusinessData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterInputs, pageNumber]);

  useEffect(() => {
    setBusinessData((prev) => prev.splice(0, prev.length));
    setPageNumber(1);
  }, [filterInputs]);

  return {
    zeroItem,
    lngLatArray,
    renderItems: (
      <>
        {zeroItem === 0 ? (
          <ClubsEveningsNoResults />
        ) : (
          <>
            <div className="clubssection">
              {businessData?.map((business) => (
                <div
                  className="clubssection-item"
                  key={business.id}
                  ref={lastItemElementRef}
                >
                  <ClubCard
                    id={business?.id}
                    photo={business?.banner}
                    name={business?.name}
                    averageRating={business?.averageRating}
                    address={business?.address}
                    city={business?.city}
                    distance={business?.distance}
                    isOpened={business?.isOpened}
                  />
                </div>
              ))}
            </div>
            {hasMore && <LoadingSpinner />}
          </>
        )}
      </>
    ),
  };
}

export default useClubsSection;

import closeButton from "../../../assets/icons/closeButton.svg";
import "./_Reservation.scss";
import "../_modalStyle.scss";
import { Modal } from "react-bootstrap";
import downloadAppPhoto from "../../../assets/photos/downloadAppPhoto.webp";

const Reservation = (props: any) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="reservationmodal">
          <div className="reservationmodal-content">
            <div className="reservationmodal-content-closebutton">
              <img src={closeButton} alt="" onClick={() => props.onHide()} />
            </div>
            <div className="reservationmodal-content-text">
              <h2>Demande de réservation</h2>
              <p>Réservations</p>
              <img src={downloadAppPhoto} alt="" />
              <p>
                La demande de réservation n'est possible que pour les
                utilisateurs de l'application mobile. Vous pouvez télécharger
                l'application sur les stores
              </p>
              <button onClick={() => props.onHide()}>D'accord</button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Reservation;

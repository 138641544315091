import axios from "../../initializations/axios";
import { ClubPhotoPostModel } from "../../interfaces/models/postModels/ClubPhotoPost.model";
import { ServicePostModel } from "../../interfaces/models/postModels/ServicePost.model";
import { ClubTablePostModel } from "../../interfaces/models/postModels/ClubTablePost.model";
import { TableUpdateModel } from "../../interfaces/models/updateModels/TableUpdate.model";
import { ServiceUpdateModel } from "../../interfaces/models/updateModels/ServiceUpdate.model";
import { SchedulePostModel } from "../../interfaces/models/postModels/SchedulePost.model";
import { ScheduleUpdateModel } from "../../interfaces/models/updateModels/ScheduleUpdate.model";
import { JurisdictionUpdateModel } from "../../interfaces/models/updateModels/JurisdictionUpdate.model";
import { JurisdictionPostModel } from "../../interfaces/models/postModels/JurisdictionPost.model";
import { ClubPostModel } from "../../interfaces/models/postModels/ClubPost.model";

interface ClubUpdateModel extends ClubPostModel {
  id: number;
}
interface searchInput {
  searchWord: string;
  pageNumber?: number | undefined;
  pageSize: number;
}

// -----DELETE-----
export const deleteClub = async (id: number) => {
  const data = await axios.delete(`Business/${id}`);
  return data;
};
export const deleteClubPhoto = async (id: number) =>
  await axios.delete(`Business/gallery/${id}`);
export const deleteClubSchedule = async (id: number) =>
  await axios.delete(`Business/businessSchedule/${id}`);
export const deleteClubJurisdiction = async (id: number) =>
  await axios.delete(`BusinessJurisdictions/${id}`);
export const deleteClubReview = async (id: number) =>
  await axios.delete(`BusinessRatings/${id}`);
export const deleteClubService = async (id: number) =>
  await axios.delete(`BusinessService/${id}`);
export const deleteClubTable = async (id: number) =>
  await axios.delete(`Table/${id}`);

// -----POST------
export const searchClubs = async (searchInput: searchInput) => {
  const { data } = await axios.post("Business/searchDashboard", searchInput);
  return data;
};
export const addClub = async (clubData: ClubPostModel) => {
  const enisData = await axios.post("Business", clubData);
  return enisData;
};

export const addClubPhoto = async (data: ClubPhotoPostModel) => {
  await axios.post("Business/gallery", data);
};
export const addClubSchedule = async (data: SchedulePostModel) => {
  await axios.post("Business/businessSchedule", data);
};
export const addClubJurisdiction = async (data: JurisdictionPostModel) => {
  await axios.post("BusinessJurisdictions", data);
};
export const addClubService = async (data: ServicePostModel) => {
  await axios.post("BusinessService", data);
};
export const addClubTable = async (data: ClubTablePostModel) =>
  await axios.post("Table", data);

// -----PUT-----
export const updateClub = async (data: ClubUpdateModel) => {
  await axios.put("Business", data);
};
export const updateClubSchedule = async (data: ScheduleUpdateModel) => {
  await axios.put("Business/businessSchedule", data);
};
export const updateClubJurisdiction = async (data: JurisdictionUpdateModel) => {
  await axios.put("BusinessJurisdiction", data);
};
export const updateClubService = async (data: ServiceUpdateModel) => {
  await axios.put("BusinessService", data);
};
export const updateClubTable = async (data: TableUpdateModel) =>
  await axios.put("Table", data);

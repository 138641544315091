import { ReactElement, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { useFetchAllDjsQuery } from "../../../../react-query/hooks/useConfiguration.query";
import DjForm from "../../../modals/DjForm";
import DjConfigurationInfo from "../../Molecules/DjConfigurationInfo/DjConfigurationInfo";
import MuiPagination from "../../../../components/MuiPagination/MuiPagination";

const ConfigurationsDjs = (): ReactElement => {
  // local state
  const [modalShow, setModalShow] = useState(false);
  const [page, setPage] = useState(1);
  /**
   * hooks
   */
  const navigate = useNavigate();
  const { search } = useLocation();

  /**
   * query string
   */
  const queryParams = queryString.parse(search);

  // react-query
  const { data: { data: djs = [], metaData } = {} } = useFetchAllDjsQuery(
    Number(queryParams.pageNumber)
  );

  // effects
  useEffect(() => {
    navigate({
      search: queryString.stringify({
        ...queryParams,
        pageNumber: page,
      }),
    });
  }, [page]);

  return (
    <div>
      <div className="configurationButton">
        <button onClick={() => setModalShow(true)}>+ Ajouter</button>
      </div>
      <div className="dashboardResults">
        <table>
          <tr>
            <th>DJ</th>
            <th className="lastElement actionColumn">ACTIONS</th>
          </tr>

          {djs.map((dj) => (
            <DjConfigurationInfo key={dj.id} dj={dj} />
          ))}
        </table>
        <div className="dashboardPagination">
          <MuiPagination
            pagesNumber={metaData?.pageCount!}
            page={page}
            setPage={setPage}
          />
        </div>
      </div>
      <DjForm show={modalShow} onHide={() => setModalShow(false)} />
    </div>
  );
};
export default ConfigurationsDjs;

import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import location from "../../../../../assets/icons/location.svg";
import "./_FavoriteClubCard.scss";
import {
  deleteFavoriteBusiness,
  postFavoriteBusiness,
} from "../../../../../api/Business";
import favoriteIcon from "../../../../../assets/icons/heart.webp";

interface FavoriteClubCardProps {
  name?: string;
  address?: string;
  banner?: string;
  id?: number;
}

const FavoriteClubCard: FC<FavoriteClubCardProps> = ({
  name,
  address,
  banner,
  id,
}) => {
  const navigate = useNavigate();
  const [favorite, setFavorite] = useState<boolean>(true);
  const handleNavigate = () => {
    navigate(`/nightclubs/${id}`);
  };

  const deleteFavoriteClub = async () => {
    try {
      await deleteFavoriteBusiness({ id });
    } catch (e) {
      console.log(e);
    }
  };
  const addFavoriteClub = async () => {
    try {
      await postFavoriteBusiness({ id });
    } catch (e) {
      console.log(e);
    }
  };

  const handleChildElementClickDelete = (e: any) => {
    e.stopPropagation();
    deleteFavoriteClub();
  };
  const handleChildElementClickAdd = (e: any) => {
    e.stopPropagation();
    addFavoriteClub();
  };

  return (
    <div className="favoriteclubcard" onClick={handleNavigate}>
      <img
        className={`favoriteIcon ${favorite ? "favorite" : "unfavorite"} `}
        onClick={(e) => {
          favorite && handleChildElementClickDelete(e);
          setFavorite((prev) => !prev);
          !favorite && handleChildElementClickAdd(e);
        }}
        src={favoriteIcon}
        alt=""
      />

      <img className="favoriteclubcard-photo" src={banner} alt="" />
      <div className="favoriteclubcard-info">
        <h3>{name}</h3>
        <div className="favoriteclubcard-info-section2">
          <div className="favoriteclubcard-info-section2-item">
            <img src={location} alt="" />
            <span>{address}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FavoriteClubCard;

import {FC} from 'react';
import centralizeReservationImage from '../../../../assets/photos/centralizeReservationImage.webp';
import './_CentralizeReservations.scss';
import '../_ClubSpaceWhy7NightSharedStyle.scss';

interface CentralizeReservationsProps {
}

 const CentralizeReservations: FC<CentralizeReservationsProps>=()=> {
  return (
    <div className='centralizereservation container'>
      <div className='centralizereservation-section1'>
        <img src={centralizeReservationImage} alt="" />
      </div>
      <div className='centralizereservation-section2  clubspacewhy7nightsharedstyle'>
        <h3>Centralisez vos réservations pour plus d’efficacité</h3>
        <p>Organisez vos prise de réservation en permettant à votre personnel
          d’accéder à votre agenda de réservations en ligne. 
          Gagnez du temps en consultant toutes vos  réservations dès que vous 
          en avez besoin. Que vos réservations soient effectuées par  téléphone, 
          e-mail, Internet votre logiciel intelligent de gestion des réservations vous
          permet de visualiser toutes vos réservations au même endroit.
        </p>
        <ul>
          <li>Consultez toutes vos réservations, d’un simple clic</li>
          <li>Recevez une notification à chaque nouvelle réservation</li>
          <li>Digitalisez votre club </li>
          <li>Recevez des réservations 24h/24, 7j/7</li>
        </ul>
        <p>Finis les réservations notées sur des bout de papiers. 
          Accueillez vos clients et confirmez leur réservation en consultant la liste 
          sur tablette, PC ou smartphone.
        </p>
      </div>
    </div>
  );
}

export default CentralizeReservations;
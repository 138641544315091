import { ReactElement, useState, FC } from "react";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import ellipsis from "../../../../assets/icons/ellipsis.svg";
import trash from "../../../../assets/icons/trash.png";
import edit from "../../../../assets/icons/edit.png";
import { useDeleteEventTableQuery } from "../../../../react-query/hooks/Events";
import AddNewEventTable from "../../../modals/AddNewEventTable";

interface EventTableInfoProps {
  tableId: number;
  eventId: number;
  name: string;
  price: number;
  maxNumber: number;
  numberOfTables: number;
  pageNumber: number;
  hasReservation: boolean;
}
const EventTableInfo: FC<EventTableInfoProps> = ({
  tableId,
  name,
  price,
  maxNumber,
  numberOfTables,
  eventId,
  pageNumber,
  hasReservation,
}): ReactElement => {
  const [modalShow, setModalShow] = useState(false);
  const { mutate: deleteEventTable } = useDeleteEventTableQuery(
    eventId,
    pageNumber
  );
  const handleDeleteTable = (tableId: number) => () => {
    deleteEventTable(tableId);
  };
  return (
    <tr className="tableinfo">
      <td>
        <p>{name}</p>
      </td>
      <td>
        <p>{`${price} €`}</p>
      </td>
      <td>
        <p>{maxNumber}</p>
      </td>
      <td>
        <p>{numberOfTables}</p>
      </td>
      <td className="actionColumn">
        <Dropdown
          className="d-inline actionColumn-action action lastElement"
          align={"end"}
        >
          <Dropdown.Toggle id="dropdown-autoclose-true">
            <img src={ellipsis} alt="" />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item href="#" onClick={() => setModalShow(true)}>
              <img src={edit} alt="" />
              <p>Éditer</p>
            </Dropdown.Item>
            {!hasReservation && (
              <Dropdown.Item href="#" onClick={handleDeleteTable(tableId)}>
                <img src={trash} alt="" />
                <p>Effacer</p>
              </Dropdown.Item>
            )}
            {hasReservation && (
              <OverlayTrigger
                placement="left"
                overlay={<Tooltip>Ce tableau est réservé!</Tooltip>}
              >
                <Dropdown.Item href="#">
                  <img src={trash} alt="" />
                  <p>Effacer</p>
                </Dropdown.Item>
              </OverlayTrigger>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </td>
      <AddNewEventTable
        show={modalShow}
        onHide={() => setModalShow(false)}
        name={name}
        price={price}
        maxNumber={maxNumber}
        numberOfTables={numberOfTables}
        eventId={eventId}
        tableId={tableId}
        pageNumber={pageNumber}
        update
      />
    </tr>
  );
};
export default EventTableInfo;

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { UserNotificationPayloadModel } from "../../interfaces/models/postModels/NotificationPayload.model";
import {
  blockUserManager,
  deleteUserManager,
  sendNotificationUserManager,
  unblockUserManager,
} from "../mutations/userManager";

export const useSendNotificationUserManagerMutation = () =>
  useMutation(["notify-users"], (payload: UserNotificationPayloadModel) =>
    sendNotificationUserManager(payload)
  );

export const useDeleteUserManagerMutation = (
  id: string,
  pageNumber: string
) => {
  const queryClient = useQueryClient();

  return useMutation([`user-delete`], () => deleteUserManager(id), {
    onSuccess: () => queryClient.invalidateQueries([`users-${pageNumber}`]),
  });
};

export const useBlockUserManagerMutation = (id: string, pageNumber: string) => {
  const queryClient = useQueryClient();

  return useMutation([`user-block`], () => blockUserManager(id), {
    onSuccess: () => queryClient.invalidateQueries([`users-${pageNumber}`]),
  });
};

export const useUnBlockUserManagerMutation = (
  id: string,
  pageNumber: string
) => {
  const queryClient = useQueryClient();

  return useMutation([`user-unblock`], () => unblockUserManager(id), {
    onSuccess: () => queryClient.invalidateQueries([`users-${pageNumber}`]),
  });
};

import { FC, ReactElement, useState } from "react";
import "./_Gallery.scss";
import trash from "../../../../assets/icons/ImgBin.png";
import LoadingSpinner from "../../../../components/LoadingSpinner/LoadingSpinner";
import {
  useClubGalleryQuery,
  useDeleteClubPhotoQuery,
} from "../../../../react-query/hooks/Clubs";
import { NoResults } from "../NoResults/NoResults";
import AddNewClubPhoto from "../../../modals/AddNewClubPhoto";
import Photo from "../../../../user/modals/Photo/Photo";
import { ClubPhotoResponseModel } from "../../../../interfaces/models/getModels/ClubPhotoResponse.model";
import MuiPagination from "../../../../components/MuiPagination/MuiPagination";

interface ClubGalleryProps {
  id: string;
}
const ClubGallery: FC<ClubGalleryProps> = ({ id }): ReactElement => {
  const [modalShow, setModalShow] = useState(false);
  const [photoOpened, setPhotoOpened] = useState<number>();
  const [photoModalShow, setPhotoModalShow] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const { data: { data, metaData } = {}, isLoading } = useClubGalleryQuery(
    +id,
    page
  );
  const photosArray: ClubPhotoResponseModel[] = data || [];

  const { mutate: deleteClubPhoto } = useDeleteClubPhotoQuery(+id, page);

  const handleDeletePhoto = (photoId: number) => () => {
    deleteClubPhoto(photoId);
  };

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div>
          <div className="configurationButton">
            <button
              onClick={() => {
                setModalShow(true);
              }}
            >
              + Ajouter
            </button>
          </div>
          {data?.length! > 0 ? (
            <div className="dashboardResults">
              <div className="clubgallery">
                {data?.map((photo, index) => {
                  return (
                    <div className="clubgallery-photo" key={photo?.id}>
                      <img
                        src={photo?.image}
                        alt=""
                        onClick={() => {
                          setPhotoOpened(index);
                          setPhotoModalShow(true);
                        }}
                      />
                      <img
                        src={trash}
                        alt=""
                        onClick={handleDeletePhoto(photo?.id)}
                      />
                    </div>
                  );
                })}
              </div>
              <div className="dashboardPagination">
                <MuiPagination
                  pagesNumber={metaData?.pageCount!}
                  page={page}
                  setPage={setPage}
                />
              </div>
            </div>
          ) : (
            <NoResults text="Aucune photo ajoutée" />
          )}
          <AddNewClubPhoto
            show={modalShow}
            onHide={() => setModalShow(false)}
            clubId={+id}
            pageNumber={page}
          />
          <Photo
            show={photoModalShow}
            onHide={() => setPhotoModalShow(false)}
            firstItem={photoOpened === 0}
            lastItem={photoOpened === photosArray.length - 1}
            setPhotoOpened={setPhotoOpened}
            photoSrc={
              photosArray[photoOpened as keyof typeof photoOpened]?.image
            }
          />
        </div>
      )}
    </>
  );
};
export default ClubGallery;

import Joi from "joi";

export const PackageFormSchema = Joi.object({
  likes: Joi.number().required().messages({
    "number.base": "Likes must not be empty",
  }),
  winks: Joi.number().required().messages({
    "number.base": "Winks must not be empty",
  }),
  drinks: Joi.number().required().messages({
    "number.base": "Drinks must not be empty",
  }),
});

import { FC, ReactElement, useState } from "react";
import LoadingSpinner from "../../../../components/LoadingSpinner/LoadingSpinner";
import { useClubJurisdictionsQuery } from "../../../../react-query/hooks/Clubs";
import AddNewDocument from "../../../modals/AddNewDocument";
import JuridictionInfo from "../../Molecules/JuridictionInfo/JuridictionInfo";
import { NoResults } from "../NoResults/NoResults";

interface ClubJuridictionsProps {
  id: string;
}
const ClubJuridictions: FC<ClubJuridictionsProps> = ({ id }): ReactElement => {
  const [modalShow, setModalShow] = useState(false);
  const { data: { data } = {}, isLoading } = useClubJurisdictionsQuery(+id);
  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div>
          <div className="configurationButton">
            <button onClick={() => setModalShow(true)}>+ Ajouter</button>
          </div>
          {data?.length! > 0 ? (
            <div className="dashboardResults">
              <table>
                <tr>
                  <th>KBIS</th>
                  <th>DESCRIPTION</th>
                  <th>DATE D'INSCRIPTION</th>
                  <th className="lastElement actionColumn">ACTION</th>
                </tr>
                {data?.map((juri) => {
                  return (
                    <JuridictionInfo
                      key={juri?.id}
                      juridictionId={juri?.id}
                      identifier={juri?.identifier}
                      date={juri?.insertedDate}
                      clubId={juri?.businessId}
                      description={juri?.description}
                      filePath={juri?.filePath}
                    />
                  );
                })}
              </table>
              <div className="dashboardPagination"></div>
            </div>
          ) : (
            <NoResults juridiction={true} />
          )}
          <AddNewDocument
            show={modalShow}
            onHide={() => setModalShow(false)}
            clubId={+id}
            post
          />
        </div>
      )}
    </>
  );
};
export default ClubJuridictions;

import axios from "../../initializations/axios";
import { ProfilePicturePostModel } from "../../interfaces/models/postModels/ProfilePicturePost.model";

export const uploadProfilePic = async (
  profilePicData: ProfilePicturePostModel
) => {
  const { data } = await axios.post(
    "User/ChangeProfilePicture",
    profilePicData
  );
  return data;
};

import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import queryString from "query-string";
import { MetaDataResponse } from "../../interfaces/mainModels/MetaDataResponse.model";
import { EventParticipantDetailsModel } from "../../interfaces/models/getModels/EventParticipantDetails.model";
import { fetchEventParticipants } from "../queries/eventParticipants";

export const useEventParticipantsQuery = (
  params: string,
  options?: Omit<
    UseQueryOptions<MetaDataResponse<EventParticipantDetailsModel[]>>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<MetaDataResponse<EventParticipantDetailsModel[]>> =>
  useQuery(
    [`eventParticipants-${queryString.parse(params)["pageNumber"]}`],
    () => fetchEventParticipants(params),
    options
  );

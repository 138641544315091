import "./_Card.scss";
import calendarIcon from "../../../../assets/icons/calendar.svg";
import buildingIcon from "../../../../assets/icons/building.svg";
import { Link } from "react-router-dom";
import { FC } from "react";

interface ClubcardProps {
  banner: string;
  title: string;
  name: string;
  time: string;
  id: number;
}
const ClubCard: FC<ClubcardProps> = ({ id, banner, title, name, time }) => {
  const localDate = new Date(time);
  const localDateString = localDate.toLocaleDateString(undefined, {
    day: "numeric",
    month: "short",
    year: "numeric",
  });

  const localTimeString = localDate.toLocaleTimeString(undefined, {
    hour: "2-digit",
    minute: "2-digit",
  });
  return (
    <section className="dashboardcard">
      <Link to={`${id}?tab=about`}>
        <div className="dashboardcard-img">
          <img src={banner} alt="" />
        </div>
        <div className="dashboardcard-content">
          <h2>{title}</h2>
          <div className="dashboardcard-content-item">
            <img src={buildingIcon} alt="" />
            <span>{name}</span>
          </div>
          <div className="dashboardcard-content-item lastItem">
            <img src={calendarIcon} alt="" />
            <span>
              {localDateString}, {localTimeString}
            </span>
          </div>
        </div>
      </Link>
    </section>
  );
};

export default ClubCard;

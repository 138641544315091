import { useEffect, useState } from "react";
import "./_Nightclub.scss";
import "./_NightclubName.scss";
import Photos from "../../components/shared/Photos/Photos";
import start from "../../../assets/icons/star.svg";
import Description from "../../components/shared/Description/Description";
import NightclubBasicinfo from "../../components/Nightclub/NightclubBasicinfo/NightclubBasicinfo";
import NightclubSchedules from "../../components/Nightclub/NightclubSchedules/NightclubSchedules";
import NightclubPrices from "../../components/Nightclub/NightclubPrices/NightclubPrices";
import { useParams } from "react-router-dom";
import {
  deleteFavoriteBusiness,
  getBusinessServices,
  getBusinessServicesResponse,
  postFavoriteBusiness,
} from "../../../api/Business";
import GoogleMapComponent from "../../../components/GoogleMapComponent/GoogleMapComponent";
import favoriteIcon from "../../../assets/icons/heart.webp";
import ClubsReviews from "../../page-sections/shared/Reviews/ClubsReviews";
import NightclubEventCard from "../../components/shared/Cards/NightclubEventCard/NightclubEventCard";
import NoEvents from "../../components/shared/NoEvents/NoEvents";
import NoReviews from "../../components/shared/NoReviews/NoReviews";
import { useAuthContext } from "../../../context/AuthContext/AuthContext";
import NoPhotos from "../../components/shared/NoPhotos/NoPhotos";
import { useClubQuery } from "../../../react-query/hooks/Clubs";

const Nightclub = () => {
  const [nightClubServicesData, setNightClubServicesData] =
    useState<getBusinessServicesResponse>();
  let { nightclubId } = useParams<{ nightclubId?: string }>();
  let id = parseInt(nightclubId!);
  const { data: { data: nightClubData } = {}, refetch } = useClubQuery(id);
  const authCtx = useAuthContext();

  const getNightClubServices = async () => {
    try {
      const clubServicesData: any = await getBusinessServices({ id: id });
      setNightClubServicesData(clubServicesData);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getNightClubServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addFavoriteClub = async () => {
    try {
      await postFavoriteBusiness({ id });
      refetch();
    } catch (e) {
      console.log(e);
    }
  };
  const removeFavoriteClub = async () => {
    try {
      await deleteFavoriteBusiness({ id });
      refetch();
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <div className="nightclub">
      <div className="nightclub-mainPhoto">
        <div
          className="nightclub-mainPhoto-underCover"
          style={{
            backgroundImage: `url(${nightClubData?.banner})`,
          }}
        ></div>
        <img src={nightClubData?.banner} alt="" />
      </div>
      <div className="nightclub-content container">
        <div className="nightclub-content-title">
          <p>
            Accueil <span>{">"}</span> Boites de nuit <span>{">"}</span>{" "}
            <span className="name">{nightClubData?.name}</span>
          </p>
        </div>
        <div className="nightclub-content-name">
          <div className="nightclubname">
            <div className="nightclubname-section1">
              <div className="nightclubname-section1-part1">
                <h3>{nightClubData?.name}</h3>
                {nightClubData?.isOpened && (
                  <button type="button">Ouvert aujourd’hui</button>
                )}
              </div>
              <div className="nightclubname-section1-part2">
                <img src={start} alt="" />
                <span>{`${nightClubData?.averageRating}/5`}</span>
                <p>{`(${nightClubData?.reviewNumber} avis clients)`}</p>
              </div>
            </div>
            {authCtx?.isAuthenticated && (
              <div className="nightclubname-section2">
                <img
                  src={favoriteIcon}
                  alt=""
                  onClick={() => {
                    !nightClubData?.isMyFavorite && addFavoriteClub();
                    nightClubData?.isMyFavorite && removeFavoriteClub();
                  }}
                  className={`${
                    nightClubData?.isMyFavorite ? "favorite" : "unfavorite"
                  }`}
                />
              </div>
            )}
          </div>
        </div>
        <div className="nightclub-content-info">
          <div className="nightclub-content-info-basicinfo">
            <NightclubBasicinfo
              location={nightClubData?.address}
              minAge={nightClubData?.minAge}
              maxAge={nightClubData?.maxAge}
              patio={nightClubData?.patio}
              musicTypes={nightClubData?.musicTypes}
              restaurant={nightClubData?.hasRestaurant}
            />
          </div>
          <div className="nightclub-content-info-schedules">
            <NightclubSchedules schedules={nightClubData?.businessSchedules} />
          </div>
          <div className="nightclub-content-info-prices">
            <NightclubPrices services={nightClubServicesData} />
          </div>
        </div>
        <div className="nightclub-content-description">
          <Description description={nightClubData?.description} />
        </div>
        <div className="nightclub-content-evenings">
          <h3>Évènements de ce club</h3>
          {nightClubData?.events?.length === 0 ? (
            <div className="no-nightclubevenings">
              <NoEvents />
            </div>
          ) : (
            <div className="nightclubevenings">
              {nightClubData?.events?.map((event, index) => (
                <div className="nightclubevenings-item" key={index}>
                  <NightclubEventCard
                    id={event.id}
                    date={event.date}
                    address={event.city}
                    distance={event.distance}
                    banner={event.banner}
                    title={event.title}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
        {nightClubData?.gallery?.length === 0 ? (
          <div className="nophotos">
            <NoPhotos />
          </div>
        ) : (
          <div className="nightclub-content-photos">
            <Photos photos={nightClubData?.gallery} />
          </div>
        )}
        <div className="nightclub-content-map">
          <GoogleMapComponent
            marksObject={[
              {
                lat: nightClubData?.latitude! as number,
                lng: nightClubData?.longitude! as number,
              },
            ]}
          />
        </div>
        <div className="nightclub-content-reviews">
          <h3>Commentaires</h3>
          {nightClubData?.reviewNumber === 0 ? (
            <NoReviews
              id={id}
              name={nightClubData?.name}
              clubReview={true}
              djReview={false}
            />
          ) : (
            <ClubsReviews
              id={id}
              rating={nightClubData?.averageRating}
              numberOfRatings={nightClubData?.reviewNumber}
              name={nightClubData?.name}
              clubReview={true}
              djReview={false}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Nightclub;

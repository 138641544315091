import { FormControlLabel, styled } from "@mui/material";
import Switch, { SwitchProps } from "@mui/material/Switch";
import { ReactElement, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { EventReservationPostModel } from "../../interfaces/models/postModels/EventReservationPost.model";
import {
  useAllEventTablesQuery,
  usePostEventParticipantQuery,
  useUsersNameQuery,
} from "../../react-query/hooks/Events";
import Select from "react-select";

const AddParticipant = (props: any): ReactElement => {
  const [restaurant, setRestaurant] = useState<boolean>(false);
  const [usersSearchWord, setUsersSearchWord] = useState<string>("");
  const schema = yup.object().shape({
    restaurantReservation: yup.boolean().required(),
    restaurantReservationTime: yup
      .string()
      .typeError("Obligatoire")
      .when("restaurantReservation", {
        is: true,
        then: yup.string().typeError("Obligatoire").required("Obligatoire"),
      }),
    restaurantPeople: yup
      .string()
      .typeError("Required")
      .when("restaurantReservation", {
        is: true,
        then: yup.string().typeError("Obligatoire").required("Obligatoire"),
      }),
    tableId: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value)),
    tablePeople: yup
      .number()
      .positive()
      .integer()
      .typeError("Obligatoire")
      .required(),
    tableReservationTime: yup
      .string()
      .typeError("Obligatoire")
      .required("Obligatoire"),
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<EventReservationPostModel>({
    resolver: yupResolver(schema),
  });
  const {
    mutate: addParticipant,
    error,
    isError,
    isSuccess,
    isLoading,
    reset: resetMutation,
  } = usePostEventParticipantQuery(props.eventId, props.pageNumber);
  const { data: { data: usersList } = {} } = useUsersNameQuery(usersSearchWord);
  const functionE = (error: any) => {
    return error?.response?.data?.errors[0]?.message;
  };
  const { data: { data } = {} } = useAllEventTablesQuery(props.eventId);
  const [clientId, setClientId] = useState<string>();
  const handleAddParticipantSubmit = (data: EventReservationPostModel) => {
    addParticipant({ data, clientId });
    setTimeout(() => {
      isSuccess && props.onHide();
    }, 3000);
  };
  const IOSSwitch = styled((props: SwitchProps) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  useEffect(() => {
    props.onHide();
    reset();
    resetMutation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess === true]);
  const handleInputChange = (characterEntered: string) => {
    setUsersSearchWord(characterEntered);
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="dashboardModal"
      onHide={() => {
        reset();
        props.onHide();
        resetMutation();
      }}
    >
      <Modal.Header>
        <h4>Réservations</h4>
      </Modal.Header>
      <Modal.Body>
        <form
          className="modalBody"
          onSubmit={handleSubmit(handleAddParticipantSubmit)}
        >
          <h5>Informations</h5>
          <div className="Inputs">
            <div className="modalBody-Input modalItem">
              <label htmlFor="name">Nom</label>
              <Select
                className="searchSelect"
                options={usersList?.map(({ id, fullName }) => ({
                  value: id,
                  label: fullName,
                }))}
                onInputChange={handleInputChange}
                onChange={(e) => {
                  setClientId(e?.value!);
                }}
                placeholder="Sélectionner"
              />
            </div>
            <div className="modalBody-Input modalItem"></div>
          </div>
          <h5>Restaurant</h5>
          <div className="switch">
            <span className="spanText">
              Voulez-vous réserver au restaurant ?
            </span>
            <FormControlLabel
              control={
                <IOSSwitch
                  sx={{ m: 1 }}
                  defaultChecked={restaurant}
                  onClick={() => setRestaurant((prev) => !prev)}
                />
              }
              label=""
              {...register("restaurantReservation")}
            />
            {errors.restaurantReservation && (
              <p className="tw-text-red-600 mb-0">
                {errors.restaurantReservation.message}
              </p>
            )}
          </div>
          <div className="Inputs">
            <div className="modalBody-Input modalItem">
              <label htmlFor="restaurantReservationTime">Heure d’arrivée</label>
              <input
                type="time"
                id="restaurantReservationTime"
                {...register("restaurantReservationTime")}
                disabled={!restaurant}
              />
              {errors.restaurantReservationTime && (
                <p className="tw-text-red-600 mb-0">
                  {errors.restaurantReservationTime.message}
                </p>
              )}
            </div>
            <div className="modalBody-Input modalItem">
              <label htmlFor="restaurantPeople">Nombre participants</label>
              <input
                type="text"
                id="restaurantPeople"
                {...register("restaurantPeople")}
                disabled={!restaurant}
              />
              {errors.restaurantPeople && (
                <p className="tw-text-red-600 mb-0">
                  {errors.restaurantPeople.message}
                </p>
              )}
            </div>
          </div>
          <h5>Évènement</h5>
          <div className="Inputs">
            <div className="modalBody-Input modalItem">
              <label htmlFor="tablePeople">Nombre participants</label>
              <input
                type="text"
                id="tablePeople"
                {...register("tablePeople")}
              />
              {errors.tablePeople && (
                <p className="tw-text-red-600 mb-0">
                  {errors.tablePeople.message}
                </p>
              )}
            </div>
            <div className="modalBody-Input modalItem">
              <label htmlFor="tableId">Table</label>

              <select id="tableId" {...register("tableId")}>
                <option hidden selected>
                  Choisi une table
                </option>
                {data?.tables?.map((table: any) => {
                  return <option value={table.id}>{table.name}</option>;
                })}
              </select>
              {errors.tableId && (
                <p className="tw-text-red-600 mb-0">{errors.tableId.message}</p>
              )}
            </div>
            <div className="modalBody-Input modalItem">
              <label htmlFor="tableReservationTime">Heure d’arrivée</label>
              <input
                type="time"
                id="tableReservationTime"
                {...register("tableReservationTime")}
              />
              {errors.tableReservationTime && (
                <p className="tw-text-red-600 mb-0">
                  {errors.tableReservationTime.message}
                </p>
              )}
            </div>
          </div>
          <div className="modalBody-buttons modalItem">
            <button
              type="button"
              onClick={() => {
                props.onHide();
                reset();
              }}
            >
              Annuler
            </button>
            <button type="submit" disabled={isLoading}>
              Ajouter
            </button>
          </div>
          {isError && (
            <p className="tw-text-red-600 mb-0">{functionE(error)}</p>
          )}
        </form>
      </Modal.Body>
    </Modal>
  );
};
export default AddParticipant;

import { DjBasicInfo } from "../../api/Dj";
import axios from "../../initializations/axios";
import { PackageResponseModel } from "../../interfaces/models/getModels/ConfigurationResponse.model";
import { MusicTypeModel } from "../../interfaces/models/getModels/MusicType.model";
import { ConstantModel } from "../../interfaces/models/getModels/Constant.model";
import { DjFormPayload } from "../../interfaces/models/postModels/DjFormPayload.model";
import { PackageFormPayload } from "../../interfaces/models/postModels/PackageFormPayload.model";
import { MetaDataResponse } from "../../interfaces/mainModels/MetaDataResponse.model";

// GET
export const fetchAllPackages = async (): Promise<PackageResponseModel[]> => {
  const { data } = await axios.get("Package/GetAllPackages");

  return data.data;
};

export const fetchAllDjs = async (
  pageNumber: number
): Promise<MetaDataResponse<DjBasicInfo[]>> => {
  const { data } = await axios.get(
    `Dj/GetAllDj?pageSize=10&pageNumber=${pageNumber}`
  );

  return data;
};

export const fetchAllMusicTypes = async (): Promise<MusicTypeModel[]> => {
  const { data } = await axios.get("MusicType");

  return data.data.data;
};

export const fetchAllConstants = async (): Promise<ConstantModel[]> => {
  const { data } = await axios.get("Constants/GetAllConstants");

  return data.data;
};

// POST
export const addDj = async ({ name, file }: DjFormPayload) =>
  await axios.post("Dj/AddDj", {
    name,
    ...file,
  });

export const addMusicType = async (title: string) =>
  await axios.post("MusicType", { title });

// PUT
export const editPackage = async (payload: PackageFormPayload) =>
  await axios.put("Package/EditPackage", payload);

export const editDj = async (payload: DjFormPayload) =>
  await axios.put("Dj/UpdateDj", payload);

export const editMusicType = async (payload: MusicTypeModel) =>
  await axios.put("MusicType", payload);

export const editContant = async (payload: Omit<ConstantModel, "key">) =>
  await axios.put("Constants/EditConstant", payload);

// DELETE
export const deleteDj = async (id: number) => await axios.delete(`Dj/${id}`);

export const deleteMusicType = async (id: number) =>
  await axios.delete(`MusicType/${id}`);

import { ChartOptions } from 'chart.js';

export const chartOptions: ChartOptions = {
    plugins: {
        legend: {
            display: false
        },
    },
    scales: {
        y: {
            min: 0,
            ticks: {
                stepSize: 1
            }
        }
    },
    interaction: {
        intersect: false,
        mode: 'nearest',
    },
}
import { joiResolver } from "@hookform/resolvers/joi";
import { FC, ReactElement, useEffect } from "react";
import { Modal, ModalProps } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { MusicTypeModel } from "../../interfaces/models/getModels/MusicType.model";
import {
  useAddMusicTypeMutation,
  useEditMusicTypeMutation,
} from "../../react-query/hooks/useConfiguration.mutation";
import { MusicTypeFormSchema } from "../../validations/MusicTypeForm.schema";

interface MusicTypeFormProps extends ModalProps {
  musicType?: MusicTypeModel;
  onHide: () => void;
}

const MusicForm: FC<MusicTypeFormProps> = ({
  musicType,
  onHide,
  ...rest
}): ReactElement => {
  // hook form
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<MusicTypeModel>({
    resolver: joiResolver(MusicTypeFormSchema),
    ...(musicType && {
      defaultValues: {
        title: musicType.title,
      },
    }),
  });

  // react-query
  const { mutate: addMusicType, isLoading: isInsertingMusicType } =
    useAddMusicTypeMutation();
  const { mutate: editMusicType, isLoading: isEditingMusicType } =
    useEditMusicTypeMutation(musicType?.id || 0);

  // effects
  useEffect(() => {
    if (musicType)
      reset({
        title: musicType.title,
      });
  }, [musicType, reset]);

  // handlers
  const handleHideModal = () => {
    reset();
    onHide();
  };
  const onSubmit = ({ title }: MusicTypeModel) => {
    if (!musicType) addMusicType(title, { onSuccess: handleHideModal });
    else
      editMusicType(
        { id: musicType.id, title },
        { onSuccess: handleHideModal }
      );
  };

  return (
    <Modal
      {...rest}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="dashboardModal"
    >
      <Modal.Header>
        <h4>
          {!musicType?.id
            ? "Ajouter un type de musique"
            : "Mettre à jour le type de musique"}
        </h4>
      </Modal.Header>
      <Modal.Body as="form" onSubmit={handleSubmit(onSubmit)}>
        <div className="modalBody">
          <div className="modalBody-Input modalItem">
            <label htmlFor="title">Type de musique</label>
            <input
              {...register("title")}
              id="title"
              placeholder="Nom de Type de musique"
            />
            {errors.title && (
              <p className="tw-text-red-600">{errors.title.message}</p>
            )}
          </div>
          <div className="modalBody-buttons modalItem">
            <button type="button" onClick={handleHideModal}>
              Annuler
            </button>
            <button type="submit">
              {!musicType?.id
                ? isInsertingMusicType
                  ? "Ajouter..."
                  : "Ajouter"
                : isEditingMusicType
                ? "Sauvegarder..."
                : "Sauvegarder"}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default MusicForm;

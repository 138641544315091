import { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import {
  getUserNotifications,
  notification,
  putAllAsReaded,
} from "../../../../api/User";
import NoNotification from "../../../page-sections/ProfilePages/ProfileNotifications/NoNotification/NoNotification";
import NotificationCard from "../../ProfilePages/ProfileNotification/NotificationCard/NotificationCard";
import "./_Notifications.scss";

const Notifications = () => {
  const [notifications, setNotifications] = useState<notification[]>();
  const [a, setA] = useState(true);

  const getAllProfileNotifications = async () => {
    try {
      const response: any = await getUserNotifications();
      setNotifications(response?.data?.data);
    } catch (e) {
      console.log(e);
    }
  };

  const putNotificationsAsAllReaded = async () => {
    try {
      await putAllAsReaded();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getAllProfileNotifications();
  }, [a]);

  const numberOfreadItems = notifications?.filter((item) => {
    return item?.isRead === true;
  });
  const numberOfunreadItems = notifications?.filter((item) => {
    return item?.isRead !== true;
  });

  const readItems = notifications?.slice(0, 5)?.filter((item) => {
    return item?.isRead === true;
  });
  const unreadItems = notifications?.slice(0, 5)?.filter((item) => {
    return item?.isRead !== true;
  });

  return (
    <div className="notifications">
      <div className="section1">
        <p>Notifications</p>
        <button
          onClick={() => {
            putNotificationsAsAllReaded();
            setA((prev) => !prev);
          }}
        >
          Marquer tout comme lu
        </button>
      </div>
      {notifications?.length! > 0 ? (
        <div className="section2">
          {!!unreadItems?.length && (
            <div className="section2-part1">
              <div className="title2">
                <span>Non Lu</span>
                <div className="notifNum">{numberOfunreadItems?.length}</div>
              </div>
              <div className="notificationsList">
                {unreadItems?.map((notification) => {
                  return (
                    <NavLink
                      to="profile/notifications"
                      style={{ color: "black", backgroundColor: "black" }}
                    >
                      <div className="notificationsList-item">
                        <NotificationCard
                          profilePicture={
                            notification?.metaData?.profilePicture
                          }
                          title={notification?.title}
                          description={notification?.description}
                          date={notification?.insertedDate}
                          readed={false}
                          modal={true}
                        />
                      </div>
                    </NavLink>
                  );
                })}
              </div>
            </div>
          )}
          {!!readItems?.length && (
            <div className="section2-part1">
              <div className="title2">
                <span>Lu</span>
                <div className="notifNum">{numberOfreadItems?.length}</div>
              </div>
              <div className="notificationsList">
                {readItems?.map((notification) => {
                  return (
                    <NavLink
                      to="profile/notifications"
                      style={{ color: "black" }}
                    >
                      <div className="notificationsList-item">
                        <NotificationCard
                          profilePicture={
                            notification?.metaData?.profilePicture
                          }
                          title={notification?.title}
                          description={notification?.description}
                          date={notification?.insertedDate}
                          readed={true}
                          modal={true}
                        />
                      </div>
                    </NavLink>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      ) : (
        <NoNotification />
      )}
      <div className="section3">
        <button>
          <NavLink to="profile/notifications">Voir Tout</NavLink>
        </button>
      </div>
    </div>
  );
};
export default Notifications;

import { useEffect, useMemo, useState } from "react";
import {
  getMyReservations,
  ReservationsArray,
} from "../../../../api/Reservation";
import ReservationCard from "../../../components/ProfilePages/ProfileReservations/ReservationCard/ReservationCard";
import "./_ProfileReservations.scss";
import NoReservation from "../../../components/ProfilePages/ProfileReservations/NoReservation/NoReservation";
import SkeletonReservationCard from "../../../components/ProfilePages/ProfileReservations/SkeletonReservationCard/SkeletonReservationCard";
import MuiPagination from "../../../../components/MuiPagination/MuiPagination";

const ProfileReservations = () => {
  const [reservationsData, setReservationsData] =
    useState<ReservationsArray[]>();
  const [pageNumber, setPageNumber] = useState<number | undefined>();
  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState(1);

  const params = useMemo(() => ({ pageNumber: page }), [page]);

  const getReservation = async () => {
    try {
      const response: any = await getMyReservations(params);
      setReservationsData(response?.data?.data);
      setPageNumber(response?.data?.metaData?.pageCount);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getReservation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const showSkeleton = (n: number) => {
    return [...Array(n)].map((e, i) => (
      <div key={`${2000 + i}`}>
        <SkeletonReservationCard />
      </div>
    ));
  };

  return (
    <div className="profilereservations">
      <h3 className="profilereservations-title">My Reservations</h3>
      {reservationsData?.length === 0 ? (
        <div className="profilereservations-empty">
          <NoReservation />
        </div>
      ) : (
        <>
          <div className="profilereservations-reservations">
            {loading && showSkeleton(3)}
            {reservationsData?.map((event) => (
              <ReservationCard
                id={event.eventId}
                title={event.title}
                banner={event.banner}
                date={event.date}
                address={event.address}
                businessName={event.businessName}
                status={event.status}
                key={event?.id}
              />
            ))}
          </div>
          <div className="profilereservations-pagination">
            <MuiPagination
              pagesNumber={pageNumber!}
              page={page}
              setPage={setPage}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ProfileReservations;

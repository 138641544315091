import "./_NoFavorites.scss";
import favoriteEmpty from "../../../../../assets/photos/favoriteEmpty.png";
import { FC } from "react";

interface textNoFavoritesProp {
  text?: string;
}

const NoFavorites: FC<textNoFavoritesProp> = ({ text }) => {
  return (
    <div className="nofavorites">
      <img src={favoriteEmpty} alt="" />
      <h3>Vous n'avez pas encore de favoris</h3>
      <p>{text}</p>
    </div>
  );
};

export default NoFavorites;

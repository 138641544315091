import Rating from "@mui/material/Rating";
import { FC, ReactElement } from "react";
import { Dropdown } from "react-bootstrap";
import ellipsis from "../../../../assets/icons/ellipsis.svg";
import black from "../../../../assets/photos/black.png";
import "./_RateInfo.scss";
import trash from "../../../../assets/icons/trash.png";
import { useDeleteClubReviewQuery } from "../../../../react-query/hooks/Clubs";
import { useAuthContext } from "../../../../context/AuthContext/AuthContext";

interface RateInfoProps {
  reviewId: number;
  firstName: string;
  email: string;
  comment: string;
  rating: number;
  clubId: number;
  pageNumber: number;
}
const RateInfo: FC<RateInfoProps> = ({
  reviewId,
  firstName,
  email,
  rating,
  comment,
  clubId,
  pageNumber,
}): ReactElement => {
  const { mutate: deleteClubReview } = useDeleteClubReviewQuery(
    clubId,
    pageNumber
  );
  const handleDeleteReview = (reviewId: number) => () => {
    deleteClubReview(reviewId);
  };
  const authCtx = useAuthContext();
  return (
    <tr className="rateinfo">
      <td className="rateinfo-user">
        <div className="avatar">
          <img src={black} alt="" />
        </div>
        <div className="rateinfo-user-nameEmail">
          <p>{firstName}</p>
          <p>{email}</p>
        </div>
      </td>
      <td className="rateinfo-comment">
        <p>{comment}</p>
      </td>
      <td>
        <Rating name="read-only" value={rating} readOnly precision={0.5} />
      </td>
      {authCtx?.decoded?.role === "Admin" && (
        <td className="actionColumn">
          <Dropdown
            className="d-inline actionColumn-action action lastElement"
            align={"end"}
          >
            <Dropdown.Toggle id="dropdown-autoclose-true">
              <img src={ellipsis} alt="" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item href="#" onClick={handleDeleteReview(reviewId)}>
                <img src={trash} alt="" />
                <p>Effacer</p>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      )}
    </tr>
  );
};
export default RateInfo;

import { ReactElement, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { SchedulePostModel } from "../../interfaces/models/postModels/SchedulePost.model";
import { ScheduleUpdateModel } from "../../interfaces/models/updateModels/ScheduleUpdate.model";
import {
  usePostClubScheduleQuery,
  usePutClubScheduleQuery,
} from "../../react-query/hooks/Clubs";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const dayOfWeek: string[] = [
  "Lundi",
  "Mardi",
  "Mercredi",
  "Jeudi",
  "Vendredi",
  "Samedi",
  "Dimanche",
];
const schema = yup.object().shape({
  startTime: yup.string().required("Obligatoire"),
  endTime: yup.string().required("Obligatoire"),
  weekDay: yup.number().typeError("Obligatoire").required(),
});
const AddNewSchedule = (props: any): ReactElement => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitSuccessful, errors },
  } = useForm<SchedulePostModel>({ resolver: yupResolver(schema) });

  const {
    mutate: addClubSchedule,
    isLoading: isAddLoading,
    isSuccess: isAddSuccess,
  } = usePostClubScheduleQuery(props.clubId);

  const {
    mutate: updateClubSchedule,
    isLoading: isUpdateLoading,
    isSuccess: isUpdateSuccess,
  } = usePutClubScheduleQuery(props.clubId);

  const handleScheduleSubmit = (
    data: SchedulePostModel | ScheduleUpdateModel
  ) => {
    if (props.post) {
      return addClubSchedule({ ...data, businessId: props.clubId });
    }
    return updateClubSchedule({ ...data, id: props.scheduleId });
  };

  useEffect(() => {
    props.onHide();
    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful === true]);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="dashboardModal"
    >
      <Modal.Header>
        {props.post && <h4>Ajouter un nouvel horaire</h4>}
        {props.update && <h4>Horaire de mise à jour</h4>}
      </Modal.Header>
      <Modal.Body>
        <form
          className="modalBody"
          onSubmit={handleSubmit(handleScheduleSubmit)}
        >
          <div className="modalBody-Input modalItem">
            <label htmlFor="dayOfWeek">Day of week</label>
            <select id="dayOfWeek" {...register("weekDay")}>
              <option hidden selected disabled={props.update}>
                Choisissez un jour
              </option>
              {dayOfWeek?.map((day, index) => {
                return (
                  <option
                    value={index + 1}
                    selected={index + 1 === props?.weekDay}
                    key={index}
                  >
                    {day}
                  </option>
                );
              })}
            </select>
            {errors.weekDay && (
              <p className="tw-text-red-600 mb-0">{errors.weekDay.message}</p>
            )}
          </div>
          <div className="modalBody-Input modalItem">
            <label htmlFor="startTime">Heure d’ouverture</label>
            <input
              type="time"
              id="startTime"
              {...register("startTime")}
              defaultValue={props?.startTime?.slice(0, 5)}
            />
            {errors.startTime && (
              <p className="tw-text-red-600 mb-0">{errors.startTime.message}</p>
            )}
          </div>
          <div className="modalBody-Input modalItem">
            <label htmlFor="endTime">Heure de fermeture</label>
            <input
              type="time"
              id="endTime"
              {...register("endTime")}
              defaultValue={props?.endTime?.slice(0, 5)}
            />
            {errors.endTime && (
              <p className="tw-text-red-600 mb-0">{errors.endTime.message}</p>
            )}
          </div>
          <div className="modalBody-buttons modalItem">
            <button onClick={() => props.onHide()}>Annuler</button>
            {props.post && (
              <button type="submit" disabled={isAddLoading}>
                Ajouter
              </button>
            )}
            {props.update && (
              <button type="submit" disabled={isUpdateLoading}>
                Sauvegarder
              </button>
            )}
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};
export default AddNewSchedule;

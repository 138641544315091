import objectToQueryString from "../utils/objectToQueryString";
import { apiRequest } from "./Api";
import { responseBody} from "./Shared";

// ----MyReservation----
export interface ReservationsArray{
      id: number,
      title: string,
      eventId: number,
      banner: string,
      city: string,
      country: string,
      address: string,
      distance: number,
      businessName: string,
      date: string,
      status: string
}
//---Get---
export interface getMyReservationResponse extends responseBody{
  data: ReservationsArray[]
}

//---post(cancel)---
export interface CancelEventReservationInput{
  id?: number
}
export interface CancalEventReservationResponse extends responseBody{
  data: string
}


// -------apiRequests---------
export const getMyReservations=async(params: any)=>
apiRequest<getMyReservationResponse>('get', `/Reservation/my?${objectToQueryString(params)}&pageSize=3`)

export const cancelEventReservation=async(data: CancelEventReservationInput)=>
apiRequest< CancelEventReservationInput, CancalEventReservationResponse>("post", `/Reservation/Cancel/${data.id}?`, data)
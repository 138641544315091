import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import { AdminDashboardResponseModel } from "../../interfaces/models/getModels/AdminDashboardResponse.model";
import { MangerDashboardPayloadModel } from "../../interfaces/models/getModels/ManagerDashboardPayload.model";
import { ManagerDashboardResponseModel } from "../../interfaces/models/getModels/ManagerDashboardResponse.model";
import {
  fetchAdminDashboard,
  fetchManagerDashboard,
} from "../queries/dashboard";

export const useAdminDashboardQuery = (
  options?: Omit<
    UseQueryOptions<AdminDashboardResponseModel>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<AdminDashboardResponseModel> =>
  useQuery(["admin-dashboard"], fetchAdminDashboard, options);

export const useManagerDashboardQuery = (
  payload: MangerDashboardPayloadModel
): UseQueryResult<ManagerDashboardResponseModel> =>
  useQuery(["manager-dashboard"], () => fetchManagerDashboard(payload));

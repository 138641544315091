import "./_Nightclubs.scss";
import "./_NightclubsFilter.scss";
import location from "../../../assets/icons/location.svg";
import building from "../../../assets/icons/building.svg";
import { useEffect, useState } from "react";
import { GetBusinessInput } from "../../../api/Api";
import useNightclubFilter from "../../modals/NightclubFilter/NightclubFilter";
import GoogleMapComponent from "../../../components/GoogleMapComponent/GoogleMapComponent";
import useClubsSection from "../../page-sections/Nightclubs/ClubsSection/ClubsSection";
import { useGoogleAutoFill } from "../../../components/GoogleAutoFill/GoogleAutoFill";

const Nightclubs = () => {
  const date = new Date();
  const today = date.getDay();
  const [modalShow, setModalShow] = useState(false);
  const { address, render: googleAutoFill } = useGoogleAutoFill("Où?");
  const { secondSearch, render } = useNightclubFilter({
    show: { modalShow },
    onHide: () => setModalShow(false),
  });
  const [todayOpen, setTodayOpen] = useState<boolean>(false);
  const [filterInputs, setFilterInputs] = useState<GetBusinessInput>({
    patio: null,
  } as GetBusinessInput);
  const [search, setSearch] = useState<GetBusinessInput>(filterInputs);

  const scrollToTop = () => {
    window.document.getElementById("results")?.scroll(0, 0);
  };

  const { lngLatArray, renderItems, zeroItem } = useClubsSection({
    filterInputs: search,
  });
  const [citySearch, setCitySearch] = useState<boolean>(false);
  const [searchedLocation, setSearchedLocation] = useState<
    string | undefined | null
  >("");
  useEffect(() => {
    setSearch(secondSearch);
  }, [secondSearch]);
  useEffect(() => {
    setFilterInputs({ ...filterInputs, location: address });
  }, [address]);

  return (
    <div className="nightclubs container">
      <div className="nightclubs-section1">
        <form
          className="nightclubsfilter filter"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div className="nightclubsfilter-section1">
            <div className="nightclubsfilter-section1-part1">
              <div className="input">
                <img src={building} alt="" />
                <input
                  type="text"
                  value={filterInputs.searchWord || ""}
                  onChange={(e) =>
                    setFilterInputs({
                      ...filterInputs,
                      searchWord: e.target.value,
                    })
                  }
                  placeholder="Quel club recherchez-vous ?"
                />
              </div>
              <div className="input">
                <img src={location} alt="" />
                <div className="clubsGoogleautofill">{googleAutoFill}</div>
              </div>
            </div>
            <div className="nightclubsfilter-section1-part2">
              <div className="chechbox-btn">
                <label htmlFor="isOpenToday">
                  <input
                    type="checkbox"
                    id="isOpenToday"
                    value={today || undefined}
                    checked={todayOpen}
                    onChange={(e) => {
                      if (e.target.checked === true) {
                        setTodayOpen(true);
                        setFilterInputs({ ...filterInputs, dayOfWeek: today });
                      } else if (e.target.checked === false) {
                        setTodayOpen(false);
                        setFilterInputs({
                          ...filterInputs,
                          dayOfWeek: undefined,
                        });
                      }
                    }}
                  />
                  <span>Ouvert aujourd’hui</span>
                </label>
              </div>

              <div className="chechbox-btn">
                <label>
                  <input
                    type="checkbox"
                    id="exterior"
                    checked={!!filterInputs.patio}
                    onChange={(e) => {
                      if (e.target.checked === true) {
                        setFilterInputs({ ...filterInputs, patio: true });
                      } else if (e.target.checked === false) {
                        setFilterInputs({ ...filterInputs, patio: null });
                      }
                    }}
                  />
                  <span>Avec patio</span>
                </label>
              </div>
            </div>
          </div>
          <div className="nightclubsfilter-section2">
            <button
              type="button"
              onClick={() => {
                setModalShow(true);
                setFilterInputs({});
                setTodayOpen(false);
              }}
            >
              Plus de filtres
            </button>
            {modalShow && render}
            <button
              type="submit"
              onClick={() => {
                setSearch(filterInputs);
                scrollToTop();
                !!(
                  filterInputs?.location !== "" ||
                  filterInputs?.location !== undefined ||
                  filterInputs?.location !== null
                ) && setCitySearch(true);
                !!(
                  filterInputs?.location === "" ||
                  filterInputs?.location === undefined ||
                  filterInputs?.location === null
                ) && setCitySearch(false);
                setSearchedLocation(filterInputs?.location);
              }}
            >
              Rechercher
            </button>
          </div>
        </form>
        <p className="nightclubs-p">
          {zeroItem !== 0 &&
            citySearch &&
            `Les meilleurs clubs à ${searchedLocation}`}
        </p>
        <div className="results" id="results">
          {renderItems}
        </div>
      </div>
      <div className="nightclubs-map">
        <GoogleMapComponent marksObject={lngLatArray} />
      </div>
    </div>
  );
};

export default Nightclubs;

import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { cancelEventReservation } from "../../../../../api/Reservation";
import building from "../../../../../assets/icons/building.svg";
import calendar from "../../../../../assets/icons/calendar.svg";
import location from "../../../../../assets/icons/location.svg";
import reservation from "../../../../../assets/icons/reservation.svg";
import "./_ReservationCard.scss";

interface ReservationCardProps {
  id?: number;
  title?: string;
  banner?: string;
  date?: string;
  address?: string;
  businessName?: string;
  status?: string;
}

const ReservationCard: FC<ReservationCardProps> = ({
  id,
  title,
  banner,
  date,
  address,
  businessName,
  status,
}) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(`/evenings/${id}`);
  };
  const localDate = new Date(date!);
  const localDateString = localDate.toLocaleDateString(undefined, {
    day: "numeric",
    month: "short",
    year: "numeric",
  });

  const localTimeString = localDate.toLocaleTimeString(undefined, {
    hour: "2-digit",
    minute: "2-digit",
  });
  const [cancelSuccess, setCancelSuccess] = useState<boolean>(false);

  const cancelEvent = async () => {
    try {
      const response = await cancelEventReservation({ id });
      setCancelSuccess(response.isSuccess);
    } catch (e) {
      console.log(e);
    }
  };
  const handleChildElementClick = (e: any) => {
    e.stopPropagation();
    cancelEvent();
  };

  return (
    <div className="reservationcard" key={id} onClick={handleNavigate}>
      <img className="reservationcard-photo" src={banner} alt="" />
      <div className="reservationcard-info">
        <h3>{title}</h3>
        <div className="reservationcard-info-section2">
          <div className="reservationcard-info-section2-item">
            <img src={building} alt="" />
            <span>{businessName}</span>
          </div>
          <div className="reservationcard-info-section2-item">
            <img src={calendar} alt="" />
            <span>
              {localDateString}, {localTimeString}
            </span>
          </div>
          <div className="reservationcard-info-section2-item">
            <img src={location} alt="" />
            <span>{address}</span>
          </div>
        </div>
        <div className="reservationcard-info-section3">
          <img src={reservation} alt="" />
          <button
            onClick={(e) => handleChildElementClick(e)}
            disabled={cancelSuccess}
            style={
              cancelSuccess || status === "Cancelled"
                ? { color: "white", backgroundColor: "#808080" }
                : {}
            }
          >
            {!cancelSuccess && status !== "Cancelled"
              ? "Annuler la réservation"
              : "Annulé"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReservationCard;

import { FC, ReactElement, useEffect, useState } from "react";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import ValidationQRIcon from "../../../../assets/icons/ValidationQRIcon.png";
import userTickIcon from "../../../../assets/icons/reservationAcceptedIcon.png";
import inwaitReservationsIcon from "../../../../assets/icons/inwaitReservationsIcon.png";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { TextField } from "@mui/material";
import { ManagerDashboardS1Props } from "./ManagerDashboardS1.props";
import { MangerDashboardPayloadModel } from "../../../../interfaces/models/getModels/ManagerDashboardPayload.model";

const ManagerDashboardS1: FC<ManagerDashboardS1Props> = ({
  data: {
    userName,
    topBusinesses,
    averageBookingTime,
    averageAgeOfReservation,
    numberOfReservations,
    boysPercentageFromAllReservations,
    girlsPercentageFromAllReservations,
  },
}): ReactElement => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const queryParams = queryString.parse(
    search
  ) as unknown as MangerDashboardPayloadModel;

  const [startDate, setStartDate] = useState<Dayjs | null>(
    queryParams.startDate ? dayjs(queryParams.startDate) : null
  );
  const [endDate, setEndDate] = useState<Dayjs | null>(
    queryParams.endDate ? dayjs(queryParams.endDate) : null
  );

  useEffect(() => {
    if (endDate)
      navigate({
        search: `${queryString.stringify({
          ...(endDate && {
            endDate: dayjs(endDate).format("YYYY-MM-DD"),
          }),
          ...(startDate && {
            startDate: dayjs(startDate).format("YYYY-MM-DD"),
          }),
        })}`,
      });
  }, [startDate, endDate, navigate]);

  return (
    <div className="dashboards1">
      <div className="tw-flex tw-justify-between tw-items-center">
        <div className="dashboardSection">
          <h2>Bonjour {userName}</h2>
          <p>Content de te revoir !</p>
        </div>
        <div className="tw-flex tw-gap-5">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              views={["day"]}
              label="Date de début"
              value={startDate}
              onChange={(date) => setStartDate(date)}
              renderInput={(params) => (
                <TextField {...params} size="small" helperText={null} />
              )}
            />
            <DatePicker
              views={["day"]}
              label="Date de fin"
              value={endDate}
              disabled={startDate === null}
              onChange={(date) => setEndDate(date)}
              renderInput={(params) => (
                <TextField {...params} size="small" helperText={null} />
              )}
            />
          </LocalizationProvider>
        </div>
      </div>
      <div className="dashboardSection tableSection">
        <h3>Clubs statistiques</h3>
        <table className="table dashboardTable">
          <tr>
            <th>Nom du club</th>
            <th>Nombre de visite de la vitrine</th>
            <th>Avis</th>
          </tr>

          {topBusinesses.map(({ id, name, numberOfReservations, average }) => (
            <tr key={id}>
              <td>{name}</td>
              <td>{numberOfReservations}</td>
              <td>{average || "-"}</td>
            </tr>
          ))}
        </table>
      </div>
      <div className="dashboardSection satistics">
        <div className="managerSatistic satisticsItem1">
          <div className="managerSatistic-title">
            <h4>Heure d'arrivée moyenne</h4>
            <img src={ValidationQRIcon} alt="" />
          </div>
          <div className="tw-flex tw-justify-between tw-items-center tw-mt-6">
            <p>{averageBookingTime}</p>
            {/* <CircleProgress
              sx={{ color: "#d818e7" }}
              size={90}
              thickness={4}
              value={20}
            /> */}
          </div>
        </div>
        <div className="managerSatistic satisticsItem2">
          <div className="managerSatistic-title">
            <h4>Pourcentage Garçons / Filles</h4>
            <img src={userTickIcon} alt="" />
          </div>
          <div className="tw-flex tw-justify-between tw-items-center tw-mt-6">
            <p>{`${boysPercentageFromAllReservations}/${girlsPercentageFromAllReservations}`}</p>
            {/* <CircleProgress
              sx={{ color: "#7d65ef" }}
              size={90}
              thickness={4}
              value={calculatePercentage(
                confirmedReservations,
                numberOfReservations
              )}
            /> */}
          </div>
        </div>
        <div className="managerSatistic satisticsItem3">
          <div className="managerSatistic-title">
            <h4>Moyenne d’âge des clients</h4>
            <img src={userTickIcon} alt="" />
          </div>

          <div className="tw-flex tw-justify-between tw-items-center tw-mt-6">
            <p>{averageAgeOfReservation}</p>
            {/* <CircleProgress
              sx={{ color: "#4d84fe" }}
              size={90}
              thickness={4}
              value={calculatePercentage(
                failedReservations,
                numberOfReservations
              )}
            /> */}
          </div>
        </div>
        <div className="managerSatistic satisticsItem4">
          <div className="managerSatistic-title">
            <h4>
              Nombre de réservations (en calculant les accompagnants inclus)
            </h4>
            <img src={inwaitReservationsIcon} alt="" />
          </div>
          <div className="tw-flex tw-justify-between tw-items-center tw-mt-6">
            <p>{numberOfReservations}</p>
            {/* <CircleProgress
              sx={{ color: "#52b2ff" }}
              size={90}
              thickness={4}
              value={calculatePercentage(
                pendingReservations,
                numberOfReservations
              )}
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ManagerDashboardS1;

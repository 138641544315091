import { useEffect, useMemo, useState } from "react";
import {
  favoritesBusinessArray,
  getFavoritesBusiness,
} from "../../../../api/Business";
import FavoriteClubCard from "../../../components/ProfilePages/ProfileFavorites/FavoriteClubCard/FavoriteClubCard";
import NoFavorites from "../../../components/ProfilePages/ProfileFavorites/NoFavorites/NoFavorites";
import SkeletonFavoriteClubCard from "../../../components/ProfilePages/ProfileFavorites/SkeletonFavoriteClubCard/SkeletonFavoriteClubCard";
import "./_ProfileFavorites.scss";
import MuiPagination from "../../../../components/MuiPagination/MuiPagination";

const NightclubsFavorites = () => {
  const [clubsFavoritesData, setClubsFavoritesData] =
    useState<favoritesBusinessArray[]>();
  const [pageNumber, setPageNumber] = useState<number | number>();
  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState(1);

  const params = useMemo(() => ({ pageNumber: page }), [page]);

  const getFavoritesBusinessData = async () => {
    try {
      const response: any = await getFavoritesBusiness(params);
      setClubsFavoritesData(response?.data?.data);
      setPageNumber(response?.metaData?.pageCount);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getFavoritesBusinessData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const showSkeleton = (n: number) => {
    return [...Array(n)].map((e, i) => (
      <div key={`${2000 + i}`}>
        <SkeletonFavoriteClubCard />
      </div>
    ));
  };

  return (
    <>
      {clubsFavoritesData?.length === 0 ? (
        <div className="profilefavorites-empty">
          <NoFavorites
            text={
              "Ajoutez des clubs à vos favoris pour les retrouver facilement !"
            }
          />
        </div>
      ) : (
        <>
          {loading && showSkeleton(3)}
          <div>
            {clubsFavoritesData?.map((club) => (
              <FavoriteClubCard
                name={club.name}
                address={club.address}
                banner={club.banner}
                id={club.id}
                key={club.id}
              />
            ))}
          </div>
          <div className="profileFavorites-pagination">
            <MuiPagination
              pagesNumber={pageNumber!}
              page={page}
              setPage={setPage}
            />
          </div>
        </>
      )}
    </>
  );
};
export default NightclubsFavorites;

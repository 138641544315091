import { ReactElement } from "react";
import { Tab, Tabs } from "react-bootstrap";
import SubscriptionPacks from "../../components/Organisms/SubscriptionPacks/SubscriptionPacks";
import { useAuthContext } from "../../../context/AuthContext/AuthContext";
import managerEmailIcon from "../../../assets/icons/managerEmailIcon.png";
import managerProfileIcon from "../../../assets/icons/managerProfileIcon.png";
import defaultProfile from "../../../assets/photos/defaultProfile.png";
import SubscriptionHistory from "../../components/Organisms/SubscriptionHistory/SubscriptionHistory";
import PaymentMethods from "../../components/Organisms/PaymentMethods/PaymentMethods";

const ManagerSubscription = (): ReactElement => {
  const authCtx = useAuthContext();
  return (
    <div className="event">
      <div className="dashboardTitle">
        <h3>Abonnements</h3>
      </div>
      <div className="managerPersonalInfo">
        <img src={authCtx?.user?.profilePicture || defaultProfile} alt="" />
        <div className="managerPersonalInfo-infos">
          <h4>
            {authCtx?.user?.firstName} {authCtx?.user?.lastName}
          </h4>
          <div className="managerPersonalInfo-infos-roleEmail">
            <div className="managerPersonalInfo-infos-roleEmail-item">
              <img src={managerProfileIcon} alt="" />
              <h5>{authCtx?.decoded?.role}</h5>
            </div>
            <div className="managerPersonalInfo-infos-roleEmail-item">
              <img src={managerEmailIcon} alt="" />
              <h5>{authCtx?.user?.email}</h5>
            </div>
          </div>
        </div>
      </div>
      <Tabs
        defaultActiveKey="subscription"
        transition={false}
        id="noanim-tab-example"
        className="dashboardNav"
      >
        <Tab
          eventKey="subscription"
          title="Abonnements"
          mountOnEnter
          unmountOnExit
        >
          <SubscriptionPacks />
        </Tab>
        <Tab
          eventKey="paymentMethods"
          title="Méthodes de payement"
          mountOnEnter
          unmountOnExit
        >
          <PaymentMethods />
        </Tab>
        <Tab
          eventKey="history"
          title="Historique d’abonnement"
          mountOnEnter
          unmountOnExit
        >
          <SubscriptionHistory />
        </Tab>
      </Tabs>
    </div>
  );
};

export default ManagerSubscription;

import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import { BasicResponse } from "../../interfaces/mainModels/BasicResponse.model";
import { MetaDataResponse } from "../../interfaces/mainModels/MetaDataResponse.model";
import { PacketResponseModel } from "../../interfaces/models/getModels/PacketResponse.model";
import { SubscriptionHistoryResponseModel } from "../../interfaces/models/getModels/SubscriptionHistoryResponse.model";
import { SubscriptionStatusResponseModel } from "../../interfaces/models/getModels/SubscriptionStatusResponse.model";
import {
  cancelSubscription,
  reactivateSubscription,
  subscribe,
  updatePreviewSubscription,
  updateSubscription,
} from "../mutations/subscription";
import {
  getSubscriptionHistory,
  getSubscriptionPackets,
  getSubscriptionStatus,
} from "../queries/subscription";

// -----GET-----
export const useSubscriptionHistoryQuery = (
  userId?: string,
  pageNumber?: number,
  options?: Omit<
    UseQueryOptions<MetaDataResponse<SubscriptionHistoryResponseModel[]>>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<MetaDataResponse<SubscriptionHistoryResponseModel[]>> =>
  useQuery(
    [`business-events-pageNumber-${pageNumber}`],
    () => getSubscriptionHistory(userId, pageNumber),
    options
  );

export const useSubscriptionStatus = (
  options?: Omit<
    UseQueryOptions<BasicResponse<SubscriptionStatusResponseModel>>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<BasicResponse<SubscriptionStatusResponseModel>> =>
  useQuery(["subscriptionStaus"], () => getSubscriptionStatus(), options);

export const useSubscriptionPackets = (
  options?: Omit<
    UseQueryOptions<BasicResponse<PacketResponseModel[]>>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<BasicResponse<PacketResponseModel[]>> =>
  useQuery(["subscriptionPackets"], () => getSubscriptionPackets(), options);

//-----POST------

export const useSubscribe = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ["subscribe"],
    (subscriptionPackId?: number) => subscribe(subscriptionPackId),
    {
      onSuccess: () => {
        setTimeout(() => {
          queryClient.invalidateQueries(["subscriptionStaus"]);
        }, 2000);
      },
    }
  );
};

export const useCancelSubscription = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ["cancelSubscription"],
    (subscriptionPackId?: number) => cancelSubscription(subscriptionPackId),
    {
      onSuccess: () => {
        setTimeout(() => {
          queryClient.invalidateQueries(["subscriptionStaus"]);
        }, 2000);
      },
    }
  );
};

export const useUpdatePreviewSubscription = () => {
  return useMutation(
    ["updatePreviewSubscription"],
    (newSubscriptionPackId?: number) =>
      updatePreviewSubscription(newSubscriptionPackId)
  );
};

export const useUpdateSubscription = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ["updateSubscription"],
    (newSubscriptionPackId?: number) =>
      updateSubscription(newSubscriptionPackId),
    {
      onSuccess: () => {
        setTimeout(() => {
          queryClient.invalidateQueries(["subscriptionStaus"]);
        }, 2000);
      },
    }
  );
};

export const useReactivateSubscription = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ["reactivateSubscription"],
    (subscriptionPackId?: number) => reactivateSubscription(subscriptionPackId),
    {
      onSuccess: () => {
        setTimeout(() => {
          queryClient.invalidateQueries(["subscriptionStaus"]);
        }, 2000);
      },
    }
  );
};

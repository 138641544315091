import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { DjBasicInfo } from "../../api/Dj";
import { MetaDataResponse } from "../../interfaces/mainModels/MetaDataResponse.model";
import { PackageResponseModel } from "../../interfaces/models/getModels/ConfigurationResponse.model";
import { ConstantModel } from "../../interfaces/models/getModels/Constant.model";
import { MusicTypeModel } from "../../interfaces/models/getModels/MusicType.model";
import {
  fetchAllConstants,
  fetchAllDjs,
  fetchAllMusicTypes,
  fetchAllPackages,
} from "../queries/configurations";

// Packages
export const useFetchAllPackagesQuery = (): UseQueryResult<
  PackageResponseModel[]
> => useQuery(["packages"], fetchAllPackages);

// Djs
export const useFetchAllDjsQuery = (
  pageNumber: number
): UseQueryResult<MetaDataResponse<DjBasicInfo[]>> =>
  useQuery([`djs-${pageNumber}`], () => fetchAllDjs(pageNumber));

// Music Types
export const useFetchAllMusicTypesQuery = (): UseQueryResult<
  MusicTypeModel[]
> => useQuery(["musicTypes"], fetchAllMusicTypes);

// Constants
export const useFetchAllContantsQuery = (): UseQueryResult<ConstantModel[]> =>
  useQuery(["constants"], fetchAllConstants);

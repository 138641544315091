import { FC, useEffect, useState } from "react";
import ClubListIcon from "../../../../assets/icons/ClubListIcon.png";
import { NavLink } from "react-router-dom";
import EventCard from "../../../components/shared/Cards/EventCard/EventCard";
import "./_HomeEveningsList.scss";
import { BusinessEventBasicInfo, getBusinessEvents } from "../../../../api/Api";
import SkeletonEventCard from "../../../components/shared/Cards/SkeletonEventCard/SkeletonEventCard";
import { useAuthContext } from "../../../../context/AuthContext/AuthContext";

interface HomeEveningsListProps {
  input?: string;
}

const HomeEveningsList: FC<HomeEveningsListProps> = ({ input }) => {
  const authCtx = useAuthContext();
  const [eveningsData, setEveningsData] = useState<BusinessEventBasicInfo[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const getBusinessEventData = async () => {
    try {
      const businessEventResponse = await getBusinessEvents(
        {
          location: input,
        },
        authCtx?.location
      );
      setEveningsData(businessEventResponse?.data?.data);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getBusinessEventData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input, authCtx?.location?.latitude]);

  const showSkeleton = (n: number) => {
    return [...Array(n)].map((e, i) => (
      <div className="homeeveninglist-section2-item" key={`${2000 + i}`}>
        <SkeletonEventCard />
      </div>
    ));
  };

  const sortedBusinessEvents = eveningsData?.sort(function compareNumber(
    a: BusinessEventBasicInfo,
    b: BusinessEventBasicInfo
  ) {
    return b.averageScore - a.averageScore;
  });

  return (
    <div className="homeeveninglist">
      <div className="homeeveninglist-section1 container-home">
        <div className="homeeveninglist-section1-part1">
          <img src={ClubListIcon} alt="" />
          <h2>Evenings Tendance</h2>
        </div>
        <NavLink to="/evenings">VOIR PLUS</NavLink>
      </div>
      <div className="homeeveninglist-section2 container-home">
        {sortedBusinessEvents?.slice(0, 6)?.map((event) => (
          <div className="homeeveninglist-section2-item" key={event.id}>
            <EventCard
              id={event?.id}
              photo={event?.banner}
              title={event?.title}
              club={event?.clubName}
              address={event?.city}
              date={event?.date}
              distance={event?.distance}
              isAlmostFull={event?.isAlmostFull}
              isFreeForGirls={event?.isFreeForGirls}
              isFreeForBoys={event?.isFreeForBoys}
              hasShowCase={event?.hasShowCase}
              isOpened={event?.isOpened}
            />
          </div>
        ))}
        {loading && showSkeleton(6)}
      </div>
    </div>
  );
};

export default HomeEveningsList;

import "../_ChangeResetVerifyStyle.scss";
import * as Yup from "yup";
import { resetPassword } from "../../../../api/Api";
import { useLoginRegisterResetPasswordContext } from "../../../../context/LoginRegisterResetPasswordContext/LoginRegisterResetPasswordContext";
import { useState } from "react";
import { ResetPassswordPostModel } from "../../../../interfaces/models/postModels/ResetPasswordPost.model";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const schema = Yup.object().shape({
  newPassword: Yup.string()
    .min(8, "Le mot de passe doit comporter 8+ caractères")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/,
      "Les mots de passe doivent contenir au moins une lettre majuscule : A-Z, un chiffre et des symboles : !, @, #, $, %"
    )
    .required("Aucun mot de passe fourni"),
  confirmNewPassword: Yup.string().oneOf(
    [Yup.ref("newPassword"), null],
    "Les mots de passe doivent correspondre"
  ),
});

const CreateNewPassword = () => {
  const [error, setError] = useState<any>();
  const [success, setSuccess] = useState<boolean>(false);
  const LRRPCtx = useLoginRegisterResetPasswordContext();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<ResetPassswordPostModel>({
    resolver: yupResolver(schema),
  });
  const handleCreateNewPassword = async (
    CreateNewPasswordInputs: ResetPassswordPostModel
  ) => {
    try {
      const response: any = await resetPassword({
        ...CreateNewPasswordInputs,
        email: LRRPCtx.email,
        resetPasswordCode: LRRPCtx.resetPasswordCode,
        languageId: LRRPCtx.languageId,
      });
      setSuccess(response.isSuccess);
      setTimeout(() => {
        response?.isSuccess && LRRPCtx.setPage(1);
      }, 2000);
    } catch (e: any) {
      setError(e.response?.data?.errors[0]?.message);
    }
  };
  return (
    <div className="changeresetverifystyle">
      <div>
        <div className="changeresetverifystyle-info">
          <h3>Créer un nouveau mot de passe</h3>
        </div>
        <form onSubmit={handleSubmit(handleCreateNewPassword)}>
          <div className="input">
            <input
              type="password"
              id="newPassword"
              placeholder="Nouveau mot de passe"
              {...register("newPassword")}
            />
            {errors.newPassword && (
              <p className="error">{errors.newPassword.message}</p>
            )}
          </div>
          <div className="input">
            <input
              type="password"
              id="confirmNewPassword"
              placeholder="Confirmer le nouveau mot de passe"
              {...register("confirmNewPassword")}
            />
            {errors.confirmNewPassword && (
              <p className="error">{errors.confirmNewPassword.message}</p>
            )}
          </div>

          <button disabled={isSubmitting} type="submit" className="submit-btn">
            {isSubmitting ? "Attendez..." : "Mettre à jour le mot de passe"}
          </button>
        </form>
        {!!error && <p className="error">{error}</p>}
        {success && (
          <p style={{ textAlign: "center", marginTop: "10px" }}>
            Votre mot de passe a été changé avec succès!
          </p>
        )}
      </div>
    </div>
  );
};
export default CreateNewPassword;

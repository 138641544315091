import { useState } from "react";
import { LoginRegisterResetPasswordContext } from "./LoginRegisterResetPasswordContext";

interface LoginRegisterResetPasswordContextProviderProps {
  children: React.ReactNode | null;
}

export const LoginRegisterResetPasswordContextProvider = (
  props: LoginRegisterResetPasswordContextProviderProps
) => {
  const [email, setEmail] = useState<string>("");
  const [languageId, setLanguageId] = useState<number>(1);
  const [resetPasswordCode, setResetPasswordCode] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [gender, setGender] = useState<number>(0);
  const [modalshow, setModalshow] = useState<boolean>(false);
  const [photo, setPhoto] = useState<string>("login");
  const [defaultKey, setDefaultKey] = useState<string>("");
  return (
    <LoginRegisterResetPasswordContext.Provider
      value={{
        email,
        setEmail,
        languageId,
        setLanguageId,
        resetPasswordCode,
        setResetPasswordCode,
        page,
        setPage,
        gender,
        setGender,
        modalshow,
        setModalshow,
        defaultKey,
        setDefaultKey,
        photo,
        setPhoto,
      }}
    >
      {props.children}
    </LoginRegisterResetPasswordContext.Provider>
  );
};

import { useEffect, useMemo, useState } from "react";
import { DjBasicInfo, getFavoritesDjs } from "../../../../api/Dj";
import FavoriteDjCard from "../../../components/ProfilePages/ProfileFavorites/FavoriteDjCard/FavoriteDjCard";
import NoFavorites from "../../../components/ProfilePages/ProfileFavorites/NoFavorites/NoFavorites";
import SkeletonFavoriteDjCard from "../../../components/ProfilePages/ProfileFavorites/SkeletonFavoriteDjCard/SkeletonFavoriteDjCard";
import "./_ProfileFavorites.scss";
import MuiPagination from "../../../../components/MuiPagination/MuiPagination";

const DjsFavorites = () => {
  const [djsFavoriteData, setDjsFavoriteData] = useState<DjBasicInfo[]>();
  const [page, setPage] = useState(1);
  const [pageNumber, setPageNumber] = useState<number | undefined>();
  const [loading, setLoading] = useState<boolean>(true);

  const params = useMemo(() => ({ pageNumber: page }), [page]);

  const getFavoritesDjsData = async () => {
    try {
      const response: any = await getFavoritesDjs(params);
      setDjsFavoriteData(response?.data);
      setPageNumber(response?.metaData?.pageCount);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getFavoritesDjsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const showSkeleton = (n: number) => {
    return [...Array(n)].map((e, i) => (
      <div key={`${2000 + i}`}>
        <SkeletonFavoriteDjCard />
      </div>
    ));
  };
  return (
    <>
      {djsFavoriteData?.length === 0 ? (
        <div className="profilefavorites-empty">
          <NoFavorites
            text={
              "Ajoutez des djs à vos favoris pour les retrouver facilement!"
            }
          />
        </div>
      ) : (
        <>
          {loading && showSkeleton(3)}
          <div>
            {djsFavoriteData?.map((dj) => (
              <FavoriteDjCard
                id={dj.id}
                name={dj.name}
                imagePath={dj.imagePath}
                rating={dj.rating}
                key={dj.id}
              />
            ))}
          </div>
          <div className="profileFavorites-pagination">
            <MuiPagination
              pagesNumber={pageNumber!}
              page={page}
              setPage={setPage}
            />
          </div>
        </>
      )}
    </>
  );
};

export default DjsFavorites;

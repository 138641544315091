import { gapi } from "gapi-script";
import { useEffect, useState } from "react";
import GoogleLogin from "react-google-login";
import { googleLogin } from "../../../../api/Api";
import { useAuthContext } from "../../../../context/AuthContext/AuthContext";
import "./_GoogleFacebook.scss";
import googleIcon from "../../../../assets/icons/googleIcon.png";
import { useLoginRegisterResetPasswordContext } from "../../../../context/LoginRegisterResetPasswordContext/LoginRegisterResetPasswordContext";

const LoginGoogle = () => {
  const authCtx = useAuthContext();
  const LRRPCtx = useLoginRegisterResetPasswordContext();
  const clientId =
    "854964941192-7hmr1l4pdcij3jvduuinkqc1f4v06lur.apps.googleusercontent.com";
  const [idToken, setIdToken] = useState<string>("");
  useEffect(() => {
    function start() {
      gapi.auth2.init({
        clientId: clientId,
        scope: "",
      });
    }
    gapi.load("client:auth2", start);
  });
  const getUser = async () => {
    try {
      const response: any = await googleLogin({
        provider: "Google",
        idToken: idToken,
        languageId: 1,
      });
      authCtx?.login(response);
      LRRPCtx.setModalshow(response?.isSuccess);
    } catch (err) {
      console.log(err);
    }
  };
  const handleSuccess = (result: any) => {
    setIdToken(result.tokenId);
    idToken !== "" && getUser();
  };
  const handleFailure = (result: any) => {
    console.log("Google SignIn failed! Errors:", result);
  };
  return (
    <GoogleLogin
      clientId={clientId}
      render={(renderProps) => (
        <button onClick={renderProps.onClick} className="googleStyle">
          <img src={googleIcon} alt="" />
        </button>
      )}
      className="googleStyle"
      onSuccess={handleSuccess}
      onFailure={handleFailure}
      cookiePolicy={"single_host_origin"}
      isSignedIn={true}
    />
  );
};
export default LoginGoogle;

import { FC } from "react";
import { Link } from "react-router-dom";
import { Nav } from "react-bootstrap";
import "./_Footer.scss";
import mainLogo from "../../../assets/icons/mainLogo.png";
import instagramLogo from "../../../assets/icons/instagramLogo.svg";
import facebookLogo from "../../../assets/icons/facebookLogo.svg";

interface FooterProps {}

const Footer: FC<FooterProps> = () => {
  return (
    <footer>
      <div className="bottomNav">
        <Nav>
          <Nav.Item className="nav-logo">
            <Link to="/">
              <img alt="" src={mainLogo}></img>
            </Link>
          </Nav.Item>
        </Nav>
        <Nav className="nav-links">
          <Nav.Item>
            <Link to="/terms-of-services" className="textColor">
              Conditions de services
            </Link>
          </Nav.Item>
          <Nav.Item>
            <Link to="/privacy-and-policy" className="textColor">
              Confidentialité et politique
            </Link>
          </Nav.Item>
          <Nav.Item>
            <Link to="/contact" className="textColor">
              Contact
            </Link>
          </Nav.Item>
        </Nav>
        <Nav className="nav-right-reserved">
          <p className=" nav-item textColor">
            @2021 7NIGHT tous droits réservés.
          </p>
          <div className="nav-item">
            <a href="https://www.instagram.com/7night_officiel/">
              <img src={instagramLogo} alt="" />
            </a>
            <a href="https://www.facebook.com/7Nightofficiel">
              <img src={facebookLogo} alt="" />
            </a>
          </div>
        </Nav>
      </div>
    </footer>
  );
};

export default Footer;

import { useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import "./_GoogleAutoFill.scss";

interface cityContryProps {
  city?: string;
  country?: string;
}
export function useGoogleAutoFill(defaultAddress?: string) {
  const [address, setAddress] = useState<string>("");
  const [cityContry, setCityContry] = useState<cityContryProps>();
  const [coordinates, setCoordinates] = useState<number | any>({
    lat: null,
    lng: null,
  });
  const handleSelect = async (value: string) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setAddress(value);
    setCoordinates(latLng);
    let city, country;
    for (let i = 0; i < results[0].address_components.length; i++) {
      for (let j = 0; j < results[0].address_components[i].types.length; j++) {
        switch (results[0].address_components[i].types[j]) {
          case "locality":
            city = results[0].address_components[i].long_name;
            break;
          case "country":
            country = results[0].address_components[i].long_name;
            break;
        }
      }
    }
    setCityContry({ city, country });
  };

  return {
    address,
    coordinates,
    cityContry,
    render: (
      <div className="googleautofill">
        <PlacesAutocomplete
          value={address}
          onChange={setAddress}
          onSelect={handleSelect}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div className="googleautofill-section">
              <input
                {...getInputProps({
                  placeholder: defaultAddress || "Où voulez-vous sortir ?",
                })}
              />
              <div className="googleautofill-section-results">
                {loading ? <div>Loading...</div> : null}
                {suggestions.map((suggestion) => {
                  const style = {
                    backgroundColor: suggestion.active
                      ? "rgb(233, 152, 225)"
                      : "#fff",
                  };
                  return (
                    <div
                      className="googleautofill-section-results-items"
                      {...getSuggestionItemProps(suggestion)}
                      key={suggestion.index}
                      style={style}
                    >
                      {suggestion.description}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      </div>
    ),
  };
}

import './_SkeletonFavoriteEventCard.scss';

const SkeletonFavoriteEventCard=()=>{
    return(
        <div className='skeletonfavoriteeventcard'>
        <div className='skeletonfavoriteeventcard-photo'></div>
        <div className='skeletonfavoriteeventcard-info'>
            <div className="h3"></div>
            <div className='skeletonfavoriteeventcard-info-section2'>
                <div className='skeletonfavoriteeventcard-info-section2-item'>
                </div>
                <div className='skeletonfavoriteeventcard-info-section2-item'>
                </div>
                <div className='skeletonfavoriteeventcard-info-section2-item'>
                </div>
            </div>
        </div>
    </div>
    )
}
export default SkeletonFavoriteEventCard;
import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Toaster } from "react-hot-toast";
import App from "./App";
import "./assets/fonts/Poppins/Poppins-Regular.ttf";
import "./styles/main.scss";
import { LoginRegisterResetPasswordContextProvider } from "./context/LoginRegisterResetPasswordContext/LoginRegisterResetPasswordContextProvider";
import { PaymentContextProvider } from "./context/PaymentContext/PaymentContextProvider";

// query client
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <LoginRegisterResetPasswordContextProvider>
      <QueryClientProvider client={queryClient}>
        <PaymentContextProvider>
          <App />
          <Toaster
            toastOptions={{
              position: "bottom-right",
              duration: 4000,
            }}
          />
          <ReactQueryDevtools initialIsOpen={false} />
        </PaymentContextProvider>
      </QueryClientProvider>
    </LoginRegisterResetPasswordContextProvider>
  </React.StrictMode>
);

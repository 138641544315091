import { createContext, useContext } from "react";

export interface PaymentContextType {
  productId?: number;
  setProductId: (value: number | undefined) => void;
  productPrice?: number;
  setProductPrice: (value: number | undefined) => void;
  clientSecretCode?: string;
  setClientSecretCode: (value: string) => void;
  isPaymentLoading: boolean;
  setIsPaymentLoading: (value: boolean) => void;
  isPaymentCardAdded: boolean;
  setIsPaymentCardAdded: (value: boolean) => void;
}
const PaymentContextValues: PaymentContextType = {
  productId: undefined,
  setProductId: () => {},
  productPrice: undefined,
  setProductPrice: () => {},
  clientSecretCode: undefined,
  setClientSecretCode: () => {},
  isPaymentLoading: false,
  setIsPaymentLoading: () => {},
  isPaymentCardAdded: false,
  setIsPaymentCardAdded: () => {},
};
export const PaymentContextContext =
  createContext<PaymentContextType>(PaymentContextValues);

export const usePaymentContextContext = () => useContext(PaymentContextContext);

import { useAuthContext } from "../../../../context/AuthContext/AuthContext";
import "./_Login.scss";
import * as yup from "yup";
import { useLoginRegisterResetPasswordContext } from "../../../../context/LoginRegisterResetPasswordContext/LoginRegisterResetPasswordContext";
import LoginGoogle from "../GoogleAppleFacebookLogin/GoogleLogin";
import LoginFacebook from "../GoogleAppleFacebookLogin/Facebook";
import { useState } from "react";
import { SignInPostModel } from "../../../../interfaces/models/postModels/SignInPost.model";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { signIn } from "../../../../react-query/mutations/loginRegister";

const schema = yup.object().shape({
  email: yup.string().email("Format d'email invalide").required("Obligatoire"),
  password: yup.string().required("Obligatoire"),
});

const Login = () => {
  const [error, setError] = useState<any>();
  const authCtx = useAuthContext();
  const LRRPCtx = useLoginRegisterResetPasswordContext();
  const [enis, setEnis] = useState<boolean>();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<SignInPostModel>({
    resolver: yupResolver(schema),
  });
  const handleSignIn = async (SignInInputs: SignInPostModel) => {
    try {
      const response: any = await signIn(SignInInputs);
      authCtx.login(response?.data);
      LRRPCtx.setModalshow(response?.data?.isSuccess);
    } catch (e: any) {
      setError(e.response?.data?.errors[0]?.code);
    }
  };
  return (
    <div className="login">
      <form onSubmit={handleSubmit(handleSignIn)}>
        <div className="inputs">
          <input
            placeholder="Email"
            type="email"
            id="email"
            {...register("email")}
          />
          {errors.email && <p className="error">{errors.email.message}</p>}
        </div>
        <div className="inputs">
          <input
            placeholder="Mot de passe"
            type="password"
            id="password"
            {...register("password")}
          />
          {errors.password && (
            <p className="error">{errors.password.message}</p>
          )}
        </div>
        <button
          className="forgot-password"
          type="button"
          onClick={() => {
            LRRPCtx.setPage(2);
          }}
        >
          Mot de passe oublié?
        </button>
        <button disabled={isSubmitting} type="submit" className="login-btn">
          {isSubmitting ? "Connexion..." : "Connexion"}
        </button>
      </form>
      {!!error && error === "invalid_credentials" && (
        <p
          style={{
            color: "red",
            fontSize: "14px",
            padding: "0",
            paddingTop: "8px",
          }}
        >
          Votre e-mail ou votre mot de passe est incorrect!
        </p>
      )}
      <p>Ou</p>
      <div className="login-icons">
        <LoginGoogle />
        <LoginFacebook />
      </div>
    </div>
  );
};

export default Login;

import { PAGE_SIZE } from "../../config/app";
import axios from "../../initializations/axios";

export const getWalletBalance = async () => {
  const { data } = await axios.get("Wallet/my");
  return data;
};

export const getWalletHistory = async (
  userId?: string,
  pageNumber?: number
) => {
  const { data } = await axios.get(
    `Wallet/purchase/${userId}?pageSize=${PAGE_SIZE}&pageNumber=${pageNumber}`
  );
  return data;
};

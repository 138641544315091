import { FC, ReactElement } from "react";
import { MdEvent, MdPerson } from "react-icons/md";
import { VscGlobe } from "react-icons/vsc";
import { ManagerDashboardS2Props } from "./ManagerDashboardS2.props";

const ManagerDashboardS2: FC<ManagerDashboardS2Props> = ({
  data: { numberOfBusinesses, numberOfClients, numberOfEvents } = {},
}): ReactElement => {
  return (
    <div className="dashboards2">
      <h5 className="dashboards2-title">Informations Généraless</h5>
      <div className="dashboards2-item">
        <VscGlobe
          color="purple"
          size={65}
          className="tw-bg-white tw-rounded-full tw-p-2"
        />
        <div className="dashboards2-item-text">
          <h4 className="tw-font-bold tw-leading-3">{numberOfBusinesses}</h4>
          <h5 className="tw-text-sm">Nombre de clubs</h5>
        </div>
      </div>
      <div className="dashboards2-item">
        <MdPerson
          color="purple"
          size={65}
          className="tw-bg-white tw-rounded-full tw-p-2"
        />
        <div className="dashboards2-item-text">
          <h4 className="tw-font-bold">{numberOfClients}</h4>
          <h5 className="tw-text-sm">Clients enregistrés</h5>
        </div>
      </div>
      <div className="dashboards2-item">
        <MdEvent
          color="purple"
          size={65}
          className="tw-bg-white tw-rounded-full tw-p-2"
        />
        <div className="dashboards2-item-text">
          <h4 className="tw-font-bold">{numberOfEvents}</h4>
          <h5 className="tw-text-sm">Nombre d’évènements</h5>
        </div>
      </div>
    </div>
  );
};
export default ManagerDashboardS2;

import { useState } from "react";
import { PaymentContextContext } from "./PaymentContext";

interface PaymentContextProviderProps {
  children: React.ReactNode | null;
}

export const PaymentContextProvider = (props: PaymentContextProviderProps) => {
  const [productId, setProductId] = useState<number>();
  const [productPrice, setProductPrice] = useState<number>();
  const [clientSecretCode, setClientSecretCode] = useState<string>();
  const [isPaymentLoading, setIsPaymentLoading] = useState<boolean>(false);
  const [isPaymentCardAdded, setIsPaymentCardAdded] = useState(false);
  return (
    <PaymentContextContext.Provider
      value={{
        productId,
        setProductId,
        productPrice,
        setProductPrice,
        clientSecretCode,
        setClientSecretCode,
        isPaymentLoading,
        setIsPaymentLoading,
        isPaymentCardAdded,
        setIsPaymentCardAdded,
      }}
    >
      {props.children}
    </PaymentContextContext.Provider>
  );
};

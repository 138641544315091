import { FC, useState } from "react";
import Photo from "../../../modals/Photo/Photo";
import "./_Photos.scss";

interface PhotosProps {
  photos?: string[];
}

const Photos: FC<PhotosProps> = ({ photos }) => {
  const [modalShow, setModalShow] = useState(false);
  const [photoOpened, setPhotoOpened] = useState<number>();
  const photosArray: string[] = photos || [];
  return (
    <div className="photos">
      <h2>Photos</h2>
      <div className="photos-items">
        {photosArray?.map((photo, index) => {
          return (
            <div
              className="photo"
              key={index}
              onClick={() => {
                setModalShow(true);
                setPhotoOpened(index);
              }}
            >
              <img src={photo} alt="" />
            </div>
          );
        })}
      </div>
      <Photo
        firstItem={photoOpened === 0}
        lastItem={photoOpened === photosArray.length - 1}
        setPhotoOpened={setPhotoOpened}
        photoSrc={photosArray[photoOpened as keyof typeof photoOpened]}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </div>
  );
};

export default Photos;

import Joi from "joi";

export const SendNotificationSchema = Joi.object({
  title: Joi.string().required().messages({
    "string.empty": "Title must not be empty",
  }),
  message: Joi.string().required().messages({
    "string.empty": "Message must not be empty",
  }),
});

import closeButton from "../../../assets/icons/closeButton.svg";
import { Modal } from "react-bootstrap";
// @ts-ignore
import ReactStars from "react-rating-stars-component";
import "../_modalStyle.scss";
import "./_NightclubFilter.scss";
import searchIconOff from "../../../assets/icons/searchIconOff.svg";
import locationIconOff from "../../../assets/icons/locationIconOff.svg";
import { useEffect, useState } from "react";
import {
  GetBusinessInput,
  getBusinessTypes,
  GetBusinessTypesResponse,
} from "../../../api/Api";
import { useGoogleAutoFill } from "../../../components/GoogleAutoFill/GoogleAutoFill";
import { useAuthContext } from "../../../context/AuthContext/AuthContext";

function useNightclubFilter(props: any) {
  const [secondSearch, setSecondSearch] = useState<GetBusinessInput>({});
  const [morefilterInputs, setMoreFilterInputs] = useState<GetBusinessInput>({
    radius: 10,
  } as GetBusinessInput);
  const [businessTypesData, setBusinessTypesData] =
    useState<GetBusinessTypesResponse>();
  const authCtx = useAuthContext();
  const { address, render: googleAutoFill } = useGoogleAutoFill("Localisation");
  const onChangeValuePatio = (e: any) => {
    e.target.value === "yes"
      ? setMoreFilterInputs({ ...morefilterInputs, patio: true })
      : setMoreFilterInputs({ ...morefilterInputs, patio: false });
  };

  const onChangeValueRestaurant = (e: any) => {
    e.target.value === "yes"
      ? setMoreFilterInputs({ ...morefilterInputs, hasRestaurant: true })
      : setMoreFilterInputs({ ...morefilterInputs, hasRestaurant: false });
  };

  const BusinessTypes = async () => {
    try {
      const data = await getBusinessTypes();
      setBusinessTypesData(data as GetBusinessTypesResponse);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    BusinessTypes();
  }, []);

  useEffect(() => {
    setMoreFilterInputs({ ...morefilterInputs, location: address });
  }, [address]);

  const thirdExample = {
    count: 5,
    isHalf: false,
    value: 4,

    color: "rgb(213, 213, 213)",
    activeColor: "rgb(147, 45, 133)",
    onChange: (newValue: number) => {
      setMoreFilterInputs({ ...morefilterInputs, minimumRating: newValue });
    },
  };

  return {
    secondSearch,
    render: (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="nightclubmorefilter">
            <div className="nightclubmorefilter-part1">
              <div className="closeButton">
                <img src={closeButton} alt="" onClick={() => props.onHide()} />
              </div>
              <div className="title">
                <h3>Filters</h3>
              </div>
            </div>
            <div className="nightclubmorefilter-part2">
              <div className="nightclubmorefilter-part2-1">
                <div className="grid-item">
                  <label htmlFor="date">Jour de la semaine</label>
                  <div className="input">
                    <select
                      onChange={(e) =>
                        setMoreFilterInputs({
                          ...morefilterInputs,
                          dayOfWeek: parseInt(e.target.value),
                        })
                      }
                    >
                      <option value={1}>Lundi</option>
                      <option value={2}>Mardi</option>
                      <option value={3}>Mercredi</option>
                      <option value={4}>Jeudi</option>
                      <option value={5}>Friday</option>
                      <option value={6}>Vendredi</option>
                      <option value={7}>Dimanche</option>
                    </select>
                  </div>
                </div>
                <div className="grid-item">
                  <label htmlFor="search">Nom de la soirée</label>
                  <div className="input">
                    <img src={searchIconOff} alt="" />
                    <input
                      type="search"
                      value={morefilterInputs.searchWord || ""}
                      onChange={(e) =>
                        setMoreFilterInputs({
                          ...morefilterInputs,
                          searchWord: e.target.value,
                        })
                      }
                      placeholder="Recherche une soirée"
                    />
                  </div>
                </div>
                <div className="grid-item">
                  <label htmlFor="loaction">Où</label>
                  <div className="input googleAutofill">
                    <img
                      src={locationIconOff}
                      alt=""
                      className="googleAutofill"
                    />
                    {googleAutoFill}
                  </div>
                </div>
              </div>

              <div className="nightclubmorefilter-part2-2">
                <div className="input-1">
                  <div className="range-input-title">
                    <label htmlFor="rayon">Dans un rayon autour de </label>
                    <span>{`${
                      morefilterInputs?.radius === undefined
                        ? 50
                        : morefilterInputs?.radius
                    } km`}</span>
                  </div>
                  <div className="range-input">
                    <input
                      type="range"
                      min="5"
                      max="100"
                      disabled={
                        authCtx?.location?.latitude === undefined &&
                        authCtx?.location?.longitude === undefined
                      }
                      value={morefilterInputs?.radius || 100}
                      onChange={(e) =>
                        setMoreFilterInputs({
                          ...morefilterInputs,
                          radius: parseInt(e.target.value),
                        })
                      }
                    />
                  </div>
                </div>
                <div className="input-2">
                  <label htmlFor="">Type</label>
                  <div className="inputs">
                    {businessTypesData?.data?.map((type) => {
                      return (
                        <div className="inputs-input" key={type.id}>
                          <label htmlFor={`${type.name}-${type.id}`}>
                            <input
                              type="radio"
                              id={`${type.name}-${type.id}`}
                              name="bussinesType"
                              onChange={(e) => {
                                if (e.target.checked === true) {
                                  setMoreFilterInputs({
                                    ...morefilterInputs,
                                    businessType: type.id,
                                  });
                                } else if (e.target.checked === false) {
                                  setMoreFilterInputs({
                                    ...morefilterInputs,
                                    businessType: 0,
                                  });
                                }
                              }}
                            />
                            <span>{type.name}</span>
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              <div className="nightclubmorefilter-part2-3">
                <div className="grid-item" onChange={onChangeValuePatio}>
                  <h3>Patio</h3>
                  <div className="input">
                    <input
                      type="radio"
                      id="avecPatio"
                      name="patio"
                      value="yes"
                    />
                    <label htmlFor="avecPatio">Avec</label>
                  </div>
                  <div className="input">
                    <input
                      type="radio"
                      id="sansPatio"
                      name="patio"
                      value="no"
                    />
                    <label htmlFor="sansPatio">Sans</label>
                  </div>
                </div>
                <div className="grid-item" onChange={onChangeValueRestaurant}>
                  <h3>Restaurant</h3>
                  <div className="input">
                    <input
                      type="radio"
                      id="avecRestaurant"
                      name="restaurant"
                      value="yes"
                    />
                    <label htmlFor="avecRestaurant">Avec</label>
                  </div>
                  <div className="input">
                    <input
                      type="radio"
                      id="sansRestaurant"
                      name="restaurant"
                      value="no"
                    />
                    <label htmlFor="sansRestaurant">Sans</label>
                  </div>
                </div>
                <div className="grid-item">
                  <h3>Note minimum du club</h3>
                  <ReactStars {...thirdExample} size={55} />
                </div>
              </div>
              <div className="nightclubmorefilter-part2-4">
                <button onClick={() => props.onHide()}>Annuler</button>
                <button
                  onClick={() => {
                    setSecondSearch(morefilterInputs);
                    props.onHide();
                  }}
                >
                  Rechercher
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    ),
  };
}

export default useNightclubFilter;

import { useState } from "react";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import { useLoginRegisterResetPasswordContext } from "../../../context/LoginRegisterResetPasswordContext/LoginRegisterResetPasswordContext";
import { useSubscriptionPackets } from "../../../react-query/hooks/Subscription";
import Pack from "../../components/shared/SubscriptionPack/Pack";
import LoginRegisterResetPassword from "../../modals/LoginRegisterResetPassword/LoginRegisterResetPassword";
import ClubsAlreadyIn from "../../page-sections/shared/ClubsAlreadyIn/ClubsAlreadyIn";
import "./_ClubSpaceOffersAndRates.scss";

const ClubSpaceOffersAndRates = () => {
  const [modalShow, setModalShow] = useState(false);
  const LRRPCtx = useLoginRegisterResetPasswordContext();
  const { data: { data } = {}, isLoading } = useSubscriptionPackets();
  const prices = data?.map((pack) => {
    return pack?.pricing[0].price;
  });
  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="clubspaceoffersandrates">
          <div className="clubspaceoffersandrates-section1 ">
            <div className="clubspaceoffersandrates-section1-tryit container">
              <button
                onClick={() => {
                  setModalShow(true);
                  LRRPCtx.setDefaultKey("register");
                }}
              >
                ESSAYER MAINTENANT
              </button>
              <h3>
                Des offres flexibles qui s’adaptent aux besoins de votre
                activité.
              </h3>
            </div>
            <div className="clubspaceoffersandrates-section1-packs container">
              {data?.map((packet) => {
                return (
                  <Pack
                    linkToManagerProfile={true}
                    name={packet?.name}
                    price={packet?.pricing[0]?.price}
                    trialDays={packet?.pricing[0]?.trialDays}
                    id={packet?.id}
                    key={packet?.id}
                    prices={prices}
                  />
                );
              })}
            </div>
          </div>
          <div className="clubspaceoffersandrates-section2 container">
            <p>
              Facturation à l’utilisation : commission facturée à 1,49 € par
              entrée validée ou 0,99 € si vous cumulez plus de 50 entrées sur un
              événement.
              <br />
              Aucunes commissions les 2 premiers mois !
            </p>
            <h3>
              Essayez maintenant: c’est simple, gratuit les 2 premiers mois et
              sans engagement de durée !
            </h3>
          </div>
          <div className="clubspaceoffersandrates-section3">
            <ClubsAlreadyIn />
          </div>
          <LoginRegisterResetPassword
            show={modalShow}
            onHide={() => setModalShow(false)}
          />
        </div>
      )}
    </>
  );
};

export default ClubSpaceOffersAndRates;

import { FC, ReactElement, useState } from "react";
import edit from "../../../../assets/icons/edit.png";
import EditConstant from "../../../modals/EditConstant";
import { ConstantsConfiguartionInfoProps } from "./ConstantsConfiguartionInfo.props";
import { ButtonBase } from "@mui/material";

const ConstantsConfiguartionInfo: FC<ConstantsConfiguartionInfoProps> = ({
  constant,
}): ReactElement => {
  // local state
  const [modalShow, setModalShow] = useState(false);

  return (
    <tr>
      <td>
        <p>{constant.name}</p>
      </td>
      <td>
        <p>{constant.value}</p>
      </td>
      <td className="actionColumn">
        <ButtonBase onClick={() => setModalShow(true)}>
          <img src={edit} alt="" />
        </ButtonBase>
      </td>
      <EditConstant
        show={modalShow}
        constant={constant}
        onHide={() => setModalShow(false)}
      />
    </tr>
  );
};
export default ConstantsConfiguartionInfo;

import "./_ClubsStyle.scss";
import { ReactElement, useEffect, useState } from "react";
import searchIcon from "../../../assets/icons/searchIconOff.svg";
import ClubCard from "../../components/Molecules/Card/ClubCard";
import { usePostClubsQuery } from "../../../react-query/hooks/Clubs";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import { ClubResponseModel } from "../../../interfaces/models/getModels/ClubResponse.model";
import { Link } from "react-router-dom";
import MuiPagination from "../../../components/MuiPagination/MuiPagination";
import { NoResults } from "../../components/Organisms/NoResults/NoResults";

const Clubs = (): ReactElement => {
  const [searchWord, setSearchWord] = useState<string>("");
  const {
    mutate: searchClubs,
    data: { data } = {},
    isLoading,
  } = usePostClubsQuery();
  const [page, setPage] = useState(1);
  useEffect(() => {
    handleSearchClubs(searchWord);
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const handleSearchClubs = (searchWord: string) => {
    searchClubs({
      searchWord: searchWord,
      pageNumber: page,
      pageSize: 6,
    });
  };

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="dashboardClubs">
          <div className="dashboardTitle">
            <h3>Mes clubs</h3>
          </div>
          <div className="dashboardClubs-filter dashboardFilter">
            <div className="dashboardClubs-filter-1 searchInput">
              <img src={searchIcon} alt="" />
              <input
                type="text"
                placeholder="Rechercher"
                value={searchWord}
                onChange={(e) => setSearchWord(e.target.value)}
              />
            </div>
            <div className="dashboardClubs-filter-2 button">
              <button
                className="searchButton"
                onClick={() => {
                  handleSearchClubs(searchWord);
                  setPage(1);
                }}
              >
                Rechercher
              </button>
            </div>

            <div className="dashboardClubs-filter-3 dashboardClubs-filter-button button">
              <Link to="add">
                <button>+ Ajouter</button>
              </Link>
            </div>
          </div>
          {data?.data?.length !== 0 ? (
            <div className="dashboardResults">
              <div className="dashboardCards">
                {data?.data?.map((club: ClubResponseModel) => {
                  return (
                    <ClubCard
                      name={club.name}
                      key={club.id}
                      id={club.id}
                      address={club.address}
                      banner={club.banner}
                    />
                  );
                })}
              </div>
              <div className="dashboardPagination">
                <MuiPagination
                  pagesNumber={data?.metaData?.pageCount}
                  page={page}
                  setPage={setPage}
                />
              </div>
            </div>
          ) : (
            <NoResults text="Dans cet onglet, vous pouvez ajouter votre club" />
          )}
        </div>
      )}
    </>
  );
};
export default Clubs;

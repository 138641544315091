import {FC} from 'react';
import './_Description.scss';

interface DescriptionProps {
  description?:string
}

 const Description: FC<DescriptionProps>=({description})=> {
  return (
    <div className='description'>
    <h4>La description</h4>
    <p>{description}</p>
    </div>
  );
}

export default Description;
import { useState } from "react";
import { Outlet } from "react-router-dom";
import LoginRegisterResetPassword from "../../modals/LoginRegisterResetPassword/LoginRegisterResetPassword";
import ProfileNavbar from "../../page-sections/ProfilePages/ProfileNavbar/ProfileNavbar";
import { useAuthContext } from "../../../context/AuthContext/AuthContext";
import "./_ProfileSharedLayout.scss";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";

const ProfileSharedLayout = () => {
  const authCtx = useAuthContext();
  const [modalShow, setModalShow] = useState(true);
  if (authCtx.isLoading)
    return (
      <div className="userProfileloading">
        <LoadingSpinner />
      </div>
    );
  return (
    <div>
      {authCtx.isAuthenticated ? (
        <div className="profile container">
          <div className="profile-dashboard">
            <ProfileNavbar />
          </div>
          <div className="profile-outlet">
            <Outlet />
          </div>
        </div>
      ) : (
        <div className="profile-noAuth">
          {
            <LoginRegisterResetPassword
              show={modalShow}
              onHide={() => setModalShow(false)}
            />
          }
        </div>
      )}
    </div>
  );
};

export default ProfileSharedLayout;

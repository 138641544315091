import { ReactElement } from "react";
import { Modal } from "react-bootstrap";
import { useReactivateSubscription } from "../../react-query/hooks/Subscription";
import "./_paymentModalsStyle.scss";

const ReactivateSubscription = (props: any): ReactElement => {
  const {
    mutate: reactivateSubscription,
    data: { data: reactivateSubscriptionResponse } = {},
    isLoading: isReactivateSubscriptionLoading,
    reset,
  } = useReactivateSubscription();

  const reactivateSubscriptionPack = () => {
    reactivateSubscription(props.subscriptionId);
    setTimeout(() => {
      reset();
      props.onHide();
    }, 3000);
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="paymentModal"
      onHide={() => {
        reset();
        props.onHide();
      }}
    >
      <Modal.Body>
        <div className="modalBodyDowngrade">
          <h3 className="modalBodyDowngrade-title">Êtes-vous sûr?</h3>
          <div className="modalBodyDowngrade-buttons">
            <button
              onClick={() => props.onHide()}
              className="no"
              disabled={
                isReactivateSubscriptionLoading ||
                reactivateSubscriptionResponse?.isSuccess
              }
            >
              Non
            </button>
            <button
              onClick={reactivateSubscriptionPack}
              className="yes"
              disabled={
                isReactivateSubscriptionLoading ||
                reactivateSubscriptionResponse?.isSuccess
              }
            >
              Oui
            </button>
          </div>
          {isReactivateSubscriptionLoading && (
            <p className="les">Chargement...</p>
          )}
          {reactivateSubscriptionResponse?.isSuccess && (
            <p className="les">
              {reactivateSubscriptionResponse?.successMessage}
            </p>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default ReactivateSubscription;

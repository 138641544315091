import { useState } from "react";
import "./_Evening.scss";
import "./_EveningName.scss";
import Description from "../../components/shared/Description/Description";
import Photos from "../../components/shared/Photos/Photos";
import EveningBasicInfo from "../../components/Evening/EveningBasicInfo/EveningBasicInfo";
import { useParams } from "react-router-dom";
import { deleteFavoriteEvent, postFavoriteEvent } from "../../../api/Events";
import GoogleMapComponent from "../../../components/GoogleMapComponent/GoogleMapComponent";
import Reservation from "../../modals/Reservation/Reservation";
import favoriteIcon from "../../../assets/icons/heart.webp";
import NoPhotos from "../../components/shared/NoPhotos/NoPhotos";
import { useAuthContext } from "../../../context/AuthContext/AuthContext";
import { useEventQuery } from "../../../react-query/hooks/Events";

const Evening = () => {
  const [modalShow, setModalShow] = useState(false);
  const { isAuthenticated } = useAuthContext();
  let { eveningId } = useParams<{ eveningId?: string }>();
  let id = parseInt(eveningId!);
  const { data: { data: eveningData } = {}, refetch } = useEventQuery(id);
  const addFavoriteEvent = async () => {
    try {
      await postFavoriteEvent({ id });
      refetch();
    } catch (e) {
      console.log(e);
    }
  };
  const removeFavoriteEvent = async () => {
    try {
      await deleteFavoriteEvent({ id });
      refetch();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="evening">
      <div className="evening-mainPhoto">
        <div
          className="evening-mainPhoto-underCover"
          style={{
            backgroundImage: `url(${eveningData?.banner})`,
          }}
        ></div>
        <img src={eveningData?.banner} alt="" />
      </div>

      <div className="evening-content container">
        <div className="evening-content-section1">
          <span>
            Accueil <span>{">"}</span> Évènements <span>{">"}</span>{" "}
            <span className="name">{eveningData?.title}</span>
          </span>
        </div>
        <div className="evening-content-section2">
          <div className="informations">
            <div className="informations-part1">
              <div className="eveningname">
                <div className="eveningname-part1">
                  <div className="eveningname-start">
                    <h3>{eveningData?.title}</h3>
                  </div>
                  <div className="eveningname-end">
                    <button
                      className="btn"
                      type="button"
                      onClick={() => setModalShow(true)}
                    >
                      Réserver
                    </button>
                    {isAuthenticated && (
                      <img
                        src={favoriteIcon}
                        alt=""
                        onClick={() => {
                          !eveningData?.isFavorite && addFavoriteEvent();
                          eveningData?.isFavorite && removeFavoriteEvent();
                        }}
                        className={`${
                          eveningData?.isFavorite ? "favorite" : "unfavorite"
                        }`}
                      />
                    )}
                  </div>

                  <Reservation
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                  />
                </div>
                <div className="badges">
                  {eveningData?.isAlmostFull && (
                    <p className="badges-red">BIENTÔT COMPLET</p>
                  )}
                  {eveningData?.isOpened && (
                    <p className="badges-green">OUVERT AUJOURD'HUI</p>
                  )}
                  {eveningData?.isFreeForGirls && (
                    <p className="badges-blue">ENTRÉE GRATUITE FILLE</p>
                  )}
                  {eveningData?.hasShowcase && (
                    <p className="badges-pink">SHOWCASE</p>
                  )}
                  {eveningData?.isFreeForBoys && (
                    <p className="badges-blue2">ENTRÉE GRATUITE GARÇON</p>
                  )}
                </div>
              </div>
            </div>
            <div className="informations-part2">
              <div className="eventBasicInfo">
                <EveningBasicInfo
                  eveningClubId={eveningData?.businessId}
                  eveningClub={eveningData?.businessName}
                  eveningDj={eveningData?.djName}
                  eveningDjId={eveningData?.djId}
                  eveningDate={eveningData?.date}
                  eveningAddress={eveningData?.address}
                  eveningEnterior={eveningData?.enterior}
                  eveningExterior={eveningData?.exterior}
                  eveningRestaurant={eveningData?.hasRestaurant}
                />
              </div>
              <div className="eventDescription">
                <Description description={eveningData?.description} />
              </div>
            </div>
            {eveningData?.gallery.length === 0 ? (
              <div className="nophotos">
                <NoPhotos />
              </div>
            ) : (
              <div className="informations-part3">
                <Photos photos={eveningData?.gallery} />
              </div>
            )}
          </div>
          <div className="map">
            <GoogleMapComponent
              marksObject={[
                {
                  lat: eveningData?.latitude as number,
                  lng: eveningData?.longitude as number,
                },
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Evening;
